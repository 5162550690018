import { attr, belongsTo } from '@ember-data/model';
import { memberAction } from 'ember-api-actions';

import GenericAttachment from 'later/models/generic-attachment';

import type LinkinbioBlockModel from 'later/models/linkinbio-block';

export interface BulkItemAttachment {
  contentType: string;
  key: string;
  processingBucket: string;
  item_id: string;
}

export default class LinkinbioAttachmentModel extends GenericAttachment {
  @belongsTo('linkinbio-block') declare linkinbioBlock: LinkinbioBlockModel;
  @attr({ defaultValue: false }) declare isBulkAttachments: boolean;
  @attr() declare bulkAttachments: BulkItemAttachment[];

  bulkUpdate = memberAction<void, void>({ path: 'bulk_update' });
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'linkinbio-attachment': LinkinbioAttachmentModel;
  }
}
