import Model, { attr, belongsTo } from '@ember-data/model';

import type ContentPlanModel from 'later/models/content-plan';

export default class ContentSuggestionModel extends Model {
  @belongsTo('contentPlan') declare contentPlan: ContentPlanModel;
  @attr('string') declare caption: string | null;
  @attr('string') declare idea: string | null;
  @attr('string') declare suggestedMedia: string | null;
  @attr('number') declare createdAt: number;
  @attr('number') declare updatedAt: number;
}

export interface RawContentSuggestion {
  id: number;
  caption: string;
  idea: string;
  suggested_media: string;
  content_plan_id: number;
  created_at: number;
  updated_at: number;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'content-suggestion': ContentSuggestionModel;
  }
}
