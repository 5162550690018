import { MimeType } from 'shared/types/image';

import type IntlService from 'ember-intl/services/intl';
import type AccountModel from 'later/models/account';
import type { FeatureObject } from 'shared/types/feature-drawer';

export enum FeatureNames {
  MediaKit = 'media_kit',
  Campaigns = 'campaigns',
  Bttp = 'bttp',
  Schedule = 'schedule'
}

enum SchedulingFeatureNames {
  Schedule = 'scheduling_main',
  Hashtag = 'hashtag_suggestions',
  Captions = 'saved_captions',
  Linkinbio = 'linkinbio'
}

const features = (intl: IntlService, currentAccount: AccountModel): FeatureObject[] => [
  {
    name: FeatureNames.MediaKit,
    title: intl.t('mobile.homepage.features.media_kit.title'),
    subtitle: intl.t('mobile.homepage.features.media_kit.subtitle'),
    icon: 'media-kit',
    image: 'assets/images/img--mob--feat--mediakit.jpg',
    platforms: ['desktop'],
    needsUpgrade: !currentAccount?.canShareMediaKit,
    featureUpgradeName: 'mediaKit'
  },
  {
    name: FeatureNames.Campaigns,
    title: intl.t('mobile.homepage.features.campaigns.title'),
    subtitle: intl.t('mobile.homepage.features.campaigns.subtitle'),
    icon: 'campaign-find',
    image: 'assets/images/img--mob--feat--campaigns.jpg',
    platforms: ['desktop'],
    needsUpgrade: !currentAccount?.canCampaignBoard,
    featureUpgradeName: 'campaignBoard'
  },
  {
    name: FeatureNames.Bttp,
    title: intl.t('mobile.homepage.features.bttp.title'),
    subtitle: intl.t('mobile.homepage.features.bttp.subtitle'),
    icon: 'bttp',
    image: 'assets/images/img--mob--feat--bttp.jpg',
    changePlatformText: intl.t('mobile.homepage.features.bttp.cta'),
    platforms: ['desktop', 'mobile'],
    needsUpgrade: !currentAccount?.canBttp,
    featureUpgradeName: 'bttp'
  },
  {
    name: FeatureNames.Schedule,
    title: intl.t('mobile.homepage.features.schedule.title'),
    subtitle: intl.t('mobile.homepage.features.schedule.subtitle'),
    icon: 'calendar',
    cards: [
      {
        name: SchedulingFeatureNames.Schedule,
        title: intl.t('mobile.homepage.features.schedule.cards.schedule.header'),
        sources: [
          { src: 'assets/images/mov--schedule_a_post.avif', type: MimeType.Avif },
          { src: 'assets/images/mov--mob--schedule_a_post.webp', type: MimeType.Webp }
        ],
        alt: intl.t('mobile.homepage.features.schedule.cards.schedule.caption'),
        needsUpgrade: false
      },
      {
        name: SchedulingFeatureNames.Hashtag,
        title: intl.t('mobile.homepage.features.schedule.cards.hashtag.header'),
        sources: [
          { src: 'assets/images/mov--hashtags_suggestions.avif', type: MimeType.Avif },
          { src: 'assets/images/mov--mob--hashtags_suggestions.webp', type: MimeType.Webp }
        ],
        alt: intl.t('mobile.homepage.features.schedule.cards.hashtag.caption'),
        needsUpgrade: !currentAccount?.canSmartHashtags,
        featureUpgradeName: 'searchHashtags'
      },
      {
        name: SchedulingFeatureNames.Captions,
        title: intl.t('mobile.homepage.features.schedule.cards.captions.header'),
        sources: [
          { src: 'assets/images/mov--saved_captions.avif', type: MimeType.Avif },
          { src: 'assets/images/mov--mob--saved_captions.webp', type: MimeType.Webp }
        ],
        alt: intl.t('mobile.homepage.features.schedule.cards.captions.caption'),
        needsUpgrade: false
      },
      {
        name: SchedulingFeatureNames.Linkinbio,
        title: intl.t('mobile.homepage.features.schedule.cards.linkinbio.header'),
        sources: [
          { src: 'assets/images/mov--linkinbio.avif', type: MimeType.Avif },
          { src: 'assets/images/mov--mob--linkinbio.webp', type: MimeType.Webp }
        ],
        alt: intl.t('mobile.homepage.features.schedule.cards.linkinbio.caption'),
        needsUpgrade: false
      }
    ],
    changePlatformText: intl.t('mobile.homepage.features.schedule.cta'),
    platforms: ['desktop', 'mobile'],
    needsUpgrade: false
  }
];

export default features;
