import moment from 'moment';

import { TEXT_POST_TYPE } from 'later/utils/constants';
import { convert, timestamp } from 'later/utils/time-format';

import type { SearchQuery } from './search-query';
import type GroupModel from 'later/models/group';
import type SocialProfileModel from 'later/models/social-profile';
import type AuthService from 'later/services/auth';
import type GlobalSearchService from 'later/services/global-search';
import type { FeatureMap } from 'shared/utils/plans-data/feature-upgrade';

export interface SearchIntent {
  id: string;
  suggested: boolean;
  getTitle(context: GlobalSearchService, meta: IntentMeta): string;
  getDescription(context: GlobalSearchService, meta: IntentMeta): string;
  getIcon(context: GlobalSearchService, meta: IntentMeta): string;
  action(context: GlobalSearchService, meta: IntentMeta): void;
  searchTerms: string[];
  mandatoryTerms?: string[];
}

export interface IntentMeta {
  query: SearchQuery;
  intent: SearchIntent;
}

function getProfile(context: GlobalSearchService, meta: IntentMeta): SocialProfileModel {
  const nickname = meta.query.tokens.mentions[0];

  if (!nickname) {
    return context.auth.currentSocialProfile;
  }

  const profile = context.store.peekAll('social-profile').find((profile) => {
    return profile.nickname?.startsWith(nickname);
  });

  if (!profile) {
    return context.auth.currentSocialProfile;
  }

  return profile;
}

/**
 * Helper function to get the current group from the auth service.
 * This is a workaround for the fact that there may not be a current group.
 * This typically is only an issue if the page is loaded when the user is in the
 *  settings page.
 */
function getGroup(auth: AuthService): GroupModel {
  if (auth.currentGroup) {
    return auth.currentGroup;
  }

  if (auth.lastGroup) {
    return auth.lastGroup;
  }

  const groups = auth.groups as GroupModel[];
  if (groups.length > 0) {
    return groups.firstObject as GroupModel;
  }

  // Note: throwing an error here can cause the app to not load
  // when opening a cached version of the app

  // throw new Error('No group found');
  console.log('No group found');
  return auth.currentGroup;
}

function bold(str: string): string {
  return `<strong>${str}</strong>`;
}

export const SEARCH_INTENTS: SearchIntent[] = [
  {
    id: 'navigate-to-conversations',
    getTitle() {
      return `Check Your Conversations`;
    },
    suggested: true,
    getDescription(context, meta) {
      const profile = getProfile(context, meta);
      const displayName = profile?.displayName;

      const searchQuery = meta.query.tokens.text[0];
      if (searchQuery) {
        return `Search for "${bold(searchQuery)}" in ${bold(displayName)}'s conversations`;
      }
      return `View recent conversations for ${bold(displayName)}`;
    },
    getIcon() {
      return 'conversations';
    },
    action(context, meta) {
      const profile = getProfile(context, meta);

      context.router.transitionTo('cluster.conversations.profile', profile.urlName || '', {
        queryParams: { social_profile_slug: profile?.get('group')?.get('slug') }
      });
    },
    searchTerms: ['navigate', 'go to', 'conversations', 'comments', 'reply', 'replies', 'messages', 'chats']
  },
  {
    id: 'navigate-to-analytics',
    suggested: false,
    getTitle() {
      return `See Your Analytics`;
    },
    getDescription(context, meta) {
      const profile = getProfile(context, meta);
      const displayName = profile?.displayName;

      return `Navigate to analytics for ${bold(displayName)}?`;
    },
    getIcon() {
      return 'insights';
    },
    action(context, meta) {
      const profile = getProfile(context, meta);

      context.router.transitionTo('cluster.analytics.profile', profile.urlName || '', {
        queryParams: { slug: profile?.get('group')?.get('slug') }
      });
    },
    searchTerms: ['navigate', 'go to', 'analytics', 'stats', 'charts', 'graphs']
  },
  {
    id: 'navigate-to-schedule',
    suggested: false,
    getTitle() {
      return `View Your Schedule`;
    },
    getDescription(context) {
      return `Navigate to your content calendar for the ${bold(getGroup(context.auth)?.name)} group`;
    },
    getIcon(): string {
      return 'calendar';
    },
    action(context) {
      const groupSlug = getGroup(context.auth).slug;

      context.router.transitionTo('cluster.schedule', groupSlug);
    },
    searchTerms: ['navigate', 'go to', 'calendar', 'schedule', 'post', 'gram', 'plan']
  },
  {
    id: 'navigate-to-billing-info',
    suggested: false,
    getTitle() {
      return `View Your Billing Info`;
    },
    getDescription(context) {
      return `Manage billing information for ${bold(context.auth.currentUserModel?.email)}`;
    },
    getIcon() {
      return 'monetary';
    },
    action(context) {
      context.router.transitionTo('account.subscription.billing');
    },
    searchTerms: ['navigate', 'go to', 'billing info', 'subscription', 'credit card', 'settings', 'update', 'change']
  },
  {
    id: 'logout',
    suggested: false,
    getTitle() {
      return `Log Out`;
    },
    getDescription(context) {
      return `Log out of ${bold(context.auth.currentUserModel?.email)}?`;
    },
    getIcon() {
      return 'warning';
    },
    action(context) {
      context.auth.logout();
    },
    searchTerms: ['logout', 'sign out', 'log out', 'signout']
  },
  {
    id: 'schedule-to-profile',
    suggested: true,
    getTitle() {
      return `Schedule Post`;
    },
    getDescription(context, meta) {
      const profile = getProfile(context, meta);
      const displayName = profile?.displayName;
      if (displayName) {
        return `Schedule a post to ${bold(displayName)}`;
      }

      return `Schedule a post to your content calendar`;
    },
    getIcon(context, meta) {
      const profile = getProfile(context, meta);
      const icon = profile?.get('profileType') ? `social-${profile.get('profileType')}` : 'calendar';

      return icon;
    },
    action(context, meta) {
      const profile = getProfile(context, meta);

      const TIME_OFFSET_MIN = 30;
      const scheduledTime = timestamp() + convert.minutes(TIME_OFFSET_MIN).toSeconds();

      context.selectedSocialProfiles.selectExactly([profile]);
      context.router.transitionTo('cluster.schedule.calendar.post.new', TEXT_POST_TYPE, {
        queryParams: {
          scheduledTime
        }
      });
    },
    searchTerms: ['schedule', 'post', 'publish', 'draft', 'make', 'create', 'media', 'profile', 'calendar']
  },
  {
    id: 'quick-schedule-to-profile',
    suggested: true,
    getTitle() {
      return `Quick Schedule Post`;
    },
    getDescription(context, meta) {
      const profile = getProfile(context, meta);
      const displayName = profile?.displayName;
      if (displayName) {
        return `Schedule a post to ${bold(displayName)} at ${bold(meta.query.tokens.timestamps[0])}`;
      }

      return `Schedule a post to your content calendar`;
    },
    getIcon(context, meta) {
      const profile = getProfile(context, meta);
      const icon = profile?.get('profileType') ? `social-${profile.get('profileType')}` : 'calendar';

      return icon;
    },
    action(context, meta) {
      const profile = getProfile(context, meta);
      const TIME_OFFSET_MIN = 30;
      const day = meta.query.tokens.day[0];
      const tokenTimestamp = meta.query.tokens.timestamps[0];
      let formattedTimestamp = moment(tokenTimestamp, 'h:mma');

      if (day) {
        formattedTimestamp = formattedTimestamp.day(day);

        if (formattedTimestamp.isSameOrBefore(moment())) {
          formattedTimestamp = formattedTimestamp.add(1, 'week');
        }
      }

      const scheduledTime =
        timestamp() > formattedTimestamp.unix()
          ? timestamp() + convert.minutes(TIME_OFFSET_MIN).toSeconds()
          : formattedTimestamp.unix();

      context.router.transitionTo('cluster.schedule.calendar.post.new', 'intent', {
        queryParams: {
          fromGlobalSearch: true,
          urlName: profile.urlName || '',
          scheduledTime
        }
      });
    },
    searchTerms: ['quick', 'schedule', 'post', 'publish', 'draft', 'make', 'create', 'media', 'profile', 'calendar']
  },
  {
    id: 'create-draft-for-profile',
    suggested: false,
    getTitle() {
      return `Create Draft`;
    },
    getDescription(context, meta) {
      const profile = getProfile(context, meta);
      const displayName = profile?.displayName;
      return `Create a draft for ${bold(displayName)}`;
    },
    getIcon(context, meta): string {
      const profile = getProfile(context, meta);
      const icon = `social-${profile.get('profileType')}`;

      return icon;
    },
    action(context, meta) {
      const profile = getProfile(context, meta);
      context.selectedSocialProfiles.selectExactly([profile]);

      context.router.transitionTo('cluster.schedule.calendar.post.new', TEXT_POST_TYPE, {
        queryParams: {
          draft: true
        }
      });
    },
    searchTerms: ['draft', 'post', 'publish', 'schedule', 'make', 'create', 'media', 'profile', 'calendar']
  },
  {
    id: 'search-unsplash',
    suggested: true,
    getTitle() {
      return `Find high-quality photos for your Media Library`;
    },
    getDescription(_context, meta) {
      const text = meta.query.tokens.text[0];
      const tag = meta.query.tokens.tags[0];
      const searchQuery = text || tag;

      if (searchQuery) {
        return `Search Unsplash for pictures of "${bold(searchQuery)}"`;
      }

      return `Use Unsplash to find stock photography for your Media Library`;
    },
    getIcon(): string {
      return 'image-filter';
    },
    action(context, meta) {
      const groupSlug = getGroup(context.auth).slug;
      const text = meta.query.tokens.text[0];
      const tag = meta.query.tokens.tags[0];
      const searchQuery = text || tag;
      const queryParams = searchQuery ? { queryParams: { query: searchQuery } } : undefined;

      if (queryParams) {
        context.router.transitionTo('cluster.collect.unsplash', groupSlug, queryParams);
        return;
      }

      context.router.transitionTo('cluster.collect.unsplash', groupSlug);
    },
    searchTerms: ['find', 'search', 'unsplash', 'navigate', 'stock photography', 'pictures', 'photos', 'images']
  },
  {
    id: 'navigate-search-by-profile',
    suggested: false,
    getTitle() {
      return `Search by Profile`;
    },
    getDescription(_context, meta) {
      const profile = meta.query.tokens.mentions[0];
      if (profile) {
        return `Find media from the ${bold('@' + profile)} Instagram account`;
      }
      return `Search Instagram profiles to find images and videos for your Media Library`;
    },
    getIcon() {
      return 'snr';
    },
    action(context) {
      const groupSlug = getGroup(context.auth).slug;

      context.router.transitionTo('cluster.collect.ig-profile', groupSlug);
    },
    searchTerms: [
      'navigate',
      'search',
      'profile',
      'find',
      'instagram',
      'pictures',
      'photos',
      'images',
      'videos',
      'media'
    ]
  },
  {
    id: 'navigate-search-by-hashtag',
    suggested: true,
    getTitle() {
      return `Search by Hashtag`;
    },
    getDescription(_context, meta) {
      const tag = meta.query.tokens.tags[0];
      if (tag) {
        return `Find Instagram posts with the ${bold('#' + tag)} hashtag`;
      }
      return `Find Instagram posts with a specific hashtag`;
    },
    getIcon(): string {
      return 'hashtag';
    },
    action(context, meta) {
      const groupSlug = getGroup(context.auth).slug;
      const profile = getProfile(context, meta);

      context.router.transitionTo('cluster.collect.profile', groupSlug, profile.urlName || '');
    },
    searchTerms: [
      'navigate',
      'search',
      'hashtags',
      'tags',
      'find',
      'instagram',
      'pictures',
      'photos',
      'images',
      'videos'
    ]
  },
  {
    id: 'see-all-plans',
    suggested: false,
    getTitle() {
      return `See Available Plans`;
    },
    getDescription() {
      return `See what plans are available for you`;
    },
    getIcon() {
      return 'monetary';
    },
    action(context) {
      // Note: seamless plans modal is currently only for trial starts
      // so non-trial eligible users will be redirected to the plans page
      context.seamlessCheckoutManager.upgrade();
    },
    searchTerms: ['plans', 'change', 'downgrade', 'upgrade', 'access', 'settings']
  },
  {
    id: 'upgrade-plan',
    suggested: true,
    getTitle() {
      return `Upgrade Your Plan`;
    },
    getDescription() {
      return `Upgrade your plan to access more features`;
    },
    getIcon() {
      return 'monetary';
    },
    action(context) {
      // Note: the getMorePosts feature maps to the next upgradeable plan
      context.seamlessCheckoutManager.upgrade({ feature: 'getMorePosts' });
    },
    searchTerms: ['upgrade', 'plans', 'change', 'limit', 'access', 'restrict', 'settings']
  },
  {
    id: 'update-plan',
    suggested: false,
    getTitle() {
      return `Update Your Plan`;
    },
    getDescription() {
      return `Manage your addons and other plan details`;
    },
    getIcon() {
      return 'settings';
    },
    action(context) {
      context.seamlessCheckoutManager.upgrade({ feature: 'user' });
    },
    searchTerms: [
      'manage',
      'plans',
      'update',
      'addon',
      'billing',
      'yearly',
      'monthly',
      'mailing',
      'address',
      'card',
      'credit',
      'change',
      'limit',
      'access',
      'restrict',
      'settings'
    ]
  },
  {
    id: 'navigate-to-linkinbio',
    suggested: true,
    getTitle() {
      return `Manage your Linkin.bio`;
    },
    getDescription() {
      return `Use Linkin.bio to drive traffic from Instagram & TikTok, track clicks, and more.`;
    },
    getIcon() {
      return 'linkin-bio';
    },
    action(context) {
      context.router.transitionTo('cluster.linkinbio');
    },
    searchTerms: [
      'navigate',
      'go to',
      'linkinbio',
      'link',
      'button',
      'featured',
      'banner',
      'media',
      'youtube',
      'feed',
      'mailchimp',
      'traffic'
    ]
  },
  {
    id: 'navigate-to-linkinbio-featured-banner',
    suggested: false,
    getTitle() {
      return `Add a Featured Banner in your Linkin.bio`;
    },
    getDescription() {
      return `Use Featured Banner to highlight your content or products to attract followers and drive revenue`;
    },
    getIcon() {
      return 'lib-featured-banner';
    },
    action(context) {
      if (!context.router.currentRouteName.includes('cluster.linkinbio.page.blocks.featured_banner')) {
        context.router.transitionTo('cluster.linkinbio.page.blocks.featured_banner');
      }
    },
    searchTerms: [
      'navigate',
      'go to',
      'linkinbio',
      'link',
      'featured',
      'banner',
      'featured banner',
      'highlight',
      'traffic'
    ]
  },
  {
    id: 'navigate-to-linkinbio-featured-media',
    suggested: false,
    getTitle() {
      return `Add a Featured Media in your Linkin.bio`;
    },
    getDescription() {
      return `Use Featured Media to give spotlight to your new content like YouTube video to increase subscribers`;
    },
    getIcon() {
      return 'lib-featured-media';
    },
    action(context) {
      if (!context.router.currentRouteName.includes('cluster.linkinbio.page.blocks.featured_media')) {
        context.router.transitionTo('cluster.linkinbio.page.blocks.featured_media');
      }
    },
    searchTerms: [
      'navigate',
      'go to',
      'linkinbio',
      'link',
      'featured',
      'media',
      'featured media',
      'youtube',
      'highlight',
      'traffic'
    ]
  },
  {
    id: 'navigate-to-linkinbio-header',
    suggested: false,
    getTitle() {
      return `Update your Linkin.bio profile presence`;
    },
    getDescription() {
      return `Update your Linkin.bio name and bio to engage your followers`;
    },
    getIcon() {
      return 'lib-header';
    },
    action(context) {
      if (!context.router.currentRouteName.includes('cluster.linkinbio.page.blocks.header')) {
        context.router.transitionTo('cluster.linkinbio.page.blocks.header');
      }
    },
    searchTerms: ['navigate', 'go to', 'linkinbio', 'link', 'profile']
  },
  {
    id: 'manage-credentials',
    suggested: false,
    getTitle() {
      return `Manage your credentials`;
    },
    getDescription() {
      return `Connect or refresh your social profiles`;
    },
    getIcon() {
      return 'refresh';
    },
    action(context) {
      const brokenProfiles = context.auth.currentGroup.socialProfiles.filter(
        ({ hasError, needsRefresh, isProfessional, hasProfessionalAccount }) =>
          hasError || needsRefresh || (isProfessional && !hasProfessionalAccount)
      );
      if (brokenProfiles.length > 0) {
        context.credentialStatus.showReconnectSocialProfileModal(brokenProfiles[0]);
      } else {
        context.router.transitionTo('account.groups.group.social_profiles', context.auth.currentGroup.id);
      }
    },
    searchTerms: ['navigate', 'go to', 'credentials', 'refresh', 'manage', 'connect']
  }
];

export function getFeatureUpgradeIntents(availableFeatures: Record<string, FeatureMap>): SearchIntent[] {
  if (!availableFeatures) {
    return [];
  }

  const featureUpgradeIntents: SearchIntent[] = [];
  const addedFeatures: string[] = [];

  for (const featureKey in availableFeatures) {
    const { featureName } = availableFeatures[featureKey];

    // Note: some features have multiple keys for different target plan upgrades
    //       don't add the feature if it's already been added
    if (!addedFeatures.includes(featureName)) {
      const searchTerms = getSearchKeysForFeature(featureKey, featureName);
      const intent: SearchIntent = {
        id: `feature-upgrade-${featureName}`,
        suggested: false,
        getTitle() {
          return `Get ${bold(featureName)}`;
        },
        getDescription() {
          return `Upgrade your plan to get access to ${bold(featureName)}`;
        },
        action(context) {
          context.seamlessCheckoutManager.upgrade({ feature: featureKey });
        },
        getIcon() {
          return 'monetary';
        },
        searchTerms,
        mandatoryTerms: ['get', 'feature', 'access', 'upgrade', 'how', 'help']
      };

      addedFeatures.push(featureName);
      featureUpgradeIntents.push(intent);
    }
  }

  return featureUpgradeIntents;
}

function getSearchKeysForFeature(featureKey: string, featureName: string): string[] {
  // Note: turns every camel-cased word into a search term
  const searchTermsFromKey = sanitizeSearchTerms(featureKey.replace(/([A-Z])/g, ' $1').split(' '));
  const searchTermsFromName = sanitizeSearchTerms(featureName.split(' '));

  const rawSearchTermsArray = [...searchTermsFromKey, ...searchTermsFromName, featureKey.toLowerCase()];
  return [...new Set(rawSearchTermsArray)];
}

function sanitizeSearchTerms(searchTerms: string[]): string[] {
  return searchTerms.filter((term) => term.length > 1).map((term) => term.toLowerCase());
}
