import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{! @glint-nocheck: not typesafe yet }}\n<ul class=\"u--listUnstyle h--globalSearch__resultList\" data-test-id=\"search-result-list\" tabindex=\"-1\">\n  {{!-- {{#let (get @results 0) as |firstResult|}}\n    {{#if (is-equal firstResult.type \"previously-used\")}}\n      <h4 class=\"u--text--light\">Recent searches: </h4>\n    {{/if}}\n  {{/let}} --}}\n  {{#each @results as |result index|}}\n    <li class=\"h--globalSearch__resultItem\">\n      {{yield result index}}\n    </li>\n  {{/each}}\n</ul>", {"contents":"{{! @glint-nocheck: not typesafe yet }}\n<ul class=\"u--listUnstyle h--globalSearch__resultList\" data-test-id=\"search-result-list\" tabindex=\"-1\">\n  {{!-- {{#let (get @results 0) as |firstResult|}}\n    {{#if (is-equal firstResult.type \"previously-used\")}}\n      <h4 class=\"u--text--light\">Recent searches: </h4>\n    {{/if}}\n  {{/let}} --}}\n  {{#each @results as |result index|}}\n    <li class=\"h--globalSearch__resultItem\">\n      {{yield result index}}\n    </li>\n  {{/each}}\n</ul>","moduleName":"later/components/global-search/search-result-list.hbs","parseOptions":{"srcName":"later/components/global-search/search-result-list.hbs"}});
import Component from '@glimmer/component';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface SearchResultListComponentArgs {
  // tdb
}

// eslint-disable-next-line ember/no-empty-glimmer-component-classes
export default class SearchResultListComponent extends Component<SearchResultListComponentArgs> {
  // todo
}
