import { action } from '@ember/object';
import Service, { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { restartableTask, dropTask } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import { isString } from 'lodash';

import { SOCIAL_PROFILE_CODES } from 'later/utils/constants';

import type RouterService from '@ember/routing/router-service';
import type Store from '@ember-data/store';
import type { TaskGenerator } from 'ember-concurrency';
import type GroupModel from 'later/models/group';
import type SocialIdentityModel from 'later/models/social-identity';
import type AuthService from 'later/services/auth';
import type ErrorsService from 'later/services/errors';
import type InstagramService from 'later/services/instagram';
import type LinkedinService from 'later/services/linkedin';
import type LocalStorageManagerService from 'later/services/local-storage-manager';
import type PinterestService from 'later/services/pinterest';
import type SegmentService from 'later/services/segment';
import type TwitterService from 'later/services/twitter';
import type YoutubeService from 'later/services/youtube';
import type { OauthSocialProfileType } from 'later/utils/constants';
import type { UntypedService, Maybe } from 'shared/types';

interface AddSocialProfileArgs {
  selectedSocialProfileType: string;
  group: GroupModel;
  area?: string;
  redirectPath?: Maybe<string>;
  passedInSocialIdentityId?: string | null;
  shouldRedirect?: boolean;
  shouldRedirectToSettings?: boolean;
}

interface AddLinkedinProfileArgs {
  id: string;
  group: GroupModel;
  path: string;
}

interface AddTiktokProfileArgs {
  name: string;
  group: GroupModel;
  path: string;
}

interface AddFacebookProfileArgs<ScopeType> {
  uid: string;
  token: string;
  scopes: ScopeType[];
  group: GroupModel;
  path?: string;
}

enum TikTokStrategy {
  TransitionToPlans = 'transitionToPlans',
  TransitionToSettings = 'transitionToSettings',
  ConnectTikTokOauth = 'connectTikTokOauth',
  ShowTikTokModal = 'showTikTokModal'
}

type WritableProperties<T> = { -readonly [P in keyof T]: T[P] }; // this type doesn't prevent setting getters

type ServiceKeyType<T> = keyof T;

type ServicePropertyType<T> = T[keyof T];

type KeyOfSocialProfileCodes = keyof typeof SOCIAL_PROFILE_CODES;

export default class ConnectProfilesService extends Service {
  @service declare auth: AuthService;
  @service declare instagram: InstagramService;
  @service declare twitter: TwitterService;
  @service declare pinterest: PinterestService;
  @service declare linkedin: LinkedinService;
  @service declare tiktok: UntypedService;
  @service declare errors: ErrorsService;
  @service declare store: Store;
  @service declare localStorageManager: LocalStorageManagerService;
  @service declare router: RouterService;
  @service('social/facebook-auth') declare facebookAuth: UntypedService;
  @service declare segment: SegmentService;
  @service declare youtube: YoutubeService;

  @tracked showFacebookSelector = false;
  @tracked showAddTiktokProfileModal = false;
  @tracked authResponse: unknown;
  @tracked selectedSocialIdentity: SocialIdentityModel | null = null;

  socialProfileNames = Object.keys(SOCIAL_PROFILE_CODES).reduce<Record<string, string>>(
    (keyValueSwapResult: Record<string, string>, key) => {
      keyValueSwapResult[SOCIAL_PROFILE_CODES[key as KeyOfSocialProfileCodes]] = key.toLowerCase();
      return keyValueSwapResult;
    },
    {}
  );

  get socialIdentityId(): string | undefined | null {
    return this.hasSocialProfile ? this.socialIdentity?.get('id') : null;
  }

  get socialIdentity(): Maybe<SocialIdentityModel> {
    if (isPresent(this.selectedSocialIdentity)) {
      return this.selectedSocialIdentity;
    }
    return this.hasSocialProfile ? this.auth.currentGroup.socialIdentities.firstObject : undefined;
  }

  get hasSocialProfile(): boolean {
    return isPresent(this.auth.currentGroup?.socialProfiles);
  }

  oAuthPath({
    socialProfileType,
    redirectGroupSlug,
    // Note: Using an 'id' instead of a 'slug' here will trigger a redirect
    redirectPath = `${redirectGroupSlug || this.auth.currentGroup?.slug}/schedule/calendar`
  }: {
    socialProfileType: OauthSocialProfileType;
    redirectGroupSlug?: string;
    redirectPath?: Maybe<string>;
  }): string {
    return `/oauth/${socialProfileType}?redirect_path=${redirectPath}`;
  }

  async addFacebookProfile<ScopeType>({
    uid,
    token,
    scopes,
    group,
    path
  }: AddFacebookProfileArgs<ScopeType>): Promise<void> {
    const identity = await this.socialIdentity;

    this.showFacebookSelector = false;
    await this.facebookAuth.addFacebookProfile(uid, token, scopes, group, identity);
    if (path) {
      this.router.transitionTo(path, group);
    }
  }

  async addTiktokProfile({ name, group, path }: AddTiktokProfileArgs): Promise<void> {
    const identity = await this.socialIdentity;

    this.showAddTiktokProfileModal = false;
    await this.tiktok.addProfile(name, identity, group);
    this.router.transitionTo(path, group);
  }

  @action
  updateProperty(propertyName: ServiceKeyType<this>, value: ServicePropertyType<this>): void {
    (this as WritableProperties<typeof this>)[propertyName] = value;
  }

  @restartableTask
  *addSocialProfile({
    selectedSocialProfileType,
    group,
    redirectPath,
    area = '',
    shouldRedirectToSettings = false,
    passedInSocialIdentityId,
    shouldRedirect = true
  }: AddSocialProfileArgs): TaskGenerator<void> {
    try {
      if (isPresent(area)) {
        this.segment.track(`attempted-connecting-${this.socialProfileNames[selectedSocialProfileType]}-account`, {
          area
        });
      }

      switch (selectedSocialProfileType) {
        case SOCIAL_PROFILE_CODES.INSTAGRAM:
          yield taskFor(this.connectInstagram).perform(
            group,
            shouldRedirectToSettings,
            redirectPath,
            passedInSocialIdentityId
          );
          break;
        case SOCIAL_PROFILE_CODES.TWITTER:
          yield this.twitter.createTwitterWithSet(Number(this.socialIdentityId) ?? undefined, group);
          break;
        case SOCIAL_PROFILE_CODES.PINTEREST:
          yield this.pinterest.createPinterestWithSet(this.socialIdentityId, group);
          break;
        case SOCIAL_PROFILE_CODES.FACEBOOK:
          yield this.#connectFacebook(group.id, shouldRedirect);
          break;
        case SOCIAL_PROFILE_CODES.LINKEDIN:
          yield this.linkedin.login(group);
          break;
        case SOCIAL_PROFILE_CODES.TIKTOK:
          yield taskFor(this.connectTikTok).perform(group, shouldRedirectToSettings, redirectPath);
          break;
        case SOCIAL_PROFILE_CODES.YOUTUBE:
          yield this.youtube.createYoutubeWithSet(this.socialIdentityId, group);
          break;
        default:
          break;
      }
    } catch (error) {
      this.errors.log(error);
    }
  }

  @dropTask
  *addLinkedinProfile({ id, group, path }: AddLinkedinProfileArgs): TaskGenerator<void> {
    try {
      const socialIdentityId = this.localStorageManager.getItem('selectedSocialIdentityId');

      let socialIdentity: SocialIdentityModel;
      if (socialIdentityId && isString(socialIdentityId)) {
        socialIdentity = yield this.store.findRecord('social-identity', socialIdentityId);
      } else {
        socialIdentity = yield this.socialIdentity;
      }

      yield this.linkedin.addProfile(id, socialIdentity, group);
      this.localStorageManager.removeItem('selectedSocialIdentityId');
      this.router.transitionTo(path, group, { queryParams: { show_linkedin_selector: false } });
    } catch (error) {
      this.errors.log(error);
    }
  }

  @dropTask
  *connectInstagram(
    group: GroupModel,
    shouldRedirectToSettings: boolean,
    redirectPath?: string | null,
    passedInSocialIdentityId?: string | null
  ): TaskGenerator<void> {
    const socialIdentityId = passedInSocialIdentityId ? passedInSocialIdentityId : this.socialIdentityId;

    if (shouldRedirectToSettings) {
      this.router.transitionTo('account.groups.group.social_profiles.new', group.id);
    } else {
      yield this.instagram.createInstagramWithSet(socialIdentityId, group, redirectPath);
    }
  }

  @dropTask
  *connectTikTok(
    group: GroupModel,
    shouldRedirectToSettings: boolean,
    redirectPath?: string | null
  ): TaskGenerator<void> {
    const tikTokStrategy = this.#decideStrategyForTikTok(shouldRedirectToSettings);

    switch (tikTokStrategy) {
      case TikTokStrategy.TransitionToSettings:
        this.router.transitionTo('account.groups.group.social_profiles.new', group.id);
        break;
      case TikTokStrategy.TransitionToPlans:
        this.router.transitionTo('plans.migrate');
        break;
      case TikTokStrategy.ConnectTikTokOauth:
        yield this.tiktok.createTiktokWithSet(this.socialIdentityId, group, redirectPath);
        break;
      case TikTokStrategy.ShowTikTokModal:
        this.showAddTiktokProfileModal = true;
        break;
      default:
        break;
    }
  }

  async #connectFacebook(groupId: string, shouldRedirect: boolean): Promise<void> {
    const group = this.store.peekRecord('group', groupId);

    if (!group) {
      throw new Error(`Unable to find Group record with provided ID: ${groupId}`);
    }

    if (shouldRedirect) {
      this.router.transitionTo('cluster.schedule.calendar', group.slug);
    }

    const authResponse = await this.facebookAuth.authenticateFacebook();
    if (authResponse) {
      this.authResponse = authResponse;
      this.showFacebookSelector = true;
    }
  }

  #decideStrategyForTikTok(shouldRedirectToSettings: boolean): TikTokStrategy {
    const { isOnLegacyPlan } = this.auth.currentAccount || {};

    if (shouldRedirectToSettings) {
      return isOnLegacyPlan ? TikTokStrategy.TransitionToPlans : TikTokStrategy.TransitionToSettings;
    } else if (!isOnLegacyPlan) {
      return TikTokStrategy.ConnectTikTokOauth;
    }

    return TikTokStrategy.ShowTikTokModal;
  }
}

declare module '@ember/service' {
  interface Registry {
    'social/connect-profiles': ConnectProfilesService;
  }
}
