import Service, { inject as service } from '@ember/service';
import { isBlank } from '@ember/utils';
import { task } from 'ember-concurrency';

import APINotFoundError from 'later/errors/api-not-found-error';
import InvalidCredentialsError from 'later/errors/invalid-credentials-error';
import { INSTAGRAM_GRAPH_URL } from 'later/utils/constants';
import { fetch } from 'later/utils/fetch';
import createIgPostFromBasicDisplay from 'later/utils/formatters/ig-post-from-basic-display';
import createIgUserFromBasicDisplay from 'later/utils/formatters/ig-user-from-basic-display';

import type { TaskGenerator } from 'ember-concurrency';
import type IntlService from 'ember-intl/services/intl';
import type IgPost from 'later/models/ig-post';
import type SocialProfileModel from 'later/models/social-profile';
import type AlertsService from 'later/services/alerts';
import type { UntypedLaterModel } from 'shared/types';
import type { InstagramMedia, InstagramRequestError, RecentInstagramMediaBasicDisplay } from 'shared/types/instagram';

export default class InstagramBasicDisplayService extends Service {
  @service declare alerts: AlertsService;
  @service declare intl: IntlService;

  baseURL = INSTAGRAM_GRAPH_URL;

  @task
  *fetchSelf(socialProfile: SocialProfileModel): TaskGenerator<UntypedLaterModel<'IgUser'> | undefined> {
    const fields = ['id', 'username', 'media_count', 'account_type'].join(',');
    const query = `fields=${fields}&access_token=${socialProfile.basicDisplayToken}`;

    try {
      const response = yield fetch(`${this.baseURL}/me?${query}`);
      return createIgUserFromBasicDisplay(response, socialProfile);
    } catch (error) {
      return undefined;
    }
  }

  @task
  *fetchUsername(token: string): TaskGenerator<string | undefined> {
    const query = `fields=username&access_token=${token}`;
    try {
      const { username } = yield fetch(`${this.baseURL}/me?${query}`);
      return username;
    } catch (error) {
      return undefined;
    }
  }

  @task
  *fetchMedia(socialProfile: SocialProfileModel, postId: string): TaskGenerator<IgPost | undefined> {
    const fields = [
      'id',
      'username',
      'caption',
      'media_type',
      'media_url',
      'permalink',
      'thumbnail_url',
      'timestamp'
    ].join(',');
    const query = `fields=${fields}&access_token=${socialProfile.basicDisplayToken}`;
    try {
      const response = yield fetch(`${this.baseURL}/${postId}?${query}`);
      return createIgPostFromBasicDisplay(response, socialProfile);
    } catch (error) {
      if (error.code === 404) {
        throw new APINotFoundError({ title: 'APINotFoundError', detail: error.message, code: error.code });
      }
      return undefined;
    }
  }

  fetchRecentMedia = task(
    async (
      socialProfile: SocialProfileModel,
      params?: { next?: string }
    ): Promise<RecentInstagramMediaBasicDisplay> => {
      const fields = [
        'id',
        'username',
        'caption',
        'media_type',
        'media_url',
        'permalink',
        'thumbnail_url',
        'timestamp'
      ].join(',');

      const token = socialProfile.basicDisplayToken;

      if (!token) {
        throw new InvalidCredentialsError(this.intl.t('errors.missing_basic_display_token'));
      }

      const builtUrl = `${this.baseURL}/${socialProfile.basicDisplayId}/media?fields=${fields}&access_token=${token}`;
      const query = params && params.next ? params.next : builtUrl;
      try {
        const { data, paging } = await fetch(query);
        return {
          posts: await Promise.all(
            data.map((post: InstagramMedia) => createIgPostFromBasicDisplay(post, socialProfile))
          ),
          pagination: paging
        };
      } catch (error) {
        if (error.code === 190) {
          throw new InvalidCredentialsError(error.message);
        }
        throw error;
      }
    }
  );

  /**
   * Helper for displaying request errors.
   */
  raiseError(error: InstagramRequestError): void {
    if (error) {
      if (error.errors) {
        this.alerts.warning(error.errors[0].detail);
      } else if (!isBlank(error.message)) {
        this.alerts.warning(error.message);
      }
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    'instagram-basic-display': InstagramBasicDisplayService;
  }
}
