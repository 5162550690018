import Service, { inject as service } from '@ember/service';

/* eslint-disable import/no-duplicates */
import type IntegrationsQueueService from 'later/services/integrations-queue';
import type { Integration } from 'later/services/integrations-queue';

export default class AppcuesService extends Service implements Integration {
  @service declare integrationsQueue: IntegrationsQueueService;

  readonly name = 'appcues';

  get appcuesContainer(): HTMLDivElement | null {
    return document.querySelector('appcues-checklists');
  }

  setup(): void {
    if (window.Appcues) {
      // NOTE: Appcues can be active before listeners are setup. If the checklist container
      // already exists, then we need to manually addToQueue.  Polling to resolve intermitent timing issue
      // where the appcuesContainer check happens before the container gets injected into the DOM
      this.checkForAppcuesContainer();

      window.Appcues.on('checklist_shown', () => this.integrationsQueue.addToQueue(this.name));
      window.Appcues.on('checklist_skipped', () => this.integrationsQueue.removeFromQueue(this.name));
      window.Appcues.on('checklist_dismissed', () => this.integrationsQueue.removeFromQueue(this.name));
    }
  }

  checkForAppcuesContainer(): void {
    const maxPollCount = 500;
    const pollDelay = 500;
    let count = 0;
    const interval = setInterval(() => {
      if (count > maxPollCount) {
        clearInterval(interval);
      }
      if (this.appcuesContainer) {
        if (!this.integrationsQueue.isInQueue(this.name)) {
          this.integrationsQueue.addToQueue(this.name);
        }
        clearInterval(interval);
      }
      count++;
    }, pollDelay);
  }

  show(): void {
    if (this.appcuesContainer) {
      this.appcuesContainer.style.display = '';
    }
  }

  track(...args: unknown[]): void {
    window.Appcues?.track(...args);
  }

  hide(): void {
    if (this.appcuesContainer) {
      this.appcuesContainer.style.display = 'none';
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    appcues: AppcuesService;
  }
}
