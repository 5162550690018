import Service, { inject as service } from '@ember/service';
import { enqueueTask } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';

import getThreeMonthsOfPosts from 'later/utils/array-filters/get-three-months-of-posts';

import type InstagramApiService from './instagram-api';
import type AnalyticsService from '../analytics';
import type { TaskGenerator } from 'ember-concurrency';
import type { UntypedService } from 'shared/types';
import type { IgUser } from 'shared/types/analytics-data';
import type { RecordWithCreatedTime } from 'shared/utils/array-filters/get-three-months-of-posts';

export default class InstagramAnalyticsService extends Service {
  @service declare analytics: AnalyticsService;
  @service declare userConfig: UntypedService;
  @service('analytics/instagram-api') declare instagramApi: InstagramApiService;

  /**
   * Reads the currentTimeZone identifier as defined in the UserConfig service
   *
   * @property timeZoneIndentifier
   */
  get timeZoneIdentifier(): string {
    return this.userConfig.currentTimeZone.identifier;
  }

  /**
   * Returns Instagram posts for free users
   *
   * @param forceRefresh
   * @returns Instagram posts in the given time range
   */
  @enqueueTask
  *getPosts(forceRefresh = false): TaskGenerator<RecordWithCreatedTime[]> {
    const posts = yield taskFor(this.instagramApi.getMedia)
      .linked()
      .perform(forceRefresh)
      .catch(() => []);

    return getThreeMonthsOfPosts(posts, this.timeZoneIdentifier);
  }

  /**
   * Returns current Instagram User
   *
   * @param forceRefresh
   * @returns formatted current Instagram User
   */
  @enqueueTask
  *getUser(forceRefresh = false): TaskGenerator<IgUser> {
    return yield taskFor(this.instagramApi.getUser).linked().perform(forceRefresh);
  }
}

declare module '@ember/service' {
  interface Registry {
    'analytics/instagram-analytics': InstagramAnalyticsService;
  }
}
