import { readOnly } from '@ember/object/computed';
import { capitalize } from '@ember/string';
import { isBlank } from '@ember/utils';
import classic from 'ember-classic-decorator';
import BaseValidator from 'ember-cp-validations/validators/base';

@classic
class MediaRequired extends BaseValidator {
  @readOnly('model.caption') caption;
  @readOnly('model.firstMediaItem') firstMediaItem;
  @readOnly('model.isText') isText;
  @readOnly('model.socialProfile.canTextOnly') canTextOnly;
  @readOnly('model.socialProfile.profileType') profileType;

  validate() {
    if (this.isDraft && !this.get('model.socialProfile.isInstagram') && !this.get('model.socialProfile.istikok')) {
      return true;
    }
    if (!this.isText) {
      return true;
    }
    if (this.canTextOnly && !this.firstMediaItem && isBlank(this.caption)) {
      return this.createErrorMessage('mediaOrCaptionRequired');
    }
    if (!this.canTextOnly && !this.firstMediaItem) {
      return this.createErrorMessage('mediaRequired', null, { platform: capitalize(this.profileType) });
    }
    return true;
  }
}

export default MediaRequired;
