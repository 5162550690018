import { action } from '@ember/object';
import { reads } from '@ember/object/computed';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import { isEmpty, isNone, isPresent } from '@ember/utils';
import classic from 'ember-classic-decorator';
import { task } from 'ember-concurrency';
import NProgress from 'nprogress';
import RSVP from 'rsvp';

import { CredentialsNotFoundError, RequestError } from 'later/errors/index';
import InvalidCredentialsError from 'later/errors/invalid-credentials-error';
import { ErrorSeverity } from 'later/services/errors';
import promiseHash from 'later/utils/promise-hash';

@classic
export default class ScheduleRoute extends Route {
  @service alerts;
  @service auth;
  @service cache;
  @service errors;
  @service intl;
  @service mediaItemQuery;
  @service mediaLibrary;
  @service onboarding;
  @service queryPersistence;
  @service router;
  @service schedule;
  @service segment;
  @service store;
  @service selectedSocialProfiles;
  @service statusPage;
  @service userConfig;
  @service dialogManager;
  @service('schedule/post') schedulePost;
  @service('social/instagram-business') instagramBusiness;

  @reads('auth.currentAccount.canBttp') canBttp;
  @reads('auth.currentAccount') currentAccount;
  @reads('auth.currentGroup') currentGroup;
  @reads('auth.currentUserModel') currentUser;
  @reads('currentAccount.owner.email') ownerEmail;
  @reads('currentGroup.socialProfiles') socialProfiles;
  @reads('currentUser.isAccountOwner') isAccountOwner;
  @reads('userConfig.currentTimeZone.name') timeZoneName;

  get socialProfilesSelectedForScheduling() {
    return this.selectedSocialProfiles.profiles.map((profile) => profile.accountType);
  }

  beforeModel() /*transition*/ {
    this.queryPersistence.updateGroup('side-library', this.get('currentGroup.id'));
  }

  model() /*transition*/ {
    if (this.get('currentGroup.contributor')) {
      this.router.transitionTo('cluster.collect.contribute');
    }

    let socialProfiles = [];
    const currentAccountProfile = this.modelFor('cluster')?.get('socialProfiles.firstObject');
    const socialProfileIds = this.selectedSocialProfiles.cachedProfileIds;
    const currentGroupProfiles = this.get('currentGroup.socialProfiles');

    if (currentGroupProfiles) {
      socialProfiles = currentGroupProfiles.filter((profile) => socialProfileIds.includes(profile.get('id')));
    }

    if (isEmpty(socialProfiles) && !isNone(currentAccountProfile)) {
      //case where last_group was from other account -iMack
      socialProfiles.push(currentAccountProfile);
    }

    this.selectedSocialProfiles.selectExactly(socialProfiles);
    return socialProfiles;
  }

  afterModel() {
    this._displayPlatformAnnouncement();

    return promiseHash({
      mediaItems: new RSVP.Promise((resolve, reject) => {
        const params = {
          groupId: this.get('currentGroup.id'),
          labelIds: this.queryPersistence.getLabelIds('side-library'),
          usedUnused: this.queryPersistence.getUsedUnused('side-library', 'unused'),
          mediaTypeFilter: this.queryPersistence.getMediaTypeFilter('side-library', ''),
          searchString: this.queryPersistence.getSearchString('side-library'),
          socialProfileIds: this.queryPersistence.getSocialProfileIds('side-library'),
          trackEvent: false,
          limit: 30,
          successCallback: resolve,
          failureCallback: reject
        };

        this.get('mediaItemQuery').queryWithFilters(params);
      })
    });
  }

  setupController(controller) {
    controller.set('allMediaItems', this.store.peekAll('media-item'));
    controller.set('devices', this.store.peekAll('device'));

    const starredMedia = this.getStarredMedia();
    controller.set('starredMedia', starredMedia);
    controller.starredMedia.forEach((mediaItem) => {
      mediaItem.set('isStarred', true);
    });

    controller.set('connectBusinessAccount', this.connectBusinessAccount);
  }

  resetController(controller, isExiting /*, transition*/) {
    if (isExiting) {
      controller.reset();
    }
  }

  @task(function* (socialProfile) {
    try {
      yield this.onboarding.connectBusinessAccount.perform(socialProfile, 'calendar');
    } catch (error) {
      if (error instanceof CredentialsNotFoundError) {
        error.resolve.call(this, socialProfile, 'calendar');
      } else if (error instanceof RequestError) {
        error.resolve.call(this);
      } else {
        this.errors.log(error);
      }
    }
  })
  connectBusinessAccount;

  @action
  changeProfile(socialProfiles) {
    this.set('selectedSocialProfile', socialProfiles);
    this.refresh();
    return true;
  }

  @action
  setSideLibraryBehavior(behavior) {
    this.schedule.set('sideLibraryBehavior', behavior);
  }

  @action
  closeModal() {
    this.router.transitionTo('cluster.schedule.calendar');
  }

  @action
  async autoScheduleMediaItems(mediaItems, socialProfile) {
    if (mediaItems.isAny('isGif', true) && socialProfile.isInstagram) {
      return this.alerts.warning(
        this.intl.t('alerts.calendar.cant_autoschedule_gif.message', { platform: socialProfile.accountType }),
        { title: this.intl.t('alerts.calendar.cant_autoschedule_gif.title') }
      );
    }

    const canSchedule = await this.schedule.canSchedule(socialProfile, {
      numPosts: mediaItems.length,
      location: 'auto schedule out of posts'
    });
    if (!canSchedule) {
      return;
    }

    NProgress.start();
    const mediaItemIds = mediaItems.mapBy('id');
    try {
      const response = await socialProfile.autoSchedule({
        media_item_ids: mediaItemIds,
        time_zone: this.timeZoneName
      });
      if (response.errors.length) {
        throw response.errors;
      } else {
        this.store.pushPayload(response);
        this.schedule.updatePostLimit.perform();
        this.segment.track('started-scheduling-post', {
          area: 'dragged_from_side_library_to_quick_schedule_calendar',
          type: mediaItems.firstObject.mediaType,
          is_multi_profile: this.socialProfilesSelectedForScheduling.length > 1,
          multi_profile_types: this.socialProfilesSelectedForScheduling
        });
      }
    } catch (error) {
      const title = this.intl.t('alerts.calendar.cant_quick_schedule_a_post');
      this.errors.log(title, error);
      if (Array.isArray(error)) {
        error.forEach(({ errors, media_item_id }) => {
          const mediaItem = this.store.peekRecord('media-item', media_item_id);
          const filename = mediaItem.originalFilename;

          this.alerts.warning(
            `${
              filename
                ? this.intl.t('alerts.calendar.skipped_quick_schedule', { filename })
                : this.intl.t('alerts.calendar.skipped_quick_schedule_a_post')
            }<br>${errors.join()}`,
            {
              title: filename ? this.intl.t('alerts.calendar.cant_quick_schedule', { filename }) : title
            }
          );
        });
      }
    } finally {
      NProgress.done();
    }
  }

  @action
  reauthCredentials() {
    this.errors.log('IENG-1448: ScheduleRoute#reauthCredentials', {}, ErrorSeverity.Info);
    this._reconnectSocialProfile();
  }

  @action
  error(reason) {
    if (reason instanceof InvalidCredentialsError) {
      this.errors.log('IENG-1448:  ScheduleRoute#error', {}, ErrorSeverity.Info);
      this._reconnectSocialProfile();
    } else {
      return true;
    }
  }

  getStarredMedia() {
    const starredMediaIds = this.cache.retrieve('starred_media_group_' + this.get('currentGroup.id')) ?? [];
    if (starredMediaIds.length > 0) {
      return this.store.query('media-item', {
        group_id: this.get('currentGroup.id'),
        filter: { ids: starredMediaIds }
      });
    }
    return [];
  }

  _reconnectSocialProfile() {
    this.errors.log('IENG-1448: ScheduleRoute#_reconnectSocialProfile', {}, ErrorSeverity.Info);
    const socialProfile = this.selectedSocialProfiles.firstProfile;
    const hasBasicDisplayToken = isPresent(socialProfile.basicDisplayToken);
    const token = hasBasicDisplayToken ? 'basicDisplayToken' : 'token';
    socialProfile.set(token, null);
    this.send('reconnectSocialProfile', socialProfile);
  }

  _displayCarouselUpgradeAlert() {
    const upgradeMessage = this.auth.currentAccount.canTrialPlan
      ? this.intl.t('alerts.calendar.cannot_schedule_carousel_free.message_trial')
      : this.intl.t('alerts.calendar.cannot_schedule_carousel_free.message');

    this.alerts.upgrade(upgradeMessage, {
      title: this.intl.t('alerts.calendar.cannot_schedule_carousel_free.title'),
      type: 'info',
      feature: 'multiPhoto',
      location: 'multi photo schedule flash message',
      upgradeText: this.intl.t('shared_phrases.upgrade_plan'),
      upgradeQaClassName: 'qa--carouselUpsell_upgradePlan_Btn'
    });
  }

  async _displayPlatformAnnouncement() {
    const { hasInstagram, hasFacebook, hasPinterest, hasTwitter } = this.currentGroup.getProperties(
      'hasInstagram',
      'hasFacebook',
      'hasPinterest',
      'hasTwitter'
    );
    if (
      (this.statusPage.isDown('facebook') && hasFacebook) ||
      (this.statusPage.isDown('instagram') && hasInstagram) ||
      (this.statusPage.isDown('twitter') && hasTwitter) ||
      (this.statusPage.isDown('pinterest') && hasPinterest)
    ) {
      try {
        const incidents = await this.statusPage.getAllIncidents();

        if (!incidents) {
          return;
        }

        incidents.forEach(({ incident, update }) => {
          if (!update) {
            return;
          }

          const map = { critical: 'alert', major: 'warning', minor: 'info' };
          const body = update && update.body ? htmlSafe(update.body.replace(/(?:\r\n|\r|\n)/g, '<br>')) : null;
          const isStatusResolved =
            update.status.toLowerCase() === 'resolved' || update.status.toLowerCase() === 'completed';
          const alertType = map[incident.impact];
          const title = this.intl.t('alerts.app.platform_announcement.title_with_name', {
            incidentName: incident.name
          });

          if (body && alertType && !isStatusResolved) {
            this.alerts[alertType](body, {
              title,
              action: () => window.open(incident.shortlink, '_blank'),
              actionText: this.intl.t('alerts.app.platform_announcement.link_button'),
              sticky: true,
              preventDuplicates: true
            });
          }
        });
      } catch (error) {
        this.errors.log(error);
      }
    }
  }
}
