import { equal, readOnly } from '@ember/object/computed';
import Model, { attr, belongsTo } from '@ember-data/model';
import { collectionAction } from 'ember-api-actions';

import type { AsyncBelongsTo } from '@ember-data/model';
import type GroupModel from 'later/models/group';
import type UserModel from 'later/models/user';

interface InviteContributorActionAPIParams {
  email: string;
  group_id: string;
}

interface InviteContributorActionAPIResponse {
  clusters: GroupModel[];
  groups: GroupModel[];
  membership: MembershipModel;
  users: UserModel[];
}

export default class MembershipModel extends Model {
  @attr('boolean') declare invited: boolean;
  @attr('string') declare role: string;

  @belongsTo('group', { async: true }) declare group: AsyncBelongsTo<GroupModel>;
  @belongsTo('user', { async: true }) declare user: AsyncBelongsTo<UserModel>;

  @equal('role', 'contributor') declare isContributor: boolean;
  @readOnly('user.name') declare name: string;

  inviteContributor = collectionAction<InviteContributorActionAPIParams, InviteContributorActionAPIResponse>({
    path: 'contributor_invite',
    type: 'post'
  });
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    membership: MembershipModel;
  }
}
