import Model, { attr } from '@ember-data/model';

import type { Variants } from 'shared/types/generic-media';

export default class GenericAttachmentModel extends Model {
  @attr() declare contentType: string;
  @attr() declare destinationKey: string;
  @attr() declare identifier: string;
  @attr() declare key: string;
  @attr() declare name: string;
  @attr() declare processingBucket: string;
  @attr() declare recordId: string;
  @attr() declare recordType: string;
  @attr() declare variants: Variants;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'generic-attachment': GenericAttachmentModel;
  }
}
