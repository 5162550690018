import Service, { inject as service } from '@ember/service';
import { isEmpty as _isEmpty } from 'lodash';

import { findUnique } from 'later/utils/array-methods';
import { areStringsEqual } from 'later/utils/compare-strings';
import { NULL_VALUE } from 'later/utils/constants';
import { SegmentEventTypes } from 'later/utils/constants/segment-events';
import filterList from 'later/utils/filter-list';
import objectPromiseProxy from 'later/utils/object-promise-proxy';
import { systemLabelsFromGram, systemLabelsFromPostMediaItems } from 'later/utils/segment/system-labels';

import type RouterService from '@ember/routing/router-service';
import type GramModel from 'later/models/gram';
import type SocialProfileModel from 'later/models/social-profile';
import type AuthService from 'later/services/auth';
import type ErrorsService from 'later/services/errors';
import type PerformanceTrackingService from 'later/services/performance-tracking';
import type SegmentService from 'later/services/segment';
import type { Maybe } from 'shared/types';
import type { JsonObject } from 'type-fest';

export default class SegmentEventsService extends Service {
  @service declare auth: AuthService;
  @service declare errors: ErrorsService;
  @service declare router: RouterService;
  @service declare segment: SegmentService;
  @service declare performanceTracking: PerformanceTrackingService;

  get fromContentIdea(): Maybe<boolean> {
    return Boolean(this.router.currentRoute.queryParams?.contentIdeaId);
  }

  /**
   * Tracks a segment Event for when a social profile attempts connect as
   * a business account
   *
   * @param socialProfile The socialProfile connecting as a business
   * @param page Page from which the event was fired
   */
  trackAttemptedBusinessConnect(socialProfile: SocialProfileModel, page: string): void {
    this.segment.track('attempted-connecting-facebook-credentials', {
      social_profile_id: socialProfile.id,
      group_id: socialProfile.get('group')?.get('id') || '',
      nickname: socialProfile.nickname ?? null,
      name: socialProfile.name ?? null,
      industry: socialProfile.industry ?? null,
      bus_model: socialProfile.businessModel ?? null,
      page
    });
  }

  /**
   * Tracks a segment Event for when a social profile connects as
   * a business account
   *
   * @param socialProfile The socialProfile connecting as a business
   * @param page Page from which the event was fired
   */
  trackBusinessConnect(socialProfile: SocialProfileModel, page: string): void {
    this.segment.track('connected-facebook-credentials', {
      social_profile_id: socialProfile.id,
      group_id: socialProfile.get('group')?.get('id') || '',
      nickname: socialProfile.nickname ?? null,
      name: socialProfile.name ?? null,
      industry: socialProfile.industry ?? null,
      bus_model: socialProfile.businessModel ?? null,
      page
    });
  }

  /**
   * Tracks a segment event for a post if scheduled or saved as a draft
   */
  async trackSavedPostEvent(post: GramModel, unverifiedPostRescheduled = false): Promise<void> {
    const { isDraft } = post;
    const isCopy = Boolean(this.router.currentRoute.queryParams.sourcePostId);
    const profileType = post.get('socialProfile')?.get('profileType') || '';
    const initiatedFrom = this.fromContentIdea ? 'content idea' : '';

    if (isDraft) {
      const area = this.router.currentRouteName.includes('draft') ? 'listview' : 'postmodal';
      this.segment.track(SegmentEventTypes.CreatedDraft, {
        area,
        copied_post: isCopy,
        initiated_from: initiatedFrom,
        profile_type: profileType
      });
    } else {
      await this.scheduledToPlatformEvent(post, initiatedFrom, isCopy);
    }

    if (isCopy) {
      this.segment.track(SegmentEventTypes.CopiedPost, {
        post_type: isDraft ? 'draft' : 'scheduled',
        profile_type: profileType
      });
    }

    if (unverifiedPostRescheduled) {
      this.segment.track('manually-rescheduled-unverified-post', {
        social_profile_id: post.get('socialProfile')?.get('id') ?? null,
        post_id: post.id,
        rescheduled_at: post.scheduledTime ?? null
      });
    }
  }

  async scheduledToPlatformEvent(post: GramModel, initiatedFrom: string, isCopiedPost = false): Promise<void> {
    const profileType = post.get('socialProfile')?.get('profileType') || '';
    const eventName = 'scheduled-to-' + profileType;
    this.segment.track(eventName, {
      system_label: await systemLabelsFromGram(post),
      copied_post: isCopiedPost,
      initiated_from: initiatedFrom,
      profile_type: profileType,
      ...post.get('scheduleEventArgs')
    });
  }

  /**
   * Tracks a segment Event when a multi-profile post is scheduled
   *
   * @param posts The post models that were scheduled
   * @param socialProfiles The socialProfile models that were scheduled
   * @param eventType save or discard event
   */
  async trackMultiSchedule(posts: GramModel[], socialProfiles: SocialProfileModel[], eventType: string): Promise<void> {
    try {
      const profilesList = socialProfiles.mapBy('nickname').join(', ');
      const profileTypes = findUnique(socialProfiles.mapBy('profileType')).join(', ');
      if (eventType === 'save') {
        const {
          calendarDrop,
          isCarouselPost,
          isText,
          isDraft,
          firstPostMediaItem,
          postMediaItems,
          scheduledTime,
          textPostType
        } = posts[0];
        let mediaType;
        let mediaUrl;
        if (isText) {
          mediaType = textPostType;
        } else {
          const firstPMI = await objectPromiseProxy(firstPostMediaItem);
          mediaType = isCarouselPost ? 'multi-photo' : firstPMI.mediaType;
          mediaUrl = firstPMI.isVideo ? firstPMI.videoUrl : firstPMI.imageUrl || firstPMI.previewUrl;
        }
        const photoText = findUnique(posts.mapBy('imageText')).join('');
        performance.mark('EndPostV2');
        const postTime = this.performanceTracking.log('PostV2');
        this.segment.track('scheduled-multi-profile', {
          builder_version: 'v2',
          calendar: calendarDrop ?? null,
          creation_time: Math.round(postTime ?? 0 / 1000),
          initiated_from: this.fromContentIdea ? 'content idea' : '',
          is_draft: isDraft,
          mediaUrl: mediaUrl ?? NULL_VALUE,
          photo_text_present: !areStringsEqual(photoText, NULL_VALUE),
          photo_text: photoText,
          profile_count: posts.length,
          profiles: profilesList,
          profile_types: profileTypes,
          system_label: await systemLabelsFromPostMediaItems(postMediaItems),
          time: scheduledTime ?? null,
          type: mediaType,
          url: mediaUrl ?? NULL_VALUE
        });
      } else {
        this.segment.track('discarded-multi-profile-posts', {
          profile_count: posts.length,
          profiles: profilesList,
          profile_types: profileTypes
        });
      }
    } catch (error) {
      this.errors.log(error);
    }
  }

  /**
   * Tracks segment events for image transformations applied to a post
   */
  async trackAppliedTransformation(post: GramModel): Promise<void> {
    if (!post) {
      return;
    }

    post.get('postMediaItems').forEach(async ({ textboxes, textboxProperties, transformation }) => {
      if (transformation?.serialized_editor_state) {
        const filterImage = transformation.serialized_editor_state.objects[1];
        if (filterImage.filters.length == 1) {
          const pmiFilter = filterImage.filters[0];
          const filterName = filterList.find((f) => f.type == pmiFilter.type)?.displayName;
          this.segment.track('applied-photo-filter', {
            filter_name: filterName ?? null,
            filter_percent: filterImage.opacity || null,
            post_id: post.id,
            profile: post.get('socialProfile')?.get('nickname') ?? null,
            social_platform: post.get('socialProfile')?.get('profileType') ?? null
          });
        }
      }

      const payload = Object.assign({}, textboxProperties, {
        system_label: await systemLabelsFromGram(post),
        post_id: post.id,
        profile: post.get('socialProfile')?.get('nickname'),
        social_platform: post.get('socialProfile')?.get('profileType')
      });

      if (!_isEmpty(textboxes)) {
        this.segment.track('applied-photo-text', payload as JsonObject, {
          user: this.auth.currentUserModel.id,
          user_id: this.auth.currentUserModel.id
        });
      }
    });
  }

  trackWithGAIntegration(eventName: string, payload: JsonObject = {}): void {
    // This isn't necessary to be it's own method now, but want to test removing some of it to see if it improves GA4
    // tracking which in theory should be automatic -iMack Sept 2023
    this.segment.track(eventName, payload);
  }
}

declare module '@ember/service' {
  interface Registry {
    'segment-events': SegmentEventsService;
  }
}
