// Note: dependentKeyCompat is needed for legacy controllers
import { dependentKeyCompat } from '@ember/object/compat';
import Service, { inject as service } from '@ember/service';
import { tracked, TrackedArray } from 'tracked-built-ins';

import { MAX_SELECTED_SOCIAL_PROFILES_COUNT } from 'later/utils/constants';
import { isStringArray } from 'later/utils/type-predicates';

import type RouterService from '@ember/routing/router-service';
import type IntlService from 'ember-intl/services/intl';
import type SocialProfileModel from 'later/models/social-profile';
import type AlertsService from 'later/services/alerts';
import type AuthService from 'later/services/auth';
import type CacheService from 'later/services/cache';

export default class SelectedSocialProfilesService extends Service {
  @service declare alerts: AlertsService;
  @service declare auth: AuthService;
  @service declare cache: CacheService;
  @service declare intl: IntlService;
  @service declare router: RouterService;

  @tracked profiles: TrackedArray<SocialProfileModel> = new TrackedArray([]);

  @dependentKeyCompat
  get cacheKey(): string {
    return `last_profiles_${this.auth.currentGroup?.id}`;
  }

  get cachedProfileIds(): string[] {
    const cachedValue = this.cache.retrieve(this.cacheKey);
    if (isStringArray(cachedValue)) {
      return cachedValue;
    }
    return new TrackedArray([]);
  }

  @dependentKeyCompat
  get firstProfile(): SocialProfileModel | undefined {
    return this.profiles[0];
  }

  @dependentKeyCompat
  get hasMultipleSelected(): boolean {
    return this.profiles.length > 1;
  }

  @dependentKeyCompat
  get profileIds(): string[] {
    return this.profiles.map((profile) => profile.id);
  }

  async addSingleProfile(socialProfile: SocialProfileModel): Promise<void> {
    if (this.profiles.length < MAX_SELECTED_SOCIAL_PROFILES_COUNT) {
      this.profiles.push(socialProfile);
      this.updateCachedProfileIds();
      await this.auth.setSocialProfile(this.firstProfile);
    } else {
      this.alerts.warning(this.intl.t('alerts.header.switching.multi_cal.6_profiles_max.message'), {
        title: this.intl.t('alerts.header.switching.multi_cal.6_profiles_max.title')
      });
    }
  }

  addProfileAndTransition(socialProfile: SocialProfileModel): void {
    // Note: Allow transitions to stay on a particular route if specified within array.
    // This is to prevent transitioning away from views (such as "drafts") when selecting
    // another social profile.
    const defaultRoute = 'cluster.schedule.calendar';
    const transitionableRoutes = [defaultRoute, 'cluster.schedule.drafts'];
    const { currentRouteName } = this.router;
    const isTransitionableRoute = transitionableRoutes.any((routeName) =>
      new RegExp(`^${routeName}(.*)?$`).test(currentRouteName)
    );
    const newRoute = isTransitionableRoute ? currentRouteName : defaultRoute;

    this.addSingleProfile(socialProfile);

    if (currentRouteName != newRoute) {
      this.router.transitionTo(newRoute, { queryParams: this.router.currentRoute.queryParams });
    }
  }

  async removeSingleProfile(socialProfile: SocialProfileModel): Promise<void> {
    const filteredProfiles = this.profiles.filter((currentProfile) => currentProfile.id !== socialProfile.id);
    this.selectExactly(filteredProfiles);
    await this.auth.setSocialProfile(this.firstProfile);
  }

  selectExactly(selectedSocialProfiles: SocialProfileModel[]): void {
    this.profiles = tracked(selectedSocialProfiles);
    this.updateCachedProfileIds();
  }

  selectSingleProfile(socialProfile: SocialProfileModel): void {
    this.profiles = tracked([]);
    this.addProfileAndTransition(socialProfile);
  }

  updateCachedProfileIds(socialProfileIds = this.profileIds): void {
    this.cache.add(this.cacheKey, socialProfileIds, { expiry: this.cache.maxExpiryDate(), persist: true });
  }
}

declare module '@ember/service' {
  interface Registry {
    'selected-social-profiles': SelectedSocialProfilesService;
  }
}
