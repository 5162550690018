/**
 * @module Services
 */
import Service, { inject as service } from '@ember/service';
import { isPresent, isNone } from '@ember/utils';
import { dropTask } from 'ember-concurrency';

import { areStringsEqual } from 'later/utils/compare-strings';
import { ADDRESS_FIELD_KEYS } from 'later/utils/constants';
import { fetch, objectToQueryString } from 'later/utils/fetch';

/**
 * @class AddressValidation
 * @extends Service
 */
export default class AddressValidationService extends Service {
  @service errors;
  @service laterConfig;
  @service jwt;

  /**
   * Dictionary for converting FE values to validation API values
   * validationAPISuggestionKey is for the suggested fields returned from the api that have a slightly different naming convention
   *
   * @property fieldsDictionary
   * @type {string[][]} [FEValue, validationAPIKey, validationAPISuggestionKey]
   * @see {@link https://www.smarty.com/docs/cloud/international-street-api#components}
   */
  fieldsDictionary = [
    [ADDRESS_FIELD_KEYS.CITY, 'locality'],
    [ADDRESS_FIELD_KEYS.COUNTRY, 'country'],
    [ADDRESS_FIELD_KEYS.LINE_ONE, 'address1'],
    [ADDRESS_FIELD_KEYS.STATE, 'administrative_area'],
    [ADDRESS_FIELD_KEYS.POSTAL_CODE, 'postal_code', 'postal_code_short']
  ];

  /**
   * Key that indicates that field is perfectly validated.
   * Address line 1 will only return this value if all other fields are perfectly validated.
   *
   * @constant NO_CHANGE_NEEDED
   * @type {String}
   * @see {@link https://www.smarty.com/docs/cloud/international-street-api#changes}
   */
  NO_CHANGE_NEEDED = 'Verified-NoChange';

  /**
   * Indicates if a field returned from API is completely garbled in which case we could not offer suggestions to the user
   *
   * @constant NOT_FOUND
   * @type {Array<String>}
   * @see {@link https://www.smarty.com/docs/cloud/international-street-api#changes}
   */
  NOT_FOUND = ['Unrecognized', 'Identified-ContextChange'];

  /**
   * Indicates if a field did not validate and needs changes.
   * Is not included on US addresses.
   *
   * @constant CHANGES_NEEDED
   * @type {Array<String>}
   * @see {@link https://www.smarty.com/docs/cloud/international-street-api#changes}
   */
  CHANGES_NEEDED = [
    'Verified-AliasChange',
    'Verified-SmallChange',
    'Verified-LargeChange',
    'Added',
    'Identified-AliasChange',
    'Identified-ContextChange',
    'Unrecognized'
  ];

  /**
   * Indicates if an address can be verified down to premise (building) level.
   * Usually the case if an apartment number is not provided.
   * If an address has a verification level of partial and a precision level of 'Premise',
   * smarty deems it deliverable.
   *
   * @constant PRECISION_LEVEL_PREMISE
   * @type {String}
   * @see {@link https://www.smarty.com/docs/cloud/international-street-api#analysis}
   */
  PRECISION_LEVEL_PREMISE = 'Premise';

  /**
   * Indicates if an address includes a PO Box. We can be a bit more lenient on these.
   *
   * @constant BOX_TYPE_PO_BOX
   * @type {String}
   * @see {@link https://www.smarty.com/docs/cloud/international-street-api#analysis}
   */
  BOX_TYPE_PO_BOX = 'PO Box';

  /**
   * Indicates if a whole address is found or not, though it may still require field level changes.
   * "Ambiguous" verification is allowed because it would be due to a missing company name, which there is no input for.
   * Partial is accepted as long as the precision level is 'Premise'
   *
   * @constant VERIFICATION_LEVEL
   * @type {Array<String>}
   * @see {@link  https://www.smarty.com/docs/cloud/international-street-api#analysis}
   */
  VERIFICATION_LEVEL = {
    NONE: 'None',
    PARTIAL: 'Partial',
    AMBIGUOUS: 'Ambiguous',
    VERIFIED: 'Verified'
  };

  /**
   * Make request to Smarty API via Jolteon proxy.
   *
   * @param {Object} addressFields from form UI.
   * @returns {Array} The raw Smarty Response. Array of found matches, or empty if no address found.
   */
  @dropTask
  *makeRequest(addressFields) {
    const token = yield this.jwt.fetchToken();
    const normalized = this.#normalizeAddress(addressFields);

    const endpoint = `${this.laterConfig.igProxy}/secure-proxy/smarty/verify${objectToQueryString(normalized)}`;
    const config = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    return yield fetch(endpoint, config);
  }

  /**
   * Validate address via Smarty api, and return any changes.
   *
   * @param {Object} addressFields from form UI.
   * @param {Boolean} isAccurateAddressRequired
   *  - True if we want to make sure the address validates to a premise (Example: countries we want to charge tax)
   *  - False for countries where smarty can not validate to a premise due to no building number in address (Example: some addresses in Bahamas)
   * @returns {Array|Null} Array of required changes, or an Empty array if valid and no changes, or null if address not found.
   */
  @dropTask
  *validate(addressFields, isAccurateAddressRequired = true) {
    const [firstFoundAddress] = yield this.makeRequest.perform(addressFields);

    const isFound = isPresent(firstFoundAddress) && this.#isAnalysisValid(firstFoundAddress, isAccurateAddressRequired);

    if (isFound) {
      return this.#requiredChanges(firstFoundAddress, addressFields);
    }
    return null;
  }

  #isAnalysisValid(firstFoundAddress, isAccurateAddressRequired) {
    const { analysis, components } = firstFoundAddress;
    const { verification_status: verificationStatus, address_precision: addressPrecision } = analysis;

    const isPOBox =
      verificationStatus === this.VERIFICATION_LEVEL.PARTIAL && components?.post_box_type === this.BOX_TYPE_PO_BOX;

    const isPartialPremise = this.VERIFICATION_LEVEL.PARTIAL && addressPrecision === this.PRECISION_LEVEL_PREMISE;

    return (
      verificationStatus === this.VERIFICATION_LEVEL.VERIFIED ||
      verificationStatus === this.VERIFICATION_LEVEL.AMBIGUOUS ||
      (verificationStatus === this.VERIFICATION_LEVEL.PARTIAL && !isAccurateAddressRequired) ||
      isPOBox ||
      isPartialPremise
    );
  }

  #normalizeAddress(addressFields) {
    const normalized = {};

    this.fieldsDictionary.forEach(
      ([key, normalizedKey]) => (normalized[normalizedKey] = encodeURIComponent(addressFields[key]))
    );
    return normalized;
  }

  /**
   * Assembles suggestions for the user based on field level analysis from the API
   *
   * @method #requiredChanges
   * @param {Object} obj Suggested address and analysis from API
   * @param {Object} obj.analysis The verification status. For non-US: includes the status of changes made for each address component
   * @param {Object} obj.suggestedValidFields Suggested address from API: core address components
   * @param {Object} obj.suggestedLineInputs Suggested additional address components from API: includes address1 and address2
   * @param {Object} addressFields User inputed address
   *
   * @returns {Array.<Object>} An Array of objects with properties: key and suggested. An empty array will be returned if no changes are needed.
   */
  #requiredChanges({ analysis, components: suggestedValidFields, ...suggestedLineInputs }, addressFields) {
    const { changes } = analysis;
    const fieldVerification = { ...changes, ...changes.components };
    const suggestedChanges = [];

    this.fieldsDictionary.forEach(([FEKey, APIKey, APISuggestionKey]) => {
      // Example: V4L 1X2 or Vancouver
      let validSuggestion = suggestedValidFields[APIKey] || suggestedLineInputs[APIKey];

      // Note: At this point only the USA needs to use postal_code_short, not postal_code.
      if (suggestedValidFields.country_iso_3 === 'USA' && APISuggestionKey) {
        validSuggestion = suggestedValidFields[APISuggestionKey] || suggestedLineInputs[APISuggestionKey];
      }

      // Note: For US, this will be undefined every time because fieldVerification will be empty.
      const fieldChangesNeeded = fieldVerification[APIKey];

      const isSuggestionDifferentFromInput =
        !isNone(validSuggestion) && !areStringsEqual(addressFields[FEKey], validSuggestion);

      const isFieldUnrecognized = this.NOT_FOUND.includes(fieldChangesNeeded);

      // No suggestion needed if:
      // - validation says no change needed (non-US address)
      // - or fallback to checking if the api response suggestion is the same as what we sent in
      if (fieldChangesNeeded === this.NO_CHANGE_NEEDED || (!isSuggestionDifferentFromInput && !isFieldUnrecognized)) {
        return;
      }

      suggestedChanges.addObject({
        key: FEKey,
        suggested: isFieldUnrecognized ? '' : validSuggestion
      });
    });
    return suggestedChanges;
  }
}
