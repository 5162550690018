import { assert, debug, runInDebug } from '@ember/debug';
import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { isObject } from 'lodash';

import { convert } from 'later/utils/time-format';
import { days, hours, minutes } from 'shared/utils/time';

import type LocalStorageManagerService from 'later/services/local-storage-manager';
import type { UntypedService } from 'shared/types';
import type { JsonValue } from 'type-fest';

interface CacheEntryArgs<ValueType extends CacheableValue = CacheableValue> {
  value: ValueType;
  expiry: Date | number;
  persist?: boolean;
}

type AddOptions = Pick<CacheEntryArgs, 'expiry' | 'persist'>;

export type CacheableValue = JsonValue;

type CacheExpiryInterval = 'minute' | 'minutes' | 'hour' | 'hours' | 'day' | 'days';

/**
 * A single item in the cache
 */
class CacheEntry<ValueType extends CacheableValue = CacheableValue> {
  value: ValueType;
  expiry: Date | number;
  persist: boolean;

  constructor({ value, expiry = new Date(), persist = false }: CacheEntryArgs<ValueType>) {
    this.value = value;
    this.expiry = expiry;
    this.persist = persist;
  }

  toPojo(): { value: ValueType; expiry: number; persist: boolean } {
    return {
      value: this.value,
      expiry: Number(this.expiry),
      persist: this.persist
    };
  }

  isExpired(): boolean {
    return new Date(this.expiry) < new Date();
  }
}

const MAX_EXPIRY_IN_HOURS = convert.days(365).toHours();

/**
 * A general purpose cache that can be used to store any data
 * that should live for the life of the application or even longer via localStorage
 */
export default class CacheService extends Service {
  @service declare errors: UntypedService;
  @service declare localStorageManager: LocalStorageManagerService;

  /**
   * The cache object which holds all cached data.
   * It is a key/value store where all values are CacheEntries, to ensure uniformity.
   */
  @tracked _cache: Record<string, CacheEntry> = {};

  readonly localStoragePrefix = '_cache_';

  maxExpiryDate(): Date {
    return this.expiry(MAX_EXPIRY_IN_HOURS, 'hours');
  }

  expiry(num: number, interval: CacheExpiryInterval): Date {
    const lowercaseInterval = interval.toLowerCase();
    switch (lowercaseInterval) {
      case 'minute':
      case 'minutes':
        return minutes(num);
      case 'hour':
      case 'hours':
        return hours(num);
      case 'day':
      case 'days':
        return days(num);
      default:
        throw new Error(`Invalid expiry interval "${interval}"`);
    }
  }

  constructor(...args: Record<string, unknown>[]) {
    super(...args);

    this.#clear();
    this.#populateCacheFromLocalStorage();
  }

  /**
   * Clear the items in the cache object that have keys that include the given keyword.
   */
  clearCacheByKeyword(keyword: string): void {
    const cacheKeysIncludingKeyword = Object.keys(this._cache).filter((key) => key.includes(keyword));
    cacheKeysIncludingKeyword?.forEach((key) => this.remove(key));
  }

  /**
   * Adds an entry to the cache.
   * In debug mode the entire cache will be logged after adding
   *
   * @param key The key that will be used for storing and looking up this CacheEntry
   * @param value The data that will be cached
   * @param config.expiry When this entry should expire. Must be a javascript Date object
   * @param config.persist Whether or not the item should be saved to localStorage for persistance across page load.
   *
   * @returns The raw value that was added to the cache. Not the full CacheEntry
   */
  add<ValueType extends CacheableValue>(
    key: string,
    value: ValueType,
    { expiry, persist }: AddOptions
  ): ValueType | void {
    const maxExpiry = this.maxExpiryDate();
    const validExpiry = expiry < maxExpiry ? expiry : maxExpiry;
    const cacheEntry = new CacheEntry<ValueType>({ value, expiry: validExpiry, persist });

    assert('Your expiry must be in the future', !cacheEntry.isExpired());

    const sanitizedKey = this.#sanitizeKey(key);

    runInDebug(() => {
      const cacheLookup = this.retrieve(sanitizedKey);
      if (cacheLookup) {
        debug(`Note: "${sanitizedKey}" was already set to ${cacheLookup}. Overwriting to "${value}".`);
      }
    });

    try {
      if (!persist) {
        this.localStorageManager.removeItem(sanitizedKey);
      }
      this._cache[sanitizedKey] = cacheEntry;

      debug(`Note: "${sanitizedKey}" was added with value "${value}".`);

      if (persist) {
        const hoursInFuture = (Number(validExpiry) - Number(new Date())) / convert.hour().toMilliseconds();
        this.localStorageManager.setItem(
          `${this.localStoragePrefix}${sanitizedKey}`,
          this._cache[sanitizedKey].toPojo(),
          hoursInFuture
        );
      }

      return cacheEntry.value;
    } catch (error) {
      this.errors.log(new Error(`Could not add "${sanitizedKey}" to cache`), {
        key: sanitizedKey,
        value,
        expiry,
        persist,
        error
      });
    }
  }

  /**
   * Removes an entry from the cache.
   * In debug mode the entire cache will be logged after removal
   *
   * @param key The lookup key used to find the CacheEntry to remove
   */
  remove(key: string): void {
    const sanitizedKey = this.#sanitizeKey(key);

    this.localStorageManager.removeItem(`${this.localStoragePrefix}${sanitizedKey}`);
    delete this._cache[sanitizedKey];
  }

  /**
   * Finds and returns a CacheEntry's value.
   * Will look at LocalStorage, if the entry is not present in cache.
   *
   * @param key The key used for looking up this CacheEntry
   */
  retrieve<ValueType = CacheableValue>(key: string): ValueType | undefined {
    const sanitizedKey = this.#sanitizeKey(key);

    try {
      let cacheEntry: CacheEntryArgs | null = this._cache[sanitizedKey];
      if (!cacheEntry) {
        cacheEntry = this.localStorageManager.getItem(`${this.localStoragePrefix}${sanitizedKey}`);

        if (!cacheEntry) {
          debug(`Note: "${sanitizedKey}" was not present in localStorage or Ember cache.`);
          return undefined;
        }
      }
      const _cacheEntry = new CacheEntry(cacheEntry);

      if (_cacheEntry?.isExpired()) {
        this.remove(sanitizedKey);
        return undefined;
      }

      return _cacheEntry.value as ValueType;
    } catch (error) {
      this.errors.log(new Error(`Could not retrieve "${sanitizedKey}" from cache`), { key: sanitizedKey, error });
      return undefined;
    }
  }

  /**
   * Empties the cache.
   */
  #clear(): void {
    this._cache = {};
  }

  /**
   * Populates the cache with the persisted values in localstorage.
   * Should only be called on init as it replaces everything in the cache.
   */
  #populateCacheFromLocalStorage(): void {
    const contents = this.localStorageManager.getContentsByPrefix(this.localStoragePrefix) as unknown as Record<
      string,
      CacheEntryArgs
    >;

    const _contents: [string, CacheEntryArgs][] = Object.entries(contents).reduce<[string, CacheEntryArgs][]>(
      (acc, [k, v]) => (this.#isValidCacheEntry(v) ? [...acc, [k, v]] : acc),
      []
    );
    this._cache = Object.fromEntries(_contents.map(([key, value]) => [key, new CacheEntry(value)]));
  }

  #isValidCacheEntry(entry: unknown): entry is CacheEntryArgs {
    if (!isObject(entry) || Array.isArray(entry)) return false;

    const matchesSchema = Object.keys(entry).every((key) => ['value', 'expiry', 'persist'].includes(key));

    return matchesSchema;
  }

  /**
   * Take a key and sanitize any illegal symbols to be safe for cacheEntry.
   * e.g. cacheEntry keys will error if they contain '.' symbols.
   *
   * @param key The key used for looking up a cacheEntry value
   *
   * @returns The sanitized key for looking up a cacheEntry value
   */
  #sanitizeKey(key: string): string {
    const sanitizedKey = key.replace('.', '_');
    if (key !== sanitizedKey) {
      debug(`Note: ${key} was sanitized to ${sanitizedKey}. Keys cannot contain '.' symbols.`);
    }
    return sanitizedKey;
  }
}

declare module '@ember/service' {
  interface Registry {
    cache: CacheService;
  }
}
