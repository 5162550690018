import { action } from '@ember/object';

import CalendarMediaRoute from 'calendar/routes/media';

export default class DraftsMediaRoute extends CalendarMediaRoute {
  @action
  closeModal(): void {
    this.router.transitionTo('cluster.schedule.drafts', { queryParams: this.router.currentRoute.queryParams });
  }
}
