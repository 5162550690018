import type IntlService from 'ember-intl/services/intl';

/**
 * Mock subscription plan for enterprise card on plans page and checkout.
 * Until we are actually selling an enterprise plan we will not have a subscription plan
 * returned from the BE, this is a modified version of the Advanced plan needed to display
 * plan card and features modal
 *
 */
export default function enterprisePlanData(intl: IntlService): Record<string, unknown> {
  return {
    active: true,
    amount: 1, // Note: we do not actually want to display the price anywhere, but certain functions require a price to be greater than 0 to be considered a paid plan
    features: {
      analytics_export: true,
      analytics_performance_report: true,
      analytics_standard: true,
      bttp: true,
      calendar_notes: true,
      caption_hashtags: true,
      change_media: true,
      chat: true,
      chrome_extension: true,
      collect_hashtags: true,
      collect_mentions: true,
      contact_form: true,
      contact_us: true,
      contributors: true,
      conversations: true,
      copy_posts: true,
      create_media_kit: true,
      draft_posts: true,
      facebook_analytics: true,
      facebook_reels: true,
      filters: true,
      first_comment: true,
      groups: true,
      hashtag_analytics: true,
      hashtag_suggestions: true,
      hashtag_trends: true,
      ig_carousel_auto_publish: true,
      ig_product_tagging: true,
      ig_reels_analytics: true,
      ig_reels_conversations: true,
      ig_stories_scheduling: true,
      influencer_opt_in: true,
      lib_analytics_heatmap: true,
      linkedin: true,
      linkinbio_bannerless: true,
      linkinbio_basic_themes: true,
      linkinbio_button_background_color: true,
      linkinbio_button_colour: true,
      linkinbio_button_shadow: true,
      linkinbio_button_shape: true,
      linkinbio_button_style: true,
      linkinbio_button_text_color: true,
      linkinbio_choose_handle: true,
      linkinbio_embeddable: true,
      linkinbio_extended_click_analytics: true,
      linkinbio_featured_media_embedded_video: true,
      linkinbio_featured_media_image: true,
      linkinbio_image_upload_page_background: true,
      linkinbio_mailchimp: true,
      linkinbio_many_linked_social_posts: true,
      linkinbio_page_background_color: true,
      linkinbio_page_font: true,
      linkinbio_page_text_color: true,
      linkinbio_premium_themes: true,
      linkinbio_reels: true,
      linkinbio_shopify: false,
      linkinbio_social_link_list_block: true,
      linkinbio_standard: true,
      linkinbio_subtle_banner: false,
      linkinbio_tiktok_post_support: true,
      location_tagging: true,
      longer_analytics: true,
      multi_photo: true,
      multi_photo_video: true,
      multi_profile_scheduling: true,
      photos: true,
      pinterest_analytics: true,
      pinterest_click_tracking: true,
      saved_captions: true,
      schedule_sharing: true,
      search_by_ig_profile: true,
      search_influencers: true,
      share_media_kit: true,
      stock_photo: true,
      support_docs: true,
      support_email: true,
      support_messaging: true,
      text_on_image: true,
      tiktok: true,
      tiktok_analytics: true,
      tiktok_bttp: true,
      tiktok_conversations: true,
      tiktok_linkinbio: true,
      twitter_analytics: true,
      twitter_click_tracking: true,
      unlimited_media: true,
      upload_large_media: true,
      upload_xl_media: true,
      user_location_tagging: true,
      user_tagging: true,
      video: true,
      video_cover_select: true,
      visual_planner: true
    },
    interval: 'monthly',
    linkinBioEnabled: true,
    name: intl.t('plans.plan_names.enterprise'),
    numberOfAiCredits: intl.t('shared_words.custom'),
    numberOfBttpSlots: 7,
    numberOfCollectTags: -1,
    numberOfFacebookPosts: -1,
    numberOfFacebookProfiles: -1,
    numberOfHashtagSearches: -1,
    numberOfInstagramPosts: -1,
    numberOfInstagramProfiles: -1,
    numberOfLinkinbioButtonGroups: 100,
    numberOfLinkinbioFeaturedBanners: 1,
    numberOfLinkinbioFeaturedMediaCustomMedia: 1,
    numberOfLinkinbioFeeds: 2,
    numberOfLinkinbioMultiItemBannerItems: 100,
    numberOfPinterestPosts: -1,
    numberOfPinterestProfiles: 6,
    numberOfPosts: -1,
    numberOfSocialSets: -1,
    numberOfTiktokPosts: -1,
    numberOfTiktokProfiles: -1,
    numberOfTwitterPosts: -1,
    numberOfTwitterProfiles: -1,
    numberOfUsers: -1,
    numberOfYoutubePosts: -1,
    numberOfYoutubeProfiles: -1,
    paid: true,
    planType: 'enterprise'
  };
}
