import { inject as service } from '@ember/service';
import { isEmpty, isNone, isPresent } from '@ember/utils';
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { tracked } from '@glimmer/tracking';
import { memberAction } from 'ember-api-actions';
import { task } from 'ember-concurrency';
import moment from 'moment';
import { TrackedArray } from 'tracked-built-ins';

import IgPreviewPost from 'later/models/ig-preview-post';
import LabelModel from 'later/models/label';
import { TEST_IMAGE_ID } from 'later/utils/constants';

import type { AsyncBelongsTo, AsyncHasMany } from '@ember-data/model';
import type { TaskGenerator } from 'ember-concurrency';
import type GramModel from 'later/models/gram';
import type GroupModel from 'later/models/group';
import type SocialProfileModel from 'later/models/social-profile';
import type UserModel from 'later/models/user';
import type ErrorsService from 'later/services/errors';
import type { Maybe } from 'shared/types';

export default class MediaItemModel extends Model {
  @service('errors') declare errorsService: ErrorsService;

  @tracked isStarred = false;
  @tracked isUploading = false;
  @tracked previewDataUrl = null;
  @tracked queryLists = new TrackedArray<string>();
  @tracked searchTerms = new TrackedArray<string>();
  @tracked uploadProgress = 0.0;
  @tracked visiblePosts = new TrackedArray<GramModel>();

  @attr('boolean', { defaultValue: true }) declare active: boolean;
  @attr({ defaultValue: undefined }) declare altText?: string;
  @attr('boolean', { defaultValue: true }) declare approved: boolean;
  @attr({ defaultValue: null }) declare collectSource: string | null;
  @attr('boolean', { defaultValue: false }) declare readonly contribution: boolean;
  @attr('number') declare readonly createdTime: number;
  @attr('string', { defaultValue: '' }) declare defaultCaption: string;
  @attr({ defaultValue: null }) declare readonly duplicatedMediaItemId: string | null;
  @attr('boolean', { defaultValue: false }) declare readonly failed: boolean;
  @attr('number') declare readonly fileSize: number;
  @attr('number') declare height: number;
  @attr('string') declare readonly highResUrl: string;
  @attr('string') declare readonly imageBucket: string;
  @attr('string') declare readonly imageKey: string;
  @attr('string') declare imageUrl: string;
  @attr('string') declare readonly largeThumbnailUrl: string;
  @attr({ defaultValue: null }) declare latitude: number | null;
  @attr({ defaultValue: null }) declare longitude: number | null;
  @attr('string') declare readonly lowResUrl: string;
  @attr('string') declare mediaType: string;
  @attr('string') declare readonly medResUrl: string;
  @attr('string') declare readonly medThumbnailUrl: string;
  @attr({ defaultValue: undefined }) declare readonly notesWhenApproved: string | undefined;
  @attr({ defaultValue: null }) declare originalFilename: string | null;
  @attr('boolean', { defaultValue: false }) declare processing: boolean;
  @attr({ defaultValue: null }) declare processingBucket: string | null;
  @attr({ defaultValue: null }) declare processingKey: string | null;
  @attr({ defaultValue: undefined }) declare processingUrl: string | undefined;
  @attr({ defaultValue: () => [] }) declare readonly publishingDim: [number, number];
  @attr({ defaultValue: null }) declare readonly publishingFileSize: number;
  @attr('string') declare readonly publishingUrl: string;
  @attr({ defaultValue: () => [] }) declare readonly rawPostIds: number[];
  @attr({ defaultValue: undefined }) declare showModal: boolean | undefined;
  @attr('string') declare readonly smallThumbnailUrl: string;
  // @ts-expect-error Fixed in @types/ember-data v4
  @attr('array') declare readonly socialProfileIds: number[];
  @attr({ defaultValue: null }) declare sourceMediaId: string | null;
  @attr({ defaultValue: null }) declare sourcePostId: string | null;
  @attr({ defaultValue: undefined }) declare sourceType: string | undefined;
  @attr({ defaultValue: undefined }) declare sourceUrl: string | undefined;
  @attr({ defaultValue: null }) declare sourceUsername: string | null;
  @attr({ defaultValue: undefined }) declare readonly tempHeight: number | undefined;
  @attr({ defaultValue: undefined }) declare readonly tempWidth: number | undefined;
  @attr({ defaultValue: undefined }) declare readonly thumbBucket: string | undefined;
  @attr({ defaultValue: undefined }) declare readonly thumbKey: string | undefined;
  @attr('string') declare readonly thumbUrl: string;
  @attr('number') declare readonly userId: number;
  @attr({ defaultValue: null }) declare readonly videoBucket: string | null;
  @attr({ defaultValue: null }) declare readonly videoDuration: number | null; // accurate to the second;
  @attr({ defaultValue: null }) declare readonly videoDurationMilliseconds: number | null; // accurate to the millisecond
  @attr({ defaultValue: null }) declare readonly videoFrameRate: number | null;
  @attr({ defaultValue: null }) declare readonly videoKey: string | null;
  @attr({ defaultValue: null }) declare readonly videoUrl: string | null;
  @attr('number') declare width: number;
  @attr('string') declare readonly workingUrl: string;

  @belongsTo('group', { async: true }) declare group: AsyncBelongsTo<GroupModel>;
  @belongsTo('user', { async: true }) declare submittedBy: AsyncBelongsTo<UserModel>;
  @hasMany('gram', { async: true }) declare grams: AsyncHasMany<GramModel>;
  @hasMany('label', { async: true }) declare labels: AsyncHasMany<LabelModel>;

  get isGif(): boolean {
    return this.mediaType === 'gif';
  }

  get isImage(): boolean {
    return this.mediaType === 'image';
  }

  get isTestImage(): boolean {
    return this.duplicatedMediaItemId === TEST_IMAGE_ID;
  }

  get isVideo(): boolean {
    return this.mediaType === 'video';
  }

  get hasPosts(): boolean {
    return this.postCount > 0;
  }

  get labelIds(): string[] {
    return this.labels.map((label: LabelModel) => label.id);
  }

  get labelNames(): string[] {
    return this.labels.map((label: LabelModel) => label.canonicalTagName);
  }

  get publishingHeight(): Maybe<number> {
    return this.publishingDim?.[1];
  }

  get publishingWidth(): Maybe<number> {
    return this.publishingDim?.[0];
  }

  get postCount(): number {
    return this.rawPostIds.length;
  }

  // we only want to use this if there's an empty crop_array
  get aspectRatio(): number {
    if (this.publishingWidth && this.publishingHeight) {
      return this.publishingWidth / this.publishingHeight;
    } else if (this.tempWidth && this.tempHeight) {
      return this.tempWidth / this.tempHeight;
    } else if (this.width && this.height) {
      return this.width / this.height;
    }
    return -1;
  }

  get isValidImageFormat(): boolean {
    if (!this.isImage || !this.originalFilename) {
      return false;
    }

    const fileNameSplit = this.originalFilename.split('.');
    if (fileNameSplit.length) {
      const imageFormat = fileNameSplit[fileNameSplit.length - 1];
      const validImageTypes = ['jpg', 'gif', 'png', 'ico', 'bmp'];
      return validImageTypes.includes(imageFormat);
    }
    return false;
  }

  get isVideoProcessed(): boolean {
    return Boolean(!this.processing && this.videoDuration);
  }

  get needsApproval(): boolean {
    if (isPresent(this.labels)) {
      return this.labels.isAny('tagName', '^rights-pending');
    }
    return false;
  }

  get largeDisplayUrl(): string {
    if (!isNone(this.previewDataUrl)) {
      return this.previewDataUrl;
    } else if (window.devicePixelRatio >= 2) {
      return this.highResUrlOrImageUrl;
    }
    return this.medResOrProcessingUrl;
  }

  get createdToday(): boolean {
    const beginningOfToday = Math.floor(Number(moment().startOf('day')) / 1000);
    return this.createdTime >= beginningOfToday;
  }

  get instagramSizeWarning(): boolean {
    return this.height > 0 && this.height < 640 && this.width > 0 && this.width < 640;
  }

  get tooSmallInstagram(): boolean {
    return this.height > 0 && this.height < 320 && this.width > 0 && this.width < 320;
  }

  get createdLastWeek(): boolean {
    const oneWeekAgo = Math.floor(Number(moment().subtract(1, 'week').startOf('day')) / 1000);
    const beginningOfToday = Math.floor(Number(moment().startOf('day')) / 1000);
    return this.createdTime >= oneWeekAgo && this.createdTime < beginningOfToday;
  }

  get createdLastMonth(): boolean {
    const oneWeekAgo = Math.floor(Number(moment().subtract(1, 'week').startOf('day')) / 1000);
    const oneMonthAgo = Math.floor(Number(moment().subtract(1, 'month').startOf('day')) / 1000);
    return this.createdTime >= oneMonthAgo && this.createdTime < oneWeekAgo;
  }

  get createdEarlier(): boolean {
    const oneMonthAgo = Math.floor(Number(moment().subtract(1, 'month').startOf('day')) / 1000);
    return this.createdTime < oneMonthAgo;
  }

  get pending(): boolean {
    return !this.approved && this.active;
  }

  get rejected(): boolean {
    return !this.approved && !this.active;
  }

  get isRecent(): boolean {
    const diff = moment().unix() - this.createdTime;
    return diff < 60; // less than 1 minutes old
  }

  get thumbnailOrProcessingUrl(): string {
    if (!isNone(this.smallThumbnailUrl)) {
      return this.smallThumbnailUrl;
    } else if (!isNone(this.thumbUrl)) {
      return this.thumbUrl;
    }
    return this.processingImageUrl;
  }

  get mediaLibraryImageUrl(): string {
    if (window.devicePixelRatio >= 2) {
      return this.largeThumbnailOrProcessingUrl;
    }
    return this.medThumbnailOrProcessingUrl;
  }

  get sideLibraryImageUrl(): string {
    if (window.devicePixelRatio >= 2) {
      return this.medThumbnailOrProcessingUrl;
    }
    return this.smallThumbnailOrProcessingUrl;
  }

  get workingUrlOrProcessingImage(): string {
    if (!isNone(this.workingUrl)) {
      return this.workingUrl;
    }
    return this.processingImageUrl;
  }

  get smallThumbnailOrProcessingUrl(): string {
    if (!isNone(this.smallThumbnailUrl)) {
      return this.smallThumbnailUrl;
    }
    return this.processingImageUrl;
  }

  get medThumbnailOrProcessingUrl(): string {
    if (!isNone(this.medThumbnailUrl)) {
      return this.medThumbnailUrl;
    }
    return this.smallThumbnailOrProcessingUrl;
  }

  get largeThumbnailOrProcessingUrl(): string {
    if (!isNone(this.largeThumbnailUrl)) {
      return this.largeThumbnailUrl;
    }
    return this.medThumbnailOrProcessingUrl;
  }

  get highResUrlOrImageUrl(): string {
    if (isNone(this.highResUrl)) {
      return this.imageUrl;
    }
    return this.highResUrl;
  }

  get lowResOrProcessingUrl(): string {
    if (!isNone(this.previewDataUrl)) {
      return this.previewDataUrl;
    } else if (!isNone(this.lowResUrl)) {
      return this.lowResUrl;
    }
    return this.processingImageUrl;
  }

  get medResOrProcessingUrl(): string {
    if (!isNone(this.previewDataUrl)) {
      return this.previewDataUrl;
    } else if (!isNone(this.medResUrl)) {
      return this.medResUrl;
    }
    return this.processingImageUrl;
  }

  get highResOrProcessingUrl(): string {
    if (!isNone(this.previewDataUrl)) {
      return this.previewDataUrl;
    } else if (!isNone(this.highResUrl)) {
      return this.highResUrl;
    }
    return this.processingImageUrl;
  }

  get processingImageUrl(): string {
    if (this.isRecent || isNone(this.id)) {
      return 'https://s3.amazonaws.com/lg-image-prod/img--imageprocessing.gif';
    }
    return '/api/v2/media_items/' + this.id + '/processing_image';
  }

  checkImages = memberAction<never, void>({ path: 'check_images' });

  async assertLabelAndApply(textLabel: string): Promise<void | Error> {
    try {
      const groupLabels = (await this.group).labels;
      //need to check for system labels

      if (groupLabels.isAny('tagName', textLabel)) {
        //system label already exists; add it to the media
        const label = groupLabels.findBy('tagName', textLabel);
        if (label instanceof LabelModel) {
          this.labels.addObject(label);
        }
        return;
      }

      //system label needs be be created for add
      const newLabel = this.store.createRecord('label', {
        tagName: textLabel,
        group: this.group
      });
      const label = await newLabel.save();
      if (label instanceof LabelModel) {
        this.labels.addObject(label);
      }
    } catch (error) {
      this.errorsService.log(error);
      return error;
    }
  }

  matchesSearchTerm(searchTerm: string): boolean {
    return this.searchTerms.includes(searchTerm);
  }

  addSearchTerm(searchTerm: string): void {
    if (!this.searchTerms.includes(searchTerm)) {
      this.searchTerms.addObject(searchTerm);
    }
  }

  removeSearchTerm(searchTerm: string): void {
    this.searchTerms.removeObject(searchTerm);
  }

  clearSearchTerms(): void {
    this.searchTerms.clear();
  }

  inDateFilter(earliestDate: number, latestDate: number): boolean {
    if (typeof earliestDate === 'number' && typeof latestDate === 'number') {
      return this.createdTime >= earliestDate && this.createdTime <= latestDate;
    } else if (typeof earliestDate === 'number') {
      return this.createdTime >= earliestDate;
    } else if (typeof latestDate === 'number') {
      return this.createdTime <= latestDate;
    }
    return true; //in case no date filter set; should show anyway --G
  }

  igPreviewPost(socialProfile: SocialProfileModel, time: number): IgPreviewPost {
    return IgPreviewPost.create({
      id: 'ig-mi-' + this.id,
      socialProfile,
      mediaItem: this,
      scheduledTime: time,
      newScheduledTime: time
    });
  }

  @task
  *fetchVisiblePosts(): TaskGenerator<void> {
    try {
      const useRawIds = isEmpty(this.grams) && !isEmpty(this.rawPostIds);
      const posts = useRawIds ? yield this.#fetchGramsFromRawIds() : this.grams;
      this.visiblePosts = posts.filter((post: GramModel) => {
        return post?.active;
      });
    } catch (error) {
      this.errorsService.log(error);
    }
  }

  #fetchGramsFromRawIds(): Promise<GramModel[]> {
    try {
      return Promise.all(this.rawPostIds.map(async (gramId: number) => await this.store.findRecord('gram', gramId)));
    } catch (error) {
      this.errorsService.log(error);
    }
    return Promise.resolve([]);
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'media-item': MediaItemModel;
  }
}
