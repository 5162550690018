import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{! @glint-nocheck: not typesafe yet }}\n<div ...attributes class=\"cPL--postWrapper\" data-test-id=\"post-list\">\n  {{#if (has-block \"banner\")}}\n    <div class=\"cPL--postWrapper__banner\">\n      {{yield to=\"banner\"}}\n    </div>\n  {{/if}}\n  {{#if this.isEmpty}}\n    <Schedule::PostList::Empty />\n  {{else}}\n    <ul class=\"cPL--postList\" data-test-id=\"post-list-cards\">\n      {{#each (sort-by \"scheduledTime\" @posts) as |post|}}\n        <Schedule::PostList::Card @editRoute={{@editRoute}} @post={{post}} />\n      {{/each}}\n    </ul>\n  {{/if}}\n</div>", {"contents":"{{! @glint-nocheck: not typesafe yet }}\n<div ...attributes class=\"cPL--postWrapper\" data-test-id=\"post-list\">\n  {{#if (has-block \"banner\")}}\n    <div class=\"cPL--postWrapper__banner\">\n      {{yield to=\"banner\"}}\n    </div>\n  {{/if}}\n  {{#if this.isEmpty}}\n    <Schedule::PostList::Empty />\n  {{else}}\n    <ul class=\"cPL--postList\" data-test-id=\"post-list-cards\">\n      {{#each (sort-by \"scheduledTime\" @posts) as |post|}}\n        <Schedule::PostList::Card @editRoute={{@editRoute}} @post={{post}} />\n      {{/each}}\n    </ul>\n  {{/if}}\n</div>","moduleName":"later/components/schedule/post-list.hbs","parseOptions":{"srcName":"later/components/schedule/post-list.hbs"}});
import Component from '@glimmer/component';

import type GramModel from 'later/models/gram';

interface PostListComponentArgs {
  posts?: GramModel[];
  editRoute?: string;
}

export default class PostListComponent extends Component<PostListComponentArgs> {
  get isEmpty(): boolean {
    return !this.args.posts?.length;
  }
}
