import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

import type RouterService from '@ember/routing/router-service';

export default class PlannerRoute extends Route {
  @service declare router: RouterService;

  beforeModel(): void {
    this.router.transitionTo('cluster.planner.content-pillars');
  }
}
