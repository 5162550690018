import { readOnly } from '@ember/object/computed';
import Service, { inject as service } from '@ember/service';
import '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import momentTimezonePlugin from '@fullcalendar/moment-timezone';
import timeGridPlugin from '@fullcalendar/timegrid';
import { task } from 'ember-concurrency';
import 'moment-timezone';
import NProgress from 'nprogress';
import { tracked } from 'tracked-built-ins';

import patchedInteractionPlugin from 'later/utils/calendar/fullcal-rightclick';
import { CALENDAR_TYPES, CALENDAR_VIEW_TYPES } from 'later/utils/constants';

import type { Calendar, CalendarOptions } from '@fullcalendar/core';
import type { TaskGenerator } from 'ember-concurrency';
import type SocialProfileModel from 'later/models/social-profile';
import type AuthService from 'later/services/auth';
import type OnboardingService from 'later/services/onboarding';
import type SelectedSocialProfilesService from 'later/services/selected-social-profiles';
import type UserConfigService from 'later/services/user-config';
import type { CalendarViews } from 'later/utils/constants';
import type { Maybe } from 'shared/types';

interface HeaderToolbar {
  left: string;
  right: string;
}

const { DEFAULT, STORIES } = CALENDAR_TYPES;
const { WEEK } = CALENDAR_VIEW_TYPES;

export default class ConfigService extends Service {
  @service declare auth: AuthService;
  @service declare onboarding: OnboardingService;
  @service declare selectedSocialProfiles: SelectedSocialProfilesService;
  @service declare userConfig: UserConfigService;

  @tracked startTime: Maybe<Date> = null;
  @tracked endTime: Maybe<Date> = null;

  // Note: This constant can eventually be replaced with a TypeScript enum
  calendarType: typeof STORIES | typeof DEFAULT = DEFAULT;
  fcView: CalendarViews = WEEK;
  fullCalendar: Maybe<Calendar> = null;

  @readOnly('selectedSocialProfiles.hasMultipleSelected') declare multipleProfilesSelected: boolean;
  @readOnly('selectedSocialProfiles.firstProfile') declare singleSocialProfile: SocialProfileModel;

  get timeZoneIdentifier(): string {
    return this.userConfig.currentTimeZone?.identifier || '';
  }

  get calendarView(): CalendarViews {
    return this.fcView;
  }

  set calendarView(value: CalendarViews) {
    this.fcView = value;
  }

  get commonConfig(): CalendarOptions {
    return {
      allDaySlot: false,
      headerToolbar: this.headerToolbar,
      nowIndicator: true,
      // Note: Using patchedInteractionPlugin instead of interactionPlugin to support right-clicking
      plugins: [dayGridPlugin, timeGridPlugin, listPlugin, patchedInteractionPlugin, momentTimezonePlugin],
      progressiveEventRendering: true,
      scrollTime: '07:00:00',
      snapDuration: '00:05:00',
      timeZone: this.timeZoneIdentifier
    };
  }

  get headerToolbar(): HeaderToolbar {
    // Note: Join is necessary here due to an issue with fullcalendar (https://stackoverflow.com/questions/64500933/fullcalendar-headertoolbar-button-displays-as-two-buttons)
    return {
      left: this.leftHeaders.join(' '),
      right: this.rightHeaders.join(' ')
    };
  }

  get leftHeaders(): string[] {
    const defaultCalendar = this.calendarType === DEFAULT;
    const defaultLeft = ['today,prev,next title'];
    const storiesLeft = ['today,prev,next title'];
    return defaultCalendar ? defaultLeft : storiesLeft;
  }

  get rightHeaders(): string[] {
    const defaultCalendar = this.calendarType === DEFAULT;
    const displayAdditionalButtons = this.singleSocialProfile?.isInstagram && !this.multipleProfilesSelected;
    const socialProfileButtons = displayAdditionalButtons
      ? 'timezoneButton storiesButton previewButton,'
      : 'timezoneButton ';
    const draftButton = this.auth.currentAccount.rolloutDraftPosts ? ' draftButton' : '';
    const defaultRight = [`${socialProfileButtons}timeGridWeek,dayGridMonth${draftButton} calendarSettings`];
    const storiesRight = [`timezoneButton storiesButton previewButton,weekButton,monthButton${draftButton}`];
    return defaultCalendar ? defaultRight : storiesRight;
  }

  targetDateObject(targetDate: string): Date {
    const targetDateTimestamp = Number(targetDate) || new Date().getTime();
    return new Date(targetDateTimestamp);
  }

  @task
  *handleWithProgressBar(runTasks: () => unknown): TaskGenerator<void> {
    NProgress.configure({ parent: '.fc-view-harness' })?.start();
    NProgress.configure({ parent: 'body' });
    yield runTasks();
    NProgress.done();
  }
}

declare module '@ember/service' {
  interface Registry {
    'calendar/config': ConfigService;
  }
}
