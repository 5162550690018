import { assert, debug } from '@ember/debug';
import Service, { inject as service } from '@ember/service';
import { isTesting } from '@embroider/macros';
import Ember from 'ember';

import { ErrorSeverity } from 'later/services/errors';
import { isProduction, isEnv } from 'later/utils/is-env';

import type AuthService from 'later/services/auth';
import type ErrorsService from 'later/services/errors';
import type SubscriptionsService from 'later/services/subscriptions';
import type { JsonObject } from 'type-fest';

type EndMeasurementFn = (additionalData?: JsonObject) => number | null;

export type MeasurementLog = {
  eventName: string;
  socialProfileId: string;
  duration: number;
  additionalData?: JsonObject;
};

/**.
 * When specific property names are used (duration, name) with
 * the correct format, they can automatically be ingested by datadog.
 */
type DatadogMeasurementLog = {
  /** Duration in Nanoseconds */
  duration: number;
  name: string;
  socialProfileId: string;
  routeName?: string;
  additionalData?: JsonObject;
};

interface MeasurementConfig {
  /** The minimum number of milliseconds required for a measurement to be logged */
  min?: number;

  /** The maximum number of milliseconds possible for a measurement to be logged */
  max?: number;

  /** The miscellaneous additional data to be added to a {@link MeasurementLog} */
  additionalData?: JsonObject;
}

interface RenderPayload {
  containerKey?: string;
  object?: string;
}

/**
 * Ember.subscribe is a private API. This interface declares
 * how it's implemented, but that could change without notice.
 * This interface should be avoided whenever possible.
 */
export interface PrivateEmberSubscribe {
  subscribe: (
    eventName: string,
    events: {
      before: (eventName: string, time: number, payload: RenderPayload) => void;
      after: (eventName: string, time: number, payload: RenderPayload) => void;
    }
  ) => void;
}
/**
 * The maximum allowable duration(ms) of a event or function.
 * Enforced to prevent outliers from skewing data
 */
const MAX_DURATION_MS = 300_000;

/**
 * The minimum allowable duration(ms) of a event or function
 * Enforced to minimize "very short" measurements.
 */
const MIN_DURATION_MS = 10;

function _shouldFilter(duration: number, config: MeasurementConfig = {}): boolean {
  const min = config.min ? Math.max(config.min, MIN_DURATION_MS) : MIN_DURATION_MS;
  const max = config.max ? Math.min(config.max, MAX_DURATION_MS) : MAX_DURATION_MS;

  const isWithinRange = duration >= min && duration <= max;
  return !isWithinRange;
}

export default class PerformanceTrackingService extends Service {
  @service declare auth: AuthService;
  @service declare subscriptions: SubscriptionsService;
  @service declare errors: ErrorsService;

  // perform tracking for users with `testGroupId` below the cutoff
  readonly #testGroupCutOff = isProduction() ? 10 : 100;

  /**
   * Measurements collected which should be saved on route transition
   */
  readonly #measurements = new Set<MeasurementLog>();

  get measurements(): Set<MeasurementLog> {
    return this.#measurements;
  }

  canTrack(): boolean {
    if (isEnv('staging') || isEnv('scratch') || isTesting()) {
      return false;
    }

    if (!this.auth.currentUserModel) {
      return false;
    }

    if (this.auth.currentUserModel.admin) {
      return true;
    }

    if (this.subscriptions.isPaid && this.auth.currentUserModel.testGroupId) {
      return this.auth.currentUserModel.testGroupId <= this.#testGroupCutOff;
    }

    return false;
  }

  /**
   * Logs performance data for a particular event or function
   * @deprecated Use {@link this.startMeasurement} instead
   */
  log(eventName: string): number | null {
    let logDurationMs: number | null = null;

    try {
      performance.measure(eventName, `Begin${eventName}`, `End${eventName}`);
      const performanceEntry = performance.getEntriesByName(eventName).pop();

      if (!performanceEntry) {
        return logDurationMs;
      }

      logDurationMs = performanceEntry.duration;

      if (!this.canTrack()) {
        return logDurationMs;
      }

      if (_shouldFilter(logDurationMs)) {
        return logDurationMs;
      }

      if (_shouldFilter(logDurationMs)) {
        return logDurationMs;
      }

      const socialProfileId = this.auth.currentSocialProfile.id;

      this.storeMeasurement({
        eventName,
        socialProfileId,
        duration: Math.min(MAX_DURATION_MS, logDurationMs)
      });

      if (!isProduction()) {
        debug(`${eventName} took ${logDurationMs}`);
      }

      return logDurationMs;
    } catch (error) {
      debug(error);
      return logDurationMs;
    }
  }

  /**
   * Measures the time elapsed between two marks. Calling this method starts the
   * measurement and returns a method which should be called to end the measurement.
   *
   * @param name The name of the measurement.
   * @param config options for filtering and sending additional data
   */
  startMeasurement(name: string, config?: MeasurementConfig): EndMeasurementFn {
    // Timestamp used to differentiate between measurements with the same name
    const now = performance.now();
    const startName = `start-${name}-${now}`;
    const endName = `end-${name}-${now}`;

    let markEndCalled = false;

    performance.mark(startName);

    return (additionalData?: JsonObject) => {
      if (markEndCalled) {
        assert(`Error: End measurement has already been called. Name: ${name}`);
      }

      markEndCalled = true;
      performance.mark(endName);

      try {
        const measure = performance.measure(name, startName, endName);
        const { duration: logDurationMs } = measure;

        if (!this.canTrack()) {
          return logDurationMs;
        }

        if (_shouldFilter(logDurationMs, config)) {
          return logDurationMs;
        }

        const socialProfileId = this.auth.currentSocialProfile.id;

        this.storeMeasurement({
          eventName: name,
          socialProfileId,
          duration: Math.min(MAX_DURATION_MS, logDurationMs),
          additionalData: Object.assign({}, config?.additionalData, additionalData)
        });

        if (!isProduction()) {
          debug(`${name} took ${logDurationMs}ms`);
        }

        return logDurationMs;
      } catch (error) {
        return null;
      }
    };
  }

  storeMeasurement(measurementLog: MeasurementLog): void {
    this.#measurements.add(measurementLog);
  }

  /**
   * Measures the time it takes for components to render.
   * Fast component renders are ignored.
   *
   * This uses a private API and should be migrated to an
   * officially supported API if one is made available.
   */
  measureSlowComponentRenderTimes(): void {
    if (!this.canTrack()) {
      return;
    }

    const MINIMUM_RENDER_TIME_MS = 100;
    const endMeasurements = new Map<string, EndMeasurementFn>();

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;

    const EmberWithSubscribe = Ember as unknown as PrivateEmberSubscribe;
    EmberWithSubscribe.subscribe('render.component', {
      before: function $beforeRender(_eventName: string, _time: number, payload: RenderPayload) {
        const container = payload.containerKey || payload.object;
        if (container) {
          const eventName = `Render:${container}`;
          endMeasurements.set(container, self.startMeasurement(eventName, { min: MINIMUM_RENDER_TIME_MS }));
        }
      },
      after: function $afterRender(_eventName: string, _time: number, payload: RenderPayload) {
        const container = payload.containerKey || payload.object;
        if (container && endMeasurements.get(container)) {
          endMeasurements.get(container)?.call(self);
          endMeasurements.delete(container);
        }
      }
    });
  }

  /**
   * Sends locally stored measurements to a cloud data storage provider.
   * After successfully sending, the measurements are cleared. If there's an
   * error when sending measurements are not cleared, so they can be retried
   */
  async saveStoredMeasurements(batchRouteName: string): Promise<void> {
    if (!this.#measurements.size || !this.canTrack()) {
      return;
    }

    try {
      await this.#batchToDatadog(batchRouteName);
      this.#measurements.clear();
    } catch (error) {
      // Ignore errors to not impact user experience.
    }
  }

  /**
   * Sends locally stored measurements to Datadog via the {@link ErrorsService}.
   */
  async #batchToDatadog(routeName: string): Promise<void> {
    function millisecondsToNanoseconds(milliseconds: number): number {
      return milliseconds * 1_000_000;
    }

    for (const _measurement of this.#measurements) {
      const measurement: DatadogMeasurementLog = {
        name: _measurement.eventName,
        duration: millisecondsToNanoseconds(_measurement.duration),
        socialProfileId: _measurement.socialProfileId,
        additionalData: _measurement.additionalData,
        routeName
      };
      this.errors.log(`PerformanceTracking: ${measurement.name}`, measurement, ErrorSeverity.Info);
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    'performance-tracking': PerformanceTrackingService;
  }
}
