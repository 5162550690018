import Service, { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { task } from 'ember-concurrency';

import attachmentData from 'later/utils/generic-media-attachment';
import { isGif, isImage, isVideo } from 'later/utils/media-item-upload/check-file-type';

import type AlertsService from './alerts';
import type AuthService from './auth';
import type StoreService from '@ember-data/store';
import type IntlService from 'ember-intl/services/intl';
import type GenericAttachmentModel from 'later/models/generic-attachment';
import type MediaKitAttachmentModel from 'later/models/media-kit-attachment';
import type PartnershipsAttachmentModel from 'later/models/partnerships-attachment';
import type { UntypedService } from 'shared/types';
import type { Variant } from 'shared/types/generic-media';

type GenericAttachmentModelWithPreviewUrl = MediaKitAttachmentModel | PartnershipsAttachmentModel;

interface UploadImageArgs {
  attachmentType: string;
  setPreview: (arg: string) => void | undefined;
  setUploadProgress: () => void | undefined;
  recordId: number;
  additionalModelData?: { [key: string]: any };
}

export default class GenericMediaManagerService extends Service {
  @service declare alerts: AlertsService;
  @service declare auth: AuthService;
  @service declare intl: IntlService;
  @service declare mediaUpload: UntypedService;
  @service declare store: StoreService;

  /**
   * The bucket within S3 to send the upload to
   */
  processingBucket = 'later-incoming';

  /**
   * The maximum size of an image a user can upload in bytes
   */
  get maxImageSize(): number {
    return this.auth.currentGroup.account.get('maxImageFileSize') || this.auth.currentAccount.maxImageFileSize;
  }

  /**
   * The maximum size of an image a user can upload in MB
   */
  get maxImageSizeMB(): number {
    const bytesInKB = 1024;
    const KBInMB = 1024;
    return this.maxImageSize / KBInMB / bytesInKB;
  }

  /**
   * This returns a promise that resolves if the given url for an image attachment loads properly
   */
  checkLoaded(attachment: Variant): Promise<void> {
    return new Promise((resolve) => {
      const image = new Image();
      image.onload = function () {
        resolve();
      };
      image.src = attachment.url;
    });
  }

  /**
   * This extracts the URL of an uploaded image prior to the attachment being saved and
   * passes it to a function that will accept the URL for display.
   */
  showPreview(file: File, setPreview: (arg: string) => void): void {
    const reader = new FileReader();

    reader.addEventListener(
      'load',
      () => {
        if (typeof reader.result === 'string') {
          setPreview(reader.result);
        }
      },
      false
    );
    reader.readAsDataURL(file);
  }

  /**
   * returns a Promise that resolves when all the attachment URLs that have been processed on the backend
   * load correctly when displayed
   */
  async loadAttachments(attachments: GenericAttachmentModel[]): Promise<void> {
    const processedAttachments = attachments.flatMap(({ variants }) =>
      Object.values(variants).filter((attachment) => attachment.processed)
    );
    await Promise.all(processedAttachments.map(this.checkLoaded));
  }

  /**
   * delete local ember data records after saving a record with a hasMany GenericAttachment relationship.
   *
   * As we do not save the attached records directly and instead rely on the BE when saving the parent record
   * Ember has no way of updating the locally created attachments used for previewing the uploaded media.
   * This results in duplicates after saving requiring manually clearing local versions of records.
   *
   */
  mergeUploadedAttachments<T extends GenericAttachmentModelWithPreviewUrl>(images: T[]): void {
    const returnedImages = new Map();
    const existingImages: T[] = [];

    images.forEach((img) => {
      if (isPresent(img.id)) {
        returnedImages.set(img.key, img);
      } else {
        existingImages.push(img);
      }
    });

    existingImages.forEach((img) => {
      // NOTE: key field returned from BE is currently missing the file extension
      const returnedImg = returnedImages.get(img.key.split('.')[0]);
      if (returnedImg) returnedImg.set('previewUrl', img.previewUrl);
      this.store.unloadRecord(img);
    });
  }

  /**
   * Handles file size validation and uploading to S3
   */
  uploadImage = task(
    { drop: true },
    async (
      file: File,
      { attachmentType, setPreview, setUploadProgress, recordId, additionalModelData }: UploadImageArgs
    ) => {
      if (!file) {
        return;
      }

      if (this.#getHasMediaTypeError(file)) {
        this.#showMediaTypeError();
        return;
      }

      if (this.#getHasImageSizeError(file)) {
        this.#showImageSizeError();
        return;
      }

      const s3Key = this.mediaUpload.generateProcessingKey(file);
      await this.mediaUpload.uploadToS3.perform(file, this.processingBucket, s3Key, setUploadProgress);

      if (setPreview) {
        this.showPreview(file, setPreview);
      }

      const { model, modelData } = attachmentData(recordId, additionalModelData)[attachmentType];
      return this.store.createRecord(model, {
        ...modelData,
        contentType: file.type,
        key: s3Key,
        processingBucket: this.processingBucket
      });
    }
  );

  /**
   * Handles file size validation and uploading to S3
   *
   * @method uploadImage
   * @param {String} attachmentType The key of the attachment being uploaded. Used to create the appropriate model.
   * @param {Function} setPreview a function that will accept the URL of unsaved attachment for display of a preview.
   * @param {Function} setUploadProgress a function that will update upload progress for use in UI loading spinners.
   * @param {Number} recordId optional recordId for a model that the attachment belongs to
   * @param {{ data:any, file: File }[]} bulkAttachments
   */
  uploadBulkImage = task(
    { drop: true },
    async ({ attachmentType, setPreview, setUploadProgress, recordId, bulkAttachments } = {}) => {
      if (!bulkAttachments || bulkAttachments.length === 0) {
        return;
      }

      const processedBulkAttachments = [];

      for (const attachment of bulkAttachments) {
        const { file } = attachment;
        if (!file) {
          continue;
        }

        if (this.#getHasMediaTypeError(file)) {
          this.#showMediaTypeError();
          continue;
        }

        if (this.#getHasImageSizeError(file)) {
          this.#showImageSizeError();
          continue;
        }
        const s3Key = this.mediaUpload.generateProcessingKey(file);
        await this.mediaUpload.uploadToS3.perform(file, this.processingBucket, s3Key, setUploadProgress);

        this.showPreview(file, setPreview);

        processedBulkAttachments.push({
          data: attachment.data,
          key: s3Key,
          contentType: file.type,
          processingBucket: this.processingBucket
        });
      }

      if (processedBulkAttachments.length === 0) {
        return;
      }

      const { model, modelData } = attachmentData(recordId)[attachmentType];
      return this.store.createRecord(model, {
        ...modelData,
        contentType: processedBulkAttachments[0].contentType,
        key: processedBulkAttachments[0].key,
        processingBucket: this.processingBucket,
        isBulkAttachments: true,
        bulkAttachments: processedBulkAttachments
      });
    }
  );

  #getHasImageSizeError(file: File): boolean {
    return file.size > this.maxImageSize;
  }

  #getHasMediaTypeError(file: File): boolean {
    return isGif(file) || isVideo(file) || !isImage(file);
  }

  #showImageSizeError(): void {
    this.alerts.alert(this.intl.t('alerts.generic_media.file_too_large', { maxSize: this.maxImageSizeMB }), {
      title: this.intl.t('alerts.media_items.new.image_limit_over.title')
    });
  }

  #showMediaTypeError(): void {
    this.alerts.alert(this.intl.t('alerts.generic_media.file_type_not_supported.description'), {
      title: this.intl.t('alerts.generic_media.file_type_not_supported.title')
    });
  }
}

declare module '@ember/service' {
  interface Registry {
    'generic-media-manager': GenericMediaManagerService;
  }
}
