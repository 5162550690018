import { ADDRESS_FIELD_KEYS } from 'later/utils/constants';

/**
 * Flattens address from saved address and converts to camel case
 *
 * @param response the most recently saved address for a user
 *
 * @returns containing AddressFields. Field will be set to empty string if it doesn't exist.
 */
export const assembleAddress = (response: any): AddressFields => ({
  //
  [ADDRESS_FIELD_KEYS.CITY]: response?.city || '',
  [ADDRESS_FIELD_KEYS.COUNTRY]: response?.country || '',
  [ADDRESS_FIELD_KEYS.LINE_ONE]: response?.line1 || '',
  [ADDRESS_FIELD_KEYS.LINE_TWO]: response?.line2 || '',
  [ADDRESS_FIELD_KEYS.STATE]: response?.state || '',
  [ADDRESS_FIELD_KEYS.POSTAL_CODE]: response?.postal_code || ''
});

export interface AddressFields {
  city?: string;
  country?: string;
  line1?: string;
  line2?: string;
  state?: string;
  postalCode?: string;
}

export interface SuggestedChangeItem {
  key: string;
  suggested: string;
}
