import { action } from '@ember/object';
import Service, { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import moment from 'moment';

import BttpResource from 'later/resources/bttp';
import { SegmentEventTypes } from 'later/utils/constants/segment-events';
import { fetch } from 'later/utils/fetch';

import type { BttpReport } from 'calendar/types/bttp';
import type IntlService from 'ember-intl/services/intl';
import type SocialProfileModel from 'later/models/social-profile';
import type TimeSlotModel from 'later/models/time-slot';
import type AlertsService from 'later/services/alerts';
import type AppcuesService from 'later/services/appcues';
import type AuthService from 'later/services/auth';
import type ConfigService from 'later/services/calendar/config';
import type ErrorsService from 'later/services/errors';
import type SelectedSocialProfilesService from 'later/services/selected-social-profiles';
import type SubscriptionsService from 'later/services/subscriptions';
import type { UntypedService, Maybe } from 'shared/types';

const AUTO_ENABLE_CACHE_KEY = 'autoEnable';
const CACHE_KEY = 'showBttp';
const MINIMUM_FOLLOWERS = 100;
const TOGGLED_BTTP_SEGMENT_EVENT = 'toggled-best-time-to-post';
const TOGGLE_STATES = Object.freeze({ ON: 'on', OFF: 'off' });

export default class BttpService extends Service {
  @service declare appcues: AppcuesService;
  @service declare auth: AuthService;
  @service declare cache: UntypedService;
  @service('calendar/config') declare calendarConfig: ConfigService;
  @service declare segment: UntypedService;
  @service declare selectedSocialProfiles: SelectedSocialProfilesService;
  @service declare subscriptions: SubscriptionsService;
  @service declare userConfig: UntypedService;
  @service declare alerts: AlertsService;
  @service declare intl: IntlService;
  @service declare errors: ErrorsService;

  bttpResource = BttpResource.from(this, () => []);

  @tracked manuallyToggledOn = false;
  @tracked hasSeenIgBttpDiscovery = true;
  @tracked hasAttemptedAutoEnable = false;

  constructor(...args: Record<string, unknown>[]) {
    super(...args);
    this.hasSeenIgBttpDiscovery = !isEmpty(this.cache.retrieve('receivedIgBttpDiscovery'));
    this.hasAttemptedAutoEnable = !isEmpty(this.cache.retrieve(this.autoEnableCacheKey));
    this.autoEnableBttp.perform();
  }

  get autoEnableCacheKey(): string {
    return `${AUTO_ENABLE_CACHE_KEY}-${this.currentProfile?.id}`;
  }

  get couldShow(): boolean {
    return Boolean(this.canBttp && this.isValidSocialProfile && !this.selectedSocialProfiles.hasMultipleSelected);
  }

  get shouldShowBttpDiscovery(): boolean {
    if (this.currentProfile?.isInstagram) {
      return !this.hasSeenIgBttpDiscovery;
    }
    return !this.currentProfile?.receivedBttpDiscovery;
  }

  get toggledOn(): boolean {
    return this.manuallyToggledOn || this.cache.retrieve(this.toggleCacheKey);
  }

  get analyticsReportName(): string {
    switch (this.currentProfile?.profileType) {
      case 'tiktok':
        return 'tt-bttp';
      case 'instagram':
        return 'ig-bttp';
      default:
        return '';
    }
  }

  get currentProfile(): SocialProfileModel | undefined {
    return this.selectedSocialProfiles.firstProfile;
  }

  get socialProfileType(): string | undefined {
    return this.currentProfile?.profileType;
  }

  get toggleCacheKey(): string {
    return `${CACHE_KEY}-${this.socialProfileType}`;
  }

  get report(): Maybe<BttpReport> {
    if (!this.bttpResource.fetchTask) {
      this.#handleMissingBttpReport();
    }

    return this.bttpResource.fetchTask.value as Maybe<BttpReport>;
  }

  get requiredFollowerCount(): number {
    return MINIMUM_FOLLOWERS;
  }

  get roundedTimeSlots(): number[] {
    return this.report?.timeSlots?.map((timeSlot) => timeSlot.roundedHalfHourMow) || [];
  }

  get shouldShow(): boolean {
    return this.toggledOn && this.couldShow;
  }

  get canBttp(): boolean {
    switch (this.currentProfile?.profileType) {
      case 'tiktok':
        return Boolean(this.auth.currentAccount.canTiktokBttp);
      case 'instagram':
        return Boolean(this.auth.currentAccount.canBttp);
      default:
        return false;
    }
  }

  get isValidSocialProfile(): boolean {
    switch (this.currentProfile?.profileType) {
      case 'tiktok':
      case 'instagram':
        return true;
      default:
        return false;
    }
  }

  get learnMoreLink(): string {
    switch (this.currentProfile?.profileType) {
      case 'tiktok':
        return 'https://help.later.com/hc/articles/7948787987863';
      case 'instagram':
      default:
        return 'https://help.later.com/hc/articles/360042771694-Find-Your-Best-Times-to-Post-on-Instagram';
    }
  }

  findNextSlotFromUnix(unixTime: number): Maybe<moment.Moment> {
    if (!this.report?.timeSlots?.length) {
      return null;
    }
    const timeZoneIdentifier = this.calendarConfig.timeZoneIdentifier || this.userConfig.currentTimeZone.identifier;

    const slotsAsMomentObjects = this.#getUpcomingSlotsAsMoments(
      this.report.timeSlots,
      unixTime,
      timeZoneIdentifier
      // Note: Sort dates using moment's 'diff' function
    ).sort((a, b) => a.diff(b));

    const nextSlot = slotsAsMomentObjects.find(
      (bttpSlot: moment.Moment) => !bttpSlot.isBefore(moment().tz(timeZoneIdentifier))
    );

    return nextSlot;
  }

  seenBttpDiscovery(): void {
    if (this.currentProfile?.isInstagram) {
      this.cache.add('receivedIgBttpDiscovery', true, { expiry: this.cache.expiry(30, 'days'), persist: true });
      this.hasSeenIgBttpDiscovery = true;
    } else {
      if (this.currentProfile) {
        this.currentProfile.receivedBttpDiscovery = true;
        this.currentProfile?.save();
      }
    }
  }

  #cacheAutoEnableAttempt(): void {
    this.cache.add(this.autoEnableCacheKey, true, { expiry: this.cache.expiry(1, 'day'), persist: true });
    this.hasAttemptedAutoEnable = true;
  }

  #getUpcomingSlotsAsMoments(
    eligibleBttpSlotList: TimeSlotModel[],
    unixTime: number,
    timeZoneIdentifier: string
  ): moment.Moment[] {
    return eligibleBttpSlotList.map((bttpSlot: TimeSlotModel) => {
      const scheduledTime = moment.unix(unixTime);
      const { wday, hour, minute } = bttpSlot;
      const nextBttpSlot = scheduledTime.clone().day(wday).hour(hour).minute(minute);

      // Note: If the slot is in the past, find the same slot in the upcoming week
      if (nextBttpSlot.isBefore(scheduledTime)) {
        nextBttpSlot.add(7, 'days');
      }

      return nextBttpSlot.utc(true).tz(timeZoneIdentifier);
    });
  }

  @action
  setBttpToggle(value: boolean): void {
    this.manuallyToggledOn = value;
    this.cache.add(this.toggleCacheKey, value, {
      expiry: this.cache.maxExpiryDate(),
      persist: true
    });
  }

  @action
  toggleBttp(): void {
    const { ON, OFF } = TOGGLE_STATES;
    this.setBttpToggle(!this.toggledOn);
    this.segment.track(TOGGLED_BTTP_SEGMENT_EVENT, {
      new_state: this.toggledOn ? ON : OFF,
      social_profile_handle: this.selectedSocialProfiles.firstProfile?.nickname
    });
  }

  autoEnableBttp = task(async () => {
    const hasNotToggledBttp = isEmpty(this.cache.retrieve(this.toggleCacheKey) && !this.toggledOn);

    if (
      hasNotToggledBttp &&
      this.couldShow &&
      this.currentProfile?.autoEnableBttp &&
      this.auth.currentAccount.rolloutAutoEnableBttp &&
      !this.hasAttemptedAutoEnable
    ) {
      this.#cacheAutoEnableAttempt();
      await this.generateBttpReport.perform();
      this.manuallyToggledOn = true;
      this.cache.add(this.toggleCacheKey, true, {
        expiry: this.cache.maxExpiryDate(),
        persist: true
      });
      this.appcues.track(SegmentEventTypes.BttpEnabled);
      this.segment.track(SegmentEventTypes.BttpEnabled, {
        social_profile_handle: this.currentProfile?.nickname,
        active_trial: !!this.subscriptions.hasActiveTrial
      });
    }
  });

  generateBttpReport = task(async () => {
    return await fetch(`/api/v2/analytics_reports`, {
      method: 'POST',
      body: {
        analytics_report: {
          name: this.analyticsReportName,
          social_profile_id: this.currentProfile?.id
        }
      }
    });
  });

  #handleMissingBttpReport(): void {
    this.alerts.warning(this.intl.t('alerts.generic_error_message'), {
      title: this.intl.t('alerts.bttp.fetch_error'),
      preventDuplicates: true
    });
    this.errors.log(new Error(`Failed to fetch bttp report for ${this.currentProfile?.id}`));
  }
}

declare module '@ember/service' {
  interface Registry {
    'calendar/bttp': BttpService;
  }
}
