/* eslint-disable no-undef */
/**
 * @module Services
 */

import Service, { inject as service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import classic from 'ember-classic-decorator';
import NProgress from 'nprogress';
import RSVP from 'rsvp';

import config from 'later/config/environment';
import { MediaUploadError } from 'later/errors/index';
import { isEnv } from 'later/utils/is-env';
import { base64toBlob } from 'shared/utils/file';

/**
 * This service handles fetching media from Google Drive for upload into Later's Amazon S3 buckets.
 *
 * @class GooglePickerService
 * @extends Service
 */
@classic
export default class GooglePickerService extends Service {
  /**
   * @property alerts
   */
  @service alerts;
  @service intl;
  @service laterConfig;
  @service mediaItemUpload;

  appId = config.APP.googleAppId;
  discoveryDocs = ['https://www.googleapis.com/discovery/v1/apis/drive/v3/rest'];
  oauthToken = null;
  pickerApiLoaded = false;

  _scopes = ['https://www.googleapis.com/auth/drive.readonly'];

  get scopes() {
    return this._scopes.join(' ');
  }

  get isLocalDevelopment() {
    // Disable in dev to prevent errors due to non-whitelisted domain
    return isEnv('development');
  }
  /**
   * Setup and load Google picker API
   *
   * @method loadPicker
   * @returns {Promise}
   */
  async loadPicker() {
    if (this.isLocalDevelopment) {
      return;
    }

    try {
      await this._loadGoogle();
      gapi.load('client:auth2', this._initClient.bind(this));
      gapi.load('picker', () => this.set('pickerApiLoaded', true));
    } catch (error) {
      const url = 'http://status.later.com/';
      const link = htmlSafe(
        `<a href="${url}">${this.intl.t('alerts.media_items.new.google_drive_not_available_link')}</a>`
      );
      const message = this.intl.t('alerts.media_items.new.google_drive_not_available', { link });
      this.alerts.warning(htmlSafe(message));
    }
  }

  /**
   * Open a Google picker widget
   *
   * @method createPicker
   * @returns {Promise}
   */
  async createPicker() {
    if (this.isLocalDevelopment) {
      this.alerts.info(this.intl.t('alerts.media_items.new.google_drive_disabled_local'));
      return;
    }

    if (this.pickerApiLoaded) {
      if (!this.oauthToken) {
        await this._googleAuth();
      }
      const picker = new google.picker.PickerBuilder()
        .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
        //.enableFeature(google.picker.Feature.SUPPORT_DRIVES)
        .setAppId(this.appId)
        .setOAuthToken(this.oauthToken)
        //.addView(new google.picker.PhotosView())
        .addView(google.picker.ViewId.DOCS_IMAGES_AND_VIDEOS)
        .setDeveloperKey(this.laterConfig.googleApiKey)
        .setCallback(this._pickerCallback.bind(this))
        .build();
      picker.setVisible(true);
    }
  }

  /**
   * Callback when a file is selected from picker
   *
   * @method _pickerCallback
   * @param {Object} data
   */
  async _pickerCallback(data) {
    try {
      if (data.action == google.picker.Action.PICKED && data.docs) {
        NProgress.start();
        const promises = data.docs.map((file) => this._uploadFile(file));
        const results = await RSVP.allSettled(promises);
        results.forEach((result) => {
          if (result?.state === 'rejected') {
            if (result.reason instanceof MediaUploadError) {
              result.reason.resolve.call(this, result.reason);
            } else {
              this.alerts.warning(
                result.reason?.length
                  ? result.reason
                  : this.intl.t('alerts.media_items.new.google_drive_download_failed.message'),
                {
                  title: this.intl.t('alerts.media_items.new.google_drive_download_failed.title')
                }
              );
            }
          }
        });
      }
    } finally {
      NProgress.done();
    }
  }

  /**
   * Process and upload drive file to media library.
   *
   * @method _uploadFile
   * @param {Object} file object of the file to upload.
   */
  async _uploadFile(file) {
    const mediaError = this.mediaItemUpload.getMediaError(file);
    if (mediaError) {
      throw mediaError;
    }

    if (file.serviceId === 'picasa') {
      const thumbnailUrl = file.thumbnails[0].url;
      const url = thumbnailUrl.replace(/\/s\S+?\//g, '/s0/');
      const blob = await this._downloadMedia(url);
      return this.mediaItemUpload.uploadMediaItem(blob);
    }

    const binary = await this._getFileBinary(file.id);
    const blob = base64toBlob(binary, file.mimeType);
    blob.name = file.name;
    return this.mediaItemUpload.uploadMediaItem(blob);
  }

  /**
   * Get a drive file's metadata.
   *
   * @param {String} fileId ID of the file to print metadata for.
   */
  _getFileResource(fileId) {
    return new RSVP.Promise((resolve) =>
      gapi.client.drive.files
        .get({
          fileId,
          fields: '*'
        })
        .then((data) => resolve(data.result))
    );
  }

  /**
   * Get a drive file's binary data.
   *
   * @param {String} fileId ID of the file to print metadata for
   * @returns {Promise|Object} base64 encoding of File
   */
  _getFileBinary(fileId) {
    return new RSVP.Promise((resolve) =>
      gapi.client.drive.files
        .get({
          fileId,
          alt: 'media'
        })
        .then((data) => resolve(data.body))
    );
  }

  _downloadMedia(downloadUrl) {
    return new RSVP.Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', downloadUrl, true);
      xhr.responseType = 'blob';
      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            resolve(xhr.response);
          } else {
            reject();
          }
        }
      };
      xhr.send(null);
    });
  }

  /**
   * Callback when Google client api loaded
   *
   * @method _initClient
   * @param {String} fileId ID of the file to print metadata for
   * @returns {Promise|Object} base64 encoding of File
   */
  _initClient() {
    gapi.client.init({
      apiKey: this.laterConfig.googleApiKey,
      clientId: this.laterConfig.googleClientID,
      discoveryDocs: this.discoveryDocs,
      scope: this.scopes
    });
  }

  /**
   * Load Required Google Drive libraries
   *
   * @method _loadGoogle
   * @returns {Promise|Object} Returns Google Drive API and Client Responses
   */
  async _loadGoogle() {
    return new RSVP.Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = 'https://apis.google.com/js/api.js';

      script.onload = () => resolve();
      script.onerror = () => reject();

      document.querySelector('head')?.appendChild(script);
    });
  }

  /**
   * Initiate Oauth2 with Google
   *
   * @method _googleAuth
   * @returns {Promise}
   */
  async _googleAuth() {
    await gapi.auth.authorize(
      {
        client_id: this.laterConfig.googleClientID,
        scope: this.scopes,
        immediate: false
      },
      (authResult) => {
        if (authResult && !authResult.error) {
          this.oauthToken = authResult.access_token;
        }
      }
    );
  }
}
