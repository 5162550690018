import { shuffle } from 'lodash';

import type { Questions, Options } from 'auth/types/user-goals';
import type IntlService from 'ember-intl/services/intl';

const questions = (intl: IntlService): Questions => ({
  bestDescribes: {
    options: shuffleAndAddOption<Options['bestDescribes']>(
      ['small_business_owner', 'creator_influencer', 'social_media_manager_lead', 'marketing_agency'],
      'other'
    ),
    qaClass: 'bestDescribes',
    question: intl.t('onboarding.user_goals_signup.best_describes.question'),
    translationIdentifier: 'best_describes',
    validationText: intl.t('onboarding.user_goals_signup.best_describes.validation')
  },
  socialMediaGoal: {
    options: shuffle<Options['socialMediaGoal']>(['awareness_engagement', 'followers', 'products', 'sales', 'traffic']),
    qaClass: 'socialGoal',
    question: intl.t('onboarding.user_goals_signup.social_media_goal.question'),
    translationIdentifier: 'social_media_goal',
    validationText: intl.t('onboarding.user_goals_signup.social_media_goal.validation')
  },
  goalWithLater: {
    options: shuffle<Options['goalWithLater']>([
      'collect_create_content',
      'linkinbio',
      'manage_multi_profiles',
      'track_performance',
      'try_it_out',
      'visually_plan_save_time'
    ]),
    qaClass: 'topGoal',
    question: intl.t('onboarding.user_goals_signup.goal_with_later.question'),
    translationIdentifier: 'goal_with_later',
    validationText: intl.t('onboarding.user_goals_signup.goal_with_later.validation')
  },
  whatEncouragedSignup: {
    options: shuffleAndAddOption<Options['whatEncouragedSignup']>(
      [
        'later_blog',
        'later_newsletter',
        'later_social_media',
        'influencer_promoting_later',
        'search_engine',
        'someones_linkinbio_page',
        'recommendation'
      ],
      'other'
    ),
    qaClass: 'whatEncouragedSignup',
    question: intl.t('onboarding.user_goals_signup.what_encouraged_signup.question'),
    translationIdentifier: 'what_encouraged_signup',
    validationText: intl.t('onboarding.user_goals_signup.what_encouraged_signup.validation')
  }
});

const shuffleAndAddOption = <T>(options: T[], option: T): T[] => {
  const shuffled = shuffle(options);
  shuffled.push(option);
  return shuffled;
};

export default questions;
