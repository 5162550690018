import Service, { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';

import objectPromiseProxy from 'later/utils/object-promise-proxy';
import BlockTypes from 'shared/utils/constants/linkinbio-block-types';

import type AlertsService from './alerts';
import type DialogManagerService from './dialog-manager';
import type Store from '@ember-data/store';
import type IntlService from 'ember-intl/services/intl';
import type LinkinbioPageModel from 'later/models/linkinbio-page';
import type AuthService from 'later/services/auth';
import type ErrorsService from 'later/services/errors';
import type SegmentService from 'later/services/segment';
import type SubscriptionsService from 'later/services/subscriptions';

export default class LinkinbioPageEnablerService extends Service {
  @service declare alerts: AlertsService;
  @service declare auth: AuthService;
  @service declare dialogManager: DialogManagerService;
  @service declare errors: ErrorsService;
  @service declare intl: IntlService;
  @service declare segment: SegmentService;
  @service declare store: Store;
  @service declare subscriptions: SubscriptionsService;

  get rolloutLinkinbioInstagramlessPage(): boolean {
    return this.auth.currentAccount.rolloutLinkinbioInstagramlessPage;
  }

  create = task({ drop: true }, async (page: LinkinbioPageModel, location: string) => {
    try {
      page.enabled = true;
      await page.save();
      await page.saveProfiles();

      if (!page.urlName) {
        throw new Error('Page urlName is not set');
      }

      this.segment.track('enabled-linkinbio', {
        enabled_type: 'created new page',
        location,
        plan_name: this.subscriptions.planName?.toLowerCase() ?? null,
        page_id: page.get('id'),
        slug: page.get('slug')
      });

      if (this.auth.currentAccount.rolloutLinkinbioInstagramlessPage) {
        await page.get('group')?.reload();
      }
    } catch (error) {
      this.errors.handleAdapter(error);
    }

    return page;
  });

  createFeedBlock = task(async (page, connectedObjectId) => {
    try {
      const blockData = {
        blockData: {
          enabled: true
        },
        blockType: BlockTypes.Feed,
        connectedObjectType: 'SocialProfile',
        connectedObjectId,
        linkinbioPage: page
      };
      const blockRecord = this.store.createRecord('linkinbio-block', blockData);
      await blockRecord.save();
    } catch (error) {
      this.errors.log(error);
    }
  });

  createHeaderBlockWithDefaultName = task(async (page) => {
    try {
      const blockData = {
        blockData: {
          enabled: true,
          display_name: page.slug,
          bio: undefined
        },
        blockType: BlockTypes.Header,
        linkinbioPage: page
      };
      const blockRecord = this.store.createRecord('linkinbio-block', blockData);
      await blockRecord.save();
    } catch (error) {
      this.errors.log(error);
    }
  });

  enable = task({ drop: true }, async (page: LinkinbioPageModel) => {
    if (page) {
      const linkinbioPage = await objectPromiseProxy(page);
      try {
        const didConfirm = await this._confirmEnable.perform();

        if (!didConfirm) {
          return;
        }

        linkinbioPage.set('enabled', true);

        await linkinbioPage.save();
        await linkinbioPage.saveProfiles();

        this.segment.track('enabled-linkinbio', {
          enabled_type: 'reactivated old page',
          plan_name: this.subscriptions.planName?.toLowerCase() ?? null,
          page_id: linkinbioPage.get('id')
        });

        this.#showSuccessfullyEnabledAlert(linkinbioPage);
      } catch (error) {
        this.errors.handleAdapter(error, linkinbioPage);
      }
    }
  });

  private _confirmEnable = task({ drop: true }, async () => {
    return await this.dialogManager.confirmation(this.intl.t('linkinbio.enabled.enable_modal_confirmation'), {
      description: this.intl.t('linkinbio.enabled.enable_modal_message'),
      confirmButton: this.intl.t('linkinbio.enabled.enable_linkinbio_ok'),
      confirmButtonClass: 'o--btn--primary'
    });
  });

  #showSuccessfullyEnabledAlert(page: LinkinbioPageModel): void {
    this.alerts.success(this.intl.t('alerts.linkinbio.enabled.description'), {
      title: this.intl.t('alerts.linkinbio.enabled.title', { username: page?.displayName })
    });
  }
}

declare module '@ember/service' {
  interface Registry {
    'linkinbio-page-enabler': LinkinbioPageEnablerService;
  }
}
