import Service, { inject as service } from '@ember/service';
import { isNone } from '@ember/utils';
import { task } from 'ember-concurrency';

import { ErrorSeverity } from 'later/services/errors';
import { OauthSocialProfileType } from 'later/utils/constants';
import redirect from 'shared/utils/redirect';

import type RouterService from '@ember/routing/router-service';
import type IntlService from 'ember-intl/services/intl';
import type GroupModel from 'later/models/group';
import type SocialProfileModel from 'later/models/social-profile';
import type AlertsService from 'later/services/alerts';
import type AuthService from 'later/services/auth';
import type ErrorsService from 'later/services/errors';
import type ConnectProfilesService from 'later/services/social/connect-profiles';
import type { PinterestAlbum, PinterestBoard } from 'shared/types/social-profile';

export default class PinterestService extends Service {
  @service declare alerts: AlertsService;
  @service declare auth: AuthService;
  @service declare errors: ErrorsService;
  @service declare intl: IntlService;
  @service declare router: RouterService;
  @service('social/connect-profiles') declare connectProfiles: ConnectProfilesService;

  /**
   * Creates an OAuth window to log in to Pinterest
   */
  loginPinterest(): void {
    const path = this.connectProfiles.oAuthPath({ socialProfileType: OauthSocialProfileType.Pinterest });

    if (isNone(this.auth.currentGroup)) {
      redirect(path);
    } else {
      redirect(`${path}&group_id=${this.auth.currentGroup.id}`);
    }
  }

  /**
   * Creates an OAuth window to log in to Pinterest for a specific socialIdentity
   */
  createPinterestWithSet(setId: string | undefined | null, group: GroupModel): void {
    const path = this.connectProfiles.oAuthPath({
      socialProfileType: OauthSocialProfileType.Pinterest,
      redirectGroupSlug: group.slug
    });

    if (isNone(setId)) {
      redirect(`${path}&group_id=${group.id}`);
    } else {
      redirect(`${path}&social_identity_id=${setId}`);
    }
  }

  /**
   * Attach public pinterest boards with ID, name and image URL to socialProfile.
   *
   * Resets token if API returns error.
   */
  fetchBoards = task(async (socialProfile: SocialProfileModel) => {
    if (socialProfile.isPinterest) {
      try {
        const boards = await this.getBoards(socialProfile);

        if (!boards) {
          socialProfile.set('token', undefined);
          return [];
        }
        return boards.map(({ id, name, image_thumbnail_url }) => ({
          identifier: id,
          name,
          image_url: image_thumbnail_url
        }));
      } catch (error) {
        this.errors.log(new Error('Unable to fetch Pinterest Boards'), error);
        socialProfile.set('token', undefined);
        return [];
      }
    }

    return [];
  });

  /**
   * Returns an ordered list of the authenticated user's public boards, including the URL, ID and name.
   *
   * The boards are sorted first by user order, then by creation date, with the most recently created boards being first.
   */
  async getBoards(socialProfile: SocialProfileModel): Promise<PinterestBoard[] | undefined> {
    const response = await socialProfile.pinterestBoards();

    const CODE_AUTHORIZATION_FAILED = 3;
    if (response && response.code === CODE_AUTHORIZATION_FAILED) {
      this.errors.log('Unable to fetch Pinterest Boards', { data: JSON.stringify(response) }, ErrorSeverity.Info);

      const userMessage = this.intl.t('errors.pinterest_boards_unauthorized');
      this.#alertProfileRefresh(socialProfile, userMessage);

      return;
    }

    return response.data;
  }

  /**
   * Get a Pinterest Album's value by key
   *
   * @param identifier Identifier for board
   * @param key Name of key to get value
   */
  async getAlbumValue<T extends keyof PinterestAlbum>(
    socialProfile: SocialProfileModel,
    identifier: string | undefined | null,
    key: T
  ): Promise<PinterestAlbum[T] | undefined> {
    if (!socialProfile.get('isPinterest')) {
      return;
    }

    const albums = await this.fetchBoards.perform(socialProfile);
    const album = albums ? albums.find((album) => album.identifier === identifier) : undefined;

    if (album && key in album) {
      return album[key];
    }

    return album ? album[key] : undefined;
  }

  #alertProfileRefresh(socialProfile: SocialProfileModel, title: string): void {
    this.alerts.alert(
      this.intl.t('alerts.errors.auto_publish.expired_token.message', {
        account_name: socialProfile.nickname
      }),
      {
        title: title || this.intl.t('shared_phrases.refresh_your_connection'),
        action: () => this.router.transitionTo('account.groups.group.social_profiles', this.auth.currentGroup?.id),
        actionText: this.intl.t('alerts.partnerships.account_owner.opt_in.button'),
        preventDuplicates: true,
        timeout: 10000
      }
    );
  }
}

declare module '@ember/service' {
  interface Registry {
    pinterest: PinterestService;
  }
}
