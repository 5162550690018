import Model, { attr, belongsTo, hasMany } from '@ember-data/model';

import type { AsyncBelongsTo, SyncHasMany } from '@ember-data/model';
import type ContentSuggestionModel from 'later/models/content-suggestion';
import type SocialIdentityModel from 'later/models/social-identity';

export default class ContentPlanModel extends Model {
  @belongsTo('socialIdentity', { async: true }) declare socialIdentity: AsyncBelongsTo<SocialIdentityModel>;
  @hasMany('contentSuggestion') declare contentSuggestions: SyncHasMany<ContentSuggestionModel>;
  @attr('number') declare createdAt: number;
  @attr('number') declare updatedAt: number;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'content-plan': ContentPlanModel;
  }
}
