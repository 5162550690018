import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("", {"contents":"","moduleName":"later/components/global-search/keyboard-navigation.hbs","parseOptions":{"srcName":"later/components/global-search/keyboard-navigation.hbs"}});
import Component from '@glimmer/component';

import type { SearchResult } from 'later/services/global-search';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface EventHandlerComponentArgs {
  selectedResultIndex: number;
  setSelectedResultIndex: (index: number) => void;
  runIntent: (result: SearchResult) => void;
  searchResults: SearchResult[];
  scrollIntoActiveResultItem: (position: ScrollLogicalPosition) => void;
}

// eslint-disable-next-line ember/no-empty-glimmer-component-classes
export default class EventHandlerComponent extends Component<EventHandlerComponentArgs> {
  keyHandler: (event: KeyboardEvent) => void;

  constructor(owner: unknown, args: EventHandlerComponentArgs) {
    super(owner, args);

    this.keyHandler = (event: KeyboardEvent): void => {
      this.handleUpArrow(event);
      this.handleDownArrow(event);
      this.handleEnter(event);
    };

    document.addEventListener('keydown', this.keyHandler);
  }

  willDestroy(): void {
    super.willDestroy();

    document.removeEventListener('keydown', this.keyHandler);
  }

  handleUpArrow(event: KeyboardEvent): void {
    if (event.key !== 'ArrowUp') {
      return;
    }

    event.preventDefault();

    if (this.args.selectedResultIndex < 0) {
      return;
    }

    if (this.args.selectedResultIndex === 0) {
      this.args.setSelectedResultIndex(this.args.searchResults.length - 1);
      this.args.scrollIntoActiveResultItem('end' as ScrollLogicalPosition);
      return;
    }

    this.args.setSelectedResultIndex(this.args.selectedResultIndex - 1);
    this.args.scrollIntoActiveResultItem('start' as ScrollLogicalPosition);
  }

  handleDownArrow(event: KeyboardEvent): void {
    if (event.key !== 'ArrowDown') {
      return;
    }

    event.preventDefault();

    if (document.activeElement?.tagName !== 'INPUT') {
      return;
    }

    if (this.args.selectedResultIndex === this.args.searchResults.length - 1) {
      this.args.setSelectedResultIndex(0);
      this.args.scrollIntoActiveResultItem('start' as ScrollLogicalPosition);
      return;
    }

    this.args.setSelectedResultIndex(this.args.selectedResultIndex + 1);
    this.args.scrollIntoActiveResultItem('end' as ScrollLogicalPosition);
  }

  handleEnter(event: KeyboardEvent): void {
    if (event.key !== 'Enter') {
      return;
    }

    if (this.args.selectedResultIndex < 0) {
      return;
    }

    if (document.activeElement?.tagName !== 'INPUT') {
      return;
    }

    const result = this.args.searchResults[this.args.selectedResultIndex];
    if (result) {
      this.args.runIntent(result);
    }
  }
}
