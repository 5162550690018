/**
 * @module Services
 */

import Service, { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';

import config from 'later/config/environment';
import { DEFAULT_PRODUCT_FETCH_LIMIT } from 'later/utils/constants';
import graphCall from 'later/utils/graph-call';

/**
 * @class FacebookGraphService
 * @extends Service
 */
export default class FacebookGraphService extends Service {
  @service alerts;
  @service auth;
  @service selectedSocialProfiles;

  facebookGraphVersion = config.APP.facebookGraphVersion;

  /**
   * List of post fields to be returned from fetch media requests
   */
  postFields = [
    'message',
    'created_time',
    'from',
    'picture',
    'full_picture',
    'permalink_url',
    'type',
    'source',
    'reactions'
  ];

  /**
   * List of comment fields to be returned from fetch comment requests
   */
  commentFields = ['message', 'from', 'created_time', 'parent', 'reactions'];

  /**
   * Gets search results for a business page query. Returns public, tag-enabled business pages only.
   *
   * @method fetchPageSearch
   * @param {SocialProfile} socialProfile The socialProfile making the request
   * @param {String} query A search query from the user.
   *
   * @return {Array} An array of business page objects with name and id.
   */
  @task
  *fetchPageSearch(socialProfile, query) {
    const encodedQuery = encodeURIComponent(query);

    const searchOptions = {
      q: encodedQuery,
      fields: 'id,name'
    };

    const result = yield graphCall(`pages/search`, searchOptions, {
      method: 'get',
      token: socialProfile.get('token')
    });

    return result?.data;
  }

  /**
   * Gets Page avatar for a Facebook Business Page. Returns the avatar url.
   *
   * @method fetchPageAvatar
   * @param {SocialProfile} socialProfile The socialProfile making the request
   * @param {String} pageId A Facebook Page id.
   *
   * @return {String} The URL of the page avatar.
   */
  @task
  *fetchPageAvatar(socialProfile, pageId) {
    const encodedID = encodeURIComponent(pageId);

    const searchOptions = {
      redirect: 0,
      type: 'small'
    };

    const result = yield graphCall(`/${encodedID}/picture?`, searchOptions, {
      method: 'get',
      token: socialProfile.get('token')
    });

    return result?.data.url;
  }

  @task
  *fetchPage(token, pageId) {
    const result = yield graphCall(
      `/${encodeURIComponent(pageId)}`,
      { fields: 'name,business' },
      {
        method: 'get',
        token
      }
    );
    return result;
  }

  @task
  *fetchProduct(token, productId, businessAccountToken) {
    const fields = ['image_url', 'name'];
    const result = yield graphCall(
      `/${productId}`,
      { fields: fields.join(',') },
      {
        method: 'get',
        token,
        backupToken: businessAccountToken
      }
    );

    return result;
  }

  @task
  *fetchProductTagEligibility(instagramUserId, token, businessAccountToken) {
    return yield graphCall(
      `/${encodeURIComponent(instagramUserId)}`,
      { fields: 'shopping_product_tag_eligibility' },
      {
        method: 'get',
        token,
        backupToken: businessAccountToken
      }
    );
  }

  @task
  *fetchAvailableCatalogs(instagramUserId, token, businessAccountToken) {
    const result = yield graphCall(
      `/${encodeURIComponent(instagramUserId)}/available_catalogs`,
      {},
      {
        method: 'get',
        token,
        backupToken: businessAccountToken
      }
    );

    return result;
  }

  @task
  *fetchCatalogProducts({ instagramUserId, token, params, paginationUrl, businessAccountToken }) {
    const catalogProductsUrl = paginationUrl || `/${encodeURIComponent(instagramUserId)}/catalog_product_search`;
    const result = yield graphCall(
      catalogProductsUrl,
      { ...params, limit: DEFAULT_PRODUCT_FETCH_LIMIT },
      {
        method: 'get',
        token,
        backupToken: businessAccountToken
      }
    );

    return result;
  }
}
