import Service, { inject as service } from '@ember/service';
import { isNone } from '@ember/utils';
import { task } from 'ember-concurrency';

import { OauthSocialProfileType } from 'later/utils/constants';
import { fetch } from 'later/utils/fetch';
import redirect from 'shared/utils/redirect';

import type StoreService from '@ember-data/store';
import type IntlService from 'ember-intl/services/intl';
import type GroupModel from 'later/models/group';
import type SocialIdentityModel from 'later/models/social-identity';
import type UserModel from 'later/models/user';
import type AlertsService from 'later/services/alerts';
import type AuthService from 'later/services/auth';
import type ErrorsService from 'later/services/errors';
import type LaterConfigService from 'later/services/later-config';
import type ConnectProfilesService from 'later/services/social/connect-profiles';
import type { LinkedinOrganizationsResponse, LinkedinProfileResponse, OrganizationData } from 'shared/types/linkedin';

interface LinkedinHeader {
  Authorization: string;
  'LinkedIn-Version': string;
}

export default class LinkedinService extends Service {
  @service declare auth: AuthService;
  @service declare alerts: AlertsService;
  @service declare errors: ErrorsService;
  @service declare intl: IntlService;
  @service declare laterConfig: LaterConfigService;
  @service declare store: StoreService;
  @service('social/connect-profiles') declare connectProfiles: ConnectProfilesService;

  get currentUser(): UserModel {
    return this.auth.currentUserModel;
  }

  get headers(): LinkedinHeader {
    const { linkedinApiVersion } = this.laterConfig;
    return { Authorization: `Bearer ${this.currentUser.linkedinToken}`, 'LinkedIn-Version': linkedinApiVersion };
  }

  /**
   * Creates an OAuth window to log in to Linkedin
   */
  async login(group: GroupModel): Promise<void> {
    const path = this.connectProfiles.oAuthPath({
      socialProfileType: OauthSocialProfileType.Linkedin,
      redirectGroupSlug: group.slug
    });

    if (isNone(group.id)) {
      redirect(path);
    } else {
      redirect(`${path}&group_id=${group.id}`);
    }
  }

  /**
   * Creates an OAuth window to log in to Linkedin
   */
  createLinkedinWithSet(setId: string, group: GroupModel): void {
    const path = this.connectProfiles.oAuthPath({
      socialProfileType: OauthSocialProfileType.Linkedin,
      redirectGroupSlug: group.slug
    });

    if (isNone(setId)) {
      redirect(`${path}&group_id=${group.id}`);
    } else {
      redirect(`${path}&social_identity_id=${setId}`);
    }
  }

  /**
   * Gets the current user from the https://api.linkedin.com/v2/me endpoint available Linkedin user
   */
  fetchLinkedinProfile = task(async () => {
    const endpoint = `${this.laterConfig.igProxy}/proxy/linkedin/me?projection=(id,localizedFirstName,localizedLastName,profilePicture(displayImage~digitalmediaAsset:playableStreams))`;
    const config = {
      method: 'GET',
      headers: this.headers
    };

    try {
      const response: LinkedinProfileResponse = await fetch(endpoint, config);
      const { profilePicture } = response;
      const avatar_url = profilePicture?.['displayImage~']?.elements[0]?.identifiers[0]?.identifier ?? '';

      return {
        id: `urn:li:person:${response.id}`,
        name: `${response.localizedFirstName} ${response.localizedLastName}`,
        avatar_url
      };
    } catch (error) {
      this.errors.log(error);
      return;
    }
  });

  /**
   * Gets all the organizations from the https://api.linkedin.com/v2/organizationAcls?q=roleAssignee endpoint available Linkedin user
   */
  fetchLinkedinOrganizations = task(async () => {
    const endpoint = `${this.laterConfig.igProxy}/proxy/linkedin/organizationAcls?q=roleAssignee&role=ADMINISTRATOR&state=APPROVED&projection=(elements*(*, organization~(id,localizedName,logoV2(original~:playableStreams))))`;
    const config = {
      method: 'GET',
      headers: this.headers
    };
    try {
      const response: LinkedinOrganizationsResponse = await fetch(endpoint, config);
      const orgs = response.elements.map((element: OrganizationData) => {
        const organization = element['organization~'];
        const avatar_url = organization.logoV2?.['original~']?.elements[0]?.identifiers[0]?.identifier ?? '';

        return {
          id: element.organization,
          name: element['organization~'].localizedName,
          avatar_url
        };
      });
      return orgs;
    } catch (error) {
      this.errors.log(error);
      return;
    }
  });

  async addProfile(id: string, identity: SocialIdentityModel, group: GroupModel): Promise<void> {
    const allProfiles = this.store.peekAll('social-profile');
    const existingProfile = allProfiles.find((profile) => profile.uid === id);
    const socialProfile = existingProfile || this.store.createRecord('social-profile');
    socialProfile.set('socialIdentity', undefined);
    const socialProfileAttributes = {
      uid: id,
      group,
      account: this.auth.currentAccount,
      token: this.currentUser.linkedinToken,
      profileType: 'linkedin',
      ...(identity?.id && { socialIdentity: identity })
    };

    Object.assign(socialProfile, socialProfileAttributes);
    try {
      const savedProfile = await socialProfile.save();
      savedProfile.get('socialIdentity');
      if (existingProfile) {
        this.alerts.success(this.intl.t('alerts.linkedin.profile_moved', { name: savedProfile.name }));
      } else if (savedProfile.isLinkedinPersonalProfile) {
        this.alerts.success(this.intl.t('alerts.linkedin.added_personal', { name: savedProfile.name }));
      } else {
        this.alerts.success(this.intl.t('alerts.linkedin.added_page', { name: savedProfile.name }));
      }
    } catch (adapterError) {
      socialProfile.rollbackAttributes();
      this.errors.handleAdapter(adapterError, socialProfile);
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    linkedin: LinkedinService;
  }
}
