import type IntlService from 'ember-intl/services/intl';
import type AccountModel from 'later/models/account';
import type { Platforms, FeatureNames, FeatureObject } from 'shared/types/feature-drawer';

interface FeaturelinkBadgeArgs {
  hide_image_css?: boolean;
  image?: string;
  platforms: Platforms[];
  start_trial_cta: boolean;
}

export interface FeaturelinkObject {
  title: string;
  subtitle: string;
  desktop_badge: boolean;
  start_trial_cta: boolean;
  mobile_apps_link: boolean;
}

export function generateFeatureLinkObject(
  featurelink: FeatureNames,
  intl: IntlService,
  currentAccount: AccountModel
): FeatureObject {
  const featurelinkType = findFeaturelinkType(featurelink);
  const featureBadge = featurelinkType ? featurelinkContent(currentAccount)[featurelinkType][featurelink] : undefined;

  return {
    name: featurelink,
    title: getTitleTranstion(intl, featurelink, featurelinkType),
    subtitle: getSubtitleTranstion(intl, featurelink, featurelinkType),
    image: featureBadge?.image || '/assets/images/img--empty__goToDesktop.svg',
    platforms: featureBadge?.platforms,
    needsUpgrade: featureBadge?.start_trial_cta,
    isFeaturelink: true,
    hideImageCss: featureBadge?.hide_image_css || false
  };
}

export function findFeaturelinkType(featurelinkText: string | undefined): string | undefined {
  if (!featurelinkText) {
    return undefined;
  }

  return featurelinkTypes.find((key) =>
    Object.keys(featurelinkContent()[`${key}` as keyof typeof featurelinkContent]).includes(featurelinkText)
  );
}

function getTitleTranstion(intl: IntlService, featurelink: FeatureNames, featurelinkType?: string): string {
  return featurelinkType === 'custom'
    ? intl.t(`mobile.feature_link.custom.${featurelink}.title`)
    : intl.t(`mobile.feature_link.dynamic_${featurelinkType}_feature.title`, {
        feature: intl.t(`mobile.feature_link.feature_name.${featurelink}.title`)
      });
}

function getSubtitleTranstion(intl: IntlService, featurelink: FeatureNames, featurelinkType?: string): string {
  return featurelinkType === 'custom'
    ? intl.t(`mobile.feature_link.custom.${featurelink}.subtitle`)
    : intl.t(`mobile.feature_link.dynamic_${featurelinkType}_feature.subtitle`, {
        feature: intl.t(`mobile.feature_link.feature_name.${featurelink}.subtitle`)
      });
}

type FeaturelinkName = Record<string, FeaturelinkBadgeArgs>;
type FeaturelinkContent = Record<string, FeaturelinkName>;

export const featurelinkTypes = ['verb', 'noun', 'analytics', 'mobile_available', 'custom'];

const featurelinkContent = (currentAccount?: AccountModel): FeaturelinkContent => ({
  verb: {
    brand_campaigns: {
      platforms: ['desktop'],
      start_trial_cta: !currentAccount?.canCampaignBoard
    },
    brand_search: {
      platforms: ['desktop'],
      start_trial_cta: !currentAccount?.canCampaignBoard
    }
  },
  noun: {
    preview: {
      platforms: ['desktop'],
      start_trial_cta: false
    },
    calendar: {
      platforms: ['desktop', 'mobile'],
      start_trial_cta: false
    },
    conversations: {
      platforms: ['desktop'],
      start_trial_cta: !currentAccount?.canConversations
    },
    media_library: {
      platforms: ['desktop', 'mobile'],
      start_trial_cta: false
    },
    stories: {
      platforms: ['desktop'],
      start_trial_cta: !currentAccount?.canInstagramStories
    },
    groups: {
      platforms: ['desktop'],
      start_trial_cta: false
    },
    education_center: {
      platforms: ['desktop'],
      start_trial_cta: false
    },
    collect_mentions: {
      platforms: ['desktop'],
      start_trial_cta: !currentAccount?.canMentions
    },
    collect_tags: {
      platforms: ['desktop'],
      start_trial_cta: !currentAccount?.canCollectHashtags
    },
    search_hashtags: {
      platforms: ['desktop'],
      start_trial_cta: !currentAccount?.canCollectHashtags
    },
    contributors: {
      platforms: ['desktop'],
      start_trial_cta: !currentAccount?.canContributors
    },
    stock_photos: {
      platforms: ['desktop'],
      start_trial_cta: false
    },
    search_profile: {
      platforms: ['desktop'],
      start_trial_cta: !currentAccount?.canSearchByProfile
    },
    creator: {
      platforms: ['desktop'],
      start_trial_cta: !currentAccount?.canCampaignBoard
    },
    creator_campaigns: {
      platforms: ['desktop'],
      start_trial_cta: !currentAccount?.canCampaignBoard
    },
    media_kit: {
      platforms: ['desktop'],
      start_trial_cta: !currentAccount?.canShareMediaKit
    },
    brand: {
      platforms: ['desktop'],
      start_trial_cta: !currentAccount?.canCampaignBoard
    },
    partnership: {
      platforms: ['desktop'],
      start_trial_cta: !currentAccount?.canCampaignBoard
    },
    devices: {
      platforms: ['desktop'],
      start_trial_cta: false
    }
  },
  analytics: {
    hashtag_analytics: {
      platforms: ['desktop'],
      start_trial_cta: !currentAccount?.canHashtagAnalytics
    },
    audience_analytics: {
      platforms: ['desktop'],
      start_trial_cta: !currentAccount?.canInstagramPaidAnalytics
    },
    posts_analytics: {
      platforms: ['desktop'],
      start_trial_cta: false
    },
    reels_analytics: {
      platforms: ['desktop'],
      start_trial_cta: false
    },
    stories_analytics: {
      platforms: ['desktop'],
      start_trial_cta: false
    },
    analytics: {
      platforms: ['desktop'],
      start_trial_cta: false
    },
    pinterest_analytics: {
      platforms: ['desktop'],
      start_trial_cta: !currentAccount?.canPinterestAnalytics
    }
  },
  mobile_available: {
    linkinbio: { platforms: ['desktop', 'mobile'], start_trial_cta: false }
  },
  custom: {
    high_performing_post: {
      image: '/assets/images/mov--mob--open_post.gif',
      platforms: ['mobile'],
      start_trial_cta: false,
      hide_image_css: true
    }
  }
});

export default featurelinkContent;
