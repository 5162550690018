import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{! @glint-nocheck: not typesafe yet }}\n<div class={{concat-classes \"cCA--account__item\" @profileClass}}>\n  <Shared::Ui::Button\n    @onClick={{perform this.addSocialProfile}}\n    @variant=\"unstyled\"\n    class=\"cCA--user o--user is--unselected u--p__0\"\n    data-test-id=\"schedule-add-social-profile-button\"\n  >\n    <img class=\"o--user__avatar\" src={{@profileImage}} />\n    <span class=\"cCA--user__action\">\n      {{svg-jar \"add\" class=\"o--icon--xs o--icon--white\"}}\n    </span>\n  </Shared::Ui::Button>\n</div>", {"contents":"{{! @glint-nocheck: not typesafe yet }}\n<div class={{concat-classes \"cCA--account__item\" @profileClass}}>\n  <Shared::Ui::Button\n    @onClick={{perform this.addSocialProfile}}\n    @variant=\"unstyled\"\n    class=\"cCA--user o--user is--unselected u--p__0\"\n    data-test-id=\"schedule-add-social-profile-button\"\n  >\n    <img class=\"o--user__avatar\" src={{@profileImage}} />\n    <span class=\"cCA--user__action\">\n      {{svg-jar \"add\" class=\"o--icon--xs o--icon--white\"}}\n    </span>\n  </Shared::Ui::Button>\n</div>","moduleName":"later/components/schedule/social-profiles/add-profile-link.hbs","parseOptions":{"srcName":"later/components/schedule/social-profiles/add-profile-link.hbs"}});
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { restartableTask } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';

import type { TaskGenerator } from 'ember-concurrency';
import type GroupModel from 'later/models/group';
import type ConnectProfilesService from 'later/services/social/connect-profiles';

interface AddProfileLinkComponentArgs {
  selectedSocialProfileType: string;
  currentGroup: GroupModel;
  profileImage: string;
  profileClass: string;
}

export default class AddProfileLinkComponent extends Component<AddProfileLinkComponentArgs> {
  @service('social/connect-profiles') declare connectProfiles: ConnectProfilesService;

  @restartableTask
  *addSocialProfile(): TaskGenerator<void> {
    yield taskFor(this.connectProfiles.addSocialProfile).perform({
      selectedSocialProfileType: this.args.selectedSocialProfileType,
      group: this.args.currentGroup,
      area: 'calendar'
    });
  }
}
