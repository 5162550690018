/* eslint-disable ember/no-unused-services */
import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task, timeout } from 'ember-concurrency';

import { getFeatureUpgradeIntents, SEARCH_INTENTS } from 'later/utils/global-search/intents';
import { parseQuery } from 'later/utils/global-search/search-query';
import { isPartialMatch, meetsSearchRequirements, scoreSearchResult } from 'later/utils/global-search/search-results';
import * as plansData from 'later/utils/plans-data';

import type AuthService from './auth';
import type CredentialStatusService from './credential-status';
import type SeamlessCheckoutManagerService from './seamless-checkout-manager';
import type SelectedSocialProfilesService from './selected-social-profiles';
import type SubscriptionsService from './subscriptions';
import type RouterService from '@ember/routing/router-service';
import type StoreService from '@ember-data/store';
import type IntlService from 'ember-intl/services/intl';
import type { SearchIntent } from 'later/utils/global-search/intents';
import type { SearchQuery } from 'later/utils/global-search/search-query';
import type { FeatureMap } from 'shared/utils/plans-data/feature-upgrade';

export interface SearchResult {
  title: string;
  description: string;
  score: number;
  icon: string;
  intent: SearchIntent;
  type: SearchResultType;
  query?: SearchQuery;
}

export enum SearchResultType {
  Live = 'live',
  PreviouslyUsed = 'previously-used',
  Suggested = 'suggested'
}

export default class GlobalSearchService extends Service {
  @service declare auth: AuthService;
  @service declare credentialStatus: CredentialStatusService;
  @service declare intl: IntlService;
  @service declare router: RouterService;
  @service declare selectedSocialProfiles: SelectedSocialProfilesService;
  @service declare seamlessCheckoutManager: SeamlessCheckoutManagerService;
  @service declare subscriptions: SubscriptionsService;
  @service declare store: StoreService;

  @tracked showGlobalSearch = false;

  staticSearchIntents: SearchIntent[] = SEARCH_INTENTS;

  get searchIntents(): SearchIntent[] {
    const dynamicSearchIntents = getFeatureUpgradeIntents(this.availableFeatureUpgrades);
    return [...this.staticSearchIntents, ...dynamicSearchIntents];
  }

  get featureMapList(): Record<string, FeatureMap> {
    return plansData.featureUpgradeMap(this.intl);
  }

  search = task(async (query: SearchQuery) => {
    await timeout(100);

    const searchIntents = this.#getSearchIntentsForQuery(query.normalized);
    const searchResults = this.#getSearchResultsForQuery(query, searchIntents);
    const sortedSearchResults = searchResults.sort((a, b) => b.score - a.score);

    return sortedSearchResults;
  });

  get availableFeatureUpgrades(): Record<string, FeatureMap> {
    const availableUpgrades: Record<string, FeatureMap> = {};

    if (!this.subscriptions.canUpgrade) {
      return availableUpgrades;
    }

    if (this.auth.currentAccount?.isOnLegacyPlan) {
      this.featureMapList;
    }

    const { upgradeablePlans } = this.subscriptions;

    for (const featureKey in this.featureMapList) {
      const featureConfig = this.featureMapList[featureKey];
      if (upgradeablePlans.includes(featureConfig.planName)) {
        availableUpgrades[featureKey] = featureConfig;
      }
    }

    return availableUpgrades;
  }

  closeSearchModal(): void {
    this.showGlobalSearch = false;
  }

  openSearchModal(): void {
    this.showGlobalSearch = true;
  }

  parseQuery(query: string): SearchQuery {
    return parseQuery(query);
  }

  runIntent(intent: SearchIntent, query: SearchQuery): void {
    const meta = {
      query,
      intent
    };

    intent.action(this, meta);
  }

  #getSearchIntentsForQuery(normalizedQuery: string): SearchIntent[] {
    const intents = this.searchIntents.filter((intent) => {
      if (meetsSearchRequirements(normalizedQuery, intent.mandatoryTerms)) {
        return isPartialMatch(normalizedQuery, intent.searchTerms);
      }
      return false;
    });

    return intents;
  }

  #getSearchResultsForQuery(query: SearchQuery, intents: SearchIntent[]): SearchResult[] {
    return intents.map((intent) => {
      const score = scoreSearchResult(query.normalized, intent);
      const meta = {
        query,
        intent
      };

      return {
        title: intent.getTitle(this, meta),
        description: intent.getDescription(this, meta),
        icon: intent.getIcon(this, meta),
        score,
        intent,
        type: SearchResultType.Live
      };
    });
  }

  getSuggestedSearchResults(): SearchResult[] {
    const fakeQuery = {
      raw: '',
      normalized: '',
      parts: [''],
      tokens: {
        day: [''],
        mentions: [''],
        timestamps: [''],
        tags: [''],
        text: ['']
      }
    };

    return this.searchIntents
      .filter((intent) => intent.suggested === true)
      .map((intent) => {
        const meta = {
          query: fakeQuery,
          intent
        };

        return {
          title: intent.getTitle(this, meta),
          description: intent.getDescription(this, meta),
          icon: intent.getIcon(this, meta),
          score: 100,
          intent,
          type: SearchResultType.Suggested
        };
      });
  }
}

declare module '@ember/service' {
  interface Registry {
    'global-search': GlobalSearchService;
  }
}
