import Service, { inject as service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import { tracked } from '@glimmer/tracking';
import moment from 'moment';

import loadScript from 'later/utils/load-script';
import { convert } from 'later/utils/time-format';
import { PlanInterval } from 'shared/types/plans';

import type IntlService from 'ember-intl/services/intl';
import type UserAgentService from 'ember-useragent/services/user-agent';
import type SubscriptionModel from 'later/models/subscription';
import type AlertsService from 'later/services/alerts';
import type AuthService from 'later/services/auth';
import type IntegrationsQueueService from 'later/services/integrations-queue';
import type { Integration } from 'later/services/integrations-queue';
import type SubscriptionsService from 'later/services/subscriptions';
import type { UntypedService } from 'shared/types';

type SegmentString = string | null;

type MetaFields = Record<string, any> | undefined;

interface UserAndAccountTraits {
  account_owner: boolean;
  activated_trial: boolean;
  active_trial: boolean;
  annual_user_initial_order_within_thirty_days: boolean;
  annual_user_renewed_over_thirty_days: boolean;
  billing_email: string;
  current_free_rollout: string;
  current_plan_rollout: string;
  email: string;
  experiment_variants_string: string;
  experiment_variants: string[] | [];
  first_thirty_days: boolean;
  force_migrated_at: string | null;
  has_business_profile: boolean;
  initial_free_rollout: string;
  language: string;
  monthly_user_completed_order_over_seven_days: boolean;
  more_than_5_social_sets: boolean;
  number_of_social_sets: boolean;
  number_of_users: boolean;
  plan_interval: string;
  plan_name: string;
  plan_type: string;
  subscription_provider: string;
  support_segment: string;
  user_experiment_variants_string: string;
  user_experiment_variants: string[] | [];
  user_plan_name: string;
}

export default class AdaService extends Service implements Integration {
  @service declare alerts: AlertsService;
  @service declare auth: AuthService;
  @service declare errors: UntypedService;
  @service declare segment: UntypedService;
  @service declare subscriptions: SubscriptionsService;
  @service declare integrationsQueue: IntegrationsQueueService;
  @service declare intl: IntlService;
  @service declare userAgent: UserAgentService;

  readonly name = 'ada';

  @tracked isOpen = false;

  get adaContainer(): HTMLDivElement | null {
    return document.querySelector('#ada-entry');
  }

  get hasActiveTrial(): boolean {
    return this.subscriptions.hasActiveTrial;
  }

  get subscription(): SubscriptionModel | Record<string, never> {
    return this.subscriptions.subscription;
  }

  async fetchUserAndAccountTraits(): Promise<UserAndAccountTraits | undefined> {
    let userAndAccountTraits;

    try {
      userAndAccountTraits = await this.segment.fetchUserAndAccountTraits.perform();
    } catch {
      return;
    }

    const { user, account } = userAndAccountTraits;
    const {
      activated_trial,
      active_trial,
      billing_email,
      created_at: account_created_at,
      current_free_rollout,
      current_plan_rollout,
      experiment_variants,
      experiment_variants_string,
      force_migrated_at,
      has_business_profile,
      initial_free_rollout,
      plan_interval,
      plan_name,
      plan_type,
      subscription_provider_name
    } = account;

    const {
      account_owner,
      email,
      plan_name: user_plan_name,
      experiment_variants: user_experiment_variants,
      experiment_variants_string: user_experiment_variants_string,
      language,
      support_segment,
      number_of_social_sets,
      number_of_users
    } = user;

    const more_than_5_social_sets = number_of_social_sets > 5;
    const first_thirty_days = Boolean(
      account_created_at && new Date(account_created_at) > new Date(Date.now() - convert.days(30).toMilliseconds())
    );

    const subscription_provider = subscription_provider_name === 'Apple' ? 'iOS' : 'Web';
    let annual_user_initial_order_within_thirty_days = false;
    let annual_user_renewed_over_thirty_days = false;
    let monthly_user_completed_order_over_seven_days = false;

    if (this.subscription?.createdDate) {
      const subscriptionCreatedDate = moment(this.subscription.createdDate);
      const daysSinceCreatingSubscription = moment().diff(subscriptionCreatedDate, 'days');

      annual_user_initial_order_within_thirty_days =
        plan_interval === PlanInterval.Yearly && daysSinceCreatingSubscription < 30;

      annual_user_renewed_over_thirty_days =
        plan_interval === PlanInterval.Yearly &&
        Boolean(
          this.subscription?.renewalDate &&
            new Date(this.subscription.renewalDate) < new Date(Date.now() - convert.days(30).toMilliseconds()) &&
            subscriptionCreatedDate.year() !== moment().year()
        );

      monthly_user_completed_order_over_seven_days =
        plan_interval === PlanInterval.Monthly && daysSinceCreatingSubscription > 7;
    }

    return {
      account_owner,
      activated_trial,
      active_trial,
      annual_user_initial_order_within_thirty_days,
      annual_user_renewed_over_thirty_days,
      billing_email,
      current_free_rollout,
      current_plan_rollout,
      email,
      experiment_variants_string,
      experiment_variants,
      first_thirty_days,
      force_migrated_at,
      has_business_profile,
      initial_free_rollout,
      language,
      monthly_user_completed_order_over_seven_days,
      more_than_5_social_sets,
      number_of_social_sets,
      number_of_users,
      plan_interval,
      plan_name,
      plan_type,
      subscription_provider,
      support_segment,
      user_experiment_variants_string,
      user_experiment_variants,
      user_plan_name
    };
  }

  async setup(): Promise<void> {
    // Note: canAdaChatbot is set to false for certain staging QA users
    // to prevent widget from interfering with locators on auto tests
    if (this.userAgent.device.isMobile || !this.auth.currentAccount?.canAdaChatbot) {
      return;
    }

    window.adaSettings = {
      handle: 'later',
      adaReadyCallback: () => {
        /**
         * Ada is slow to load so integrationsQueue may have set up and processed
         * an item in the queue already. So Ada may need to be hidden when loaded
         */
        this.integrationsQueue.refreshIntegrations();
      },
      toggleCallback: (isDrawerOpen: boolean) => {
        this.isOpen = isDrawerOpen;
        if (isDrawerOpen) {
          this.integrationsQueue.addToQueue(this.name, true);
          this.#trackOpened();
        } else {
          this.integrationsQueue.removeFromQueue(this.name);
        }
      }
    };

    try {
      await loadScript('https://static.ada.support/embed2.js', {
        id: '__ada'
      });
      await this.#setUserAndAccountDetails();
    } catch (error) {
      this.errors.log('Ada failed to load', error);
      this.#displayError();
    }
  }

  show(): void {
    if (this.adaContainer) {
      this.adaContainer.style.display = '';
    }
  }

  hide(): void {
    if (this.adaContainer) {
      this.adaContainer.style.display = 'none';
    }
  }

  open(location: SegmentString = null, page: SegmentString = null): void {
    if (!this.isOpen) {
      window.adaEmbed?.toggle();
      this.#trackManualOpen(location, page);
    }
  }

  close(): void {
    if (this.isOpen) {
      window.adaEmbed?.toggle();
    }
  }

  closeCampaign(): void {
    window.adaEmbed?.closeCampaign();
  }

  reset(): void {
    window.adaEmbed?.reset();
  }

  trackEvent(name: string): void {
    window.adaEmbed?.trackEvent(name);
  }

  triggerCampaign(campaignKey: string, metaFields?: MetaFields): void {
    this.setMetaFields(metaFields);
    window.adaEmbed?.triggerCampaign(campaignKey);
  }

  setMetaFields(metaFields: MetaFields): void {
    if (metaFields) {
      window.adaEmbed?.setMetaFields(metaFields);
    }
  }

  #trackManualOpen(location: SegmentString, page: SegmentString): void {
    this.segment.track('user-opened-chabot', {
      location,
      active_trial: !!this.hasActiveTrial,
      page
    });
  }

  #trackOpened(): void {
    this.segment.track('chatbot-opened', { active_trial: !!this.hasActiveTrial });
  }

  #displayError(): void {
    this.alerts.warning(htmlSafe(this.intl.t('alerts.customer_support.error.service_not_available.message')), {
      title: this.intl.t('alerts.customer_support.error.service_not_available.title')
    });
  }

  async #setUserAndAccountDetails(): Promise<void> {
    const metaFields = await this.fetchUserAndAccountTraits();
    this.setMetaFields(metaFields);
  }
}

declare module '@ember/service' {
  interface Registry {
    ada: AdaService;
  }
}
