import { A } from '@ember/array';
import Service, { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';

import { areStringsEqual } from 'later/utils/compare-strings';
import { timestamp } from 'later/utils/time-format';
import { CampaignKey, CacheEventName } from 'shared/utils/constants/ada-campaigns';

import type SubscriptionsService from './subscriptions';
import type RouterService from '@ember/routing/router-service';
import type Store from '@ember-data/store';
import type { TaskGenerator } from 'ember-concurrency';
import type GroupModel from 'later/models/group';
import type AdaService from 'later/services/ada';
import type AuthService from 'later/services/auth';
import type CacheService from 'later/services/cache';
import type { UntypedService } from 'shared/types';

interface CampaignActivatedEventPayload {
  campaign_id: string;
  active_trial: boolean;
  plan: string;
  goal_with_later: string | null;
}

export default class AdaCampaignsService extends Service {
  @service declare ada: AdaService;
  @service declare auth: AuthService;
  @service declare cache: CacheService;
  @service declare errors: UntypedService;
  @service declare router: RouterService;
  @service declare store: Store;
  @service declare subscriptions: SubscriptionsService;
  @service declare segment: UntypedService;

  readonly prefix = 'campaigns';

  @tracked initialNumberOfSocialProfilesToBeRefreshed: number | undefined = undefined;
  @tracked seenCampaigns: string[] = A([]);

  get abandonedCartId(): string {
    return `cart_${this.auth.currentUserModel?.id}`;
  }

  get currentGroup(): GroupModel {
    return this.auth.currentGroup;
  }

  get planPath(): string {
    return `planPath_${this.auth.currentUserModel?.id}`;
  }

  get abandonedCartCampaignKey(): CampaignKey {
    const { planName, isEligibleForTrial, hasActiveTrial } = this.subscriptions;
    const paidToUpgrade =
      !hasActiveTrial &&
      (areStringsEqual(planName, 'Growth') || areStringsEqual(planName, 'Starter')) &&
      CampaignKey.PaidToUpgrade;
    const freeToTrial = isEligibleForTrial && areStringsEqual(planName, 'Free') && CampaignKey.FreeToTrial;
    const freeToPaid = !isEligibleForTrial && areStringsEqual(planName, 'Free') && CampaignKey.FreeToPaid;

    return paidToUpgrade || freeToTrial || freeToPaid || CampaignKey.None;
  }

  get numberOfSocialProfilesToBeRefreshed(): number {
    return this.currentGroup.socialProfiles?.filter((profile) => profile.get('hasError'))?.length || 0;
  }

  @task
  *initAll(): TaskGenerator<void> {
    if (!this.auth.currentUserModel?.id) {
      return;
    }
    yield taskFor(this._loadCampaigns).perform();
    this.#initAbandonedCartCampaign();
    this.#initRefreshCredentialsCampaign();

    if (this.#shouldCloseRefreshCredentialsCampaign()) {
      this.ada.closeCampaign();
    }
  }

  cacheAbandonedCartEvent(eventName: CacheEventName, expiryInDays: null | number = null): void {
    const expiry = expiryInDays ? this.cache.expiry(expiryInDays, 'days') : this.cache.maxExpiryDate();
    this.cache.add(this.abandonedCartId, eventName, { expiry, persist: true });
  }

  cachePlanName(planName: string): void {
    this.cache.add(this.planPath, planName, {
      expiry: this.cache.maxExpiryDate(),
      persist: true
    });
  }

  @task
  *_loadCampaigns(): TaskGenerator<void> {
    if (!isEmpty(this.seenCampaigns)) {
      return;
    }
    const discoveryListItems = yield this.store.peekAll('discovery-list-task');
    discoveryListItems.forEach(({ identifier }: { identifier: string }) => {
      const [prefix, rest] = identifier.split('.');
      if (prefix === this.prefix) {
        this.seenCampaigns.pushObject(rest);
      }
    });
  }

  #initAbandonedCartCampaign(): void {
    const path = this.cache.retrieve(this.planPath);
    if (this.#shouldShowAbandonedCartCampaign() && path) {
      const url = `${window.location.origin}/plans/${path}`;
      this.#triggerCampaign(this.abandonedCartCampaignKey, { cart_url: url });
      this.#trackCampaignActivated({
        campaign_id: this.abandonedCartCampaignKey,
        active_trial: !!this.subscriptions.hasActiveTrial,
        plan: this.subscriptions.planName ?? '',
        goal_with_later: this.auth.currentAccount.goalWithLater ?? ''
      });
    }
  }

  #initRefreshCredentialsCampaign(): void {
    if (this.#shouldShowRefreshCredentialsCampaign()) {
      this.#triggerCampaign(CampaignKey.RefreshCredentials);
      this.#trackCampaignActivated({
        campaign_id: CampaignKey.RefreshCredentials,
        active_trial: this.subscriptions.hasActiveTrial,
        plan: this.subscriptions.planName ?? '',
        goal_with_later: this.auth.currentAccount.goalWithLater ?? ''
      });
      if (this.initialNumberOfSocialProfilesToBeRefreshed === undefined) {
        this.initialNumberOfSocialProfilesToBeRefreshed = this.numberOfSocialProfilesToBeRefreshed;
      }
    }
  }

  #shouldCloseRefreshCredentialsCampaign(): boolean {
    //Note: close campaign if user has successfully refreshed a profile
    return (
      this.initialNumberOfSocialProfilesToBeRefreshed !== undefined &&
      this.numberOfSocialProfilesToBeRefreshed < this.initialNumberOfSocialProfilesToBeRefreshed
    );
  }

  #shouldShowAbandonedCartCampaign(): boolean {
    return (
      !this.router.currentRouteName.includes('plans') &&
      !this.seenCampaigns.includes(this.abandonedCartCampaignKey) &&
      this.cache.retrieve(this.abandonedCartId) === CacheEventName.ViewedCheckout &&
      !!this.abandonedCartCampaignKey
    );
  }

  #shouldShowRefreshCredentialsCampaign(): boolean {
    return (
      this.currentGroup?.socialProfiles?.any((profile) => profile.get('hasError')) &&
      this.router.currentRouteName.includes('groups.group.social_profiles')
    );
  }

  #triggerCampaign(campaignKey: CampaignKey, metaFields?: { cart_url: string }): void {
    try {
      this.ada.triggerCampaign(campaignKey, metaFields);
      this.#saveCampaignKey(campaignKey);
    } catch (error) {
      this.errors.log('Failed to trigger Ada campaign', error);
    }
  }

  #saveCampaignKey(campaignKey: CampaignKey): void {
    if (!this.seenCampaigns.includes(campaignKey)) {
      const item = this.store.createRecord('discovery-list-task', {
        identifier: `${this.prefix}.${campaignKey}`,
        completedTime: timestamp(),
        user: this.auth.currentUserModel
      });
      item.save();
    }
  }

  #trackCampaignActivated(properties: CampaignActivatedEventPayload): void {
    this.segment.track('chatbot-campaign-activated', properties);
  }
}

declare module '@ember/service' {
  interface Registry {
    'ada-campaigns': AdaCampaignsService;
  }
}
