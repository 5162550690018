import { action } from '@ember/object';
import Service, { inject as service } from '@ember/service';

import loadScript from 'later/utils/load-script';

import type SegmentService from 'later/services/segment';

interface InitConfig {
  id: string;
  onReady: (video: any) => void;
}

/**
 * Service for initializing and interacting with Wistia API.
 */
export default class WistiaVideoService extends Service {
  @service declare segment: SegmentService;

  wistiaInitConfigMap = new Map<string, InitConfig>();

  constructor(...args: Record<string, unknown>[]) {
    super(...args);

    const urls = [
      'https://fast.wistia.com/assets/external/popover-v1.js',
      'https://fast.wistia.com/assets/external/E-v1.js'
    ];

    Promise.all(urls.map((url) => loadScript(url)));
  }

  /**
   * Loads the Wistia SpeedDemon script for a specific video to help it initialize faster
   **/
  setup(url: string): void {
    loadScript(url);
  }

  /**
   * Adds an event to the Wistia initialization queue to fire a Segment event based on Wistia's
   * percentwatchedchanged event. See percentTrigger const.
   * Using the Wistia library to queue listeners is more reliable. We can queue event listeners
   * without considering the element's status and they will be attached when the element is mounted.
   */
  trackPercentWatched(id: string, customPayload?: Record<string, unknown>): void {
    const percentTrigger = 0.25;
    window._wq = window._wq || [];

    const initConfig = {
      id,
      onReady: (video: any) => {
        video.bind('percentwatchedchanged', (percent: number, lastPercent: number) => {
          const defaultPayload = {
            percent: `${percentTrigger * 100}%`,
            video_name: video.data.media.name,
            video_id: video.data.media.hashedId,
            url: `https://fast.wistia.com/embed/medias/${video.data.media.hashedId}.jsonp`
          };

          const payload = { ...defaultPayload, ...customPayload };

          if (percent >= percentTrigger && lastPercent < percentTrigger) {
            this.segment.track('watched-video', payload);
          }
        });
      }
    };

    this.wistiaInitConfigMap.set(id, initConfig);
    window._wq.push(initConfig);
  }

  @action
  revoke(id: string): void {
    // Note: You must use Wistia's revoke to remove bound event listeners, otherwise you will have
    // compounding calls when the component is unmounted and remounted repeatedly
    window._wq.push({ revoke: this.wistiaInitConfigMap.get(id) });
    this.wistiaInitConfigMap.delete(id);
  }
}

declare module '@ember/service' {
  interface Registry {
    'wistia-video': WistiaVideoService;
  }
}
