import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { dropTask, enqueueTask } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import { keyBy } from 'lodash';

import config from 'later/config/environment';
import { fetch } from 'later/utils/fetch';
import { timestamp } from 'later/utils/time-format';

import type { TaskGenerator } from 'ember-concurrency';
import type ErrorsService from 'later/services/errors';
import type { SocialPlatformType } from 'shared/types/social-profile';
import type {
  Incident,
  Status,
  LaterStatusPageSummary,
  IncidentUpdate,
  LaterComponents
} from 'shared/types/status-page';

export default class StatusPageService extends Service {
  @service declare errors: ErrorsService;

  @tracked response: LaterStatusPageSummary | null = null;
  @tracked components: LaterComponents | null = null;
  @tracked lastUpdated: number | null = null;

  get status(): Status['status'] | undefined {
    return this.response?.status;
  }

  get incidents(): Incident[] | undefined {
    return this.response?.incidents;
  }

  /**
   * Check if a particular platform(component) is not fully operational
   *
   * @param platform name of platform
   * @returns if the platform is non-operational
   */
  async isDown(platform: Exclude<SocialPlatformType, SocialPlatformType.Unknown>): Promise<boolean> {
    await taskFor(this._getSummary).perform();
    if (this.components && this.components[platform]) {
      return this.components[platform].status !== 'operational';
    }
    return false;
  }

  /**
   * Get the last incident to be reported on statuspage.io
   *
   * @returns incident and update objects
   */
  async getLastIncident(): Promise<{ incident: Incident | null; update: IncidentUpdate | null }> {
    await taskFor(this._getSummary).perform();
    const incident = this.incidents && this.incidents.length ? this.incidents[0] : null;
    const update = incident && incident.incident_updates ? incident.incident_updates[0] : null;
    return { incident, update };
  }

  /**
   * Get all the incidents to be reported on statuspage.io
   *
   * @returns incident and update objects
   */
  async getAllIncidents(): Promise<{ incident: Incident; update: IncidentUpdate | null }[] | undefined> {
    await taskFor(this._getSummary).perform();
    return this.incidents?.map((incident) => {
      const update = incident?.incident_updates ? incident.incident_updates[0] : null;
      return { incident, update };
    });
  }

  @enqueueTask
  *_getSummary(): TaskGenerator<void> {
    if (!this.lastUpdated || this.lastUpdated < timestamp() - 60) {
      yield taskFor(this._fetchSummary)
        .perform()
        .catch((error) => {
          if (error.name !== 'TaskCancelation') {
            throw error;
          }
        });
    }
  }

  @dropTask
  *_fetchSummary(): TaskGenerator<void> {
    const response = yield fetch(config.APP.statusPageSummaryEndpoint);
    if (response) {
      this.setProperties({
        components: keyBy(response.components, 'name'),
        lastUpdated: timestamp(),
        response
      });
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    'status-page': StatusPageService;
  }
}
