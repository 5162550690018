import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class PartnershipsRoute extends Route {
  @service alerts;
  @service auth;
  @service intl;
  @service router;

  get group() {
    return this.auth.lastGroup;
  }

  get groupSlug() {
    return this.group.slug;
  }

  get socialIdentity() {
    const identity = this.group.socialIdentities.firstObject;
    return identity;
  }

  redirect(model, transition) {
    const { feature } = transition.to.params;
    switch (feature) {
      case 'partnership':
        this.router.transitionTo('cluster.partnerships', this.groupSlug);
        break;

      case 'creator':
        this.router.transitionTo('cluster.partnerships.profile', this.groupSlug, this.socialIdentity.id);
        break;

      case 'creator_campaigns':
        this.router.transitionTo('cluster.partnerships.profile.campaigns', this.groupSlug, this.socialIdentity.id);
        break;

      case 'creator_campaign_manager':
        this.router.transitionTo(
          'cluster.partnerships.profile.manage-campaigns',
          this.groupSlug,
          this.socialIdentity.id
        );
        break;

      case 'brand':
        this.router.transitionTo('cluster.partnerships.profiles', this.groupSlug, this.socialIdentity.id);
        break;

      case 'brand_search':
        this.router.transitionTo(
          'cluster.partnerships.profiles.creators.search',
          this.groupSlug,
          this.socialIdentity.id
        );
        break;

      case 'brand_campaigns':
        this.router.transitionTo('cluster.partnerships.profiles.campaigns', this.groupSlug, this.socialIdentity.id);
        break;

      default:
        this.router.transitionTo('/');
    }
  }
}
