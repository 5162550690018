import Service, { inject as service } from '@ember/service';
import loadImage from 'blueimp-load-image';
import { task } from 'ember-concurrency';
import NProgress from 'nprogress';
import RSVP from 'rsvp';

import IgPost from 'later/models/ig-post';
import MentionModel from 'later/models/mention';
import { MEDIA_LABEL_NAMES, NULL_VALUE } from 'later/utils/constants';
import buildMentionSegmentType from 'later/utils/regram/build-mention-segment';
import captionHelper from 'later/utils/regram/caption-helper';
import downloadMedia from 'later/utils/regram/download-media';

import type RouterService from '@ember/routing/router-service';
import type StoreService from '@ember-data/store';
import type IntlService from 'ember-intl/services/intl';
import type MediaItemModel from 'later/models/media-item';
import type UnsplashImage from 'later/models/unsplash-image';
import type AlertsService from 'later/services/alerts';
import type AuthService from 'later/services/auth';
import type ErrorsService from 'later/services/errors';
import type SegmentService from 'later/services/segment';
import type { COLLECT_SOURCE_TYPES } from 'later/utils/constants';
import type { UntypedService, ValueOfType } from 'shared/types';
import type { CarouselMediaItem } from 'shared/types/mentions';

type CollectSource = ValueOfType<typeof COLLECT_SOURCE_TYPES>;
type MediaLabelNames = ValueOfType<typeof MEDIA_LABEL_NAMES>;
type CollectMediaType = MentionModel | IgPost | UnsplashImage;
type RegramObject =
  | { mediaItem: MediaItemModel; item: CarouselMediaItem }
  | { mediaItem: MediaItemModel; item: CollectMediaType };

export default class RegramService extends Service {
  @service declare alerts: AlertsService;
  @service declare auth: AuthService;
  @service declare errors: ErrorsService;
  @service declare intl: IntlService;
  @service declare router: RouterService;
  @service declare segment: SegmentService;
  @service declare store: StoreService;
  @service declare uploadBus: UntypedService;

  /**
   * Adds raw media to media library. Pulls caption and applies system labels if possible.
   */
  openRegram = task(async (media: CollectMediaType, mediaLabel: MediaLabelNames, collectSource: CollectSource) => {
    try {
      let mediaItems: RegramObject[];
      if ('isCarouselPost' in media && media.isCarouselPost) {
        mediaItems = media.carouselMediaItems.map((item: CarouselMediaItem, index, array: CarouselMediaItem[]) => {
          const carouselCaption = captionHelper(item, media.username, index, array);
          const mediaItem = this.store.createRecord('media-item', {
            group: this.auth.currentGroup,
            mediaInstagramId: item.id,
            collectSource,
            sourceMediaId: item.id,
            sourcePostId: media.id,
            sourceUsername: media.username,
            defaultCaption: carouselCaption
          });
          return { mediaItem, item };
        });
      } else {
        const mediaItem = this.store.createRecord('media-item', {
          group: this.auth.currentGroup,
          mediaInstagramId: media.id,
          collectSource,
          sourceMediaId: media.id,
          sourcePostId: media.id,
          sourceUsername: media.username,
          defaultCaption: media.captionText
        });
        mediaItems = [{ mediaItem, item: media }];
      }

      await Promise.all(
        mediaItems.map(async ({ mediaItem, item }) => {
          const updatedMediaItem = await this.mediaLibraryRegram(mediaItem, item);
          await updatedMediaItem.assertLabelAndApply(`^${mediaLabel}`);
          return updatedMediaItem.save();
        })
      );
      this.#trackSegmentEvents(mediaItems);
    } catch (error) {
      this.alerts.warning(this.intl.t('alerts.generic_error_message'), {
        title: this.intl.t('alerts.regram.trouble_loading.title')
      });
      this.errors.log(error);
    }
  });

  /**
   * Takes a newly-created Media Item and an Instagram media and uploads the Instagram's Media through uploadBus and updates the {MediaItem}.
   * Will download the media on the Instagram post normally available through the API
   */
  mediaLibraryRegram(mediaItem: MediaItemModel, media: CarouselMediaItem | CollectMediaType): Promise<MediaItemModel> {
    return new Promise((resolve, reject) => {
      if ((media instanceof MentionModel || media instanceof IgPost) && media.type === 'video') {
        mediaItem.set('mediaType', 'video');
        downloadMedia(media.standardResVideoUrl as string)
          .then((video) => this.#uploadMedia(mediaItem, video))
          .then((mediaItem) => resolve(mediaItem))
          .catch((error) => {
            reject(error);
          });
      } else {
        mediaItem.set('mediaType', 'image');
        downloadMedia(media.standardResUrl as string)
          .then((image) => this.#processImage(mediaItem, image))
          .then(([mediaItem, image]) => this.#uploadMedia(mediaItem, image))
          .then((mediaItem) => resolve(mediaItem))
          .catch((error) => {
            this.errors.log(error, { sourceUrl: media.standardResUrl as string });
            this.alerts.warning(this.intl.t('alerts.generic_error_message'), {
              title: this.intl.t('alerts.regram.trouble_loading.title')
            });
          });
      }
    });
  }

  /**
   * Takes a download image, sets the dimensions and ensures it's large enough for regram
   */
  #processImage(mediaItem: MediaItemModel, image: Blob): Promise<[MediaItemModel, Blob]> {
    const LOW_RES_THRESHOLD = 500;
    return new Promise((resolve, reject) => {
      loadImage(image, (img: HTMLImageElement) => {
        if (!img) {
          reject('Could not process image');
        }

        if (img.width < LOW_RES_THRESHOLD || img.height < LOW_RES_THRESHOLD) {
          this.alerts.alert(
            this.intl.t('alerts.regram.low_resolution.message', { width: img.width, height: img.height }),
            {
              title: this.intl.t('alerts.regram.low_resolution.title')
            }
          );
        }
        mediaItem.set('width', img.width);
        mediaItem.set('height', img.height);
        resolve([mediaItem, image]);
      });
    });
  }

  /**
   * Triggers an upload of the file blob from the regram
   */
  #uploadMedia(mediaItem: MediaItemModel, file: Blob): Promise<MediaItemModel> {
    return new RSVP.Promise((resolve, reject) => {
      NProgress.start();
      this.uploadBus.addUploadToQueue(
        file,
        mediaItem,
        () => {
          const message = this.intl.t('alerts.regram.added_to_library');
          this.alerts.success(message, {
            action: () => this.router.transitionTo('cluster.media'),
            actionText: this.intl.t('alerts.regram.view_media'),
            preventDuplicates: true
          });
          NProgress.done();
          resolve(mediaItem);
        },
        (error: Error) => {
          NProgress.done();
          this.errors.show(error);
          reject(error);
        }
      );
    });
  }

  /**
   * Tracks segment events when a media item(s) is regrammed
   */
  #trackSegmentEvents(mediaItems: RegramObject[]): void {
    const standardResUrl: string[] = [];
    const mediaItemType = new Set();
    const mediaItemCount = mediaItems.length;
    const { sourceUsername, labelNames } = mediaItems[0].mediaItem;
    const { item } = mediaItems[0];
    let mentionType: string | undefined;
    let link: string | undefined;
    if ('mentionType' in item) {
      ({ mentionType } = item);
    }
    if ('link' in item) {
      ({ link } = item);
    }

    mediaItems.forEach(({ item }) => {
      if (item.standardResUrl === 'string') {
        standardResUrl.push(item.standardResUrl);
      } else if ('standardResVideoUrl' in item && item.standardResVideoUrl === 'string') {
        standardResUrl.push(item.standardResVideoUrl);
      }
      if ('mediaType' in item) {
        mediaItemType.add(item.mediaType);
      } else if ('type' in item) {
        mediaItemType.add(item.type);
      }
    });

    const standardResUrlString = standardResUrl.join(',');
    const mediaItemTypeString = [...mediaItemType].join(',');

    const { nickname = NULL_VALUE, industry = NULL_VALUE } = this.auth.currentSocialProfile;

    if (labelNames.includes(MEDIA_LABEL_NAMES.TAGS)) {
      this.segment.track('added-to-library-from-tags', {
        media_from: '@' + sourceUsername,
        media_type: mediaItemTypeString,
        media_url: standardResUrlString,
        social_profile: nickname,
        industry,
        media_item_count: mediaItemCount
      });
    } else if (
      labelNames.includes(MEDIA_LABEL_NAMES.MENTIONS) ||
      labelNames.includes(MEDIA_LABEL_NAMES.MENTION_COMMENTS)
    ) {
      const mention_type = buildMentionSegmentType(nickname, sourceUsername, mentionType);
      this.segment.track('added-to-library-from-mentions', {
        media_from: '@' + sourceUsername,
        media_type: mediaItemTypeString,
        media_url: standardResUrlString,
        social_profile: nickname,
        mention_type,
        industry,
        media_item_count: mediaItemCount
      });
    } else if (labelNames.includes(MEDIA_LABEL_NAMES.HASHTAG)) {
      this.segment.track('added-to-library-from-hashtag', {
        media_from: '@' + sourceUsername,
        media_type: mediaItemTypeString,
        media_url: standardResUrlString,
        network: 'Instagram',
        media_item_count: mediaItemCount
      });
    } else if (labelNames.includes(MEDIA_LABEL_NAMES.URL)) {
      this.segment.track('added-to-library-from-url', {
        base_url: link ?? NULL_VALUE,
        media_type: mediaItemTypeString,
        media_url: standardResUrlString,
        industry
      });
    } else if (labelNames.includes(MEDIA_LABEL_NAMES.UNSPLASH)) {
      this.segment.track(' added-to-library-from-stock-photos', {
        media_from: sourceUsername,
        media_type: mediaItemTypeString,
        media_url: standardResUrlString,
        stock_library: 'unsplash'
      });
    } else if (labelNames.includes(MEDIA_LABEL_NAMES.SEARCH_BY_PROFILE)) {
      this.segment.track('added-to-library-from-profile', {
        media_from: '@' + sourceUsername,
        media_type: mediaItemTypeString,
        media_url: standardResUrlString,
        media_item_count: mediaItemCount
      });
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    regram: RegramService;
  }
}
