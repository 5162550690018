import Model, { attr, belongsTo } from '@ember-data/model';

import type SocialProfileModel from 'later/models/social-profile';

export const ListenerType = {
  Hashtag: 'InstagramHashtagListener',
  Mention: 'InstagramMentionListener',
  User: 'InstagramUserListener'
} as const;

export default class ListenerModel extends Model {
  @belongsTo('socialProfile') declare socialProfile: SocialProfileModel;
  @attr('string') declare targetName: string;
  @attr('string') declare type: typeof ListenerType;
  @attr('number') declare createdAt: number;
  @attr('string') declare targetId: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    listener: ListenerModel;
  }
}
