import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<ul class=\"cNA--mediaGrid\" {{did-insert this.updateMediaItemPreview}}>\n  {{#each (repeat this.NUM_MEDIA_PREVIEW) as |media index|}}\n    {{#let (object-at index this.previewMediaItems) as |mediaItem|}}\n      {{#if mediaItem}}\n        <Shared::MediaItems::MediaItem @mediaItem={{mediaItem}} />\n      {{else}}\n        <li class=\"cLIB--boardItem\">\n          <div class=\"o--mediaContainer\">\n            <figure class=\"img--empty o--media u--m__0\"></figure>\n          </div>\n        </li>\n      {{/if}}\n    {{/let}}\n  {{/each}}\n</ul>", {"contents":"<ul class=\"cNA--mediaGrid\" {{did-insert this.updateMediaItemPreview}}>\n  {{#each (repeat this.NUM_MEDIA_PREVIEW) as |media index|}}\n    {{#let (object-at index this.previewMediaItems) as |mediaItem|}}\n      {{#if mediaItem}}\n        <Shared::MediaItems::MediaItem @mediaItem={{mediaItem}} />\n      {{else}}\n        <li class=\"cLIB--boardItem\">\n          <div class=\"o--mediaContainer\">\n            <figure class=\"img--empty o--media u--m__0\"></figure>\n          </div>\n        </li>\n      {{/if}}\n    {{/let}}\n  {{/each}}\n</ul>","moduleName":"later/components/nav/app-header-subnav-media-grid.hbs","parseOptions":{"srcName":"later/components/nav/app-header-subnav-media-grid.hbs"}});
import { A } from '@ember/array';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';

export default class AppHeaderSubnavMediaGridComponent extends Component {
  @service auth;
  @service store;

  NUM_MEDIA_PREVIEW = 6;

  @tracked previewMediaItems = A();

  @action
  updateMediaItemPreview() {
    this.fillMediaItemPreview.perform();
  }

  /**
   * Retrieves NUM_MEDIA_PREVIEW media items from the current access group
   * to show in the media preview tile
   *
   * @method fillMediaItemPreview
   *
   * @return {Array} An array of the user's media items for preview
   *
   */
  @task
  *fillMediaItemPreview() {
    this.previewMediaItems = yield this.store.query('media-item', {
      limit: this.NUM_MEDIA_PREVIEW,
      group_id: this.auth.currentGroup.id
    });
  }
}
