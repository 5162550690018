export interface SearchQuery {
  raw: string;
  normalized: string;
  parts: string[];
  tokens: SearchQueryTokens;
}

interface SearchQueryTokens {
  day: string[];
  mentions: string[];
  tags: string[];
  text: string[];
  timestamps: string[];
  [key: string]: string[] | undefined;
}

const DAY_NAMES = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sun',
  'mon',
  'tue',
  'tues',
  'wed',
  'weds',
  'thu',
  'thur',
  'fri',
  'sat'
];

function safePush(obj: Record<string, string[]>, key: string, value: string): void {
  if (obj[key].length) {
    obj[key].push(value);
    return;
  }

  obj[key] = [value];
}

function getQueryParts(query: string): string[] {
  const parts = [];
  let isInQuote = false;
  let currentPart = '';

  query.split('').forEach((char) => {
    if (char === ' ') {
      if (!isInQuote) {
        parts.push(currentPart);
        currentPart = '';
        return;
      }
    }

    if (char === '"') {
      isInQuote = !isInQuote;
    }

    currentPart += char;
  });

  currentPart && parts.push(currentPart);

  return parts;
}

export function parseQuery(query: string): SearchQuery {
  const tokens = {
    day: [],
    mentions: [],
    tags: [],
    text: [],
    timestamps: []
  };

  const parts = getQueryParts(query);
  parts.forEach((part, partIdx) => {
    const isLastPart = partIdx === parts.length - 1;
    if (part.startsWith('@')) {
      safePush(tokens, 'mentions', part.replace('@', ''));
    } else if (part.startsWith('#')) {
      safePush(tokens, 'tags', part.replace('#', ''));
    } else if (DAY_NAMES.includes(part)) {
      safePush(tokens, 'day', part);
    } else if (part.match(/^\d{1,2}(:\d{2})?([ap]m)?$/)) {
      safePush(tokens, 'timestamps', part);
    } else if (part.startsWith('"') && (part.endsWith('"') || isLastPart)) {
      safePush(tokens, 'text', part.replace(/"/g, ''));
      /*
      Timestamp write as 1:00 or 1:00pm or just a number to indidate the wanted hour.
      This works with the following: 1, 1am 1:11am/pm. 1:11.
      It will use the closest future time based on the current time (ex 11am will schedule today if 9am).
      */
    } else if (part.includes(':')) {
      const [key, value] = part.split(':');
      safePush(tokens, key, value);
    }
  });

  return {
    raw: query,
    normalized: normalizeQuery(query),
    parts,
    tokens
  };
}

function normalizeQuery(query: string): string {
  return query.toLowerCase().trim();
}
