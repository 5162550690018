import Service, { inject as service } from '@ember/service';
import { typeOf } from '@ember/utils';
import Model from '@ember-data/model';
import { isTesting } from '@embroider/macros';
import Ember from 'ember';
import { isError, isNull, isObject, isString } from 'lodash';
import RSVP from 'rsvp';

import type { User as DatadogUser } from '@datadog/browser-core';
import type RouterService from '@ember/routing/router-service';
import type IntlService from 'ember-intl/services/intl';
import type AlertsService from 'later/services/alerts';
import type DatadogService from 'later/services/datadog';
import type { FeatureFlags } from 'later/utils/feature-flags';
import type { JsonObject, JsonValue } from 'type-fest';

interface UnknownAjaxError {
  message?: string;
}

export const enum ErrorSeverity {
  Critical = 'critical',
  Error = 'error',
  Warning = 'warning',
  Info = 'info',
  Debug = 'debug'
}

interface UnknownSpecificAdapterError {
  status?: string;
  title?: string;
  detail?: any;
}

const hasErrorsProperty = (error: unknown): error is { errors: unknown } =>
  isObject(error) && !isNull(error) && 'errors' in error;

const hasMessageProperty = (error: unknown): error is { message: string } =>
  isObject(error) && !isNull(error) && 'message' in error;

/**
 * This service provides general error handling for the app
 */
export default class ErrorsService extends Service {
  @service declare intl: IntlService;
  @service declare alerts: AlertsService;
  @service declare router: RouterService;
  @service declare datadog: DatadogService;

  setup(): void {
    this.#setupGlobalErrorLogging();
  }
  /**
   * Log error to console / critical=red error / error/warning = white log, info/debug = blue debug
   *
   * @param error javascript error or message describing the error
   * @param data additional information that should be sent along with the error
   */
  log(error: Error | string, data: JsonObject = {}, severity: ErrorSeverity = ErrorSeverity.Error): void {
    let normalizedSeverity = severity;

    const isValidSeverity = [
      ErrorSeverity.Critical,
      ErrorSeverity.Error,
      ErrorSeverity.Warning,
      ErrorSeverity.Info,
      ErrorSeverity.Debug
    ].includes(normalizedSeverity);
    if (!isValidSeverity) {
      console.log('Errors service: Invalid severity level passed. ', data, severity);
      normalizedSeverity = ErrorSeverity.Error;
    }

    if (!error) {
      console.log('Errors service: No error passed.', data, normalizedSeverity);
      return;
    }

    switch (normalizedSeverity) {
      case ErrorSeverity.Critical:
        console.error(error);
        break;
      case ErrorSeverity.Error:
        console.log(error);
        break;
      case ErrorSeverity.Warning:
        console.log(error);
        break;
      default:
        console.debug(error);
    }

    if (normalizedSeverity === ErrorSeverity.Debug) {
      return;
    }

    // Note: We add the full route that was active when the error occurred, as well the stack trace for the error (even if no error was thrown)
    const defaultData = {
      defaultsProvided: true,
      topLevelRoute: this.#getTopLevelErrorPath(),
      fullActiveRoute: this.router.currentRouteName,
      stackTrace: this.#generateStackTrace()
    };

    const dataWithDefaults = data.defaultsProvided ? data : Object.assign(data, defaultData);

    this.datadog.log(error, dataWithDefaults, normalizedSeverity);
  }

  /**
   * Displays error to user via alerts service; type of alert matches severity
   * Logs the error by calling {@link ErrorsService.log()}
   *
   * @param error javascript error or message describing the error
   * @param data additional information that should be sent along with the error
   */
  show(error: Error | string, data: JsonObject = {}, severity = ErrorSeverity.Error): void {
    if (!error || !['critical', 'error', 'warning', 'info', 'debug'].includes(severity)) {
      return this.log(error, data, severity);
    }

    const severityToAlertType = {
      critical: 'alert',
      error: 'warning',
      warning: 'warning',
      info: 'info',
      debug: 'info'
    } as const;

    const alertMethod: keyof AlertsService = severityToAlertType[severity];
    this.alerts[alertMethod](error.toString());
    this.log(error, data, severity);
  }

  /**
   * Handles any general adapter error generated from a failed request to the Later API.
   * Displays a warning flash message and logs the error.
   *
   * @deprecated This method's scope is too broad & has bad type safety.
   * An alternative is being developed
   */
  handleAdapter(error: unknown, model?: unknown): void {
    if (!error) {
      return;
    }

    let adapterErrors: JsonObject[] | null = null;
    this.alerts.clear();

    if (hasErrorsProperty(error) && Array.isArray(error.errors) && error.errors.length) {
      this.#internalUsageLog('handleAdapter - has errors array', {
        error,
        errors: error.errors,
        model
      });

      adapterErrors = error.errors;
      error.errors.forEach(this._specificAdapterError.bind(this));
    } else if (hasMessageProperty(error)) {
      this.alerts.warning(error.message);
    }

    if (!isError(error) || !isString(error)) {
      this.log('Invalid property passed to handleAdapter', { error: error as JsonObject }, ErrorSeverity.Info);
    }

    let modelErrors: null | string[] = null;
    let _model: JsonValue = null;

    if (model instanceof Model) {
      modelErrors = model.get('errors').get('messages');
      _model = `${model}`;
    }

    // To maintain compatibility we assume this is an Error object
    this.log(error as Error, {
      model: _model,
      modelErrors,
      adapterErrors
    });
  }

  /**
   * Handles a raw AJAX error generated from a failed AJAX request.
   * Displays the error as a warning flash message.
   *
   * @param error An error returned from a `fetch` call
   * @deprecated This method's scope is too broad & has bad type safety.
   * An alternative is being developed
   */
  handleAjax(error: UnknownAjaxError): void {
    if (!error) {
      return;
    }

    this.alerts.clear();

    if (error.message) {
      this.alerts.warning(error.message);
    }

    // To maintain compatibility we assume this is an Error object
    this.log(error as Error);
  }

  /**
   * Sets the current user for all subsequent errors & logs
   */
  setUser(user: DatadogUser): void {
    this.datadog.setUser(user);
  }

  /**
   * Attaches the current Account's feature flags to the current user session.
   * These feature flags are then attributed to all errors & logs.
   */
  setFeatureFlags(flags: FeatureFlags): void {
    this.datadog.setFeatureFlags(flags);
  }

  /**
   * Handles a specific adapter error generated from a failed request to the Later API
   * by flashing a specific error message for certain known error codes.
   *
   * @param error adapter error object
   */
  _specificAdapterError(error: UnknownSpecificAdapterError): void {
    if (!error) {
      return;
    }

    this.#internalUsageLog('_specificAdapterError', { error });

    if (error.status === '500') {
      this.alerts.warning(this.intl.t('alerts.app.500_error', { error: error.detail }), { title: error.title });
    } else if (error.status === '503') {
      this.alerts.warning(this.intl.t('alerts.app.maintenance_mode.message'), {
        title: this.intl.t('alerts.app.maintenance_mode.title')
      });
    } else if (error.status === '403') {
      this.alerts.warning(this.intl.t('alerts.app.403_error.message'), {
        title: this.intl.t('alerts.app.403_error.title')
      });
    } else if (error.title && error.detail && isString(error.detail)) {
      this.alerts.warning(error.detail, { title: error.title });
    } else if (typeOf(error) === 'object') {
      this.#internalUsageLog('_specificAdapterError - generic object', { error });
      this.alerts.warning(this.intl.t('alerts.generic_error_message'));
    } else {
      // To maintain compatibility we assume this is a string
      this.alerts.warning(error as unknown as string);
    }
  }

  #getTopLevelErrorPath(): string {
    const { currentRouteName } = this.router;
    if (!currentRouteName) {
      return '';
    }
    const pathArray = currentRouteName.split('.');
    if (currentRouteName.includes('cluster')) {
      return pathArray[1];
    }
    return pathArray[0];
  }

  // Note: Generates a stack trace for when errors.log is called but no actual error is thrown.
  // 'ErrorsService.log' will always exist in the trace, but can be ignored. Also, 'captureStackTrace'
  // is only available on v8 browsers so a check is necessary to see if this functionality is available.
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error
  #generateStackTrace(): string | undefined {
    const errorObject: { stack?: string } = {};
    if (Error.captureStackTrace) {
      Error.captureStackTrace(errorObject, this.#generateStackTrace);
      return errorObject.stack;
    }
    return 'Unable to capture stack trace';
  }

  #internalUsageLog(message: string, data: Record<string, any>): void {
    // Investigative log to determine if/when this code path is being hit.
    // This should be removed after Sept. 15 2022
    this.log(`[LOG] ${message}: ${JSON.stringify(data)}`, data, ErrorSeverity.Info);
  }

  #setupGlobalErrorLogging(): void {
    if (!isTesting()) {
      Ember.onerror = (error) => {
        this.log(error, { source: 'onError' }, ErrorSeverity.Critical);
      };

      RSVP.on('error', (error) => {
        if (!error || error?.name === 'TransitionAborted') {
          return;
        }
        this.log(error, { source: 'RSVP' }, ErrorSeverity.Critical);
      });
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    errors: ErrorsService;
  }
}
