import Model, { attr, belongsTo, hasMany } from '@ember-data/model';

import type LinkinbioAttachmentModel from 'later/models/linkinbio-attachment';
import type LinkinbioPageModel from 'later/models/linkinbio-page';
import type { ConnectedObjectType } from 'linkinbio/blocks/linkinbio-block';
import type { Maybe } from 'shared/types';
import type { AllBlockData } from 'shared/types/linkinbio-block-data';

export default class LinkinbioBlockModel extends Model {
  @belongsTo('linkinbioPage', { async: false }) declare linkinbioPage: LinkinbioPageModel;
  @hasMany('linkinbioAttachment', { async: false }) declare linkinbioAttachments: LinkinbioAttachmentModel[];

  @attr() declare blockData: AllBlockData;
  @attr('string') declare blockType: string;
  @attr('string') declare createdAt: string;
  @attr('string') declare connectedObjectId: string | null;
  @attr('string') declare connectedObjectType: ConnectedObjectType | null;

  /**
   * @param blockData - blockData object is unique to each type of block.
   */
  updateBlockData(blockData: AllBlockData): void {
    this.blockData = Object.assign({}, this.blockData, blockData);
  }

  /**
   * Compares initial and current values of the object for change.
   */
  hasTrulyDirtyAttributes(): boolean {
    return Object.entries(this.changedAttributes()).some(
      ([key, values]: [string, [Maybe<AllBlockData | string>, Maybe<AllBlockData | string>] | undefined]) => {
        if (!values) return false;
        const [initialValue, currentValue] = values;
        if (key === 'blockData') {
          return JSON.stringify(initialValue) !== JSON.stringify(currentValue);
        }
        return initialValue !== currentValue;
      }
    );
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'linkinbio-block': LinkinbioBlockModel;
  }
}
