/**
 * @module Services
 */

import Service, { inject as service } from '@ember/service';
import { task, enqueueTask } from 'ember-concurrency';

import { fetch, objectToQueryString } from 'later/utils/fetch';
import generateCacheKey from 'shared/utils/analytics/generate-cache-key';

/**
 * @class LaterAPIService
 * @extends Service
 */
export default class LaterApiService extends Service {
  @service analytics;
  @service cache;
  @service('analytics/helpers-analytics') helpersAnalytics;

  /**
   * Gets the current social profile as defined in the Analytics Service
   *
   * @property socialProfile
   * @type {SocialProfile}
   */
  get socialProfile() {
    return this.analytics.socialProfile;
  }

  /**
   * Default start date for data calls in this service
   *
   * @property startDate
   * @type {(Moment|Date)}
   * @default
   */
  get startDate() {
    return this.helpersAnalytics.createMomentInTz().subtract(3, 'months').subtract(1, 'day');
  }

  clearCache() {
    const cacheKeyPrefix = 'laterApi';
    this.cache.clearCacheByKeyword(cacheKeyPrefix);
  }

  /**
   * Returns linkinbio posts with data for analytics
   *
   * @property getLinkinbioAnalyticsPosts
   * @param {Boolean} [forceRefresh=false]
   *
   * @return {LinkinbioAnalyticsPosts}
   */
  @enqueueTask
  *getLinkinbioAnalyticsPosts(forceRefresh) {
    const socialProfileId = this.socialProfile?.id;
    const endpoint = 'linkinbio';
    const cacheKey = generateCacheKey('laterApi', { endpoint, socialProfileId });
    const cachedPosts = this.cache.retrieve(cacheKey);

    if (!cachedPosts || forceRefresh) {
      return yield this._fetch.linked().perform({
        endpoint,
        cacheKey,
        queryParams: {
          after: this.startDate.unix()
        }
      });
    }

    return cachedPosts;
  }

  /**
   * Fetches data based on the given config object
   * and sets the resulting data in the
   * cache under the given cache key name.
   *
   * @property _fetch
   * @param {Object} config The configuration object stating
   * the required data parameters for the fetch
   *
   * @returns {Array.<Object>} Data returned from the XHR request
   * @protected
   */
  @task
  *_fetch(config) {
    const params = { social_profile_id: this.socialProfile?.id, ...config.queryParams };
    const result = yield fetch(`/api/v2/analytics/${config.endpoint}.json${objectToQueryString(params)}`);

    this.cache.add(config.cacheKey, result, { expiry: this.cache.expiry(1, 'day'), persist: false });
    return result;
  }
}
