import { assert } from '@ember/debug';
import Service, { inject as service } from '@ember/service';
import { isPresent, isEmpty, isBlank } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { isObject, isString } from 'lodash';
import { tz } from 'moment-timezone';

import { NetworkError } from 'later/errors/fetch';
import { ErrorSeverity } from 'later/services/errors';
import { fetch } from 'later/utils/fetch';
import createMomentInTz from 'shared/utils/time-helpers/create-moment-in-tz';

import type RouterService from '@ember/routing/router-service';
import type StoreService from '@ember-data/store';
import type IntlService from 'ember-intl/services/intl';
import type { EnableDisableUpdateAPIActionResponse } from 'later/models/analytics-report';
import type AnalyticsReportModel from 'later/models/analytics-report';
import type SocialProfileModel from 'later/models/social-profile';
import type AlertsService from 'later/services/alerts';
import type AuthService from 'later/services/auth';
import type DialogManagerService from 'later/services/dialog-manager';
import type ErrorsService from 'later/services/errors';
import type FayeService from 'later/services/faye';
import type SegmentService from 'later/services/segment';
import type UserConfigService from 'later/services/user-config';
import type { Maybe } from 'shared/types';
import type { JsonObject } from 'type-fest';

type ReportErrorCode = (typeof REPORT_ERROR_CODES)[number];

const REPORT_ERROR_CODES = [
  'name_has_already_been_taken',
  'report_update_recently_requested',
  'report_recently_requested',
  'report_recently_updated',
  'invalid_report_request',
  'unsupported_report_type',
  'report_already_disabled',
  'report_already_enabled',
  'missing_parameters'
] as const;

export default class PerformanceReportService extends Service {
  @service declare auth: AuthService;
  @service declare dialogManager: DialogManagerService;
  @service declare alerts: AlertsService;
  @service declare intl: IntlService;
  @service declare errors: ErrorsService;
  @service declare store: StoreService;
  @service declare userConfig: UserConfigService;
  @service declare segment: SegmentService;
  @service declare router: RouterService;
  @service declare faye: FayeService;

  @tracked analyticsPerformanceReport: AnalyticsReportModel | null = null;
  @tracked isLoading = isBlank(this.analyticsPerformanceReport);
  @tracked isUpdating = false;
  @tracked reportViews: number | null = null;
  @tracked isLoadingPreview = true;

  get socialProfile(): Maybe<SocialProfileModel> {
    return this.auth.socialProfile;
  }

  get isReportPresent(): boolean {
    return isPresent(this.analyticsPerformanceReport);
  }

  get isReportDisabled(): boolean {
    return this.isReportPresent && !this.analyticsPerformanceReport?.enabled;
  }

  get reportRecentlyUpdated(): boolean {
    if (!this.analyticsPerformanceReport?.dataUpdatedMomentInTz) {
      return false;
    }

    const minimumHoursBeforeUpdatable = 24;
    const timeZone = this.userConfig.currentTimeZone?.identifier || tz.guess();
    const todayMoment = createMomentInTz(null, timeZone);
    const timeDifference = todayMoment.diff(this.analyticsPerformanceReport.dataUpdatedMomentInTz, 'hours');
    return timeDifference < minimumHoursBeforeUpdatable;
  }

  get isReportBeingGenerated(): boolean {
    return this.isReportPresent && this.analyticsPerformanceReport?.reportStatus === 'report_being_generated';
  }

  get hasMissingProfessionalPermission(): boolean {
    return Boolean(
      this.socialProfile?.isProfessional &&
        this.socialProfile?.hasProfessionalAccount &&
        !this.socialProfile?.hasInstagramManageInsights
    );
  }

  get page(): 'analytics' | 'influencers' {
    return this.router.currentRouteName?.includes('analytics') ? 'analytics' : 'influencers';
  }

  constructor(...args: Record<string, unknown>[]) {
    super(...args);

    this.faye.on('instagramPerformanceReportUpdated', (updatedReport) => {
      if (this.isReportBeingGenerated && updatedReport.report_status === 'report_available') {
        this.fetchRecentReport();
      }
    });
  }

  async createReport(): Promise<void> {
    assert('Must have a valid social profile to request a report', isPresent(this.socialProfile));

    this.isLoading = true;
    const newReport = !this.isReportDisabled;

    try {
      if (this.isReportDisabled) {
        await this._enableReport();
      } else {
        await this._createNewReport();
      }

      this._handleReportBeingGeneratedAlert();
      this.segment.track('changed-performance-report-state', {
        new_state: 'enabled',
        initial: newReport,
        page: this.page
      });
    } catch (error) {
      this._handleGenericError(error);
    } finally {
      this.isLoading = false;
    }
  }

  async fetchRecentReport(): Promise<void> {
    this.isLoading = true;
    try {
      const analyticsPerformanceReport = await this._fetchRecentReport();
      const reportViews = await this._fetchReportViews();
      if (reportViews) {
        this.reportViews = reportViews;
      }

      if (analyticsPerformanceReport) {
        this.analyticsPerformanceReport = analyticsPerformanceReport;
      } else {
        this._clearReport();
      }
    } catch (error) {
      this._handleGenericError(error);
    } finally {
      this.isLoading = false;
    }
  }

  async manuallyUpdateReport(): Promise<EnableDisableUpdateAPIActionResponse | undefined> {
    this.isUpdating = true;
    try {
      const response = await this.analyticsPerformanceReport?.updateReportData();
      this._handleReportUpdateAlert();

      this.segment.track('updated-performance-report-manually', {
        page: this.page
      });

      return response;
    } catch (error) {
      this._handleGenericError(error);
    } finally {
      this.isUpdating = false;
    }
    return;
  }

  async toggleReportAutoUpdate(): Promise<void> {
    try {
      this.#expectReport(this.analyticsPerformanceReport);

      const didToggleAutoUpdate = !this.analyticsPerformanceReport.autoUpdateEnabled;
      this.analyticsPerformanceReport.autoUpdateEnabled = didToggleAutoUpdate;
      await this.analyticsPerformanceReport.save();
      this._handleAutoUpdateAlert(didToggleAutoUpdate);
    } catch (error) {
      this._handleGenericError(error);
    }
  }

  async toggleEngagementIndustryBenchmark(): Promise<void> {
    try {
      this.#expectReport(this.analyticsPerformanceReport);

      const didEnableEngagementBenchmark = !this.analyticsPerformanceReport.benchmarkEngagementEnabled;
      this.analyticsPerformanceReport.benchmarkEngagementEnabled = didEnableEngagementBenchmark;
      await this.analyticsPerformanceReport.save();

      this.segment.track('changed-performance-report-benchmark-state', {
        benchmark: 'engagement',
        new_state: didEnableEngagementBenchmark ? 'enabled' : 'disabled',
        page: this.page
      });

      try {
        await this.analyticsPerformanceReport.updateReportData();
        this._handleIndustryEngagementAlert(didEnableEngagementBenchmark);
      } catch (e) {
        this.alerts.warning(
          this.intl.t('alerts.analytics.report.settings.advanced.toggle_engagement_update_data_error'),
          {
            title: this.intl.t('alerts.analytics.report.settings.advanced.engagement_toggle_label'),
            timeout: 5000
          }
        );
      }
    } catch (error) {
      this._handleGenericError(error);
    }
  }

  async toggleReport(): Promise<boolean> {
    try {
      if (this.isReportDisabled) {
        await this._enableReport();
        this.segment.track('changed-performance-report-state', {
          new_state: 'enabled',
          initial: false,
          page: this.page
        });
        return true;
      }

      await this._disableReport();
      this.segment.track('changed-performance-report-state', {
        new_state: 'disabled',
        initial: false,
        page: this.page
      });
      return true;
    } catch (error) {
      this._handleGenericError(error);
      return false;
    }
  }

  setIsLoadingPreview(state = false): void {
    this.isLoadingPreview = state;
  }

  _clearReport(): void {
    this.analyticsPerformanceReport = null;
  }

  async _createNewReport(): Promise<void> {
    const analyticsReport = this.store.createRecord('analytics-report', {
      socialProfile: this.socialProfile,
      name: 'ig-performance-report'
    });

    this.analyticsPerformanceReport = await analyticsReport.save();
  }

  async _enableReport(): Promise<void> {
    this.#expectReport(this.analyticsPerformanceReport);
    await this.analyticsPerformanceReport.enableReport();
    this.analyticsPerformanceReport.enabled = true;
  }

  async _disableReport(): Promise<void> {
    try {
      await this.dialogManager.confirm(this.intl.t('analytics.report.settings.advanced.disabled_title'), {
        confirmButton: this.intl.t('analytics.report.settings.advanced.disabled_success_prompt'),
        description: this.intl.t('analytics.report.settings.advanced.disabled_main')
      });

      this.#expectReport(this.analyticsPerformanceReport);

      await this.analyticsPerformanceReport.disableReport();
      this.analyticsPerformanceReport.enabled = false;
      this._handleReportDisabledAlert();

      this.segment.track('changed-performance-report-state', {
        new_state: 'disabled',
        initial: false,
        page: this.page
      });
    } catch (_cancelled) {
      // Refresh report to keep toggle ui in correct state
      // eslint-disable-next-line no-self-assign
      this.analyticsPerformanceReport = this.analyticsPerformanceReport;
    }
  }

  async _fetchRecentReport(refresh = false): Promise<AnalyticsReportModel | null> {
    if (!this.socialProfile?.id) {
      return null;
    }

    let analyticsReportRecords: AnalyticsReportModel[] = this.store
      .peekAll('analytics-report')
      ?.filterBy('name', 'ig-performance-report')
      ?.filterBy('socialProfile.id', this.socialProfile.id);

    if (isEmpty(analyticsReportRecords) || refresh) {
      const reportProxy = await this.store.query('analytics-report', {
        social_profile_id: this.socialProfile.id,
        names: ['ig-performance-report']
      });

      // Since .query returns a proxy, it's type doesn't match the model.
      // However in this case we can treat them at the same.
      analyticsReportRecords = reportProxy as unknown as AnalyticsReportModel[];
    }

    const analyticsReport = analyticsReportRecords?.sortBy('updatedTime').firstObject;

    if (!analyticsReport) {
      return null;
    }

    if (isPresent(analyticsReport.preview)) {
      return analyticsReport;
    }

    const reportWithPreview = await this.store.findRecord('analytics-report', analyticsReport.id, {
      adapterOptions: { query: { preview: true } },
      reload: true
    });

    return reportWithPreview;
  }

  async _fetchReportViews(): Promise<number | undefined> {
    try {
      const id = this.socialProfile?.id;
      const { response } = await fetch(`api/v2/keen/analytics_report_pageviews.json?social_profile_id=${id}`);
      return response;
    } catch (error) {
      this.errors.log(error, {}, ErrorSeverity.Warning);
      return;
    }
  }

  _handleReportDisabledAlert(): void {
    this.alerts.success(this.intl.t('alerts.analytics.report.settings.disable.success.message'), {
      title: this.intl.t('alerts.analytics.report.settings.disable.success.title'),
      timeout: 5000
    });
  }

  _handleReportBeingGeneratedAlert(): void {
    this.alerts.success(this.intl.t('alerts.analytics.report.settings.share.sub_text'), {
      title: this.intl.t('alerts.analytics.report.settings.create.success'),
      timeout: 5000
    });
  }

  _handleReportUpdateAlert(): void {
    this.alerts.success(this.intl.t('alerts.analytics.report.settings.update.success.message'), {
      title: this.intl.t('alerts.analytics.report.settings.update.success.title'),
      timeout: 5000
    });
  }

  _handleGenericError(error: unknown): void {
    const errorCode = this._getReportErrorCodeIfExists(error);
    if (errorCode) {
      this._handleReportError(errorCode);
    } else {
      let message = 'Unexpected error in performance report service';
      if (isObject(error) && 'message' in error && isString(error.message)) {
        // eslint-disable-next-line prefer-destructuring
        message = error.message;
      }
      this.errors.log(message, error as JsonObject);

      if (error instanceof NetworkError) {
        this.alerts.warning(this.intl.t('alerts.app.offline_message'), {
          sticky: false
        });
      } else {
        this.alerts.warning(this.intl.t(`alerts.analytics.report.settings.error.generic.message`), {
          title: this.intl.t(`alerts.analytics.report.settings.error.generic.title`),
          sticky: false
        });
      }
    }
  }

  _handleReportError(error_code: ReportErrorCode): void {
    this.errors.log(error_code);
    this.alerts.warning(this.intl.t(`alerts.analytics.report.settings.error.${error_code}.message`), {
      title: this.intl.t(`alerts.analytics.report.settings.error.${error_code}.title`),
      sticky: false
    });
  }

  _getReportErrorCodeIfExists(error: unknown): ReportErrorCode | null {
    function hasValidError(error: unknown): error is { errors: { detail: unknown }[] } {
      if (isObject(error) && 'errors' in error && Array.isArray(error.errors)) {
        return error.errors.length > 0 && 'detail' in error.errors[0];
      }

      return false;
    }

    if (!hasValidError(error)) {
      return null;
    }

    const errorDetail = error.errors?.[0]?.detail;

    if (!isString(errorDetail) || errorDetail === '[object Object]') {
      return null;
    }

    const errorCode = errorDetail.split(' ').join('_').toLowerCase() as ReportErrorCode;

    return REPORT_ERROR_CODES.includes(errorCode) ? errorCode : null;
  }

  _handleAutoUpdateAlert(autoUpdateEnabled: boolean): void {
    if (autoUpdateEnabled) {
      this.alerts.success(this.intl.t('alerts.analytics.report.settings.update.enabled.message'), {
        title: this.intl.t('alerts.analytics.report.settings.update.enabled.title'),
        timeout: 5000
      });
    } else {
      this.alerts.warning(this.intl.t(`alerts.analytics.report.settings.update.disabled.message`), {
        title: this.intl.t(`alerts.analytics.report.settings.update.disabled.title`),
        sticky: false,
        timeout: 5000
      });
    }
  }

  _handleIndustryEngagementAlert(didEnable: boolean): void {
    const alertMethod = didEnable ? 'success' : 'warning';
    this.alerts[alertMethod](
      this.intl.t(`alerts.analytics.report.settings.advanced.toggle_engagement_${didEnable}_success`),
      {
        title: this.intl.t(`alerts.analytics.report.settings.advanced.engagement_toggle_${didEnable}`),
        sticky: false,
        timeout: 5000
      }
    );
  }

  #expectReport(report: AnalyticsReportModel | null): asserts report is AnalyticsReportModel {
    if (!report) {
      throw new Error('No report present');
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    'performance-report': PerformanceReportService;
  }
}
