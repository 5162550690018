import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

import { fetch } from 'later/utils/fetch';
import promiseHash from 'later/utils/promise-hash';

export default class ReferralsRoute extends Route {
  @service session;
  @service router;

  beforeModel(transition) {
    this.session.requireAuthentication(transition, 'user.login');
  }

  async model() {
    const response = await fetch('/api/v2/referrals.json');
    return promiseHash({
      referrals: response.user_referrals
    });
  }
}
