import { readOnly } from '@ember/object/computed';
import Service, { inject as service } from '@ember/service';
import { isEmpty, isNone } from '@ember/utils';

import TiktokPost from 'later/models/tiktok-post';
import { DEFAULT_TIKTOK_VIDEO_LIMIT_PER_PAGE, OauthSocialProfileType } from 'later/utils/constants';
import { getCookie } from 'later/utils/cookie';
import { fetch } from 'later/utils/fetch';
import objectPromiseProxy from 'later/utils/object-promise-proxy';
import redirect from 'shared/utils/redirect';
/**
 * @class TiktokService
 * @extends Service
 */

export default class TiktokService extends Service {
  @service auth;
  @service alerts;
  @service errors;
  @service intl;
  @service store;
  @service('social/connect-profiles') connectProfiles;

  @readOnly('auth.currentAccount') currentAccount;
  @readOnly('auth.currentGroup') currentGroup;

  async addProfile(name, socialIdentity, group) {
    const socialProfileAttributes = {
      group,
      nickname: name,
      account: this.currentAccount,
      socialIdentity,
      profileType: 'tiktok'
    };

    if (socialIdentity?.id) {
      socialProfileAttributes.socialIdentity = socialIdentity;
    }

    const socialProfile = this.store.createRecord('social-profile', socialProfileAttributes);
    try {
      const savedProfile = await socialProfile.save();
      //reqd to get new social identity for the saved profile
      savedProfile.get('socialIdentity');
      this.alerts.success(this.intl.t('alerts.tiktok.add_profile.success', { name: savedProfile.name }));
    } catch (adapterError) {
      socialProfile.rollbackAttributes();
      this.errors.handleAdapter(adapterError, socialProfile);
    }
  }

  /**
   *
   * @method refresh
   * @param {SocialProfile} socialProfile The Tiktok Social Profile to be refreshed
   */
  refresh(socialProfile, redirectPath) {
    if (!socialProfile.canConnectTiktokApi) {
      return; //safeguard
    }

    this.loginWithBusinessApiAccount(socialProfile, redirectPath);
  }

  /**
   * Redirects the user to the Tiktok Business API login page
   *
   * @method loginWithBusinessApiAccount
   * @param {SocialProfile} socialProfile The Tiktok Social Profile to be refreshed
   */
  loginWithBusinessApiAccount(socialProfile, redirectPath) {
    const group = socialProfile ? socialProfile.get('group') : this.currentGroup;
    const refreshSocialProfile = socialProfile ? `refresh_social_profile_id=${socialProfile.id}` : '';
    const path = this.connectProfiles.oAuthPath({
      socialProfileType: OauthSocialProfileType.TiktokBusiness,
      redirectPath
    });

    if (!this.auth.currentAccount.canConnectTiktokApi) {
      this.errors.log(new Error('Not authorized to connect to Tiktok Business'), [
        { socialProfileId: socialProfile.id }
      ]);

      return; // safeguard
    }

    if (isNone(this.currentGroup)) {
      redirect(`${path}&${refreshSocialProfile}`);
    } else {
      redirect(`${path}&group_id=${group.get('id')}&${refreshSocialProfile}`);
    }

    redirect(`${path}&${refreshSocialProfile}`);
  }

  /**
   * Creates an OAuth window to log in to Tiktok
   *
   * @method createTiktokWithSet
   */
  createTiktokWithSet(setId, group, redirectPath) {
    const path = this.connectProfiles.oAuthPath({
      socialProfileType: OauthSocialProfileType.TiktokBusiness,
      redirectGroupSlug: group.slug,
      redirectPath
    });

    if (isNone(setId)) {
      redirect(`${path}&group_id=${group.id}`);
    } else {
      redirect(`${path}&social_identity_id=${setId}`);
    }
  }

  /**
   * Fetches Recent Media (Posts) for specified socialProfile,
   * can handle paging through this endpoint.
   *
   * @method fetchRecentMedia
   * @param {SocialProfile} socialProfileProxy The socialProfile making the request (can be an ObjectProxy)
   * @param {Object} [pagination] Pagination object return from a
   * previous call to this method. Used to get the next page of data
   *
   * @return {Promise|Array<TiktokPost>} Formatted Recent TikTok Posts
   */
  async fetchRecentMedia(socialProfileProxy, pagination, videoLimitPerPage = DEFAULT_TIKTOK_VIDEO_LIMIT_PER_PAGE) {
    const socialProfile = await objectPromiseProxy(socialProfileProxy);
    const cursorSuffix = this.hasMorePages(pagination) ? `?cursor=${pagination.cursor}` : '';
    const limitSuffix = isEmpty(cursorSuffix) ? `?limit=${videoLimitPerPage}` : `&limit=${videoLimitPerPage}`;
    const urlSuffix = cursorSuffix + limitSuffix;
    const url = `/api/v2/social_profiles/${socialProfile.id}/tiktok_video_list${urlSuffix}`;

    const response = await fetch(url);
    const { video_list, cursor, has_more, error_code, error_msg } = response.data;
    const { extra } = response;

    if (error_code || error_msg || extra.error_detail) {
      throw new Error(this.intl.t('tiktok.errors.api.video_list', { error: error_msg }));
    }

    if (!video_list) {
      return { posts: null, pagination: null };
    }

    const posts = video_list.map((rawPost) => new TiktokPost({ ...rawPost, socialProfile }));
    return { posts, pagination: { cursor, hasMore: has_more } };
  }

  /**
   * Checks whether the provided pagination object indicates
   * that there is another page of data that can be requested
   *
   * @method hasMorePages
   * @param {Object | undefined} pagination The pagination object returned
   * from a previous call to an Instagram API
   *
   * @return {Boolean}
   */
  hasMorePages(pagination) {
    return pagination?.cursor && pagination?.hasMore;
  }

  /**
   * Display alert with error message
   *
   * @method raiseError
   * @param {Array} args All arguments passed to this method
   */
  raiseError(error) {
    if (error) {
      this.alerts.warning(error);
    }
  }

  /**
   * When a Tiktok user clicks on an ad and lands on our app, Tiktok adds
   * a tiktok click id to the users cookie, which can be accessed with the key `ttclid`.
   * This function compares the tiktok click id from the URL with the tiktok click id from the server.
   * If they differ, it sets the tiktokClickId property on the user to the new click id.
   */
  updateTiktokClickId() {
    try {
      const currentUser = this.auth.currentUserModel;
      const cookieTiktokClickId = getCookie('ttclid');
      const serverTiktokClickId = currentUser.tiktokClickId;
      if (cookieTiktokClickId && cookieTiktokClickId !== serverTiktokClickId) {
        currentUser.set('tiktokClickId', cookieTiktokClickId);
        currentUser.save();
      }
    } catch (error) {
      this.errors.log(error);
    }
  }
}
