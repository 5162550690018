import { htmlSafe } from '@ember/template';
import { isPresent } from '@ember/utils';
import Model, { attr, hasMany, belongsTo } from '@ember-data/model';
import { tracked } from '@glimmer/tracking';

import { isProduction } from 'later/utils/is-env';

import type ContentPlanModel from './content-plan';
import type { SafeString } from '@ember/template/-private/handlebars';
import type { AsyncBelongsTo, AsyncHasMany, SyncHasMany } from '@ember-data/model';
import type BrandModel from 'later/models/brand';
import type GroupModel from 'later/models/group';
import type InfluencerModel from 'later/models/influencer';
import type LinkinbioPageModel from 'later/models/linkinbio-page';
import type MediaKitModel from 'later/models/media-kit';
import type SocialProfileModel from 'later/models/social-profile';

export default class SocialIdentityModel extends Model {
  @attr('string') declare name?: string;
  @attr('boolean', { defaultValue: false }) declare advancedLinkinbio: boolean;
  @attr('string') declare influencerId?: string;
  @attr('string') declare brandId?: string;
  @attr('string') declare brandDescription?: string;
  @attr('string') declare brandVoice?: string;
  @hasMany('socialProfile', { async: true }) declare socialProfiles: AsyncHasMany<SocialProfileModel>;
  @hasMany('contentPlan', { async: false }) declare contentPlans: SyncHasMany<ContentPlanModel>;
  @belongsTo('linkinbio-page', { async: true }) declare linkinbioPage: LinkinbioPageModel;
  @belongsTo('group', { async: true }) declare group: AsyncBelongsTo<GroupModel>;
  @belongsTo('influencer', { async: true }) declare influencer: AsyncBelongsTo<InfluencerModel>;
  @belongsTo('brand') declare brand: BrandModel;
  @belongsTo('media-kit') declare mediaKit: MediaKitModel;

  @tracked temporaryProfiles?: SocialProfileModel[]; // Needed for trading profiles between identities

  get hasInstagram(): boolean {
    return isPresent(this.instagramProfile);
  }

  get hasTiktok(): boolean {
    return isPresent(this.tiktokProfile);
  }

  get instagramProfile(): SocialProfileModel | undefined {
    return this.socialProfiles.findBy('profileType', 'instagram');
  }

  get tiktokProfile(): SocialProfileModel | undefined {
    return this.socialProfiles.findBy('profileType', 'tiktok');
  }

  get linkinbioEnabled(): boolean | undefined {
    return (
      (this.hasInstagram && this.instagramProfile?.get('linkinbioEnabled')) ||
      (this.hasTiktok && this.tiktokProfile?.get('linkinbioEnabled'))
    );
  }

  get linkinbioUrl(): string | undefined {
    const domain = isProduction() ? 'linkin.bio' : 'staging.linkin.bio';
    const urlName = this.linkinbioPage.get('urlName');

    return isPresent(urlName) ? `https://${domain}/${urlName}` : undefined;
  }

  get linkinbioUrlFormatted(): SafeString | undefined {
    const domain = isProduction() ? 'linkin.bio' : 'staging.linkin.bio';
    const urlName = this.linkinbioPage.get('urlName');

    return isPresent(urlName) ? htmlSafe(`https://${domain}/<strong>${urlName}</strong>`) : undefined;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'social-identity': SocialIdentityModel;
  }
}
