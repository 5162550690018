import Service, { inject as service } from '@ember/service';
import { didCancel, keepLatestTask } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import { tracked } from 'tracked-built-ins';

import { timestamp } from 'later/utils/time-format';

import type RouterService from '@ember/routing/router-service';
import type Store from '@ember-data/store';
import type { TaskGenerator } from 'ember-concurrency';
import type IntlService from 'ember-intl/services/intl';
import type AccountModel from 'later/models/account';
import type ContentSuggestionModel from 'later/models/content-suggestion';
import type MediaItemModel from 'later/models/media-item';
import type SocialProfileModel from 'later/models/social-profile';
import type AlertsService from 'later/services/alerts';
import type AuthService from 'later/services/auth';
import type ErrorsService from 'later/services/errors';
import type MediaLibraryService from 'later/services/media-library';
import type SegmentService from 'later/services/segment';
import type SelectedSocialProfilesService from 'later/services/selected-social-profiles';
import type { SocialPlatformType } from 'shared/types/social-profile';

type SideLibraryBehavior = 'calendar' | 'stories' | 'preview' | 'drafts' | null;

interface CanScheduleConfig {
  numPosts: number;
  location: string;
}

export default class ScheduleService extends Service {
  @service declare alerts: AlertsService;
  @service declare auth: AuthService;
  @service declare errors: ErrorsService;
  @service declare intl: IntlService;
  @service declare mediaLibrary: MediaLibraryService;
  @service declare store: Store;
  @service declare segment: SegmentService;
  @service declare selectedSocialProfiles: SelectedSocialProfilesService;
  @service declare router: RouterService;

  @tracked isDraggingEmptyPost = false;
  @tracked isDragging = false;
  @tracked isQuickScheduleWizardVisible = false;
  @tracked sideLibraryBehavior: SideLibraryBehavior = null;

  get account(): AccountModel {
    return this.auth.currentAccount;
  }

  get isSchedulingDraft(): boolean {
    return this.sideLibraryBehavior === 'drafts';
  }

  get socialProfilesSelectedForScheduling(): SocialPlatformType[] {
    return this.selectedSocialProfiles.profiles.map((profile) => profile.accountType);
  }

  async canSchedule(socialProfile: SocialProfileModel, { numPosts, location }: CanScheduleConfig): Promise<boolean> {
    try {
      await taskFor(this.updatePostLimit).perform();
      if (socialProfile.hasLimitedPosts && socialProfile.postsLeft < numPosts) {
        this.alerts.upgrade(
          this.intl.t('alerts.calendar.out_of_posts.message', {
            number_posts_remaining: socialProfile.postsLeft
          }),
          {
            title: this.intl.t('alerts.calendar.out_of_posts.title'),
            feature: 'plans',
            location,
            upgradeText: this.intl.t('shared_phrases.view_my_plan')
          }
        );
        return false;
      }
      return true;
    } catch (error) {
      if (!didCancel(error)) {
        this.errors.log(error);
      }
      return false;
    }
  }

  async canScheduleMulti(): Promise<boolean> {
    for (const socialProfile of this.selectedSocialProfiles.profiles) {
      const canSchedule = await this.canSchedule(socialProfile, {
        numPosts: 1,
        location: 'calendar out of posts'
      });
      if (!canSchedule) {
        return false;
      }
    }
    return true;
  }

  async startSchedulingNewPost(mediaItems: MediaItemModel[], location: string): Promise<void> {
    performance.mark('BeginPostV2');

    if (mediaItems.length === 0) {
      throw new Error('Cannot schedule a post without media');
    }

    const firstMediaItem = mediaItems[0];
    const isMultiImagePost = mediaItems.length > 1;

    if (isMultiImagePost) {
      this.segment.track('attempted-multi-photo-post');
      if (!this.account.canCarousel) {
        this._displayCarouselUpgradeAlert();
        return;
      }
      this.mediaLibrary.selectedMedia.addObjects(mediaItems);
    }

    this.segment.track('started-scheduling-post', {
      area: location,
      type: firstMediaItem.mediaType,
      is_multi_profile: this.socialProfilesSelectedForScheduling.length > 1,
      multi_profile_types: this.socialProfilesSelectedForScheduling
    });

    if (this.selectedSocialProfiles.hasMultipleSelected) {
      this.#scheduleNewMultiProfilePost(firstMediaItem);
      return;
    }

    this.#scheduleNewSingleProfilePost(firstMediaItem, this.selectedSocialProfiles.firstProfile);
  }

  async startSchedulingNewPostWithoutMediaItem(contentSuggestion: ContentSuggestionModel): Promise<void> {
    this.#scheduleNewSingleProfilePostWithoutMediaItem(contentSuggestion);
  }

  setDragging(isDragging: boolean): void {
    this.set('isDragging', isDragging);
  }

  setSideLibraryBehavior(behavior: SideLibraryBehavior): void {
    this.set('sideLibraryBehavior', behavior);
  }

  @keepLatestTask
  *updatePostLimit(): TaskGenerator<void> {
    yield this.account.reload();
  }

  _displayCarouselUpgradeAlert(): void {
    const upgradeMessage = this.account.canTrialPlan
      ? this.intl.t('alerts.calendar.cannot_schedule_carousel_free.message_trial')
      : this.intl.t('alerts.calendar.cannot_schedule_carousel_free.message');

    this.alerts.upgrade(upgradeMessage, {
      title: this.intl.t('alerts.calendar.cannot_schedule_carousel_free.title'),
      type: 'info',
      feature: 'multiPhoto',
      location: 'multi photo schedule flash message',
      upgradeText: this.intl.t('shared_phrases.upgrade_plan'),
      upgradeQaClassName: 'qa--carouselUpsell_upgradePlan_Btn'
    });
  }

  async #scheduleNewSingleProfilePost(mediaItem: MediaItemModel, socialProfile?: SocialProfileModel): Promise<void> {
    if (socialProfile) {
      const canSchedule = await this.canSchedule(socialProfile, {
        numPosts: 1,
        location: 'calendar out of posts'
      });
      if (!canSchedule) {
        return;
      }
    }

    if (this.isSchedulingDraft) {
      this.router.transitionTo('cluster.schedule.drafts.post.new', mediaItem.id, {
        queryParams: {
          draft: true,
          backTo: 'cluster.schedule.drafts',
          scheduledTime: timestamp()
        }
      });
    } else {
      this.router.transitionTo('cluster.schedule.calendar.post.new', mediaItem.id, {
        queryParams: {
          scheduledTime: timestamp()
        }
      });
    }
  }

  // Loads the user's first available media-item and uses that as the base for a new post
  // data to be filled by BE provided GPT generated content which will overwrite existing fields
  async #scheduleNewSingleProfilePostWithoutMediaItem({
    caption = 'N/A',
    idea = 'N/A',
    suggestedMedia = 'N/A',
    contentPlan
  }: ContentSuggestionModel): Promise<void> {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const mediaItem = await this.store.peekAll('media-item').firstObject!;
    mediaItem.defaultCaption = `Caption: ${caption}\nIdea: ${idea}\nSuggested Media: ${suggestedMedia}`;

    const socialIdentityId = contentPlan.get('socialIdentity').get('id');

    this.router.transitionTo('cluster.schedule.calendar.post.new', mediaItem.id, {
      queryParams: {
        draft: true,
        backTo: 'cluster.labs.auto-content-plan',
        backToModel: socialIdentityId,
        scheduledTime: timestamp()
      }
    });
  }

  async #scheduleNewMultiProfilePost(mediaItem: MediaItemModel): Promise<void> {
    const canSchedule = await this.canScheduleMulti();
    if (!canSchedule) {
      return;
    }

    if (this.isSchedulingDraft) {
      this.router.transitionTo('cluster.schedule.drafts.post.multi', mediaItem.id, {
        queryParams: {
          draft: true,
          backTo: 'cluster.schedule.drafts',
          scheduledTime: timestamp()
        }
      });
    } else {
      this.router.transitionTo('cluster.schedule.calendar.post.multi', mediaItem.id, {
        queryParams: {
          scheduledTime: timestamp()
        }
      });
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    schedule: ScheduleService;
  }
}
