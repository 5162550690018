import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import classic from 'ember-classic-decorator';
import BaseValidator from 'ember-cp-validations/validators/base';

import { canMediaItemsAltText, getAltTextCharacterLimit } from 'later/utils/alt-text-validation';

@classic
class AltTextLength extends BaseValidator {
  @service auth;

  validate() {
    const profileType = this.get('model.gram.socialProfile.profileType');
    const socialProfile = this.get('model.gram.socialProfile');
    if (!profileType) {
      return false;
    }

    if (isEmpty(this.get('model.ordering'))) {
      return true;
    }

    if (!canMediaItemsAltText(socialProfile, this.model)) {
      return true;
    }

    if (this.model.altText?.length > getAltTextCharacterLimit(profileType, this.model.mediaType)) {
      return this.createErrorMessage('altTextLength');
    }
    return true;
  }
}

export default AltTextLength;
