import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from 'tracked-built-ins';

import type RouterService from '@ember/routing/router-service';
import type IntlService from 'ember-intl/services/intl';
import type SessionService from 'ember-simple-auth/services/session';
import type UserAgentService from 'ember-useragent/services/user-agent';
import type SocialProfileModel from 'later/models/social-profile';
import type AlertsService from 'later/services/alerts';
import type { AlertMessage } from 'later/services/alerts';
import type AuthService from 'later/services/auth';
import type GlobalSearchService from 'later/services/global-search';
import type { UntypedService } from 'shared/types';

export default class ApplicationController extends Controller {
  @service declare alerts: AlertsService;
  @service declare auth: AuthService;
  @service declare browserSupport: UntypedService;
  @service declare globalSearch: GlobalSearchService;
  @service declare intl: IntlService;
  @service declare router: RouterService;
  @service declare session: SessionService;
  @service declare userAgent: UserAgentService;

  @tracked newSocialProfile: SocialProfileModel | null = null;
  isUatTest = false;

  keyHandler: (event: KeyboardEvent) => void;

  get uaClass(): string {
    return this.browserSupport.userAgentCssClass;
  }

  get isDev(): boolean {
    return this.auth.currentUserModel?.dev;
  }

  constructor(...args: Record<string, unknown>[]) {
    super(...args);
    if (this.userAgent.device.isMobile) {
      this.#handleDeviceOrientationWarning();
    }

    this.keyHandler = (event: KeyboardEvent): void => {
      if (!this.session.isAuthenticated || !this.isDev) {
        return;
      }

      this.#handleCmdK(event);
    };

    document.addEventListener('keydown', this.keyHandler);
  }

  willDestroy(): void {
    super.willDestroy();

    document.removeEventListener('keydown', this.keyHandler);
  }

  get alertsQueue(): AlertMessage[] {
    return this.alerts.queue;
  }

  get isLinkinbioRoute(): boolean {
    return this.router.currentURL.includes('linkinbio');
  }

  get isInfluencersRoute(): boolean {
    return this.router.currentURL.includes('partnerships');
  }

  get isProfileCategorized(): boolean {
    return Boolean(this.newSocialProfile?.industry) && Boolean(this.newSocialProfile?.businessModel) && !this.isUatTest;
  }

  get hideProfileCategorization(): boolean | undefined {
    return this.isLinkinbioRoute || this.isInfluencersRoute;
  }

  get shouldCategorizeProfile(): boolean | undefined {
    return this.hideProfileCategorization ? false : this.newSocialProfile?.isInstagram;
  }

  get isGlobalSearchOpen(): boolean {
    return this.globalSearch.showGlobalSearch;
  }

  @action
  closeProfileCategorizationModal(): void {
    this.newSocialProfile = null;
    this.router.transitionTo(this.router.currentRouteName, { queryParams: { new_social_profile_id: null } });
  }

  @action
  onReconnectSocialProfile(): void {
    this.send('refreshModel');
  }

  #handleDeviceOrientationWarning(): void {
    const initialWindowWidth = window.innerWidth;
    const mediaQueryListObject = window.matchMedia('(orientation: landscape)');

    if (mediaQueryListObject.matches) {
      this.#displayLandscapeWarning();
    }

    mediaQueryListObject.onchange = (e) => {
      if (e.matches && window.innerWidth !== initialWindowWidth) {
        this.#displayLandscapeWarning();
      }
    };
  }

  #displayLandscapeWarning(): void {
    this.alerts.warning(this.intl.t('errors.landscapeWarning.message'), {
      title: this.intl.t('errors.landscapeWarning.title'),
      preventDuplicates: true
    });
  }

  #isModifierPressed(event: KeyboardEvent): boolean {
    if (this.userAgent.os.isMacOS || this.userAgent.os.isIOS) {
      return event.metaKey;
    }
    return event.ctrlKey;
  }

  #handleCmdK(event: KeyboardEvent): void {
    if (event.key === 'k' && this.#isModifierPressed(event)) {
      event.preventDefault();

      this.globalSearch.openSearchModal();
    }
  }
}
