import { A } from '@ember/array';
import Service, { inject as service } from '@ember/service';
import { isPresent, isEmpty } from '@ember/utils';
import { snakeToCamel } from '@latermedia/ember-later-analytics/utils';
import { task, enqueueTask } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import { chunk, flatten, isEmpty as _isEmpty } from 'lodash';
import RSVP from 'rsvp';

import {
  findUniqueValuesById,
  extractValuesOverTime,
  getFirstPointByName,
  filterTimeSeries
} from 'later/utils/array-filters';
import { fetch, objectToQueryString } from 'later/utils/fetch';
import { stackObjectArrayValues } from 'later/utils/object-methods';
import generateCacheKey from 'shared/utils/analytics/generate-cache-key';

import type HelpersAnalyticsService from './helpers-analytics';
import type AnalyticsService from '../analytics';
import type { TaskGenerator } from 'ember-concurrency';
import type SocialProfileModel from 'later/models/social-profile';
import type { Moment } from 'moment';
import type { AllSettledResult, EmptyObject, UntypedService } from 'shared/types';
import type {
  DynamoCacheObject,
  DynamoCacheFn,
  DynamoFetchConfig,
  FirstDataPoint,
  DynamoGenericDataRaw
} from 'shared/types/analytics-data';
import type { HashtagObject } from 'shared/types/analytics-data/hashtags';
import type { InsightsPointRaw, TimePeriod } from 'shared/types/analytics-data/insights';
import type {
  FormattedMedia,
  RawMedia,
  PinterestMediaRawObject,
  RawDynamoIgMedia,
  PaginatedMedia
} from 'shared/types/analytics-data/media';
import type {
  MediaEngagementPointRaw,
  MediaEngagementEndpointConfigItem
} from 'shared/types/analytics-data/media-engagements';
import type { ProfileCountPointRaw } from 'shared/types/analytics-data/profile-counts';

type MediaPayload = { endpoint: string; queryParams: Record<string, string[]>; dataCacheFn: DynamoCacheFn };

export default class DynamoApiService extends Service {
  @service declare analytics: AnalyticsService;
  @service declare cache: UntypedService;
  @service declare errors: UntypedService;
  @service('analytics/helpers-analytics') declare helpersAnalytics: HelpersAnalyticsService;

  /**
   * Default data point for the profile counts endpoint
   *
   * @defaultValue
   */
  defaultProfileCountsPoint: ProfileCountPointRaw = {
    time: null,
    followed_by_count: null,
    follows_count: null,
    media_count: null
  };

  /**
   * Default data point for the media engagements endpoint
   *
   * @defaultValue
   */
  defaultMediaEngagementPoint: MediaEngagementPointRaw = {
    media_id: '',
    sampled_at: null,
    comment_count: null,
    like_count: null,
    repin_count: null,
    favorite_count: null,
    retweet_count: null,
    impressions: null,
    reach: null,
    saved: null,
    shares: null,
    video_views: null,
    plays: null,
    carousel_album_engagement: null,
    carousel_album_impressions: null,
    carousel_album_reach: null,
    carousel_album_saved: null,
    carousel_album_video_views: null,
    exits: null,
    replies: null,
    taps_forward: null,
    taps_back: null
  };

  /**
   * List of media engagement properties for each
   * data point for the media engagements endpoint
   */
  mediaEngagementProperties: MediaEngagementEndpointConfigItem[] = [
    {
      name: 'impressions',
      canCarousel: true,
      isPinterest: false,
      isTwitter: false
    },
    {
      name: 'reach',
      canCarousel: true,
      isPinterest: false,
      isTwitter: false
    },
    {
      name: 'replies',
      canCarousel: false,
      isPinterest: false,
      isTwitter: false
    },
    {
      name: 'video_views',
      canCarousel: false,
      isPinterest: false,
      isTwitter: false
    },
    {
      name: 'plays',
      canCarousel: false,
      isPinterest: false,
      isTwitter: false
    },
    {
      name: 'comment_count',
      canCarousel: false,
      isPinterest: false,
      isTwitter: false
    },
    {
      name: 'like_count',
      canCarousel: false,
      isPinterest: false,
      isTwitter: false
    },
    {
      name: 'saved',
      canCarousel: false,
      isPinterest: false,
      isTwitter: false
    },
    {
      name: 'shares',
      canCarousel: false,
      isPinterest: false,
      isTwitter: false
    },
    {
      name: 'repin_count',
      canCarousel: false,
      isPinterest: true,
      isTwitter: false
    },
    {
      name: 'retweet_count',
      canCarousel: false,
      isPinterest: false,
      isTwitter: true
    },
    {
      name: 'favorite_count',
      canCarousel: false,
      isPinterest: false,
      isTwitter: true
    }
  ];

  /**
   * Moment timezone name for the profile insights dataset
   */
  get insightsCollectionTimezoneName(): string {
    return this.socialProfile?.isTiktok ? 'Etc/UTC' : 'America/Vancouver';
  }

  get socialProfile(): SocialProfileModel | undefined {
    return this.analytics.socialProfile;
  }

  /**
   * Whether the current social profile
   * is a Pinterest profile
   */
  get isPinterest(): boolean {
    return this.analytics.isPinterest;
  }

  /**
   * Default start date for data calls in this service
   *
   * @defaultValue
   */
  get startDate(): Moment {
    return this.helpersAnalytics.createMomentInTz().subtract(3, 'months').subtract(1, 'day');
  }

  /**
   * Default end date for data calls in this service
   *
   * @defaultValue
   */
  get endDate(): Moment {
    return this.helpersAnalytics.createMomentInTz();
  }

  /**
   * Clear this service's cached values
   */
  clearCache(): void {
    const cacheKeyPrefix = 'dynamoApi';
    this.cache.clearCacheByKeyword(cacheKeyPrefix);
  }

  /**
   * Takes a period and generates a default
   * profile insights data point
   *
   * @param period - Period of the data requested
   *
   * @returns Returns the default profile insights data point
   */
  getDefaultInsightsPoint(period: TimePeriod = 'day'): InsightsPointRaw {
    return {
      id: null,
      period,
      end_time: null,
      end_day: null,
      end_month: null,
      impressions: null,
      reach: null,
      follower_count: null,
      email_contacts: null,
      phone_call_clicks: null,
      text_message_clicks: null,
      get_directions_clicks: null,
      website_clicks: null,
      profile_views: null,
      audience_gender_age: null,
      audience_locale: null,
      audience_country: null,
      audience_city: null,
      online_followers_map: null,
      video_views: null
    };
  }

  /**
    * Gets limited number of media items between start and end date.
    *
    * @param startDate - Start of interval
    * @param endDate - End of interval
    * @param limit - number of items to return for each page
    * @param cursor - Previously retreived cursor to load more data. If provided will override other query params passed to fetch.

    *
    * @returns Returns a task that resolves to an array of paginated media
    */
  @enqueueTask
  *getPaginatedMedia(
    startDate: Moment = this.startDate,
    endDate: Moment = this.endDate,
    limit = 50,
    cursor: string | null = null
  ): TaskGenerator<RawMedia[]> {
    const socialProfileId = this.socialProfile?.id || '';
    const endpoint = 'media';
    const dateKey = this.helpersAnalytics.createKeyFromDates(startDate, endDate);
    const cacheKey = generateCacheKey('dynamoApi', {
      endpoint,
      type: 'data',
      dateKey,
      socialProfileId,
      cursor
    });
    const cachedMedia = this.cache.retrieve(cacheKey) as RawMedia[];

    if (cachedMedia) {
      return cachedMedia;
    }

    if (cursor) {
      return yield taskFor(this._fetch).linked().perform({
        includePaginationDataInResponse: true,
        startDate,
        endDate,
        endpoint,
        queryParams: {
          cursor
        }
      });
    }

    return yield taskFor(this._fetch)
      .linked()
      .perform({
        includePaginationDataInResponse: true,
        endpoint,
        startDate,
        endDate,
        queryParams: {
          start_time: startDate.unix(),
          end_time: endDate.unix(),
          limit
        }
      });
  }

  /**
    * Gets limited number of reels items between start and end date.
    *
    * @param startDate - Start of interval
    * @param endDate - End of interval
    * @param limit - number of items to return for each page
    * @param cursor - Previously retreived cursor to load more data. If provided will override other query params passed to fetch.

    *
    * @returns Returns a task that resolves to an array of paginated reels
    */
  @enqueueTask
  *getPaginatedReels(
    startDate: Moment = this.startDate,
    endDate: Moment = this.endDate,
    limit = 50,
    cursor: string | null = null
  ): TaskGenerator<RawMedia[]> {
    const socialProfileId = this.socialProfile?.id || '';
    const endpoint = 'reels';
    const dateKey = this.helpersAnalytics.createKeyFromDates(startDate, endDate);
    const cacheKey = generateCacheKey('dynamoApi', {
      endpoint,
      type: 'data',
      dateKey,
      socialProfileId,
      cursor
    });
    const cachedMedia = this.cache.retrieve(cacheKey) as RawMedia[];

    if (cachedMedia) {
      return cachedMedia;
    }

    if (cursor) {
      return yield taskFor(this._fetch).linked().perform({
        includePaginationDataInResponse: true,
        startDate,
        endDate,
        endpoint,
        queryParams: {
          cursor
        }
      });
    }

    return yield taskFor(this._fetch)
      .linked()
      .perform({
        includePaginationDataInResponse: true,
        endpoint,
        startDate,
        endDate,
        queryParams: {
          start_time: startDate.unix(),
          end_time: endDate.unix(),
          limit
        }
      });
  }

  /**
   * Gets all media
   *
   * @param forceRefresh - whether to force refresh data
   *
   * @returns all media
   */
  @enqueueTask
  *getMedia(forceRefresh = false): TaskGenerator<RawMedia[]> {
    const socialProfileId = this.socialProfile?.id || '';
    const endpoint = 'media';
    const dateKey = this.helpersAnalytics.createKeyFromDates(this.startDate, this.endDate);
    const cacheKey = generateCacheKey('dynamoApi', {
      endpoint,
      socialProfileId,
      dateKey
    });
    const cachedMedia = this.cache.retrieve(cacheKey) as RawMedia[];

    if (_isEmpty(cachedMedia) || forceRefresh) {
      return yield taskFor(this._fetch).linked().perform({
        startDate: this.startDate,
        endDate: this.endDate,
        endpoint
      });
    }

    return cachedMedia;
  }

  /**
   * Gets all hashtags.
   *
   * @param startDate - Start of interval
   * @param endDate - End of interval
   * @param forceRefresh - whether to force refresh data
   *
   * @returns all hashtags
   */
  @task
  *getHashtags(
    startDate: Moment = this.startDate,
    endDate: Moment = this.endDate,
    forceRefresh = false
  ): TaskGenerator<HashtagObject> {
    const dateKey = this.helpersAnalytics.createKeyFromDates(startDate, endDate);
    const socialProfileId = this.socialProfile?.id || '';
    const endpoint = 'hashtags';
    const cacheKey = generateCacheKey('dynamoApi', {
      endpoint,
      dateKey,
      socialProfileId
    });
    const cachedHashtags = this.cache.retrieve(cacheKey) as HashtagObject;

    if (_isEmpty(cachedHashtags) || forceRefresh) {
      return yield taskFor(this._fetch)
        .linked()
        .perform({
          endpoint,
          queryParams: {
            start_time: startDate.unix(),
            end_time: endDate.unix()
          },
          metaCacheFn: () => null,
          dataCacheFn: (data) =>
            this._createHashtagCacheObject(socialProfileId, startDate, endDate, data as HashtagObject)
        });
    }

    return cachedHashtags;
  }

  /**
   * Gets limited number of stories between start and end date.
   *
   * @param startDate - Start of interval
   * @param endDate - End of interval
   * @param limit - Number of items to return for each page
   * @param cursor - Previously retreived cursor to load more data. If provided will override other query params passed to fetch.
   *
   * @returns paginated media
   */
  @enqueueTask
  *getPaginatedStories(startDate: Moment, endDate: Moment, limit = 50, cursor: string): TaskGenerator<PaginatedMedia> {
    const endpoint = 'stories';

    if (cursor) {
      return yield taskFor(this._fetch).linked().perform({
        includePaginationDataInResponse: true,
        endpoint,
        queryParams: {
          cursor
        }
      });
    }

    return yield taskFor(this._fetch)
      .linked()
      .perform({
        includePaginationDataInResponse: true,
        endpoint,
        queryParams: {
          start_time: startDate.unix(),
          end_time: endDate.unix(),
          limit
        }
      });
  }

  /**
   * Gets all stories.
   *
   * @param forceRefresh - whether to force refresh data
   *
   * @returns All stories
   */
  @enqueueTask
  *getStories(forceRefresh = false): TaskGenerator<RawDynamoIgMedia[]> {
    const socialProfileId = this.socialProfile?.id || '';
    const endpoint = 'stories';
    const dateKey = this.helpersAnalytics.createKeyFromDates(this.startDate, this.endDate);
    const cacheKey = generateCacheKey('dynamoApi', { endpoint, socialProfileId, dateKey });
    const cachedStories = this.cache.retrieve(cacheKey) as RawDynamoIgMedia[];

    if (_isEmpty(cachedStories) || forceRefresh) {
      return yield taskFor(this._fetch).linked().perform({
        startDate: this.startDate,
        endDate: this.endDate,
        endpoint
      });
    }

    return cachedStories;
  }

  /**
   * Returns Instagram profile insights.
   *
   * @param startDate - Start of interval
   * @param endDate - End of interval
   * @param forceRefresh - whether to force refresh data
   *
   * @returns Instagram profile insights.
   */
  @enqueueTask
  *getProfileInsights(
    startDate: Moment = this.startDate,
    endDate: Moment = this.endDate,
    forceRefresh = false
  ): TaskGenerator<InsightsPointRaw[]> {
    let rawInsights: InsightsPointRaw[];
    const dateKey = this.helpersAnalytics.createKeyFromDates(startDate, endDate);
    const socialProfileId = this.socialProfile?.id || '';
    const endpoint = 'profile_insights';
    const cacheKey = generateCacheKey('dynamoApi', { endpoint: snakeToCamel(endpoint), dateKey, socialProfileId });
    const cachedProfileInsights = this.cache.retrieve(cacheKey) as InsightsPointRaw[];

    if (_isEmpty(cachedProfileInsights) || forceRefresh) {
      rawInsights = yield taskFor(this._fetch)
        .linked()
        .perform({
          endpoint,
          queryParams: {
            start_time: startDate.unix(),
            end_time: endDate.unix(),
            period: 'day'
          },
          metaCacheFn: () => null,
          dataCacheFn: (data) =>
            this._createDateRangeCacheObject(
              socialProfileId,
              startDate,
              endDate,
              snakeToCamel(endpoint),
              data as InsightsPointRaw[]
            )
        });
    } else {
      rawInsights = cachedProfileInsights;
    }

    const filteredInsights = filterTimeSeries<InsightsPointRaw, 'end_time'>(
      A(rawInsights),
      startDate.unix(),
      endDate.unix(),
      'end_time'
    );

    if (filteredInsights.length) {
      return this.helpersAnalytics.fillData(
        startDate.unix(),
        endDate.unix(),
        filteredInsights,
        this.getDefaultInsightsPoint('day'),
        'end_time'
      );
    }
    return filteredInsights;
  }

  /**
   * Formats and Returns lifetime Instagram profile insights.
   *
   * @param forceRefresh - whether to force refresh data
   * @param startDate - Start of interval
   * @param endDate - End of interval
   *
   * @returns Instagram profile insights
   */
  @enqueueTask
  *getProfileInsightsLifetime(
    forceRefresh = false,
    startDate: Moment = this.startDate,
    endDate: Moment = this.endDate
  ): TaskGenerator<InsightsPointRaw[]> {
    let lifetimeInsights: InsightsPointRaw[];
    const socialProfileId = this.socialProfile?.id || '';
    const endpoint = 'profile_insights';
    const endpointCacheKey = 'profileInsightsLifetime';
    const dateKey = this.helpersAnalytics.createKeyFromDates(startDate, endDate);
    const cacheKey = generateCacheKey('dynamoApi', { endpoint: endpointCacheKey, dateKey, socialProfileId });

    const cachedProfileInsightsLifetime = this.cache.retrieve(cacheKey) as InsightsPointRaw[];

    if (_isEmpty(cachedProfileInsightsLifetime) || forceRefresh) {
      lifetimeInsights = yield taskFor(this._fetch)
        .linked()
        .perform({
          endpoint,
          cacheKey: endpointCacheKey,
          queryParams: {
            start_time: startDate.unix(),
            end_time: endDate.unix(),
            period: this.socialProfile?.isFacebook ? 'day' : 'lifetime'
          },
          metaCacheFn: () => null,
          dataCacheFn: (data) =>
            this._createDateRangeCacheObject(
              socialProfileId,
              startDate,
              endDate,
              endpointCacheKey,
              data as InsightsPointRaw[]
            )
        });
    } else {
      lifetimeInsights = cachedProfileInsightsLifetime;
    }

    const filteredInsights = filterTimeSeries<InsightsPointRaw, 'end_time'>(
      A(lifetimeInsights),
      startDate.unix(),
      endDate.unix(),
      'end_time'
    );

    const filledInsights = filteredInsights.length
      ? this.helpersAnalytics.fillData(
          startDate.unix(),
          endDate.unix(),
          filteredInsights,
          this.getDefaultInsightsPoint('lifetime'),
          'end_time'
        )
      : filteredInsights;

    return filledInsights.sortBy('end_time').reverse();
  }

  /**
   * Returns Instagram profile counts.
   *
   * @param startDate - Start of interval
   * @param endDate - End of interval
   * @param forceRefresh - whether to force refresh data
   *
   * @returns Instagram profile counts.
   */
  @enqueueTask
  *getProfileCounts(
    startDate: Moment = this.startDate,
    endDate: Moment = this.endDate,
    forceRefresh = false
  ): TaskGenerator<ProfileCountPointRaw[]> {
    let rawCounts: ProfileCountPointRaw[];
    const dateKey = this.helpersAnalytics.createKeyFromDates(startDate, endDate);
    const socialProfileId = this.socialProfile?.id || '';
    const endpoint = 'profile_counts';
    const cacheKey = generateCacheKey('dynamoApi', { endpoint: snakeToCamel(endpoint), dateKey, socialProfileId });
    const cachedProfileCounts = this.cache.retrieve(cacheKey) as ProfileCountPointRaw[];

    if (_isEmpty(cachedProfileCounts) || forceRefresh) {
      rawCounts = yield taskFor(this._fetch)
        .linked()
        .perform({
          endpoint,
          queryParams: {
            start_time: startDate.unix(),
            end_time: endDate.unix()
          },
          metaCacheFn: () => null,
          dataCacheFn: (data) =>
            this._createDateRangeCacheObject(
              socialProfileId,
              startDate,
              endDate,
              snakeToCamel(endpoint),
              data as ProfileCountPointRaw[]
            )
        });
    } else {
      rawCounts = cachedProfileCounts;
    }

    const filteredData = filterTimeSeries<ProfileCountPointRaw, 'time'>(
      A(rawCounts),
      startDate.unix(),
      endDate.unix(),
      'time'
    );

    if (filteredData.length) {
      return this.helpersAnalytics.fillData(
        startDate.unix(),
        endDate.unix(),
        filteredData,
        this.defaultProfileCountsPoint,
        'time'
      );
    }

    return filteredData;
  }

  /**
   * Returns media engagement for a given media item over the first two weeks of posting.
   *
   * @param media - Media item
   * @param isStory - If media item is a story
   * @param forceRefresh - whether to force refresh data
   *
   * @returns like and comment counts over time for a given media item.
   */
  @enqueueTask
  *getMediaEngagements(
    media: FormattedMedia,
    isStory = false,
    forceRefresh = false
  ): TaskGenerator<MediaEngagementPointRaw[] | null> {
    const socialProfileId = this.socialProfile?.id || '';
    const endpoint = 'media_engagements';
    const cacheKey = generateCacheKey('dynamoApi', {
      endpoint: snakeToCamel(endpoint),
      mediaId: media.id,
      socialProfileId
    });
    const cachedMediaEngagements = this.cache.retrieve(cacheKey) as MediaEngagementPointRaw[];

    let interactions: MediaEngagementPointRaw[];
    if (_isEmpty(cachedMediaEngagements) || forceRefresh) {
      interactions = yield taskFor(this._fetch)
        .linked()
        .perform({
          endpoint,
          queryParams: {
            media_id: media.id
          },
          metaCacheFn: (data) => {
            const meta = this._calculateMediaEngagementsMeta(data as MediaEngagementPointRaw[], media.type);
            return this._createMediaEngagementsCacheObject(socialProfileId, media.id, meta, 'meta');
          },
          dataCacheFn: (data) =>
            this._createMediaEngagementsCacheObject(
              socialProfileId,
              media.id,
              data as MediaEngagementPointRaw[],
              'data'
            )
        });
    } else {
      interactions = cachedMediaEngagements;
    }

    const startDate = this.helpersAnalytics.createMomentInTz(Number(media.createdTime));

    // Note: Need to use 25 hours here to get entire period of story & tweet - needs further investigation
    const isTweet = media.type === 'tweet';
    const endDate = isStory || isTweet ? startDate.clone().add(25, 'hours') : startDate.clone().add(2, 'weeks');

    const timeKeyName = 'sampled_at';

    if (interactions) {
      if (media.type === 'pin') {
        // Note: for Pinterest, the period of data points we want to show is undecided, so for now we skip filtering
        return interactions;
      }
      const mediaEngagements = filterTimeSeries<MediaEngagementPointRaw, 'sampled_at'>(
        interactions,
        startDate.unix(),
        endDate.unix(),
        timeKeyName
      );

      let result: MediaEngagementPointRaw[];
      if (mediaEngagements.length) {
        result = this.helpersAnalytics.fillData(
          startDate.unix(),
          endDate.unix(),
          mediaEngagements,
          this.defaultMediaEngagementPoint,
          'sampled_at',
          isStory || media.type === 'tweet' ? 3600 : 86400,
          media.id
        );
      } else {
        result = mediaEngagements;
      }
      return result;
    }
    return null;
  }

  /**
   * Fetches media for the given array of media IDs.
   *
   * @param ids - Array of Media IDs
   * @param forceRefresh - whether to force refresh data
   *
   * @returns a media payload
   */
  @task
  *getPostsByIds(ids: string[], forceRefresh = false): TaskGenerator<RawMedia[]> {
    const createMediaConfig = (ids: string[], dataCacheFn: DynamoCacheFn): MediaPayload => ({
      endpoint: 'media',
      queryParams: { ids },
      dataCacheFn
    });

    const { cachedIds = [], cachedPosts = [] } = forceRefresh ? {} : this._getCachedPosts(ids);
    const uncachedIds = ids.filter((id) => !cachedIds.includes(id));

    if (isPresent(uncachedIds)) {
      // Note: This is based off of the max request_uri length of 2,083
      const numIdsPerCall = 40;
      const dataCacheFn: DynamoCacheFn = (data) =>
        this._createMediaCacheObject(this.socialProfile?.id || '', data as RawMedia[] | PinterestMediaRawObject);

      const promises = chunk(uncachedIds, numIdsPerCall).map((setOfIds) =>
        taskFor(this._fetch).linked().perform(createMediaConfig(setOfIds, dataCacheFn))
      );

      const settledPromises: AllSettledResult[] = yield RSVP.allSettled(promises);
      const rejectedPromises = settledPromises.filterBy('state', 'rejected');
      const fulfilledPromises = settledPromises.filterBy('state', 'fulfilled');

      if (isPresent(rejectedPromises)) {
        const errors = rejectedPromises.map((settledPromise) => settledPromise.reason);
        this.errors.log(errors[0], errors);
      }

      if (isEmpty(fulfilledPromises)) {
        return cachedPosts;
      }

      if (this.isPinterest) {
        const rawValues = fulfilledPromises.map((settledPromise) => settledPromise.value);
        const fetchedData = stackObjectArrayValues({ media: [], boards: [] }, rawValues) as PinterestMediaRawObject;
        const sanitizedMedia = fetchedData?.media || [];
        return sanitizedMedia.concat(cachedPosts);
      }

      const rawValues: RawMedia[] = fulfilledPromises.map((settledPromise) => settledPromise.value);
      const fetchedData = flatten(rawValues);
      return fetchedData.concat(cachedPosts);
    }

    return cachedPosts;
  }

  /**
   * Calls fetchAnalyticsTrackMedia
   *
   * @param mediaId - Media ID
   * @param socialProfileId - The socialProfile.id making the request
   *
   * @returns an empty object
   */
  @task
  *getAnalyticsTrackMedia(mediaId: string, socialProfileId: string): TaskGenerator<EmptyObject> {
    return yield taskFor(this.fetchAnalyticsTrackMedia).linked().perform(mediaId, socialProfileId);
  }

  /**
   * Messages squirtle to track given media
   *
   * @param mediaId - Media ID
   * @param socialProfileId - The socialProfile.id making the request
   */
  @task
  *fetchAnalyticsTrackMedia(mediaId: string, socialProfileId: string): TaskGenerator<EmptyObject> {
    return yield fetch('/api/v2/analytics/track_media.json', {
      method: 'POST',
      body: {
        media_id: mediaId,
        social_profile_id: socialProfileId
      }
    });
  }

  /**
   * Fetches data based on the given config object
   * and sets the resulting data and meta in the
   * cache under the given cache key name.
   *
   * @param config - The configuration object stating
   * the required data parameters for the fetch
   *
   * @returns Data array for the given config object
   * @internal
   */
  @task
  *_fetch(config: DynamoFetchConfig): TaskGenerator<DynamoGenericDataRaw> {
    const isPinterestMedia = this.socialProfile?.isPinterest && config.endpoint === 'media';

    const defaultParams = {
      social_profile_id: this.socialProfile?.id
    };
    const params = Object.assign({}, defaultParams, config.queryParams);

    const result = yield fetch(`/api/v2/analytics/${config.endpoint}.json${objectToQueryString(params)}`);
    const responseData =
      Object.prototype.hasOwnProperty.call(result, config.endpoint) && !isPinterestMedia
        ? result[config.endpoint]
        : result;
    let data = responseData;

    if (config.includePaginationDataInResponse) {
      data = {
        data: responseData,
        cursors: {
          next: result.paging?.cursors.next,
          previous: result.paging?.cursors.previous
        }
      };
    }
    this._createCacheValues(config, result.meta, 'meta');
    this._createCacheValues(config, data, 'data');

    return data;
  }

  /**
   * Creates a cacheString and cacheValue to set cache data with
   *
   *
   * @param config - The configuration object stating the required data parameters
   * @param data - The data we want to cache
   * @param dataType - The type of data we're caching: either 'data' or 'meta'
   *
   * @internal
   */
  _createCacheValues(config: DynamoFetchConfig, data: DynamoGenericDataRaw, dataType: 'data' | 'meta'): void {
    // Note: do not need a dateKey unless endpoint config requires a start and end
    let dateKey = '';
    if (config.startDate && config.endDate) {
      dateKey = this.helpersAnalytics.createKeyFromDates(config.startDate, config.endDate);
    }

    const cacheKey = generateCacheKey('dynamoApi', {
      cursor: config.queryParams?.cursor ? config.queryParams.cursor : null,
      dateKey,
      type: dataType,
      endpoint: config.cacheKey || snakeToCamel(config.endpoint),
      socialProfileId: this.socialProfile?.id || ''
    });

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
    const cacheFnKey = `${dataType}CacheFn` as 'dataCacheFn' | 'metaCacheFn';
    const cacheFn = config[cacheFnKey];

    const cacheObject = cacheFn ? cacheFn(data) : null;
    const options = { expiry: this.cache.expiry(1, 'day'), persist: false };
    if (cacheObject) {
      this.cache.add(`${cacheObject.cacheKey}`, cacheObject.cacheValue || {}, options);
    } else {
      this.cache.add(cacheKey, data || {}, options);
    }
  }

  /**
   * For each mediaEngagement property, generates a meta object with first_data_point
   *
   * @param mediaEngagements - Media Engagements raw response from fetchMediaEngagements
   * @param mediaType - Media type
   *
   * @returns Media engagement meta
   * @internal
   */
  _calculateMediaEngagementsMeta(mediaEngagements: MediaEngagementPointRaw[], mediaType: string): FirstDataPoint[] {
    const mediaEngagementsMetaArray: FirstDataPoint[] = [];

    const propertiesForMediaType = this._getMediaEngagementPropertiesByType(mediaType);

    propertiesForMediaType.forEach((property) => {
      const { name, canCarousel } = property;
      const useCarousel = canCarousel && mediaType === 'carousel';

      const firstDataPointObject = this._createFirstDataPointObject<MediaEngagementPointRaw, typeof name, 'sampled_at'>(
        mediaEngagements,
        name,
        'sampled_at',
        useCarousel
      );

      if (firstDataPointObject) {
        mediaEngagementsMetaArray.push(firstDataPointObject);
      }
    });

    return mediaEngagementsMetaArray.length ? Object.assign([], ...mediaEngagementsMetaArray) : [];
  }

  /**
   * Creates a single meta object containing first_data_point, for given value and time keys
   *
   * @param data
   * @param valueKey - Name of value key for data
   * @param timeKey - Name of time key for data
   * @param Boolean - indicating whether property changes for carousel
   *
   * @returns A single media engagement meta
   * @internal
   */
  _createFirstDataPointObject<T extends Record<string, any>, ValueKey extends keyof T, TimeKey extends keyof T>(
    data: T[],
    valueKey: ValueKey,
    timeKey: TimeKey,
    useCarousel = false
  ): FirstDataPoint | null {
    const valueKeyForType = useCarousel ? `carousel_album_${String(valueKey)}` : valueKey;
    const values = extractValuesOverTime(data, valueKeyForType, timeKey) ?? [];
    const firstDataPoint = getFirstPointByName(values, 'value');

    if (!firstDataPoint) {
      return null;
    }

    return {
      [valueKey]: {
        first_data_point: firstDataPoint.time
      }
    };
  }

  /**
   * Takes given value and creates a media engagements cache object
   *
   * @param socialProfileId
   * @param mediaId
   * @param value
   * @param dataType
   *
   * @returns An object with a cacheKey and cacheValue
   * @internal
   */
  _createMediaEngagementsCacheObject(
    socialProfileId: string,
    mediaId: string,
    value: FirstDataPoint[] | MediaEngagementPointRaw[],
    dataType: 'data' | 'meta'
  ): DynamoCacheObject {
    return {
      cacheKey: generateCacheKey('dynamoApi', {
        endpoint: 'mediaEngagements',
        type: dataType,
        mediaId,
        socialProfileId
      }),
      cacheValue: value
    };
  }

  /**
   * Gets media engagement properties for a given media type
   *
   * @param mediaType
   *
   * @returns Returns the media engagement properties for the given type
   * @internal
   */
  _getMediaEngagementPropertiesByType(mediaType: string): MediaEngagementEndpointConfigItem[] {
    switch (mediaType) {
      case 'pin':
        return this.mediaEngagementProperties.filter((property) => property.isPinterest);
      case 'tweet':
        return this.mediaEngagementProperties.filter((property) => property.isTwitter);
      default:
        return this.mediaEngagementProperties.filter((property) => !property.isTwitter && !property.isPinterest);
    }
  }

  /**
   * Takes given value and creates a date range cache object
   *
   * @param socialProfileId
   * @param startDate
   * @param endDate
   * @param endpoint
   * @param value
   *
   * @returns An object with a cacheKey and cacheValue
   * @internal
   */
  _createDateRangeCacheObject(
    socialProfileId: string,
    startDate: Moment,
    endDate: Moment,
    endpoint: string,
    value: DynamoGenericDataRaw
  ): DynamoCacheObject {
    const dateKey = this.helpersAnalytics.createKeyFromDates(startDate, endDate);
    const cacheKey = generateCacheKey('dynamoApi', { endpoint, dateKey, socialProfileId });
    return {
      cacheKey,
      cacheValue: value
    };
  }

  /**
   * Takes given value and creates a hashtag cache object
   *
   * @param socialProfileId
   * @param startDate
   * @param endDate
   * @param value
   *
   * @returns An object with a cacheKey and cacheValue
   * @internal
   */
  _createHashtagCacheObject(
    socialProfileId: string,
    startDate: Moment,
    endDate: Moment,
    value: HashtagObject
  ): DynamoCacheObject {
    const dateKey = this.helpersAnalytics.createKeyFromDates(startDate, endDate);
    const cacheKey = generateCacheKey('dynamoApi', { endpoint: 'hashtags', dateKey, socialProfileId });

    return {
      cacheKey,
      cacheValue: value
    };
  }

  /**
   * From the list of given media ids, returns an object
   * with an array of those Media Ids and array of those
   * posts that have already been cached
   *
   * @param ids - Array of Media IDs
   *
   * @returns cachedPostsObject
   */
  _getCachedPosts(ids: string[]): { cachedIds: string[]; cachedPosts: RawMedia[] } {
    const cacheKey = generateCacheKey('dynamoApi', {
      endpoint: 'media',
      socialProfileId: this.socialProfile?.id || ''
    });
    const allCachedData = this.cache.retrieve(cacheKey) as RawMedia[] | PinterestMediaRawObject;

    if (!allCachedData) {
      return {
        cachedPosts: allCachedData,
        cachedIds: []
      };
    }

    const media = this.isPinterest ? (allCachedData as PinterestMediaRawObject).media : (allCachedData as RawMedia[]);
    const cachedPosts = media.filter((media) => ids.includes(media.id));
    return {
      cachedPosts,
      cachedIds: cachedPosts.map((media) => media.id)
    };
  }

  /**
   * Takes given value and creates a media cache object with a cacheKey and cacheValue.
   * The cacheValue in the object is an array of media items generated from cached media and
   * newly fetched media. Any newly fetched media overwrites any media in the cache with the same ids.
   *
   * @param socialProfileId
   * @param uncachedData
   *
   * @returns An object with a cacheKey and cacheValue
   * @internal
   */
  _createMediaCacheObject(
    socialProfileId: string,
    uncachedData: RawMedia[] | PinterestMediaRawObject
  ): DynamoCacheObject {
    const cacheKey = generateCacheKey('dynamoApi', { endpoint: 'media', socialProfileId });
    const cachedData = this.cache.retrieve(cacheKey);

    if (_isEmpty(cachedData) && !uncachedData) {
      return { cacheKey, cacheValue: [] };
    }

    if (this.isPinterest) {
      const uncachedMediaValues = (uncachedData as PinterestMediaRawObject)?.media;
      const cachedMediaValues = (cachedData as PinterestMediaRawObject)?.media;
      const media = findUniqueValuesById<RawMedia, RawMedia>(cachedMediaValues, uncachedMediaValues);

      const uncachedBoardValues = (uncachedData as PinterestMediaRawObject)?.boards;
      const cachedBoardValues = (cachedData as PinterestMediaRawObject)?.boards;
      const boards = findUniqueValuesById(cachedBoardValues, uncachedBoardValues);

      return { cacheKey, cacheValue: { boards, media } };
    }

    const uncachedMediaValues = uncachedData as RawMedia[];
    const cachedMediaValues = cachedData as RawMedia[];
    const media = findUniqueValuesById<RawMedia, RawMedia>(cachedMediaValues, uncachedMediaValues);
    return { cacheKey, cacheValue: media };
  }
}

declare module '@ember/service' {
  interface Registry {
    'analytics/dynamo-api': DynamoApiService;
  }
}
