import Service, { inject as service } from '@ember/service';
import { all, task } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';

import { INSTAGRAM_PRODUCT_TAG_KEY } from 'later/utils/constants';

import type RouterService from '@ember/routing/router-service';
import type { TaskGenerator } from 'ember-concurrency';
import type InstagramProductCatalogService from 'later/services/social//instagram-product-catalog';
import type { UntypedService } from 'shared/types';
import type FacebookProduct from 'shared/utils/facebook-product';

export interface ProductTag {
  x: number;
  y: number;
  [INSTAGRAM_PRODUCT_TAG_KEY]: string;
  product?: FacebookProduct;
}

export default class InstagramProductTaggingService extends Service {
  // TODO: Declare services after they've been converted to typescript
  @service declare locale: UntypedService;
  @service declare router: RouterService;
  @service('social/instagram-product-catalog') declare instagramProductCatalog: InstagramProductCatalogService;
  @service('social/facebook-graph') declare facebookGraph: UntypedService;

  getRawTags(productTags: ProductTag[]): ProductTag[] {
    return productTags.map((tag) => ({
      x: tag.x,
      y: tag.y,
      [INSTAGRAM_PRODUCT_TAG_KEY]: tag[INSTAGRAM_PRODUCT_TAG_KEY]
    }));
  }

  findTagIndex(productTags: ProductTag[], tag: ProductTag): number {
    return productTags.findIndex(
      (productTag) => productTag[INSTAGRAM_PRODUCT_TAG_KEY] == tag[INSTAGRAM_PRODUCT_TAG_KEY]
    );
  }

  removeTag(productTags: ProductTag[], tag: ProductTag): void {
    const index = this.findTagIndex(productTags, tag);
    if (index >= 0) {
      productTags.removeAt(index);
    }
  }

  @task
  *getProductTags(rawTags = [], businessAccountToken: string): TaskGenerator<ProductTag[]> {
    return yield all(
      (rawTags ?? []).map((rawTag) => taskFor(this.getProductTag).perform(rawTag, businessAccountToken))
    );
  }

  @task
  *getProductTag(tag: ProductTag, businessAccountToken: string): TaskGenerator<ProductTag> {
    const product = yield taskFor(this.instagramProductCatalog.fetchAndCacheProduct).perform(
      tag[INSTAGRAM_PRODUCT_TAG_KEY],
      businessAccountToken
    );

    return {
      x: tag.x,
      y: tag.y,
      [INSTAGRAM_PRODUCT_TAG_KEY]: tag[INSTAGRAM_PRODUCT_TAG_KEY],
      product
    };
  }
}

declare module '@ember/service' {
  interface Registry {
    'social/instagram-product-tagging': InstagramProductTaggingService;
  }
}
