import type { SearchIntent } from 'later/utils/global-search/intents';

interface SearchMatch {
  term: string;
  index: number;
  value: number;
}

export function isPartialMatch(query: string, searchTerms: string[]): boolean {
  const partialMatches = getPartialMatches(query, searchTerms);

  return partialMatches.length > 0;
}

export function meetsSearchRequirements(query: string, mandatoryTerms: string[] | undefined): boolean {
  if (!mandatoryTerms || mandatoryTerms.length == 0) {
    return true;
  }

  return getExactMatches(query, mandatoryTerms).length > 0;
}

export function scoreSearchResult(normalizedQuery: string, intent: SearchIntent): number {
  const partialMatches = getPartialMatches(normalizedQuery, intent.searchTerms);
  const exactMatches = getExactMatches(normalizedQuery, intent.searchTerms);

  const partialScore = calculateScoreForMatches(partialMatches);
  const exactScore = calculateScoreForMatches(exactMatches);

  return partialScore + exactScore;
}

function calculateScoreForMatches(matches: SearchMatch[]): number {
  return matches.reduce((acc, match) => {
    return acc + match.value * (1 / (match.index + 1));
  }, 0);
}

function getExactMatches(searchQuery: string, searchTerms: string[]): SearchMatch[] {
  const queryTerms = searchQuery.split(' ').filter((term) => term.length > 1);
  const matches: SearchMatch[] = [];

  searchTerms.forEach((term, index) => {
    if (queryTerms.some((queryTerm) => term === queryTerm)) {
      const match = {
        term,
        index,
        value: 2
      };
      matches.push(match);
    }
  });

  return matches;
}

function getPartialMatches(searchQuery: string, searchTerms: string[]): SearchMatch[] {
  const queryTerms = searchQuery.split(' ').filter((term) => term.length > 1);
  const matches: SearchMatch[] = [];

  searchTerms.forEach((term, index) => {
    if (queryTerms.some((queryTerm) => term.includes(queryTerm))) {
      const match = {
        term,
        index,
        value: 1
      };
      matches.push(match);
    }
  });

  return matches;
}
