import { isBlank, isNone, isEmpty } from '@ember/utils';
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { validator, buildValidations } from 'ember-cp-validations';
import moment from 'moment';

import { timestamp } from 'later/utils/time-format';

import type { AsyncBelongsTo, AsyncHasMany } from '@ember-data/model';
import type AccountModel from 'later/models/account';
import type DeviceModel from 'later/models/device';
import type GramModel from 'later/models/gram';
import type GroupModel from 'later/models/group';
import type MembershipModel from 'later/models/membership';

const Validations = buildValidations({
  password: [
    validator('presence', {
      presence: true,
      description: 'Password'
    }),
    validator('length', {
      min: 8,
      description: 'Password'
    })
  ],
  email: [
    validator('presence', {
      presence: true,
      description: 'Email'
    }),
    validator('format', { type: 'email', description: 'Email' })
  ]
});
export default class UserModel extends Model.extend(Validations) {
  @attr('boolean', { defaultValue: false }) declare readonly admin: boolean;
  @attr('string', { defaultValue: '' }) declare readonly avatarUrl: string;
  @attr('boolean') declare readonly canAddUser?: boolean;
  @attr('boolean') declare readonly canSetProfilePermissions?: boolean;
  @attr('number') declare readonly createdTime?: number;
  @attr('string') declare currentPassword?: string;
  @attr('boolean', { defaultValue: false }) declare dev: boolean;
  @attr('string') declare readonly displayName?: string;
  @attr('string', { defaultValue: '' }) declare email: string;
  @attr('boolean', { defaultValue: false }) declare emailFollowUpNotifications: boolean;
  @attr('string') declare facebookToken?: string;
  @attr('string') declare facebookUid?: string;
  @attr('number') declare firstDay?: number;
  @attr('boolean', { defaultValue: false }) declare followUpPushNotifications: boolean;
  @attr('string') declare googleClientId?: string;
  @attr('boolean') declare readonly hasEncryptedPassword?: boolean;
  @attr('boolean') declare highPerformingContentEmailNotifications?: boolean;
  @attr('boolean', { defaultValue: false }) declare readonly isAccountOwner: boolean;
  @attr('string') declare readonly keenReadKey?: string;
  @attr('string') declare language?: string;
  @attr('number') declare readonly lastSignedIn?: number;
  @attr('number') declare loginsSinceScheduleReminder?: number;
  @attr('string') declare name?: string;
  // This should only be modified during signup as it affects BE params
  @attr('boolean', { defaultValue: false }) declare newAccount: boolean;
  @attr('boolean') declare otp_required_for_login?: boolean;
  @attr('boolean') declare owl?: boolean;
  @attr('string') declare linkedinToken?: string;
  @attr('number') declare readonly linkedinTokenExpiresTime?: number;
  @attr('string') declare password?: string;
  @attr('string') declare passwordConfirmation?: string;
  @attr('boolean') declare receivedImportMediaEducation?: boolean;
  @attr('boolean') declare receivedInstagramStoriesEducation?: boolean;
  @attr('boolean') declare receivedMentionsEducation?: boolean;
  @attr('boolean') declare receivedMultiImageEducation?: boolean;
  @attr('boolean') declare receivedRegramEducation?: boolean;
  @attr('boolean') declare receivedSchedulingRewards?: boolean;
  @attr('boolean') declare receivedSearchByProfileEducation?: boolean;
  @attr('boolean') declare receivedTaggedEducation?: boolean;
  @attr('boolean') declare receivedUnsplashEducation?: boolean;
  @attr('boolean') declare receivedYoutubeEducation?: boolean;
  @attr('string') declare referralCode?: string;
  @attr('number') declare referredById?: number;
  @attr('boolean') declare readonly showBanner?: boolean;
  @attr('number') declare testGroupId?: number;
  @attr('string') declare tiktokClickId?: string;
  @attr('string', { defaultValue: () => moment.tz.guess() }) timeZone?: string;
  @attr('string', { defaultValue: () => moment.tz.guess() }) readonly timeZoneIdentifier?: string;
  @attr('boolean') declare unconfirmed_two_factor?: boolean;
  @attr('string') declare readonly websocketToken?: string;

  @belongsTo('account', { async: true }) declare account: AsyncBelongsTo<AccountModel>;
  @hasMany('device', { async: true }) declare devices: AsyncHasMany<DeviceModel>;
  @hasMany('membership', { async: true }) declare memberships: AsyncHasMany<MembershipModel>;
  @hasMany('gram', { async: true }) declare posts: AsyncHasMany<GramModel>;

  get canKeen(): boolean {
    return this.admin && !isEmpty(this.keenReadKey);
  }

  get secureAvatarUrl(): string {
    if (!isNone(this.avatarUrl)) {
      return this.avatarUrl.replace('http://', 'https://');
    }
    return this.avatarUrl;
  }

  get hasValidLinkedinUserToken(): boolean {
    if (!this.linkedinTokenExpiresTime) {
      return false;
    }

    const isLinkedinUserTokenExpired =
      !isBlank(this.linkedinTokenExpiresTime) && this.linkedinTokenExpiresTime < timestamp();
    return !isBlank(this.linkedinToken) && !isLinkedinUserTokenExpired;
  }

  membershipFor(group: GroupModel): MembershipModel | undefined {
    return this.memberships.find((membership) => membership.get('group').get('id') === group.get('id'));
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    user: UserModel;
  }
}
