/**
 * @module Services
 */
import EmberObject from '@ember/object';
import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';
import classic from 'ember-classic-decorator';
import { TrackedArray } from 'tracked-built-ins';

@classic
class State extends EmberObject {
  @tracked activePage = null;
  @tracked hasRevoked = null;
  @tracked isAdminOfPage = null;
  @tracked isCorrectPage = null;
  @tracked nameOfPage = null;
  @tracked currentModal = 'credentialsStatus';
}

/**
 * This service manages credentials to 3rd party platforms across the app
 *
 * @class Credentials
 * @extends Service
 */
export default class CredentialsService extends Service {
  @tracked socialProfile = null;
  @tracked failedConnections = new TrackedArray([]);
  @tracked state = null;
  @tracked source = null;

  initState(socialProfile, source) {
    if (socialProfile) {
      this.source = source;
      this.socialProfile = socialProfile;
      this.state = State.create({ isConnecting: !socialProfile.hasProfessionalAccount });
    }
  }

  updateFailedConnections(socialProfile, didFail = true) {
    if (didFail) {
      this.failedConnections.pushObject(socialProfile.id);
    } else {
      this.failedConnections.removeObject(socialProfile.id);
    }
  }

  updateActivePage(payload) {
    this.state.activePage = payload;
  }

  updateIsCorrectPage(payload) {
    this.state.isCorrectPage = payload;
  }

  updateNameOfPage(payload) {
    this.state.nameOfPage = payload;
  }

  updateCurrentModal(payload) {
    this.state.currentModal = payload;
  }

  updateIsAdminOfPage(payload) {
    this.state.isAdminOfPage = payload;
  }

  updateHasRevoked(payload) {
    this.state.hasRevoked = payload;
  }
}
