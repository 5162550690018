import Evented from '@ember/object/evented';
import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';

import { InstagramAccountTypes } from 'shared/utils/constants/social-profile';
import objectPromiseProxy from 'shared/utils/object-promise-proxy';

import type AnalyticsAccessService from './analytics/analytics-access';
import type HelpersAnalyticsService from './analytics/helpers-analytics';
import type RouterService from '@ember/routing/router-service';
import type { TimePeriodDurations } from 'analytics/types';
import type IntlService from 'ember-intl/services/intl';
import type AccountModel from 'later/models/account';
import type GroupModel from 'later/models/group';
import type LinkinbioPageModel from 'later/models/linkinbio-page';
import type SocialProfileModel from 'later/models/social-profile';
import type UserModel from 'later/models/user';
import type AuthService from 'later/services/auth';
import type SubscriptionsService from 'later/services/subscriptions';
import type { UntypedService } from 'shared/types';
import type { SocialPlatformType, InstagramAccountType } from 'shared/types/social-profile';

export default class AnalyticsService extends Service.extend(Evented) {
  @service declare auth: AuthService;
  @service declare faye: UntypedService;
  @service declare intl: IntlService;
  @service declare router: RouterService;
  @service declare segment: UntypedService;
  @service declare subscriptions: SubscriptionsService;
  @service declare userConfig: UntypedService;
  @service('analytics/analytics-access') declare analyticsAccess: AnalyticsAccessService;
  @service('analytics/helpers-analytics') declare helpersAnalytics: HelpersAnalyticsService;

  @tracked showUpgradeModal = false;
  @tracked showProfileCategorizationModal = false;
  @tracked socialProfile: SocialProfileModel | undefined = undefined;
  @tracked linkinbioPage: LinkinbioPageModel | undefined = undefined;

  /**
   * Initializes the analytics service,
   * and sets up the the keen ember evented listener
   * socialProfileHasKeenKey which sets the SocialProfile
   */
  constructor(...args: Record<string, unknown>[]) {
    super(...args);

    this.faye.on('socialProfileHasKeenKey', (sp: SocialProfileModel) => {
      if (sp === this.socialProfile) {
        this.setSocialProfile(sp);
      }
    });
  }

  get timeZoneIdentifier(): string {
    return this.userConfig.currentTimeZone.identifier;
  }

  get timeZoneName(): string {
    return this.userConfig.currentTimeZone.name;
  }

  get group(): SocialProfileModel['group'] | undefined {
    return this.socialProfile?.group;
  }

  get isPaid(): boolean {
    return this.subscriptions.isPaid;
  }

  get currentAccount(): AccountModel {
    return this.auth.currentAccount;
  }

  get isLaterBusinessPlan(): boolean {
    return !!this.currentAccount?.canCluster;
  }

  get accountType(): SocialPlatformType | undefined {
    return this.socialProfile?.accountType;
  }

  get socialProfiles(): GroupModel['socialProfiles'] | undefined {
    return this.auth.currentGroup?.socialProfiles;
  }

  get currentSocialProfile(): SocialProfileModel {
    return this.auth.currentSocialProfile;
  }

  get currentLinkinbioPage(): LinkinbioPageModel | undefined {
    if (this.isLinkinbio) {
      return this.linkinbioPage;
    }

    return this.currentAccount.rolloutLinkinbioInstagramlessPage
      ? this.socialProfile?.get('linkinbioPage')
      : this.socialProfile?.get('socialIdentity').get('linkinbioPage');
  }

  get isLinkinbio(): boolean {
    return this.router.currentRouteName?.includes('linkinbio.overview');
  }

  get isTwitter(): boolean {
    return !!this.socialProfile?.isTwitter;
  }

  get isTiktok(): boolean {
    return !!this.socialProfile?.isTiktok;
  }

  get isPinterest(): boolean {
    return !!this.socialProfile?.isPinterest;
  }

  get isInstagram(): boolean {
    return !!this.socialProfile?.isInstagram;
  }

  get isFacebook(): boolean {
    return !!this.socialProfile?.isFacebook;
  }

  get isProfessional(): boolean {
    return !!this.socialProfile?.isProfessional;
  }

  get isCreator(): boolean {
    return !!this.socialProfile?.isCreator;
  }

  get isBusiness(): boolean {
    return !!this.socialProfile?.isBusiness;
  }

  get hasProfessionalAccount(): boolean {
    return !!this.socialProfile?.hasProfessionalAccount;
  }

  get followerCount(): number | null | undefined {
    return this.socialProfile?.followedBy;
  }

  get currentUser(): UserModel {
    return this.auth.currentUserModel;
  }

  get userIsAccountOwner(): boolean {
    return this.currentUser.isAccountOwner;
  }

  get ownerEmail(): string | undefined {
    return this.currentAccount?.owner.get('email');
  }

  get needsProfessionalTokenRefresh(): boolean {
    return !!this.socialProfile?.needsRefresh;
  }

  get canAccessPinterestAnalytics(): boolean {
    return this.analyticsAccess.features.pinterest;
  }

  get canAccessTwitterAnalytics(): boolean {
    return this.analyticsAccess.features.twitter;
  }

  get canAccessFacebookAnalytics(): boolean {
    return this.analyticsAccess.features.facebook;
  }

  get showUpgrade(): boolean {
    if (this.isInstagram) {
      return this.userIsAccountOwner && !this.analyticsAccess.features.analyticsStandard;
    } else if (this.isPinterest || this.isTwitter) {
      return this.userIsAccountOwner && !this.canAccessPinterestAnalytics && !this.canAccessTwitterAnalytics;
    } else if (this.isFacebook) {
      return this.userIsAccountOwner && !this.canAccessFacebookAnalytics;
    }
    return false;
  }

  get shouldShowUpgradeModal(): boolean {
    return this.showUpgradeModal && this.showUpgrade;
  }

  get instagramAccountTypeName(): InstagramAccountType {
    if (!this.isProfessional) {
      return InstagramAccountTypes.Personal;
    }

    return this.isCreator ? InstagramAccountTypes.Creator : InstagramAccountTypes.Business;
  }

  get extendedTimePickerDurations(): TimePeriodDurations[] {
    return [
      {
        endDate: this.helpersAnalytics.createMomentInTz(),
        value: '7',
        type: 'days',
        text: this.intl.t('datetime.distance_in_words.x_days', { count: 7 }),
        isActive: true,
        isLonger: false
      },
      {
        endDate: this.helpersAnalytics.createMomentInTz(),
        value: '1',
        type: 'months',
        text: this.intl.t('datetime.distance_in_words.x_months', { count: 1 }),
        isActive: false,
        isLonger: false
      },
      {
        endDate: this.helpersAnalytics.createMomentInTz(),
        value: '3',
        type: 'months',
        text: this.intl.t('datetime.distance_in_words.x_months', { count: 3 }),
        isActive: false,
        isLonger: false
      },
      {
        endDate: this.helpersAnalytics.createMomentInTz(),
        value: '6',
        type: 'months',
        text: this.intl.t('datetime.distance_in_words.x_months', { count: 6 }),
        isActive: false,
        isLonger: true
      },
      {
        endDate: this.helpersAnalytics.createMomentInTz(),
        value: '12',
        type: 'months',
        text: this.intl.t('datetime.distance_in_words.x_years', { count: 1 }),
        isActive: false,
        isLonger: true
      }
    ];
  }

  get defaultTimePickerDurations(): TimePeriodDurations[] {
    return this.extendedTimePickerDurations.filter((value) => !value.isLonger);
  }

  get hasInstagramManageInsights(): boolean {
    return !!this.socialProfile?.hasInstagramManageInsights;
  }

  get hasMissingProfessionalPermission(): boolean {
    return Boolean(
      this.socialProfile?.isInstagram &&
        this.isProfessional &&
        this.hasProfessionalAccount &&
        !this.hasInstagramManageInsights
    );
  }

  get hasUnconnectedProfessional(): boolean {
    return this.isProfessional && !this.hasProfessionalAccount;
  }

  get hasConnectedProfessional(): boolean {
    return this.isProfessional && this.hasInstagramManageInsights && this.hasProfessionalAccount;
  }

  get userType(): 'owner' | 'team_member' {
    return this.userIsAccountOwner ? 'owner' : 'team_member';
  }

  get hasMissingTiktokScope(): boolean {
    return !this.socialProfile?.hasTiktokAnalyticsScope;
  }

  /**
   * Sets the given socialProfile as the current one and triggers socialProfileSet event
   *
   * Using ember-evented in place of using didUpdateAttrs to refresh components on Social Profile change
   * and to ensure that all related analytics services are in sync.
   *
   * @param socialProfile The socialProfile to be set
   * @returns The set socialProfile
   */
  async setSocialProfile(socialProfile: SocialProfileModel): Promise<SocialProfileModel> {
    this.socialProfile = socialProfile;
    this.trigger('socialProfileSet', socialProfile);
    await this.auth.setSocialProfile(socialProfile);
    return socialProfile;
  }

  /**
   * Creates a clicked-upgrade-intent event, logging info
   * about button location and whether click was towards
   * starting a trial or immediate purchase (for_trial)
   *
   * @param location
   * @returns void
   */
  trackUpgradeIntent(location: string): void {
    if (!this.socialProfile) {
      return;
    }
    const { industry, businessModel } = this.socialProfile;
    const forTrial = this.analyticsAccess.isEligibleForTrial;

    if (this.showUpgrade) {
      this.segment.track('clicked-upgrade-intent', {
        button_location: location,
        bus_model: businessModel,
        for_trial: forTrial,
        industry,
        source_page: 'analytics'
      });
    }
  }

  setLinkinbioPage = task(async (slug: string) => {
    const pages = this.auth.currentGroup.get('adaptedLinkinbioPages');
    this.linkinbioPage = await objectPromiseProxy(pages.find((page: LinkinbioPageModel) => page.get('slug') === slug));
  });
}

declare module '@ember/service' {
  interface Registry {
    analytics: AnalyticsService;
  }
}
