import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{! @glint-nocheck: not typesafe yet }}\n<div class=\"o--inputAction--light h--globalSearch__searchInput\" data-test-id=\"search-input\">\n  {{svg-jar \"search\" class=\"o--icon o--inputAction__icon--left\"}}\n\n  <input\n    aria-label={{t \"global_search_input.prompt\"}}\n    class=\"o--inputAction__input\"\n    disabled={{false}}\n    placeholder={{t \"global_search_input.prompt\"}}\n    type=\"text\"\n    {{did-insert this.autofocus}}\n    {{on \"input\" this.onChange}}\n    {{on \"blur\" @onBlur}}\n  />\n\n  {{#if @isRunning}}\n    <Shared::LoadingSpinner @additionalClass=\"h--globalSearch__loading\" />\n  {{/if}}\n</div>", {"contents":"{{! @glint-nocheck: not typesafe yet }}\n<div class=\"o--inputAction--light h--globalSearch__searchInput\" data-test-id=\"search-input\">\n  {{svg-jar \"search\" class=\"o--icon o--inputAction__icon--left\"}}\n\n  <input\n    aria-label={{t \"global_search_input.prompt\"}}\n    class=\"o--inputAction__input\"\n    disabled={{false}}\n    placeholder={{t \"global_search_input.prompt\"}}\n    type=\"text\"\n    {{did-insert this.autofocus}}\n    {{on \"input\" this.onChange}}\n    {{on \"blur\" @onBlur}}\n  />\n\n  {{#if @isRunning}}\n    <Shared::LoadingSpinner @additionalClass=\"h--globalSearch__loading\" />\n  {{/if}}\n</div>","moduleName":"later/components/global-search/search-input.hbs","parseOptions":{"srcName":"later/components/global-search/search-input.hbs"}});
import { action } from '@ember/object';
import { next } from '@ember/runloop';
import Component from '@glimmer/component';

import type { Task } from 'ember-concurrency';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface SearchInputComponentArgs {
  onChange: Task<void, [string]>;
  isRunning: boolean;
}

// eslint-disable-next-line ember/no-empty-glimmer-component-classes
export default class SearchInputComponent extends Component<SearchInputComponentArgs> {
  autofocus(element: HTMLInputElement): void {
    element?.focus();
    next(() => {
      if (element !== document.activeElement) {
        element?.focus();
      }
    });
  }

  @action
  onChange(event: Event): void {
    const { value } = event.target as HTMLInputElement;
    this.args.onChange.perform(value);
  }
}
