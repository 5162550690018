import { reads } from '@ember/object/computed';
/**
 * @module Services
 */
import Service, { inject as service } from '@ember/service';
import classic from 'ember-classic-decorator';
import RSVP from 'rsvp';

/**
 * Wrapper for default flash message options
 *
 * @class Alerts
 * @extends Service
 */
@classic
export default class FacebookAuthService extends Service {
  @service alerts;
  /**
   * @property auth
   * @type {AuthService}
   */
  @service auth;
  @service errors;
  @service('social/instagram-business') instagramBusiness;
  @service intl;
  @service store;

  @reads('auth.currentAccount.canFacebookGroups') canFacebookGroups;

  /**
   * Gets the Facebook access token for a user.
   *
   * @method loadFacebookSDK
   *
   * @return {Promise} Resolves if user is authenticated.
   */
  loadFacebookSDK() {
    return this.instagramBusiness.loadFacebookSDK();
  }

  /**
   * Gets the Facebook login response for a user.
   * This *MUST* be called immediately upon user action such as a click event or it will be blocked by ad-blockers
   *
   * @method authenticateFacebook
   *
   * @return {Promise} Resolves if user is authenticated.
   */
  authenticateFacebook(
    scopes = [
      'pages_manage_metadata',
      'pages_read_engagement',
      'pages_read_user_content',
      'pages_show_list',
      'business_management',
      'instagram_basic',
      'read_insights'
    ],
    auth_type = 'rerequest'
  ) {
    if (this.canFacebookGroups) {
      scopes.push('publish_to_groups');
    }
    const params = {
      auth_type,
      return_scopes: true,
      scope: scopes.join(',')
    };
    return new RSVP.Promise((resolve, reject) => {
      if (typeof FB === 'undefined') {
        return reject();
      }
      FB.login((response) => {
        this.errors.log(
          'authenticateFacebook response',
          { ...response, debug: response?.authResponse?.accessToken },
          'debug'
        );
        if (response.status === 'connected') {
          resolve(response.authResponse);
        } else {
          reject();
        }
      }, params);
    });
  }

  async addFacebookProfile(uid, token, scopes, group, identity) {
    const allProfiles = this.store.peekAll('socialProfile');
    const socialProfile =
      allProfiles.find((profile) => profile.uid === uid) || this.store.createRecord('socialProfile');
    socialProfile.set('socialIdentity', null);
    const socialProfileAttributes = {
      token,
      uid,
      group,
      account: this.get('auth.currentAccount'),
      profileType: 'facebook',
      permissionScope: scopes,
      facebookProfileType: 'page',
      isFbPage: true
    };

    if (identity?.id) {
      socialProfileAttributes.socialIdentity = identity;
    }

    socialProfile.setProperties(socialProfileAttributes);

    try {
      const savedProfile = await socialProfile.save();
      savedProfile.get('socialIdentity');
      this.alerts.success(
        this.intl.t('alerts.account.controllers.groups.facebook.added', { name: savedProfile.nickname })
      );
    } catch (adapterError) {
      socialProfile.rollbackAttributes();
      this.errors.handleAdapter(adapterError, socialProfile);
    }
  }
}
