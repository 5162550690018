import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

import type ScheduleService from 'later/services/schedule';

export default class DraftsRoute extends Route {
  @service declare schedule: ScheduleService;

  activate(): void {
    this.schedule.setSideLibraryBehavior('drafts');
  }

  deactivate(): void {
    this.schedule.setSideLibraryBehavior(null);
  }
}
