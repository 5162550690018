import { dependentKeyCompat } from '@ember/object/compat';
import Service, { inject as service } from '@ember/service';
import { isPresent, isEmpty } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import { intersection } from 'lodash';

import config from 'later/config/environment';
import { areStringsEqual } from 'later/utils/compare-strings';
import { fetch } from 'later/utils/fetch';
import loadScript from 'later/utils/load-script';
import redirect from 'shared/utils/redirect';

import type RouterService from '@ember/routing/router-service';
import type { TaskGenerator } from 'ember-concurrency';
import type IntlService from 'ember-intl/services/intl';
import type UserAgentService from 'ember-useragent/services/user-agent';
import type AccountModel from 'later/models/account';
import type SubscriptionModel from 'later/models/subscription';
import type SubscriptionPlan from 'later/models/subscription-plan';
import type UserModel from 'later/models/user';
import type AuthService from 'later/services/auth';
import type ErrorsService from 'later/services/errors';
import type SegmentService from 'later/services/segment';
import type SubscriptionsService from 'later/services/subscriptions';
import type { Maybe } from 'shared/types';

interface Alternative {
  id: string;
  type: string;
  title: string;
  subTitle: string;
  route?: string;
  routeModel?: Maybe<string>;
  link?: string;
  onClickTask?: (alternative: string) => TaskGenerator<void>;
}

interface ContextualResult {
  title: string;
  subCopy: string;
  mainReasons?: string[];
  subReasons?: string[];
  kb?: {
    text: string;
    link: string;
  };
  imageUrl?: string;
  videoUrl?: string;
  videoId?: string;
  alternatives?: Alternative[];
}

interface SubReason {
  id: string;
  text: string;
}

interface SubQuestion {
  title: string;
  reasons: SubReason[];
}

interface ReasonFormat {
  id: string;
  text: string;
  subQuestions: SubQuestion[];
}

interface Reasons {
  switchingService: ReasonFormat;
  notExpected: ReasonFormat;
  temporaryNeed: ReasonFormat;
  noUse: ReasonFormat;
  directionChange: ReasonFormat;
  accountIssue: ReasonFormat;
  covid19: ReasonFormat;
}

type ReasonOptions = keyof Reasons;

interface BrightbackPayload {
  valid?: boolean;
  url?: string;
  message?: string;
}

export default class CancellationService extends Service {
  @service declare auth: AuthService;
  @service declare errors: ErrorsService;
  @service declare intl: IntlService;
  @service declare router: RouterService;
  @service declare segment: SegmentService;
  @service declare subscriptions: SubscriptionsService;
  @service declare userAgent: UserAgentService;

  @tracked firstOtherReason: Maybe<string> = null;
  @tracked firstSubReason: Maybe<string> = null;
  @tracked secondOtherReason: Maybe<string> = null;
  @tracked secondSubReason: Maybe<string> = null;
  @tracked selectedMainReason: Maybe<ReasonOptions> = null;
  @tracked successfullyCancelled: Maybe<boolean>;
  @tracked isOpenToFeedback = '';
  @tracked isTimedOut: Maybe<boolean>;
  @tracked timeoutId: Maybe<NodeJS.Timeout>;

  get currentAccount(): AccountModel {
    return this.auth.currentAccount;
  }

  get currentUser(): UserModel {
    return this.auth.currentUserModel;
  }

  get isOnLegacyPlan(): boolean {
    return this.currentAccount.isOnLegacyPlan;
  }

  get isPaid(): Maybe<boolean> {
    return this.subscriptionPlan?.isPaid;
  }

  get planType(): Maybe<string> {
    return this.subscriptionPlan?.planType;
  }

  get subscription(): SubscriptionModel | Record<string, never> {
    return this.subscriptions.subscription;
  }

  get subscriptionPlan(): SubscriptionPlan | Record<string, never> {
    return this.subscriptions.subscriptionPlan;
  }

  get testGroupId(): Maybe<number> {
    return this.currentUser.testGroupId;
  }

  get canDowngrade(): boolean {
    return this.isOnLegacyPlan ? !areStringsEqual(this.planType, 'plus') : !areStringsEqual(this.planType, 'starter');
  }

  get canCancelPlan(): Maybe<boolean> {
    return this.isPaid && !this.currentAccount.hasActiveCancelledSubscription;
  }

  get selectedSubReasons(): Maybe<string>[] {
    return [this.firstSubReason, this.secondSubReason]?.filter((reason) => isPresent(reason));
  }

  set selectedSubReasons(value: Maybe<string>[]) {
    this.firstSubReason = value[0];
    this.secondSubReason = value[1];
  }

  get otherReasons(): Maybe<string>[] {
    return [this.firstOtherReason, this.secondOtherReason].filter((reason) => isPresent(reason));
  }

  set otherReasons(value: Maybe<string>[]) {
    this.firstOtherReason = value[0];
    this.secondOtherReason = value[1];
  }

  /**
   * Whether or not the final button in the flow to cancel their plan
   * is disabled. This is dependent on whether or not a user has answered
   * the final question of if they are open to giving additional feedback.
   */
  get disableFinish(): boolean {
    if (taskFor(this.cancelPlan).isRunning) {
      return true;
    }

    if (this.isTimedOut) {
      return false;
    }

    return isEmpty(this.isOpenToFeedback);
  }

  /**
   * An array of all the possible contextual results.
   */
  get contextualResults(): ContextualResult[] {
    return [
      {
        subReasons: ['too_complicated', 'not_user_friendly'],
        title: this.intl.t('account.change_plan.cancellation_feedback.result.title.learn'),
        subCopy: this.intl.t('account.change_plan.cancellation_feedback.result.sub_copy.training'),
        kb: {
          text: this.intl.t('account.change_plan.cancellation_feedback.result.action.explore_training'),
          link: 'https://later.com/product-training/'
        }
      },
      {
        subReasons: ['auto_publish_single', 'auto_publishing_ig'],
        title: this.intl.t('account.change_plan.cancellation_feedback.result.title.auto_publish'),
        subCopy: this.intl.t('account.change_plan.cancellation_feedback.result.sub_copy.auto_publish'),
        imageUrl:
          'https://embedwistia-a.akamaihd.net/deliveries/6332f1618f631666eec1139e1257d6e7.jpg?image_play_button_size=2x&amp;image_crop_resized=960x537&amp;image_play_button=1&amp;image_play_button_color=54bbffe0',
        videoUrl: 'https://fast.wistia.com/embed/medias/5o3jgi7nt8.jsonp',
        videoId: '5o3jgi7nt8',
        kb: {
          text: this.intl.t('account.change_plan.cancellation_feedback.result.action.learn_auto_publish'),
          link: 'https://help.later.com/hc/en-us/articles/360042772934-Enable-Auto-Publishing'
        }
      },
      {
        subReasons: ['setup_auto_publish', 'auto_publish_setup_issues'],
        title: this.intl.t('account.change_plan.cancellation_feedback.result.title.help'),
        subCopy: this.intl.t('account.change_plan.cancellation_feedback.result.sub_copy.auto_publish_setup'),
        kb: {
          text: this.intl.t('account.change_plan.cancellation_feedback.result.action.learn_auto_publish'),
          link: 'https://help.later.com/hc/en-us/articles/360042772934-Enable-Auto-Publishing'
        }
      },
      {
        subReasons: ['connect_profile'],
        title: this.intl.t('account.change_plan.cancellation_feedback.result.title.help'),
        subCopy: this.intl.t('account.change_plan.cancellation_feedback.result.sub_copy.connect_profile'),
        kb: {
          text: this.intl.t('account.change_plan.cancellation_feedback.result.action.connect_profile'),
          link: 'https://help.later.com/hc/articles/360043244733'
        }
      },
      {
        subReasons: ['price', 'budget'],
        title: this.intl.t('account.change_plan.cancellation_feedback.result.title.save'),
        subCopy: this.intl.t('account.change_plan.cancellation_feedback.result.sub_copy.explore_plans'),
        alternatives: [
          {
            id: 'interval',
            type: 'link-external',
            routeModel: this.planType,
            route: 'plans.plan',
            title: this.intl.t('account.change_plan.cancellation_feedback.alternatives.change_billing.title'),
            subTitle: this.intl.t('account.change_plan.cancellation_feedback.alternatives.change_billing.description')
          },
          {
            id: 'plan',
            type: 'link-external',
            route: 'plans',
            title: this.intl.t('account.change_plan.cancellation_feedback.alternatives.change_plan.title'),
            subTitle: this.intl.t('account.change_plan.cancellation_feedback.alternatives.change_plan.description')
          }
        ]
      },
      {
        mainReasons: ['notExpected'],
        subReasons: [
          'better_analytics',
          'better_hashtag_suggestions',
          'igtv',
          'link_shortener_tracker',
          'linkedin_posts',
          'other_analytics',
          'pinterest_features',
          'schedule_linkedin',
          'schedule_stories_mobile',
          'schedule_text',
          'schedule_tiktok',
          'stories_schedule_mobile',
          'text_only_posts'
        ],
        title: this.intl.t('account.change_plan.cancellation_feedback.result.title.thanks_trying'),
        subCopy: this.intl.t('account.change_plan.cancellation_feedback.result.sub_copy.ideas')
      },
      {
        mainReasons: ['temporaryNeed'],
        subReasons: ['extra_posts', 'analytics', 'campaign', 'promotion', 'busy_period'],
        title: this.intl.t('account.change_plan.cancellation_feedback.result.title.thanks_choosing'),
        subCopy: this.intl.t('account.change_plan.cancellation_feedback.result.sub_copy.paid_features')
      },
      {
        subReasons: ['no_login'],
        title: this.intl.t('account.change_plan.cancellation_feedback.result.title.fix'),
        subCopy: this.intl.t('account.change_plan.cancellation_feedback.result.sub_copy.help_options'),
        alternatives: [
          {
            id: 'reset_password',
            type: 'link',
            link: 'https://help.later.com/hc/en-us/articles/360042990433-Troubleshooting-Log-In-Issues-Resetting-Your-Later-Password',
            title: this.intl.t('account.change_plan.cancellation_feedback.alternatives.forgot_password.title'),
            subTitle: this.intl.t('account.change_plan.cancellation_feedback.alternatives.forgot_password.description')
          },
          {
            id: 'forgot_email',
            type: 'link-external',
            route: 'user.forgot_email',
            title: this.intl.t('account.change_plan.cancellation_feedback.alternatives.forgot_email.title'),
            subTitle: this.intl.t('account.change_plan.cancellation_feedback.alternatives.forgot_email.description')
          }
        ]
      },
      {
        mainReasons: ['switchingService'],
        subReasons: [
          'auto_publishing_carousel',
          'auto_publishing_creator',
          'auto_publish_multi',
          'auto_publishing_stories'
        ],
        title: this.intl.t('account.change_plan.cancellation_feedback.result.title.thanks_trying'),
        subCopy: this.intl.t('account.change_plan.cancellation_feedback.result.sub_copy.free_forever_use')
      },
      {
        subReasons: ['auto_publish_stories_multi'],
        title: this.intl.t('account.change_plan.cancellation_feedback.result.title.multi_stories'),
        subCopy: this.intl.t('account.change_plan.cancellation_feedback.result.sub_copy.multi_stories')
      },
      {
        mainReasons: ['directionChange', 'noUse'],
        subReasons: ['too_busy', 'forgot', 'will_not_post', 'no_post'],
        title: this.intl.t('account.change_plan.cancellation_feedback.result.title.here'),
        subCopy: this.intl.t('account.change_plan.cancellation_feedback.result.sub_copy.free_forever')
      },
      {
        subReasons: ['refresh_bug', 'connect_profile'],
        title: this.intl.t('account.change_plan.cancellation_feedback.result.title.help'),
        subCopy: this.intl.t('account.change_plan.cancellation_feedback.result.sub_copy.refresh'),
        kb: {
          text: this.intl.t('account.change_plan.cancellation_feedback.result.action.learn_refresh'),
          link: 'https://help.later.com/hc/en-us/articles/360043244093-Refresh-Your-Social-Profile-Connection'
        }
      },
      {
        subReasons: ['image_issues', 'high_res_images'],
        title: this.intl.t('account.change_plan.cancellation_feedback.result.title.images'),
        subCopy: this.intl.t('account.change_plan.cancellation_feedback.result.sub_copy.images'),
        kb: {
          text: this.intl.t('shared_phrases.learn_more'),
          link: 'https://help.later.com/hc/en-us/articles/360043361213-Uploading-Media-Format-Requirements'
        }
      },
      {
        mainReasons: ['accountIssue'],
        subReasons: ['auto_publish_stories_multi', 'experienced_bug', 'linkinbio_bug', 'notification_bug', 'post_bug'],
        title: this.intl.t('account.change_plan.cancellation_feedback.result.title.thanks_feedback'),
        subCopy: this.intl.t('account.change_plan.cancellation_feedback.result.sub_copy.help_articles')
      },
      {
        mainReasons: ['covid19'],
        subReasons: ['covid_19'],
        title: this.intl.t('account.change_plan.cancellation_feedback.result.title.here'),
        subCopy: this.subscriptions.hasActiveTrial
          ? this.intl.t('account.change_plan.cancellation_feedback.result.sub_copy.covid_19_trial')
          : this.intl.t('account.change_plan.cancellation_feedback.result.sub_copy.covid_19')
      }
    ];
  }

  /**
   * An object with the default data to use when giving the user alternatives to cancelling
   */
  get defaultAlternatives(): ContextualResult {
    return {
      title: this.intl.t('account.change_plan.cancellation_feedback.alternatives.default.title'),
      subCopy: this.intl.t('account.change_plan.cancellation_feedback.alternatives.default.description'),
      alternatives: [
        {
          id: 'interval',
          type: 'link-external',
          routeModel: this.planType,
          route: 'plans.plan',
          title: this.intl.t('account.change_plan.cancellation_feedback.alternatives.change_billing.title'),
          subTitle: this.intl.t('account.change_plan.cancellation_feedback.alternatives.change_billing.description'),
          onClickTask: this.trackCancellationAlternatives
        },
        {
          id: 'plan',
          type: 'link-external',
          route: 'plans',
          title: this.intl.t('account.change_plan.cancellation_feedback.alternatives.change_plan.title'),
          subTitle: this.intl.t('account.change_plan.cancellation_feedback.alternatives.change_plan.description'),
          onClickTask: this.trackCancellationAlternatives
        },
        {
          id: 'training',
          type: 'link',
          link: 'https://later.com/product-training/',
          title: this.intl.t('account.change_plan.cancellation_feedback.alternatives.get_help.title'),
          subTitle: this.intl.t('account.change_plan.cancellation_feedback.alternatives.get_help.description'),
          onClickTask: this.trackCancellationAlternatives
        }
      ]
    };
  }

  /**
   * An array of the main reasons for cancelling
   */
  @dependentKeyCompat
  get mainReasons(): ReasonFormat[] {
    return Object.values(this.reasons);
  }

  /**
   * The possible reasons a user can select for cancelling
   */
  get reasons(): Reasons {
    return {
      switchingService: {
        id: 'switchingService',
        text: this.intl.t('account.change_plan.cancellation_feedback.reasons.switching_service'),
        subQuestions: [
          {
            title: this.intl.t('account.change_plan.cancellation_feedback.sub_questions.main_reason_switching'),
            reasons: this._buildSubReasonsArray([
              'schedule_text',
              'schedule_linkedin',
              'auto_publish_single',
              'auto_publish_multi',
              'auto_publish_stories',
              'schedule_stories_mobile',
              'link_shortener_tracker',
              'other_analytics',
              'price',
              'other'
            ])
          },
          {
            title: this.intl.t('account.change_plan.cancellation_feedback.sub_questions.switch_service_which'),
            reasons: this._buildSubReasonsArray([
              'buffer',
              'hootsuite',
              'iconosquare',
              'linktree',
              'planoly',
              'tailwind',
              'native',
              'fb_creator_studio',
              'sprout_social',
              'other'
            ])
          }
        ]
      },
      notExpected: {
        id: 'notExpected',
        text: this.intl.t('account.change_plan.cancellation_feedback.reasons.not_expected'),
        subQuestions: [
          {
            title: this.intl.t('account.change_plan.cancellation_feedback.sub_questions.main_need'),
            reasons: this._buildSubReasonsArray([
              'auto_publishing_creator',
              'auto_publishing_ig',
              'auto_publishing_stories',
              'auto_publishing_carousel',
              'igtv',
              'text_only_posts',
              'linkedin_posts',
              'stories_scheduling_mobile',
              'high_res_images',
              'better_analytics',
              'better_hashtag_suggestions',
              'schedule_tiktok',
              'pinterest_features',
              'other'
            ])
          }
        ]
      },
      temporaryNeed: {
        id: 'temporaryNeed',
        text: this.intl.t('account.change_plan.cancellation_feedback.reasons.temp_posts'),
        subQuestions: [
          {
            title: this.intl.t('account.change_plan.cancellation_feedback.sub_questions.paid_need'),
            reasons: this._buildSubReasonsArray([
              'extra_posts',
              'carousel_posts',
              'additional_users',
              'additional_social_profiles',
              'schedule_stories',
              'analytics',
              'other'
            ])
          },
          {
            title: this.intl.t('account.change_plan.cancellation_feedback.sub_questions.why_temp'),
            reasons: this._buildSubReasonsArray(['campaign', 'promotion', 'event', 'busy_period', 'trial', 'other'])
          }
        ]
      },
      noUse: {
        id: 'noUse',
        text: this.intl.t('account.change_plan.cancellation_feedback.reasons.no_use'),
        subQuestions: [
          {
            title: this.intl.t('account.change_plan.cancellation_feedback.sub_questions.why_no_use'),
            reasons: this._buildSubReasonsArray([
              'too_busy',
              'forgot',
              'no_login',
              'no_post',
              'too_complicated',
              'not_user_friendly',
              'setup_auto_publish',
              'connect_profile',
              'other'
            ])
          }
        ]
      },
      directionChange: {
        id: 'directionChange',
        text: this.intl.t('account.change_plan.cancellation_feedback.reasons.direction_change'),
        subQuestions: [
          {
            title: this.intl.t('account.change_plan.cancellation_feedback.sub_questions.why_social_media'),
            reasons: this._buildSubReasonsArray([
              'covid_19',
              'will_not_post',
              'role_change',
              'social_media_hire',
              'budget',
              'business_end',
              'other'
            ])
          }
        ]
      },
      accountIssue: {
        id: 'accountIssue',
        text: this.intl.t('account.change_plan.cancellation_feedback.reasons.acc_issue'),
        subQuestions: [
          {
            title: this.intl.t('account.change_plan.cancellation_feedback.sub_questions.what_issue'),
            reasons: this._buildSubReasonsArray([
              'post_bug',
              'notification_bug',
              'auto_publish_setup_issues',
              'refresh_bug',
              'image_issues',
              'auto_publish_stories_multi',
              'experienced_bug',
              'linkinbio_bug',
              'other'
            ])
          }
        ]
      },
      covid19: {
        id: 'covid19',
        text: this.intl.t('account.change_plan.cancellation_feedback.reasons.covid_19'),
        subQuestions: []
      }
    };
  }

  /**
   * The result object based on the selected subreasons. If none of the subreasons have
   * a specified result, this returns the result based on the selected main reason.
   * This is null when no reason has been selected.
   */
  @dependentKeyCompat
  get result(): Maybe<ContextualResult> {
    const resultFromSubReason = this.contextualResults.find((result) =>
      isPresent(intersection(result.subReasons, this.selectedSubReasons))
    );
    const resultFromMainReason = this.contextualResults.find((result) =>
      result.mainReasons?.includes(this.selectedMainReason ?? '')
    );

    return resultFromSubReason || resultFromMainReason;
  }

  /**
   * The secondary questions to ask a user based on the selected main reason for cancelling
   */
  @dependentKeyCompat
  get subQuestions(): SubQuestion[] {
    return this.selectedMainReason ? this.reasons[this.selectedMainReason]?.subQuestions : [];
  }

  /**
   * On initialization of the service, set isOpenToFeedback
   * and all reasons properties to an initial empty value
   */
  constructor(...args: Record<string, unknown>[]) {
    super(...args);

    this._clearReasons();
  }

  /**
   * A task that deletes the account's current subscription.
   * Sets successfullyCancelled to true.
   */
  @task
  *cancelPlan(): TaskGenerator<void> {
    if (!this.subscription) {
      return;
    }

    const currentSubscriptionId = this.subscription.id;
    const url = `/api/v2/subscriptions/${currentSubscriptionId}`;
    const mainReason = this.reasons[this.selectedMainReason as ReasonOptions].text;
    const subReasons = this.selectedSubReasons.map((reasonId) =>
      this.intl.t(`account.change_plan.cancellation_feedback.sub_reasons.${reasonId}`)
    );

    try {
      if (isPresent(currentSubscriptionId)) {
        yield fetch(url, {
          method: 'DELETE',
          body: JSON.stringify({
            id: currentSubscriptionId,
            cancellation_reason: mainReason,
            cancellation_subreason: subReasons.join(),
            other_sub_reason: this.otherReasons.join(),
            discuss_reason: areStringsEqual(this.isOpenToFeedback, 'Yes')
          })
        });

        this.successfullyCancelled = true;
      }
    } catch (error) {
      this.errors.handleAjax(error);
    }
  }

  /**
   * A task that loads the Brightback script and sends config and user data to
   * the window.Brightback.handleDataPromise method
   * That method returns an object with keys:
   * valid {Boolean}: whether Brightback has sufficient data
   * url {String}: url to which the user may be redirected to cancel their plan
   */
  @task
  *initializeBrightback(): TaskGenerator<Maybe<BrightbackPayload>> {
    try {
      yield loadScript('https://app.brightback.com/js/current/brightback.js');
      const userAndAccountTraits = yield taskFor(this.segment.fetchUserAndAccountTraits).perform();
      if (!userAndAccountTraits) {
        return;
      }
      const returnUrl = this.userAgent.device.isMobile
        ? window.location.origin + '/account/overview'
        : window.location.origin + '/account/subscription';

      const brightbackConfig = {
        app_id: config.APP.brightbackAppId,
        email: this.currentAccount.canonicalBillingEmail,
        full_name: this.currentUser.name,
        save_return_url: returnUrl,
        cancel_confirmation_url: returnUrl,
        subscription_id: userAndAccountTraits.user.current_plan_stripe_subscription_id,
        account: {
          internal_id: this.currentUser.id
        },
        custom: {
          later_subscription_id: this.subscription?.id,
          segment: userAndAccountTraits
        }
      };
      return yield window.Brightback?.handleDataPromise(brightbackConfig);
    } catch {
      return;
    }
  }

  /**
   * A task to run when the user chooses to exit the cancellation flow and keep their plan.
   * Clears all reasons. Also, tracks the stayed-with-later event.
   */
  @task
  *stayWithPlan(step: string): TaskGenerator<void> {
    if (!this.subscription) {
      return;
    }

    const cancellationStats = yield fetch('/api/v2/cancellation_stats.json');

    this.segment.track(
      'stayed-with-later',
      {
        id: this.subscription.id,
        cancellation_reason: this.selectedMainReason ?? null,
        cancellation_subreason: this.selectedSubReasons.join(),
        other_sub_reason: this.otherReasons.join(),
        discuss_reason: isPresent(this.isOpenToFeedback) ? areStringsEqual(this.isOpenToFeedback, 'Yes') : null,
        paid_plan: this.subscription.name,
        monthly_spend: this.subscription.monthlyRate,
        days_on_plan: cancellationStats.days_on_plan,
        num_posts_sched: cancellationStats.num_posts_scheduled,
        num_posts_posted: cancellationStats.num_posts_posted,
        step,
        location: 'Later'
      },
      {
        follower_count: 'currentAccount.mainProfile.followedBy',
        main_profile_industry: 'currentAccount.mainProfile.industry',
        main_profile_business_model: 'currentAccount.mainProfile.businessModel'
      }
    );

    this._clearReasons();
  }

  /**
   * A task that tracks `clicked-cancellation-alternative`
   * with the given alternative
   */
  @task
  *trackCancellationAlternatives(alternativeClicked: string): TaskGenerator<void> {
    if (!this.subscription) {
      return;
    }

    const cancellationStats = yield fetch('/api/v2/cancellation_stats.json');

    this.segment.track(
      'clicked-cancellation-alternative',
      {
        alternative_option: alternativeClicked,
        paid_plan: this.subscription.name,
        monthly_spend: this.subscription.monthlyRate,
        days_on_plan: cancellationStats.days_on_plan,
        num_posts_sched: cancellationStats.num_posts_scheduled,
        num_posts_posted: cancellationStats.num_posts_posted
      },
      {
        follower_count: 'currentAccount.mainProfile.followedBy',
        main_profile_industry: 'currentAccount.mainProfile.industry',
        main_profile_business_model: 'currentAccount.mainProfile.businessModel'
      }
    );
  }

  /**
   * Sets the last reason selected and if applicable,
   * the corresponding Other response to null
   */
  clearLastReason(): void {
    if (this.secondSubReason) {
      this.secondSubReason = null;
      this.secondOtherReason = null;
    } else if (this.firstSubReason) {
      this.firstSubReason = null;
      this.firstOtherReason = null;
    } else {
      this.selectedMainReason = null;
    }
  }

  /**
   * Clears any previously selected reasons and sets selectedMainReason with the given reason
   */
  selectMainReason(reason: ReasonOptions): void {
    this._clearReasons();

    this.selectedMainReason = reason;
  }

  /**
   * Sets either the first subreason or second subreason
   */
  selectSubReason(reason: string): void {
    if (this.firstSubReason || this.firstOtherReason) {
      this.secondSubReason = reason;
    } else {
      this.firstSubReason = reason;
    }
  }

  /**
   * Sets either the first other reason or second other reason
   */
  submitOtherReason(response: string): void {
    if (this.firstOtherReason || (this.firstSubReason && this.firstSubReason !== 'other')) {
      this.secondOtherReason = response;
    } else {
      this.firstOtherReason = response;
    }
  }

  /**
   * Initializes cancellation reasons in old cancellation flow
   * from before Brightback implementation.
   */
  initializeCancelPlanWizard(): void {
    this._clearReasons();
    this.successfullyCancelled = false;
  }

  /**.
   * Calls initializeBrightback task and redirects to Brightback the return payload is valid.
   * Otherwise, it initializes the fallback cancellation wizard
   */
  @task
  *startCancellationFlow(location: string): TaskGenerator<void> {
    yield taskFor(this.trackCancelIntent).perform(location);

    try {
      const brightbackPayload = yield taskFor(this.initializeBrightback).perform();
      if (!brightbackPayload?.valid) {
        throw new Error('Redirect to Brightback failed due to invalid payload' + brightbackPayload.message);
      }
      redirect(brightbackPayload.url);
    } catch (error) {
      this.errors.log(error);
      return this.startCancellationFallbackFlow(location);
    }
  }

  /**
   * A task that tracks `cancelled-paid-account-intent`.
   * Makes an api call to get account stats to send with the event.
   */
  @task
  *trackCancelIntent(page: string): TaskGenerator<void> {
    const { name: planName, monthlyRate } = this.subscription as SubscriptionModel;
    const response = yield fetch('/api/v2/cancellation_stats.json');

    this.segment.track(
      'cancelled-paid-account-intent',
      {
        page,
        paid_plan: planName,
        monthly_spend: monthlyRate,
        days_on_plan: response.days_on_plan,
        num_posts_sched: response.num_posts_scheduled,
        num_posts_posted: response.num_posts_posted
      },
      {
        follower_count: 'currentAccount.mainProfile.followedBy',
        main_profile_industry: 'currentAccount.mainProfile.industry',
        main_profile_business_model: 'currentAccount.mainProfile.businessModel'
      }
    );
  }

  /**
   * Transitions to our old cancellation flow from before Brightback implementation
   */
  startCancellationFallbackFlow(location: string): void {
    this.router.transitionTo('account.subscription.cancel', { queryParams: { location } });
  }

  /**
   * Starts a ten second timeout to enable the cancel button if no choice is made
   */
  startTimeout(): void {
    const TEN_SECONDS = 10000;
    this.isTimedOut = false;

    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }

    this.timeoutId = setTimeout(() => {
      this.isTimedOut = true;
    }, TEN_SECONDS);
  }

  /**
   * Given an array of ids, creates an array of objects with an id and sub reasons text
   */
  _buildSubReasonsArray(reasonIds: string[]): SubReason[] {
    return reasonIds.map((id: string) => ({
      id,
      text: this.intl.t(`account.change_plan.cancellation_feedback.sub_reasons.${id}`)
    }));
  }

  /**
   * Sets the selected main reason to null and clears the
   * arrays for selected sub reasons and Other responses
   */
  _clearReasons(): void {
    this.selectedMainReason = null;
    this.firstSubReason = null;
    this.firstOtherReason = null;
    this.secondSubReason = null;
    this.secondOtherReason = null;
    this.isOpenToFeedback = '';
  }
}

declare module '@ember/service' {
  interface Registry {
    cancellation: CancellationService;
  }
}
