import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { TrackedArray } from 'tracked-built-ins';

import type RouterService from '@ember/routing/router-service';
import type MediaItemModel from 'later/models/media-item';
import type UnsplashImage from 'later/models/unsplash-image';
import type { Maybe } from 'shared/types';

type NavigableType = MediaItemModel | UnsplashImage;

/**
 * Media navigation service to keep track of which media item
 * the user is currently viewing
 */
export default class MediaNavigationService extends Service {
  @service declare router: RouterService;

  @tracked currentMediaItem: Maybe<NavigableType>;
  @tracked mediaItems = new TrackedArray<NavigableType>();
  @tracked narrowArrows = false;

  /**
   * Returns whether the current media item is the first item
   * If the media cannot be found return true
   */
  get firstMediaItem(): boolean {
    return this.index === 0 || this.index === -1;
  }

  /**
   * Returns the current index of the media item in the list by
   * loosely comparing the id property
   */
  get index(): number {
    return this.mediaItems?.findIndex((item) => item.id == this.currentMediaItem?.id);
  }

  /**
   * Returns whether the current media item is the last item
   * If the media cannot be found return true
   */
  get lastMediaItem(): boolean {
    return this.index === this.mediaItems?.length - 1 || this.index === -1;
  }

  /**
   * Whether the media modal needs arrows closer to fix spacing
   * inconsistency for media library, side library and preview-grid
   */
  get needsNarrowArrows(): boolean {
    return this.narrowArrows;
  }

  clearMediaItems(): void {
    this.mediaItems = new TrackedArray<NavigableType>();
  }

  navigate(route: string, isPositive = true): void {
    const index = this.mediaItems.findIndex((item) => item.id === this.currentMediaItem?.id);

    if (index === undefined || (index === 0 && !isPositive) || (index === this.mediaItems.length - 1 && isPositive)) {
      return;
    }

    const navigateTo = this.mediaItems[index + (isPositive ? 1 : -1)];
    this.router.transitionTo(route, navigateTo);
  }

  setCurrentMediaItem(mediaItem: NavigableType): void {
    this.currentMediaItem = mediaItem;
  }

  setMediaItems(mediaItems: NavigableType[]): void {
    this.mediaItems = new TrackedArray(mediaItems);
  }

  setNarrowArrows(isNarrowArrows: boolean): void {
    this.narrowArrows = isNarrowArrows;
  }
}

declare module '@ember/service' {
  interface Registry {
    'media-navigation': MediaNavigationService;
  }
}
