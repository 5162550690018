import Service, { inject as service } from '@ember/service';
import { isEmpty, isPresent, isNone } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { camelToSnake } from '@latermedia/ember-later-analytics/utils';
import { dropTask } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import { isEqual } from 'lodash';
import { TrackedArray, TrackedObject } from 'tracked-built-ins';

import { assembleAddress } from 'later/utils/address';
import {
  ADDRESS_FIELD_KEYS,
  ADDRESS_COUNTRIES_REQUIRING_POSTAL_CODE,
  ADDRESS_COUNTRIES_REQUIRING_STATE,
  ADDRESS_COUNTRIES_TAXABLE
} from 'later/utils/constants';
import { fetch } from 'later/utils/fetch';
import { objectMap } from 'later/utils/object-methods';
import handleSettingsError from 'settings/utils/handle-settings-error';

import type { TaskGenerator } from 'ember-concurrency';
import type IntlService from 'ember-intl/services/intl';
import type AccountModel from 'later/models/account';
import type AlertsService from 'later/services/alerts';
import type AuthService from 'later/services/auth';
import type SubscriptionsService from 'later/services/subscriptions';
import type { SuggestedChangeItem, AddressFields } from 'later/utils/address';
import type { Maybe, UntypedService } from 'shared/types';

export default class AddressService extends Service {
  @service declare addressValidation: UntypedService;
  @service declare alerts: AlertsService;
  @service declare auth: AuthService;
  @service declare errors: UntypedService;
  @service declare intl: IntlService;
  @service declare segment: UntypedService;
  @service declare subscriptions: SubscriptionsService;

  DEFAULT_LOADING_STATE_THRESHOLD_IN_MS = 3000;

  /**
   * Set when creating, updating or retrieving a credit card from stipe,
   * Used for determining if the user is in a taxable country.
   *
   */
  @tracked cardCountry: Maybe<string> = null;

  /**
   * True when we could not find any address or could only find a partial address
   * match during validation.
   *
   * False when we found an address, or we are currently editing a new address.
   */
  @tracked isUnsavedAddressNotFound = false;

  @tracked suggestedChanges: TrackedArray<SuggestedChangeItem> = new TrackedArray([]);

  /**
   * Holds saved address values.
   * Can be in two states:
   *  - holds a validated, saved address from the BE.
   *  - has empty values for every key when no saved address on the BE.
   * @property savedAddressFields
   */
  @tracked savedAddressFields: AddressFields = new TrackedObject();

  /**
   * Representation of an address that has not been saved or validated
   * used in the address form and in determining unsaved changes
   */
  @tracked unsavedAddressFields: AddressFields = new TrackedObject();

  /**
   * The check for if a user can enter a new address or edit an existing
   */
  get addressCollectionEnabled(): boolean {
    return !this.subscriptions.isMobileSubscription;
  }

  /**
   * The check for if a user can enter a new address or edit an existing address in settings
   * Includes users who have an address, or a subscription that stripe can charge tax for.
   */
  get addressCollectionEnabledInSettings(): boolean {
    if (!this.currentAccount.hasActiveSubscription) {
      return false;
    }

    // Note: based on credit address if present, otherwise the credit card zip code.
    const canChargeTax = this.currentAccount.stripeAutoSalesTaxSupported;

    return (
      this.addressCollectionEnabled &&
      isPresent(this.currentAccount.stripeCustomerId) &&
      (this.isSavedAddressValid || canChargeTax)
    );
  }

  get canAttemptSave(): boolean {
    return (
      !this.isUnsavedAddressNotFound &&
      !this.hasOutstandingSuggestions &&
      !taskFor(this.validateAddress).isRunning &&
      !taskFor(this.save).isRunning
    );
  }

  get isFullAddressRequired(): boolean {
    if (this.isSavedAddressValid) {
      return true;
    }

    return (
      ADDRESS_COUNTRIES_TAXABLE.includes(this.unsavedAddressFields?.country ?? '') ||
      ADDRESS_COUNTRIES_TAXABLE.includes(this.cardCountry ?? '')
    );
  }

  get completedAddress(): string {
    return `${this.savedAddressFields.line2 || ''} ${this.savedAddressFields.line1}, ${this.savedAddressFields.city}, ${
      this.savedAddressFields.state
    } ${this.savedAddressFields.postalCode}`;
  }

  get countryCode(): Maybe<string> {
    return this.savedAddressFields.country || this.cardCountry;
  }

  get currentAccount(): AccountModel {
    return this.auth.currentAccount;
  }

  get hasOutstandingSuggestions(): boolean {
    return !isEmpty(this.suggestedChanges);
  }

  get hasUnsavedChanges(): boolean {
    return !isEqual(this.unsavedAddressFields, this.savedAddressFields);
  }

  get isSavedAddressValid(): boolean {
    return this.hasRequiredFields(this.savedAddressFields);
  }

  /**
   * Address Banner should show to users who are in a region we are collecting tax,
   * but are not yet being taxed on their current subscripiton.
   * When a tax region is enabled in stripe, stripeAutoSalesTaxSupported will be updated
   * based on the current information we have for an account(stripe card or previously existing address)
   *
   */
  get showAddressBanner(): boolean {
    return Boolean(
      this.auth.currentUserModel?.isAccountOwner &&
        this.auth.currentAccount?.hasActiveSubscription &&
        this.auth.currentAccount?.stripeAutoSalesTaxSupported &&
        !this.subscriptions.subscription?.automaticTaxEnabled
    );
  }

  setSelectedCountry(country: string): void {
    this.unsavedAddressFields.country = country;
  }

  /**
   * For Automatically setting country to match card in settings and checkout
   * Country is provided from Stripe Credit Card. The country is saved on the service in order
   * to determine if the full address is required for tax calculation, the country is then set on
   * the form address fields if no existing address exists.
   *
   */
  setSelectedCountryIfNew(country: Maybe<string>): void {
    this.cardCountry = country;
    if (country && !this.isSavedAddressValid) {
      this.unsavedAddressFields.country = country;
    }
  }

  setSuggestion(addressField: keyof AddressFields, suggestedValue: string): void {
    this.unsavedAddressFields[addressField] = suggestedValue;
  }

  @dropTask
  *save(unsavedAddress: AddressFields): TaskGenerator<void> {
    try {
      yield fetch('/api/v2/addresses.json', {
        method: 'POST',
        body: {
          // TODO: Remove saving all addresses as verified when releasing https://github.com/Latermedia/ember-later/pull/13531
          address: objectMap({ ...unsavedAddress, verified: true }, ([camelCaseKey, value]: [string, string]) => [
            camelToSnake(camelCaseKey),
            value
          ])
        }
      });
      this.savedAddressFields = { ...unsavedAddress };
    } catch (error) {
      this.errors.log('Failed to save address', error);
      throw handleSettingsError(error, 'account.subscription.billing.address', this.intl);
    }
  }

  @dropTask
  *validateAddress(unsavedAddress: AddressFields): TaskGenerator<boolean | void> {
    if (this.isSavedAddressValid && isEqual(unsavedAddress, this.savedAddressFields)) {
      return true;
    }

    if (!this.hasRequiredFields(unsavedAddress)) {
      return false;
    }

    try {
      const isAccurateAddressRequired = this.isAccurateAddressRequired(unsavedAddress.country);
      const validationSuggestions = yield this.addressValidation.validate.perform(
        unsavedAddress,
        isAccurateAddressRequired
      );

      //Note: validationSuggestions will be null if not found.
      this.isUnsavedAddressNotFound = isNone(validationSuggestions);
      if (this.isUnsavedAddressNotFound) {
        return false;
      }

      this.suggestedChanges.addObjects(validationSuggestions);

      return this.isUnsavedAddressValid(unsavedAddress);
    } catch (error) {
      this.errors.log('Failed to validate address', error);
      throw handleSettingsError(error, 'account.subscription.billing.address', this.intl);
    }
  }

  /**
   * retrieves users saved address, if one exists
   * then sets tracked property denoting if address has previously been completed
   * @returns an object of address fields
   */
  @dropTask
  *setup(): TaskGenerator<void> {
    const { addresses } = yield fetch('/api/v2/addresses.json');
    this.savedAddressFields = assembleAddress(addresses[0]);
    this.resetForm();
  }

  clearSuggestion(addressField: keyof AddressFields): void {
    const index = this.suggestedChanges.findIndex((item) => item.key === addressField);
    this.suggestedChanges.removeAt(index);
  }

  /**
   * @param countryCode will be empty when creating an address for the first time.
   */
  getRequiredFields(countryCode = ''): Array<keyof AddressFields> {
    const DEFAULT_REQUIRED_FIELDS = [ADDRESS_FIELD_KEYS.CITY, ADDRESS_FIELD_KEYS.COUNTRY, ADDRESS_FIELD_KEYS.LINE_ONE];

    const ifRequiresPostalCode = ADDRESS_COUNTRIES_REQUIRING_POSTAL_CODE.includes(countryCode);
    const ifRequiresState = ADDRESS_COUNTRIES_REQUIRING_STATE.includes(countryCode);

    // Note: Super strict mode.
    if (countryCode === '' || (ifRequiresPostalCode && ifRequiresState)) {
      return [...DEFAULT_REQUIRED_FIELDS, ADDRESS_FIELD_KEYS.POSTAL_CODE, ADDRESS_FIELD_KEYS.STATE];
    }

    // Note: Medium Strict mode. UK Addresses don't require state.
    // there is no need for checking for only ifRequiresState at this time.
    if (ifRequiresPostalCode) {
      return [...DEFAULT_REQUIRED_FIELDS, ADDRESS_FIELD_KEYS.POSTAL_CODE];
    }

    // Note: Super lenient mode. Ensures just the basic info is collected.
    return DEFAULT_REQUIRED_FIELDS;
  }

  hasRequiredFields(addressFields: AddressFields): boolean {
    return isEmpty(
      this.getRequiredFields(addressFields.country).filter(
        (field: keyof AddressFields) => !isPresent(addressFields?.[field])
      )
    );
  }

  /**
   * Countries that we want to make sure we collect a full address.
   * This is based on what countries we will want to collect tax from.
   * Some countries not in this list are smaller and do not have a state/province
   * or postal code in their address.
   * @param countryCode will be empty when creating an address for the first time.
   */
  isAccurateAddressRequired(countryCode = ''): boolean {
    return (
      countryCode === '' ||
      ADDRESS_COUNTRIES_REQUIRING_POSTAL_CODE.includes(countryCode) ||
      ADDRESS_COUNTRIES_REQUIRING_STATE.includes(countryCode)
    );
  }

  isUnsavedAddressValid(unsavedAddress: AddressFields): boolean {
    return !this.isUnsavedAddressNotFound && !this.hasOutstandingSuggestions && this.hasRequiredFields(unsavedAddress);
  }

  /**
   * Ensure that users returning to editing a form they are not presented with old state.
   *
   */
  resetForm(): void {
    this.resetValidation();
    this.unsavedAddressFields = new TrackedObject({ ...this.savedAddressFields });
    this.suggestedChanges = new TrackedArray([]);
    this.cardCountry = null;
  }

  resetValidation(): void {
    this.isUnsavedAddressNotFound = false;
  }

  /**
   * A user has started editing an address
   *
   * @param addressFields The initial state of the address Can be two states:
   *  - has empty values for every key when no saved address on the BE.
   *  - has saved values for some keys if they are creating an address for the first time.
   * @param location where the event happened. Either checkout or billing
   */
  trackViewedForm(addressFields: AddressFields, location: string): void {
    this.segment.track('address-viewed-form', {
      address_fields: addressFields,
      is_new_address: !this.isSavedAddressValid,
      location
    });
  }

  /**
   * User started to save their address (clicked 'save')
   *
   * @param addressFields unsaved address from form
   * @param location where the event happened. Either checkout or billing
   */
  trackClickedSave(addressFields: AddressFields, location: string): void {
    this.segment.track('address-clicked-save', {
      address_fields: addressFields,
      location
    });
  }

  /**
   * @param addressFields newly saved address from form
   * @param location where the event happened. Either checkout or billing
   */
  trackSavedSuccessfully(addressFields: AddressFields, location: string): void {
    this.segment.track('address-saved-successfully', {
      address_fields: addressFields,
      location
    });
  }

  /**
   * User started to save address, and the spinner appeared because it is taking too long to load. Likely throttling happening.
   *
   * @param addressFields unsaved address from form
   * @param location where the event happened. Only 'checkout' used for now.
   */
  trackViewedLoadingSpinner(addressFields: AddressFields, location: string): void {
    this.segment.track('address-viewed-loading-spinner', {
      address_fields: addressFields,
      location
    });
  }

  /**
   * After user started to save their address they saw a server error.
   * Examples: 5xx or more likely 429 (throttling)
   *
   * Note: This will only capture the last error as fetch will retry a few times.
   * The first 1-2 throttling errors can be looked up in datadog.
   *
   * @param addressFields unsaved address from form
   * @param location where the event happened. Either checkout or billing
   */
  trackViewedServerError(addressFields: AddressFields, location: string): void {
    this.segment.track('address-viewed-server-error', {
      address_fields: addressFields,
      location
    });
  }

  /**
   * After user started to save their address they saw validation error with some fields or address not found.
   *
   * Extra Segment payload:
   * - fieldsEmpty: Example [line2,postalCode]
   * - fieldsWithSuggestions: Example [state,postalCode]
   * - notFound: true if the not found error ends up displaying to user
   *
   * @param addressFields unsaved address from form
   * @param location where the event happened. Either checkout or billing
   */
  trackViewedValidationError(addressFields: AddressFields, location: string): void {
    this.segment.track('address-viewed-validation-error', {
      address_fields: addressFields,
      address_not_found: this.isUnsavedAddressNotFound,
      fields_with_suggestions: this.suggestedChanges.map((item) => item.key),
      location,
      missing_required_fields: !this.hasRequiredFields(addressFields)
    });
  }
}

declare module '@ember/service' {
  interface Registry {
    address: AddressService;
  }
}
