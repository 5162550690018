import { computed } from '@ember/object';
import { reads, filterBy } from '@ember/object/computed';
import Service, { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import RSVP from 'rsvp';

import { CredentialsNotFoundError } from 'later/errors/credentials';
import { arrayDiff } from 'later/utils/array-methods';
import { fetch } from 'later/utils/fetch';
import graphCall from 'later/utils/graph-call';
import objectPromiseProxy from 'later/utils/object-promise-proxy';

const instagramAdditionalScopes = [
  'business_management',
  'instagram_basic',
  'instagram_content_publish',
  'instagram_manage_comments',
  'instagram_manage_insights',
  'pages_read_engagement',
  'pages_read_user_content',
  'pages_show_list'
];

/**
 * @class InstagramBusinessService
 * @extends Service
 */
export default class InstagramBusinessService extends Service {
  @service auth;
  @service credentials;
  @service credentialStatus;
  @service errors;
  @service('social/facebook-graph') facebook;
  @service alerts;
  @service intl;
  @service instagram;
  @service localStorageManager;
  @service router;
  @service segment;
  @service segmentEvents;
  @service store;

  @tracked _isConnectingTagProducts = false;

  @reads('auth.currentUserModel') currentUserModel;
  @reads('auth.currentSocialProfile') socialProfile;
  @reads('socialProfile.needsRefresh') needsRefresh;
  @reads('socialProfile.hasUnconnectedProfessional') hasUnconnectedProfessional;

  @filterBy('auth.socialProfiles', 'isFacebook', true) facebookProfiles;

  /**
   * The scopes that we need from a professional instagram profile
   *
   * @property potentialScopes
   *
   */
  get potentialScopes() {
    const scopes = [
      ...instagramAdditionalScopes,
      'pages_manage_posts',
      'pages_manage_metadata',
      'read_insights',
      'instagram_shopping_tag_products',
      'catalog_management'
    ];
    return scopes;
  }

  get isConnectingTagProducts() {
    return this._isConnectingTagProducts;
  }

  set isConnectingTagProducts(value) {
    this._isConnectingTagProducts = value;
  }

  get requiredInstagramAdditionalScopes() {
    const scopes = [...instagramAdditionalScopes];
    if (this.isConnectingTagProducts) {
      scopes.push('instagram_shopping_tag_products', 'catalog_management');
    }
    return scopes;
  }

  @computed('instagram.errorCode')
  get needsAPI() {
    return this.instagram.errorCode === 10;
  }

  get needsProfessional() {
    return this.socialProfile?.isInstagram && !this.socialProfile?.isProfessional;
  }

  @computed('instagram.errorCode')
  get expiredProfessionalToken() {
    if ([190, 803, 100, 102].includes(this.instagram.errorCode)) {
      return true;
    }

    return null;
  }

  get needsFacebookUserToken() {
    return !this.currentUserModel.facebookToken && this.socialProfile.isProfessional;
  }

  /**
   * Gets a user's Facebook credentials and
   * checks that the correct permissions are granted
   *
   * @method getFacebookCredentials
   *
   * @return {Promise} Resolves with the FB auth response and rejects with an object of the denied permissions
   */
  getFacebookCredentials(auth_type = 'rerequest') {
    return new RSVP.Promise((resolve, reject) => {
      try {
        const params = {
          return_scopes: true,
          scope: this.potentialScopes,
          auth_type
        };

        FB.getLoginStatus((response) => {
          if (response.status === 'connected') {
            this._updateAndReturnCredentials(response, resolve, reject);
          } else if (response.status === 'not_authorized') {
            FB.login((response) => this._updateAndReturnCredentials(response, resolve, reject), params);
          } else {
            FB.login((response) => this._updateAndReturnCredentials(response, resolve, reject), params);
          }
        }, true); // skip cache and force round-trip request
      } catch (error) {
        reject(this.intl.t('alerts.account.controllers.groups.facebook.cant_load_sdk'));
      }
    });
  }

  /**
   * refresh credentials for a FacebookProfile
   *
   * @method refreshFacebook
   *
   * @return {Promise} Resolves with the FB auth response and rejects with an object of the denied permissions
   */

  refreshFacebook(socialProfile) {
    // update *all* facebook profiles available to user -iMack
    return new RSVP.Promise((resolve, reject) => {
      this.getFacebookCredentials()
        .then((authResponse) => {
          FB.api('/me?fields=picture,name', async (selfResponse) => {
            const facebookProfile = this.facebookProfiles.findBy('uid', selfResponse.id);
            const loginName = selfResponse.name;
            const updatedPages = await this._updateFacebookPages.perform();

            if (isPresent(facebookProfile)) {
              this.updateSocialProfile.perform(facebookProfile, authResponse.accessToken, this.potentialScopes);
            }
            if (!facebookProfile && !updatedPages.length) {
              reject(
                this.intl.t('account.controllers.groups.facebook.refresh_error', {
                  social_profile: socialProfile.nickname,
                  loginName
                })
              );
              return;
            }
            resolve(socialProfile);
          });
        })
        .catch((error) => reject(error));
    });
  }

  /**
   * Gets picture and name of currently logged in Facebook user
   *
   * @method fetchFacebookSelf
   *
   * @return {UserModel} Facebook users profile
   */
  fetchFacebookSelf() {
    return this.simpleGraphRequest('/me?fields=picture,name');
  }

  @task(function* (socialProfileProxy) {
    try {
      const socialProfile = yield objectPromiseProxy(socialProfileProxy);
      if (!socialProfile) {
        throw new Error('No social profile provided to getConnectedPages');
      }
      const authResponse = yield this.getFacebookCredentials();
      const facebookUser = this.fetchFacebookSelf();
      const { connectedPages, pages } = yield this.getInstagramBusinessAccounts.perform(socialProfile);
      return { connectedPages, pages, authResponse, facebookUser };
    } catch (error) {
      this.errors.log(error);
      return {};
    }
  })
  getConnectedPages;

  @task(function* (socialProfileProxy) {
    const socialProfile = yield objectPromiseProxy(socialProfileProxy);
    const socialProfileId = Number(socialProfile.id);
    const url = `/api/v2/social_profiles/${socialProfileId}/refresh_facebook_token`;
    const authResponse = yield this.getFacebookCredentials();
    try {
      const response = yield fetch(url, {
        method: 'POST',
        headers: { Accept: 'application/json', 'Content-type': 'application/json' },
        body: JSON.stringify({
          social_profile_id: socialProfileId,
          facebook_token: authResponse.accessToken
        })
      });
      this.store.push(this.store.normalize('social-profile', response.social_profiles.findBy('id', socialProfileId)));
      yield this.currentUserModel.reload();
      this.credentialStatus.checkBrokenProfiles();
      return response;
    } catch (error) {
      this.segment.track('refresh_facebook_token_failure', { social_profile: socialProfileId });
      this.errors.log('refresh_facebook_token failure', { socialProfileId, debug: authResponse.accessToken });
      throw error;
    }
  })
  refreshBusiness;

  /**
   * The *NEW* Endpoint for refreshing Instagram Business Accounts
   *
   * @method refreshBusinessAccountEndpoint
   * @param socialProfileProxy socialProfile; possibly unresolved as a proxy
   *
   * @return {pages} List of Facebook pages
   */

  refreshBusinessAccountEndpoint(socialProfileProxy, page) {
    return new RSVP.Promise(async (resolve, reject) => {
      const socialProfile = await objectPromiseProxy(socialProfileProxy);
      if (!socialProfile.hasProfessionalAccount) {
        try {
          await this._saveInstagramBusinessAccount(socialProfile);
          this.credentials.updateFailedConnections(socialProfile, false);
        } catch (error) {
          reject(error);
          this.credentials.updateFailedConnections(socialProfile);
          return;
        }
      }

      let connectedPages;
      let pages;
      this.getFacebookCredentials()
        .then(async (authResponse) => {
          const socialProfileId = Number(socialProfile.id);
          const url = `/api/v2/social_profiles/${socialProfileId}/refresh_facebook_token`;
          const accountResponse = await this.getInstagramBusinessAccounts.perform(socialProfile);
          ({ connectedPages, pages } = accountResponse);
          if (connectedPages.length) {
            const response = await fetch(url, {
              method: 'POST',
              headers: { Accept: 'application/json', 'Content-type': 'application/json' },
              body: JSON.stringify({
                social_profile_id: socialProfileId,
                facebook_token: authResponse.accessToken
              })
            });
            const returnedProfile = response.social_profiles.findBy('id', socialProfileId);
            if (!returnedProfile) {
              throw new Error('No token response');
            }
            this.store.push(this.store.normalize('social-profile', returnedProfile));
            await this.currentUserModel.reload();
            const pageName = page || this.router.currentRouteName;
            this.segmentEvents.trackBusinessConnect(socialProfile, pageName);
            resolve(pages);
            this.credentials.updateFailedConnections(socialProfile, false);
            this.credentialStatus.checkBrokenProfiles();
          } else {
            this.credentials.updateFailedConnections(socialProfile);
            reject(
              new CredentialsNotFoundError({
                message: this.intl.t('account.controllers.groups.facebook.insufficient_permissions', {
                  social_profile: socialProfile.nickname
                })
              }).withAdditionalData({ pages })
            );
          }
        })
        .catch((error) => {
          // Note: If reject is called in the .then block, it will be picked up in the
          // catch block of the function calling refreshBusinessAccountEndpoint (if it has one)
          const logError = error instanceof Error ? error : new Error(`refreshBusinessAccountEndpoint error: ${error}`);

          this.errors.log(logError, {
            credentials: {
              uid: socialProfile.uid,
              basicDisplayId: socialProfile.basicDisplayId,
              facebookToken: this.currentUserModel.facebookToken,
              pages
            }
          });
          this._handleFailedRefresh.perform(socialProfile);
          reject(error);
        });
    });
  }

  @task
  *_handleFailedRefresh(socialProfileProxy) {
    const socialProfile = yield objectPromiseProxy(socialProfileProxy);
    this.credentials.updateFailedConnections(socialProfile);
    this.auth.currentUserModel.set('facebookToken', null);
    this.auth.currentUserModel.save();
  }

  /**
   * Gets the Facebook access token for a user.
   *
   * @method loadFacebookSDK
   *
   * @return {Promise} Resolves if user is authenticated.
   */
  loadFacebookSDK() {
    return new RSVP.Promise((resolve, reject) => {
      if (typeof FB !== 'undefined') {
        FB.getLoginStatus((response) => {
          if (response.status === 'connected') {
            console.log('FB: connected: ' + response.authResponse.accessToken);
            resolve(response);
          } else {
            resolve(response);
          }
        }, true);
      } else {
        this.alerts.warning(this.intl.t('alerts.account.controllers.groups.facebook.cant_load_sdk'));
        reject(this.intl.t('alerts.account.controllers.groups.facebook.cant_load_sdk'));
      }
    });
  }

  /**
   * Gets all the pages from the /me/accounts endpoint available to the logged in facebook user
   *
   * @method fetchFacebookPages
   * @param {String} includeInsufficientPermissions Whether to include pages with permissions below Admin or Editor (necessary for `instagram_content_publish`)
   *
   * @return {Array} List of Facebook pages available to the user
   */
  @task(function* (includeInsufficientPermissions = false) {
    return yield new RSVP.Promise((resolve, reject) => {
      FB.api(
        '/me/accounts?fields=picture,username,name,name_with_location_descriptor,access_token,tasks,instagram_business_account.fields(id,username)',
        async (response) => {
          if (!response) {
            this.errors.log(new Error('FB Account Get fail'));
            reject(response);
          } else if (response.error) {
            const SUB_CODES = {
              REQ_BLOCKED: 1357045
            };

            if (response.error?.error_subcode) {
              const subcode = response.error.error_subcode;
              const { message } = response.error;
              const graphError = `${message}: ${subcode}`;
              switch (subcode) {
                case SUB_CODES.REQ_BLOCKED:
                  this.alerts.warning(this.intl.t('alerts.facebook_graph_code.REQ_BLOCKED'));
                  return subcode;

                default:
                  this.errors.log(graphError, response);
              }
            }
            // ========= END =========
            reject(response);
          } else {
            let facebookPages = response.data;
            if (response.paging && response.paging.cursors.after) {
              const pages = await this._fetchMoreFacebookPages(response.paging.cursors.after);
              facebookPages = facebookPages.concat(pages);
            }
            if (!includeInsufficientPermissions) {
              facebookPages = facebookPages.filter((page) => this.sufficientPermissions(page));
            }
            resolve(facebookPages.sort((a, b) => a.name > b.name));
          }
        }
      );
    });
  })
  fetchFacebookPages;

  /**
   * Gets all the Instagram Business Accounts
   * associated with the current Later account
   *
   * @method getInstagramBusinessAccounts
   *
   * @return {Array} Resolves with an array of Instagram Business Pages
   */
  @task(function* (socialProfile) {
    const includeInsufficientPermissions = true;
    const pages = yield this.fetchFacebookPages.perform(includeInsufficientPermissions);
    const connectedPages = pages.filter((page) => {
      const businessAccount = page.instagram_business_account;
      const isConnectedPage =
        isPresent(businessAccount) &&
        (businessAccount.id == socialProfile.get('businessAccountId') ||
          businessAccount.username == socialProfile.get('nickname') ||
          businessAccount.id == socialProfile.get('uid'));
      return isConnectedPage;
    });
    return { connectedPages, pages };
  })
  getInstagramBusinessAccounts;

  /**
   * A promise wrapper for generic FB.api graph requests
   *
   * @method simpleGraphRequest
   * @param {String} endpoint The graph endpoint to use
   * @param {String} method HTTP request type
   * @param {Object} params parameters to send in body of request
   *
   * @return {Boolean} graph request response object or error
   * @protected
   */
  simpleGraphRequest(endpoint, method = 'GET', params = {}) {
    return new RSVP.Promise((resolve, reject) => {
      FB.api(endpoint, method, params, (response) => {
        if (!response || response.error) {
          reject(response.error);
        } else {
          resolve(response);
        }
      });
    });
  }

  /**
   * Revoke all FB user permissions for app
   *
   * @method revokePermissions
   *
   * @return {Promise} Resolves with true or error message
   * @protected
   */

  @task(function* (socialProfile) {
    const authResponse = yield this.getFacebookCredentials();
    return this._revoke(socialProfile, authResponse);
  })
  revokePermissions;

  @task(function* (socialProfile, token, permissionScope, basicDisplayId) {
    const existingBasicDisplayToken = socialProfile.basicDisplayToken;
    const existingToken = socialProfile.token;
    try {
      if (token === existingBasicDisplayToken || token === existingToken) {
        this.errors.log('updateSocialProfileError', {
          reason: 'New token matches existing token',
          isInstagram: socialProfile.isInstagram,
          socialProfileId: socialProfile.id
        });
      }
      if (socialProfile.isInstagram) {
        socialProfile.setProperties({
          basicDisplayId,
          basicDisplayToken: token,
          permissionScope
        });
      } else {
        socialProfile.setProperties({ token, permissionScope, tokenExpiresTime: null });
      }
      return yield socialProfile.save();
    } catch (adapterError) {
      socialProfile.rollbackAttributes();
      this.errors.handleAdapter(adapterError, socialProfile);
    }
  })
  updateSocialProfile;

  /**
   * Whether or not a social profile has
   * Instagram Business Scopes
   *
   * @method hasRequiredInstagramAdditionalScopes
   * @param {SocialProfile} socialProfile The social profile to check for correct scopes
   * @type {ComputedProperty<Boolean>}
   */
  hasRequiredInstagramAdditionalScopes(socialProfile) {
    return this.requiredInstagramAdditionalScopes.every((scope) =>
      socialProfile.additionalPermissionScope.includes(scope)
    );
  }

  /**
   * Uses the given business acount ID to request
   * details from the Facebook API
   *
   * @method getInstagramBusinessDetails
   * @param instagram_business_account_id ID of the Instagram business account
   *
   * @return {Promise} Resolves with the response from the Facebook API
   */
  getInstagramBusinessDetails(instagram_business_account_id) {
    return new RSVP.Promise((resolve /*, reject*/) => {
      FB.api(
        '/' + instagram_business_account_id + '?fields=id,ig_id,biography,username,website,followers_count,media_count',
        (response) => {
          resolve(response);
        }
      );
    });
  }

  /**
   * Determines whether the current user has the tasks required to publish content and obtain analytics for the page
   *
   * @method sufficientPermissions
   * @param {Object} page The page that requires the permissions
   *
   * @return {Boolean} user has required tasks for page
   * @protected
   */
  sufficientPermissions(page, isCreator) {
    // MANAGE is only given to admins but it does not seem to be required for publish
    const requiredTasks = ['ADVERTISE', 'ANALYZE', 'MODERATE'];
    if (!isCreator) {
      requiredTasks.push('CREATE_CONTENT');
    }
    const hasRequiredTasks = (requiredTasks, pageTasks) => requiredTasks.every((item) => pageTasks.includes(item));
    if (!page.tasks) {
      return false;
    }
    return hasRequiredTasks(requiredTasks, page.tasks);
  }

  @task(function* () {
    const pages = yield this.fetchFacebookPages.perform(true);
    return pages.filter((page) => {
      const facebookPage = this.facebookProfiles.findBy('uid', page.id);
      if (facebookPage) {
        this.updateSocialProfile.perform(facebookPage, page.access_token, this.potentialScopes);
        return true;
      }
      return false;
    });
  })
  _updateFacebookPages;

  /**
   * Fetches more facebook pages starting from the after the given paging cursor
   *
   * @method _fetchMoreFacebookPages
   * @param {String} after the paging cursor that points to the end of the page of data that has been returned
   *
   * @return {Array} Array of pages
   * @protected
   */
  _fetchMoreFacebookPages(after, resolvedPages = []) {
    const nextUrl = `/me/accounts?fields=picture,name,name_with_location_descriptor,access_token,tasks,category,instagram_business_account.fields(id,ig_id,biography,username,website,followers_count,media_count)&after=${after}`;
    return new RSVP.Promise((resolve) => {
      FB.api(nextUrl, (response) => {
        if (!response || response.error) {
          this.errors.log(new Error('FB Account Get fail'), response);
          resolve(resolvedPages); // fail and go forwards
        } else {
          if (response.data.length && response.paging && response.paging.cursors.after) {
            const concatenatedPages = resolvedPages.concat(response.data);
            resolve(this._fetchMoreFacebookPages(response.paging.cursors.after, concatenatedPages));
          } else {
            resolve(resolvedPages);
          }
        }
      });
    });
  }

  _updateAndReturnCredentials(loginResponse, resolve, reject) {
    if (!loginResponse || loginResponse.status !== 'connected') {
      return reject(this.intl.t('account.controllers.groups.facebook.login_failed'));
    }
    const { grantedScopes, userID, accessToken } = loginResponse.authResponse;
    console.log(`FB: connected: ${userID} : ${accessToken}`);
    if (!this.currentUserModel.facebookToken) {
      this.currentUserModel.setProperties({
        facebookUid: userID,
        facebookToken: accessToken
      });
      this.currentUserModel.save();
    }

    const deniedPermissions = grantedScopes ? arrayDiff(this.potentialScopes, grantedScopes.split(',')) : [];
    if (deniedPermissions.length) {
      reject(
        this.intl.t('account.controllers.groups.facebook.insufficient_permission', {
          permissions: deniedPermissions.map((perm) => perm.replace(/_/g, ' ')).join(', ')
        })
      );
    } else {
      resolve(loginResponse.authResponse);
    }
  }

  /**
   * Handles _attachInstagramBusinessAccount promise by saving socialProfile or handling errors when credentials not found
   *
   * @method _saveInstagramBusinessAccount
   *
   * @return {SocialProfile|EmberError} Saved model with businessAccountId and businessAccountToken or an error
   */
  _saveInstagramBusinessAccount(socialProfile) {
    return this._attachInstagramBusinessAccount(socialProfile)
      .then((sp) => (sp ? sp.save() : sp))
      .catch((error) => {
        if (socialProfile && socialProfile.rollbackAttributes) {
          socialProfile.rollbackAttributes();
        }
        throw error;
      });
  }

  /**
   * Gets Facebook Page for a given Instagram SocialProfile and attaches updated business account credentials. Will resolve
   * to nothing if a profile is not found
   *
   * @method _attachInstagramBusinessAccount
   *
   * @return {SocialProfile|EmberError} Updated model with businessAccountId and businessAccountToken set or an Error with reason not
   */
  _attachInstagramBusinessAccount(socialProfile) {
    return new RSVP.Promise((resolve, reject) => {
      this.getFacebookCredentials()
        .then(async (authResponse) => {
          const { connectedPages, pages } = await this.getInstagramBusinessAccounts.perform(socialProfile);
          const page = connectedPages.firstObject;
          if (authResponse && page) {
            if (!this.sufficientPermissions(page, socialProfile.isCreator)) {
              reject(
                new CredentialsNotFoundError({
                  message: this.intl.t('account.controllers.groups.facebook.insufficient_permissions', {
                    social_profile: socialProfile.nickname
                  }),
                  pages
                })
              );
            } else {
              socialProfile.set('businessAccountId', page.instagram_business_account.id);
              resolve(socialProfile);
            }
          } else {
            reject(
              new CredentialsNotFoundError({
                message: this.intl.t('account.controllers.groups.facebook.insufficient_permissions', {
                  social_profile: socialProfile.nickname
                }),
                pages
              })
            );
          }
        })
        .catch((error) => reject(error));
    });
  }

  async _revoke(socialProfile, authResponse) {
    try {
      const endpoint = '/me/permissions';
      socialProfile.setProperties({
        businessAccountId: null,
        businessAccountToken: null,
        additionalPermissionScope: []
      });
      socialProfile.save();
      const response = await graphCall(
        endpoint,
        {},
        { method: 'del', token: authResponse?.accessToken || this.currentUserModel.facebookToken }
      );
      return response;
    } catch (error) {
      this.errors.log(error);
    }
  }
}
