import Service, { inject as service } from '@ember/service';

import { fetch, objectToQueryString } from 'later/utils/fetch';
import trackCallDuration from 'shared/decorators/track-call-duration';

import type IntlService from 'ember-intl/services/intl';
import type { UnsplashImageArgs } from 'later/models/unsplash-image';
import type AlertsService from 'later/services/alerts';
import type ErrorsService from 'later/services/errors';
import type LaterConfigService from 'later/services/later-config';

type SearchMediaResponse = { results: UnsplashImageArgs[]; total: number; total_pages: number };

export default class UnsplashService extends Service {
  @service declare alerts: AlertsService;
  @service declare errors: ErrorsService;
  @service declare intl: IntlService;
  @service declare laterConfig: LaterConfigService;

  /**
   * Fetches Latest Media (photos) from Unsplash
   */
  async fetchLatestMedia(params: { order_by?: string; page: number; per_page: number }): Promise<UnsplashImageArgs[]> {
    const endpoint = `/photos`;
    return (await this._fetch(endpoint, params)) as UnsplashImageArgs[];
  }

  /**
   * Searches Media(photos) from Unsplash based on the passed search query param
   */
  @trackCallDuration('searchMedia')
  async searchMedia(params: { query: string; page: number; per_page: number }): Promise<SearchMediaResponse> {
    const endpoint = `/search/photos`;
    return (await this._fetch(endpoint, params)) as SearchMediaResponse;
  }

  /**
   * Fetches Media(Photo) details from Unsplash based on passed Media id
   */
  async fetchMediaDetails(mediaId: string): Promise<UnsplashImageArgs> {
    const endpoint = `/photos/${mediaId}`;
    return (await this._fetch(endpoint)) as UnsplashImageArgs;
  }

  /**
   * Sends download event to Unsplash
   */
  async sendDownloadEvent(mediaId: string): Promise<object | void> {
    const endpoint = `/photos/${mediaId}/download`;

    try {
      const response = await this._fetch(endpoint);
      if (response) {
        return response;
      }
    } catch (error) {
      this.errors.log(`Error while sending download event to Unsplash ${error}`);
    }
  }

  /**
   * GET Request to Unsplash
   */
  async _fetch(endpoint: string, params?: object): Promise<unknown> {
    const formattedEndpoint = endpoint.charAt(0) === '/' ? endpoint : `/${endpoint}`;
    const url = encodeURI(
      `${this.laterConfig.unsplashProxy}/proxy/unsplash${formattedEndpoint}${objectToQueryString(params)}`
    );

    try {
      return await fetch(url);
    } catch (error) {
      if (error.code === 403) {
        this.alerts.alert(this.intl.t('alerts.import_from_external_library.unsplash.ratelimit_error.message'), {
          title: this.intl.t('alerts.import_from_external_library.unsplash.ratelimit_error.title')
        });
      } else {
        this.alerts.alert(error.message);
      }
      throw error;
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    unsplash: UnsplashService;
  }
}
