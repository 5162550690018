import Service, { inject as service } from '@ember/service';
import RSVP from 'rsvp';

import loadScript from 'later/utils/load-script';

import type { Stripe as StripeClient, StripeCardElement, Token } from '@stripe/stripe-js';
import type LaterConfigService from 'later/services/later-config';

/**
 *
 * This service exists as an Interface for the Stripe JS SDK.
 * Details of the SDK can be found here https://stripe.com/docs/stripe-js/reference.
 *
 * @class StripeService
 * @extends Service
 */

export default class StripeService extends Service {
  @service declare laterConfig: LaterConfigService;

  card?: StripeCardElement = undefined;
  client?: StripeClient = undefined;

  get stripePublishableKey(): string {
    return this.laterConfig.stripePublishableKey;
  }

  /**
   * Creates a "Stripe Elements" credit card input box
   * which is injected into the supplied HTML element
   *
   * @method createCard
   * @param elementString A string to identify the HTML element
   * which will be used to create a Stripe Card Input. Ex: `#stripe-card-div`
   *
   * @returns The generated Stripe SDK Card
   */
  async createCard(elementString: string): Promise<StripeCardElement | undefined> {
    await this._setup();

    if (!this.client) {
      return;
    }

    const style = {
      base: {
        fontSize: '1rem',
        lineHeight: '16px'
      }
    };

    const card = this.client.elements().create('card', { style });
    this.card = card;

    card.mount(elementString);

    return card;
  }

  /**
   * Generates a Stripe Credit Card Token for the supplied Card
   *
   * @method createToken
   * @param card A "Stripe Elements" card
   * which will be used to generate a token
   *
   * @returns The generated Stripe card token
   */
  async createToken(card: StripeCardElement): Promise<Token> {
    await this._setup();

    if (!this.client) {
      return RSVP.reject();
    }

    return new RSVP.Promise((resolve, reject) => {
      this.client?.createToken(card).then((result) => {
        if (result.error) {
          reject(result.error);
        } else {
          resolve(result.token);
        }
      });
    });
  }

  /**
   * Updates a User's stored Stripe Card using a supplied
   * Stripe Credit Card token.
   *
   * @method updateCard
   * @param token A Stripe Credit Card Token
   *
   */
  async updateCard(token: Token): Promise<void> {
    await this._setup();

    if (!this.client) {
      return RSVP.reject();
    }

    const body = new FormData();
    body.append('stripeToken', token.id);
    return fetch('/api/v2/update_stripe_card', {
      method: 'POST',
      body
    }).then((res) => {
      if (!res.ok) {
        return RSVP.reject(res);
      }
      return;
    });
  }

  /**
   * Opens an on-page "checkout" that can be used to update Billing information.
   * Refer to Stripe Legacy Checkout Reference:
   * https://stripe.com/docs/legacy-checkout#integration-custom
   *
   * @method openCheckout
   * @param email The current billing email, used to automatically fill in the email field.
   *
   * @returns The newly generated Stripe Credit Card Token
   */
  async openCheckout(email: string): Promise<Token | undefined> {
    await this._setup();

    if (!this.client) {
      return RSVP.reject();
    }

    return new RSVP.Promise((resolve) => {
      const handler = StripeCheckout.configure({
        key: this.stripePublishableKey,
        locale: 'auto',
        token: (token: Token) => resolve(token)
      });

      handler.open({
        description: 'Update Credit Card',
        email,
        panelLabel: 'Update Credit Card',
        name: 'Later',
        label: 'Update Credit Card',
        zipCode: 'true'
      });

      window.addEventListener('popstate', () => {
        handler.close();
      });
    });
  }

  async _setup(): Promise<void> {
    await Promise.all([loadScript('https://js.stripe.com/v3/'), loadScript('https://checkout.stripe.com/checkout.js')]);
    if (!this.client && this.stripePublishableKey) {
      this.client = Stripe(this.stripePublishableKey);
    }
    return;
  }
}

declare module '@ember/service' {
  interface Registry {
    stripe: StripeService;
  }
}
