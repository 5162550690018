import Service, { inject as service } from '@ember/service';
import { isEmpty, isPresent, isBlank } from '@ember/utils';

import { convert } from 'later/utils/time-format';
import { computeUtcOffsetFromTz } from 'shared/utils/analytics/timezone-helpers';
import createMomentInTz from 'shared/utils/time-helpers/create-moment-in-tz';

import type DynamoApiService from './dynamo-api';
import type IntlService from 'ember-intl/services/intl';
import type { DynamoIgPost } from 'later/models/ig-post';
import type AnalyticsService from 'later/services/analytics';
import type { Moment } from 'moment';
import type { AnalyticsError, Stat, Bins, PartialDataTimeProperty, PartialData } from 'shared/types/analytics-data';

type PostWithLinkinbioClicks = DynamoIgPost & { linkinbioClicks: string | number };

export default class HelpersAnalyticsService extends Service {
  @service declare analytics: AnalyticsService;
  @service declare intl: IntlService;
  @service('analytics/dynamo-api') declare dynamoApi: DynamoApiService;

  /**
   * @property dataCollectionOffset
   */
  get dataCollectionOffset(): number {
    return computeUtcOffsetFromTz(this.dynamoApi.insightsCollectionTimezoneName);
  }

  /**
   * Returns an object with error information.
   *
   * @param errorText Error message
   * @param errorType String to indicate the error type
   * @returns Formatted error object
   */
  formatError(errorText: string, errorType: string | null = null): AnalyticsError {
    if (errorType) {
      return { error: { errorText, errorType } };
    }
    return { error: { errorText } };
  }

  /**
   * Format short link clicks on a post
   *
   * @param media
   * @returns media with number of clicks on shortlinks or N/A clicks
   */
  formatShortLinkClicksOnMedia<T extends { clicks: number | string | null; shortcodes: string[] }>(media: T): T {
    if (isBlank(media.clicks) || media.clicks === 0) {
      media.clicks = isEmpty(media.shortcodes) ? 'N/A' : 0;
    }

    return media;
  }

  /**
   * Returns an array of igPosts with Linkin.bio clicks as a property.
   *
   * @param posts Array of igPosts from Dynamo with linkinbio post ids
   * @param libClicks Total number of Linkin.bio clicks in format {lib_post_id : click count}
   * @returns Original posts object, now including property linkinbioClicks
   */
  addLinkinbioClicksToPosts(posts: DynamoIgPost[], libClicks: Record<string, number>): PostWithLinkinbioClicks[] {
    const hasClicks = libClicks && Object.keys(libClicks).length > 0;
    return posts.map((post: DynamoIgPost) => {
      const libPostExists = isPresent(post.linkinbioPostId);
      const clicksExist = libPostExists && hasClicks && Boolean(libClicks[post.linkinbioPostId ?? '']);

      if (clicksExist) {
        post.set('linkinbioClicks', Number(libClicks[post.linkinbioPostId ?? ''].toFixed(0)));
      } else if (hasClicks && libPostExists) {
        post.set('linkinbioClicks', 0);
      } else {
        post.set('linkinbioClicks', 'N/A');
      }
      return post as PostWithLinkinbioClicks;
    });
  }

  /**
   * Scales a number, given a range between min and max.
   *
   * @param min Minimum value
   * @param max Maximum value
   * @param value Number to be scaled
   * @returns The scaled percentage for the given value.
   */
  scaleNumber(min: number, max: number, value: number): string {
    if (max < min) {
      return '0';
    }

    if (max - min === 0) {
      return '0';
    } else if (!value) {
      return '0';
    }
    return (((value - min) / (max - min)) * 100).toFixed(0);
  }

  /**
   * Generates a stat object.
   *
   * @param value Value
   * @param text Stat name
   * @param isPercent Whether to format as a percentage
   * @param showPositive Whether to prefix with a +/- sign
   * @param iconClassName Icon class name, if an icon is desired
   * @returns The stat object.
   */
  generateStat(value: number, text: string, isPercent = false, showPositive = false, iconClassName?: string): Stat {
    return {
      text,
      value: value || value === 0 ? value : '-',
      isPercent,
      showPositive,
      iconClassName
    };
  }

  /**
   * Generates a count object for a translation pluralization.
   *
   * @param value Value
   * @returns The count object.
   */
  generateCountObj(value: number): { count: number } {
    return {
      count: value ? value : 2
    };
  }

  /**
   * Fills in data with defaultDataPoints for the given period, once per day.
   *
   * @param startDateUnix Unix start date
   * @param endDateUnix Unix end date
   * @param data Data
   * @param defaultDataPoint Default data point
   * @param timePropertyName Name of each data point's time property
   * @param secondsBetweenPoints Bin spacing in seconds
   * @param mediaId mediaId if required
   * @returns The filled in data.
   */
  fillData<T extends PartialData>(
    startDateUnix: number,
    endDateUnix: number,
    data: T[] = [],
    defaultDataPoint: T,
    timePropertyName: PartialDataTimeProperty,
    secondsBetweenPoints = 86400,
    mediaId: string | null = null
  ): T[] {
    const bins = this._generateBins(startDateUnix, endDateUnix, secondsBetweenPoints);
    const filledBins = this._sortIntoBins(bins, data, timePropertyName);
    const extraPoints = this._generatePartialData(filledBins, timePropertyName, defaultDataPoint, mediaId);

    return this.sortByProperty([...data, ...extraPoints], timePropertyName);
  }

  /**
   * Sorts an array of objects by the given property, in ascending order.
   *
   * @param data Data
   * @param propertyName Name of property to sort by
   * @returns The sorted data object.
   */
  sortByProperty<T, K extends keyof T>(unsortedData: T[] = [], propertyName: K): T[] {
    return unsortedData.sort((prev, curr) => Number(prev[propertyName]) - Number(curr[propertyName]));
  }

  /**
   * Creates a key from the start and end dates
   *
   * @param startMoment
   * @param endMoment
   * @returns String to be used as a key for the given dates
   */
  createKeyFromDates(startMoment: Moment, endMoment: Moment): string {
    const startDate = startMoment ? startMoment.clone().startOf('day').unix() : '';
    const endDate = endMoment ? endMoment.clone().startOf('day').unix() : '';
    return `${startDate}-${endDate}`;
  }

  /**
   * Generates a formatted data collection text
   * object for the given message
   *
   * @param unixTime
   * @param isHourly
   * @returns Formatted data collection text object
   */
  formatDataCollectionMessage(unixTime: number, isHourly = false): Record<string, string> {
    const momentFormatter = isHourly ? 'LLLL' : 'MMMM D, YYYY';
    const date = this.createMomentInTz(unixTime).format(momentFormatter);

    return {
      text: this.intl.t('analytics.charts.growth_chart.still_collecting', { date }),
      type: 'dataCollection'
    };
  }

  /**
   * Takes the unix timestamp and returns the
   * moment object in the local timezone
   *
   * @param unixTime the unix timestamp given
   * @param timeZoneIdentifier the timezone name set in Settings
   * @returns Moment object in local timezone
   */
  createMomentInTz(unixTime?: number, timeZoneIdentifier: string | null = null): Moment {
    const tz = timeZoneIdentifier ? timeZoneIdentifier : this.analytics.timeZoneIdentifier;
    return createMomentInTz(unixTime, tz);
  }

  /**
   * Generates an error message string.
   *
   * @param error
   * @returns Error message as a string.
   */
  generateErrorMessage(error: Response): Response | string {
    if (typeof error === 'object') {
      return error.status ? `${error.status} ${error.statusText}` : `${error.statusText}`;
    }

    return error;
  }

  /**
   * Generates a bin for each day in the given time period.
   *
   * @param startDateUnix Unix start date
   * @param endDateUnix Unix end date
   * @param secondsBetweenPoints Bin spacing in seconds
   * @returns Bins for given time period.
   */
  _generateBins(startDateUnix: number, endDateUnix: number, secondsBetweenPoints: number): Bins[] {
    const oneDayInSeconds = convert.day().toSeconds();
    const oneHourInSeconds = convert.hour().toSeconds();
    const isDailyData = secondsBetweenPoints >= oneDayInSeconds;

    const startMoment = this.createMomentInTz(startDateUnix);
    const startMomentShifted = isDailyData ? startMoment.startOf('day') : startMoment;

    const endMoment = this.createMomentInTz(endDateUnix);
    const endMomentShifted = isDailyData ? endMoment.endOf('day') : endMoment;

    const bins = [];

    let currentTime = startMomentShifted.unix();
    let isPrevDST = this.createMomentInTz(currentTime).isDST();
    let isCurrDST;

    while (currentTime <= endMomentShifted.unix()) {
      isCurrDST = this.createMomentInTz(currentTime).isDST();

      let end = currentTime + secondsBetweenPoints;

      if (isPrevDST && !isCurrDST) {
        currentTime += oneHourInSeconds;
        end += oneHourInSeconds;
      } else if (!isPrevDST && isCurrDST) {
        currentTime -= oneHourInSeconds;
        end -= oneHourInSeconds;
      }

      bins.push({
        start: currentTime,
        end,
        count: null
      });

      currentTime = end;
      isPrevDST = isCurrDST;
    }

    return bins;
  }

  /**
   * Takes adds a count to each bin, for each data point that falls within that time period.
   *
   * @param bins Bins
   * @param data Data
   * @returns Returns filled bins (each bin has a count value).
   */
  _sortIntoBins<T extends PartialData>(
    bins: Bins[] = [],
    data: T[] = [],
    timePropertyName: PartialDataTimeProperty
  ): Bins[] {
    data.forEach((dataPoint) => {
      bins.forEach((bin) => {
        if (Number(dataPoint[timePropertyName]) >= bin.start && Number(dataPoint[timePropertyName]) < bin.end) {
          bin.count ? bin.count++ : (bin.count = 1);
        }
      });
    });

    return bins;
  }

  /**
   * Returns an array of default data points for each missing time period.
   *
   * @param filledBins Bins with data present have counts > 0
   * @param timePropertyName Name of each data point's time property
   * @param defaultDataPoint Default data point
   * @param mediaId mediaId if required
   * @returns The missing data points.
   */
  _generatePartialData<T extends PartialData>(
    filledBins: Bins[] = [],
    timePropertyName: PartialDataTimeProperty,
    defaultDataPoint: T,
    mediaId: string | null = null
  ): T[] {
    const missingPoints: T[] = [];

    filledBins.forEach((bin) => {
      if (!bin.count) {
        const currentDataPoint: T = Object.assign({}, defaultDataPoint);

        currentDataPoint[timePropertyName] = (bin.start + bin.end) / 2;

        if (mediaId && mediaId !== '') {
          // Note: Must do to avoid naming conflict
          currentDataPoint.media_id = mediaId;
        }

        missingPoints.push(currentDataPoint);
      }
    });

    return missingPoints;
  }
}

declare module '@ember/service' {
  interface Registry {
    'analytics/helpers-analytics': HelpersAnalyticsService;
  }
}
