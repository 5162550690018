import { readOnly } from '@ember/object/computed';
import Service, { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { dropTask, restartableTask } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import 'moment-timezone';
import { TrackedArray } from 'tracked-built-ins';

import { FetchError } from 'later/errors/fetch';
import isWithinDates from 'later/utils/is-within-dates';
import { days } from 'shared/utils/time';

import type MutableArray from '@ember/array/mutable';
import type StoreService from '@ember-data/store';
import type { TaskGenerator } from 'ember-concurrency';
import type AccountModel from 'later/models/account';
import type CalendarNoteModel from 'later/models/calendar-note';
import type GramModel from 'later/models/gram';
import type SocialProfileModel from 'later/models/social-profile';
import type AuthService from 'later/services/auth';
import type ConfigService from 'later/services/calendar/config';
import type ErrorsService from 'later/services/errors';
import type SelectedSocialProfilesService from 'later/services/selected-social-profiles';
import type { Maybe } from 'shared/types';

export enum FetchableRecordTypes {
  Notes = 'calendar-note',
  Posts = 'gram'
}

export enum FetchGramVersionTypes {
  All = 'all',
  Draft = 'draft',
  Final = 'final'
}

export enum FetchGramQueryTypes {
  Time = 'time'
}

export interface FetchRecordsParams {
  start: Date;
  end: Date;
  isPrefetch?: boolean;
  versionType?: FetchGramVersionTypes;
}

export type FetchResults<T> = Promise<T> | MutableArray<T>;

export type FetchableRecords = GramModel | CalendarNoteModel;

interface QueryStoreParams {
  query_type: FetchGramQueryTypes;
  version_type: FetchGramVersionTypes;
  start_time: number;
  end_time: number;
  group_id: string;
}

interface FetchedRangeEntry {
  start: Date;
  end: Date;
  groupId: string;
  recordType: FetchableRecordTypes;
  versionType: FetchGramVersionTypes;
}

export default class FetchWithinDatesService extends Service {
  @service declare auth: AuthService;
  @service('calendar/config') declare calendarConfig: ConfigService;
  @service declare errors: ErrorsService;
  @service declare selectedSocialProfiles: SelectedSocialProfilesService;
  @service declare store: StoreService;

  fetchedDateRanges: TrackedArray<FetchedRangeEntry> = new TrackedArray([]);

  @readOnly('auth.currentAccount') declare account: AccountModel;
  @readOnly('auth.currentGroup.id') declare currentGroupId: string;
  @readOnly('selectedSocialProfiles.hasMultipleSelected') declare multipleProfilesSelected: boolean;
  @readOnly('selectedSocialProfiles.firstProfile') declare singleSocialProfile: SocialProfileModel;

  // Note: Check for existing fullCalendar date ranges when determining default start and end
  get defaultFetchInfo(): FetchRecordsParams {
    return {
      start: this.calendarConfig.fullCalendar?.view.activeStart ?? new Date(),
      end: this.calendarConfig.fullCalendar?.view.activeEnd ?? days(7, new Date())
    };
  }

  // Note: Return true if records within this range have already been fetched
  #recordsFetched(
    start: Date,
    end: Date,
    recordType: FetchableRecordTypes,
    versionType: FetchGramVersionTypes
  ): boolean {
    if (isEmpty(this.fetchedDateRanges)) {
      return false;
    }

    return this.fetchedDateRanges.any((dateRange) => {
      // Note: If dateRange versionType is 'all', assume 'final' and 'draft' records have been fetched
      const allVersionType = dateRange.versionType === FetchGramVersionTypes.All;
      const versionTypeMatches = dateRange.versionType === versionType;
      const draftOrFinalVersionType =
        versionType === FetchGramVersionTypes.Draft || versionType === FetchGramVersionTypes.Final;

      const versionTypeIncluded = versionTypeMatches || (allVersionType && draftOrFinalVersionType);

      return (
        versionTypeIncluded &&
        dateRange.recordType === recordType &&
        this.currentGroupId === dateRange.groupId &&
        isWithinDates(start, end, dateRange)
      );
    });
  }

  // Note: If prefetch is complete, no need to make a network request
  #fetchFromStore(
    model: FetchableRecordTypes,
    params: QueryStoreParams,
    prefetchComplete: Maybe<boolean>
  ): FetchResults<FetchableRecords> | void {
    try {
      if (prefetchComplete) {
        return this.store.peekAll(model);
      }
      return this.store.query(model, params);
    } catch (error) {
      if (error instanceof FetchError) {
        error.resolve.call(this, null, error.message);
      } else {
        this.errors.log(error);
      }
    }
  }

  #getInitialDate(start: Date): Date {
    return new Date(start.getTime());
  }

  #getParams({ start, end, isPrefetch, versionType }: FetchRecordsParams): QueryStoreParams {
    const startDate = this.#queryDate(start, isPrefetch, -7);
    const endDate = this.#queryDate(end, isPrefetch, 7);

    return {
      query_type: FetchGramQueryTypes.Time,
      version_type: versionType || FetchGramVersionTypes.All,
      start_time: startDate.getTime() / 1000,
      end_time: endDate.getTime() / 1000,
      group_id: this.currentGroupId
    };
  }

  #queryDate(date: Date, isPrefetch: Maybe<boolean>, offset: number): Date {
    const queryDate = new Date(date.getTime());
    if (!isPrefetch) {
      queryDate.setDate(queryDate.getDate() + offset);
    }
    return queryDate;
  }

  @dropTask
  *prefetch(start: Date, recordType: FetchableRecordTypes): TaskGenerator<void> {
    // Note:  Note: #getInitialDate needed for each variable declaration as functions in shared/utils/time mutate the Date object
    const prefetchStart = days(-7, this.#getInitialDate(start));
    const prefetchEnd = days(14, this.#getInitialDate(start));

    // Note: Prefetch events for the previous and next week views
    yield taskFor(this.fetchRecords).perform(null, recordType, {
      start: prefetchStart,
      end: prefetchEnd,
      isPrefetch: true
    });
  }

  @restartableTask
  *fetchRecords(
    existingRecords: Maybe<MutableArray<FetchableRecords> | FetchableRecords[]>,
    recordType: FetchableRecordTypes,
    fetchInfo = this.defaultFetchInfo
  ): TaskGenerator<[] | FetchResults<FetchableRecords>> {
    const { isPrefetch, start, end, versionType = FetchGramVersionTypes.All } = fetchInfo;
    const params = this.#getParams(fetchInfo);
    const fetchedWithinDates = this.#recordsFetched(start, end, recordType, versionType);
    const recordsAreFetched = fetchedWithinDates && existingRecords;

    // Note: If records have already been fetched, use data from store instead of making a request
    const records = recordsAreFetched
      ? existingRecords
      : yield this.#fetchFromStore(recordType, params, fetchedWithinDates);

    // Note: Add new date range to fetchedDateRanges after fetching
    if (!fetchedWithinDates) {
      this.fetchedDateRanges.pushObject({
        start,
        end,
        groupId: this.currentGroupId,
        recordType,
        versionType
      });
    }

    // Note: Do not return records if this is a prefetch
    if (isPrefetch) {
      return [];
    }

    return records;
  }
}

declare module '@ember/service' {
  interface Registry {
    'schedule/fetch-within-dates': FetchWithinDatesService;
  }
}
