import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { isTesting } from '@embroider/macros';
import { tracked } from '@glimmer/tracking';
import _SessionService from 'ember-simple-auth/services/session';

import redirect from 'shared/utils/redirect';

import type RouterService from '@ember/routing/router-service';
import type AuthService from 'later/services/auth';
import type SegmentService from 'later/services/segment';

type RouteAfterAuthentication = 'index' | 'plans.plan' | 'cluster.partnerships';

export default class SessionService extends _SessionService {
  @service declare router: RouterService;
  @service declare segment: SegmentService;
  @service declare auth: AuthService;

  @tracked redirectUrl = '';

  get #hasRedirectUrl(): boolean {
    return isPresent(this.redirectUrl);
  }

  setRedirectUrl(url: string): void {
    this.redirectUrl = url || '';
  }

  get routeAfterAuthentication(): RouteAfterAuthentication {
    if (this.router.currentRouteName === 'plans.plan') {
      return 'plans.plan';
    }

    if (this.router.currentRoute.queryParams.utm_source === 'mavrck') {
      this.segment.track('new_user_fast_track_onboarding');
      return 'cluster.partnerships';
    }

    return 'index';
  }

  /**
   * This ensures that the login is in a proper state
   * before advancing to the route requested post-authentication
   */
  async handleAuthentication(): Promise<void> {
    await this.auth.setup();
    await this.auth.handleNewAccount();

    if (this.#hasRedirectUrl) {
      redirect(this.redirectUrl);
      return;
    }

    this.router.transitionTo(this.routeAfterAuthentication);
  }

  /**
   * Handles redirecting after a logout
   */
  handleInvalidation(): void {
    if (isTesting() || this.auth.hasRedirectUrl) {
      return;
    }

    window.location.reload();
  }
}

declare module '@ember/service' {
  interface Registry {
    '-private/session': SessionService;
  }
}
