import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { isNone } from '@ember/utils';

import { ErrorSeverity } from 'later/services/errors';

import type RouterService from '@ember/routing/router-service';
import type StoreService from '@ember-data/store';
import type IntlService from 'ember-intl/services/intl';
import type UserAgentService from 'ember-useragent/services/user-agent';
import type GroupModel from 'later/models/group';
import type SessionService from 'later/services/-private/session';
import type AlertsService from 'later/services/alerts';
import type AuthService from 'later/services/auth';
import type CacheService from 'later/services/cache';
import type ErrorsService from 'later/services/errors';
import type FayeService from 'later/services/faye';
import type LocalStorageManagerService from 'later/services/local-storage-manager';
import type { Maybe, ModelFrom, Transition } from 'shared/types';

export default class ClusterRoute extends Route {
  @service declare alerts: AlertsService;
  @service declare auth: AuthService;
  @service declare cache: CacheService;
  @service declare errors: ErrorsService;
  @service declare faye: FayeService;
  @service declare intl: IntlService;
  @service declare localStorageManager: LocalStorageManagerService;
  @service declare session: SessionService;
  @service declare router: RouterService;
  @service declare userAgent: UserAgentService;
  @service declare store: StoreService;

  beforeModel(transition: Transition): void {
    this.session.requireAuthentication(transition, 'user.login');
  }

  model(params: { profile_path: string }): Maybe<GroupModel> {
    return this.store.peekAll('group').findBy('slug', params.profile_path);
  }

  afterModel(group: ModelFrom<ClusterRoute>, transition: Transition): void {
    if (!this.auth.currentAccount) {
      this.auth.logout();
      return;
    }

    // NOTE: If the mobile user is not on an allowed page, redirect to "/mobile"
    const allowedMobilePages = ['linkinbio', 'unsubscribe'];
    // TODO: add partnerships to default array post rollout
    if (this.auth.currentAccount.rolloutOcePhase1) {
      allowedMobilePages.push('partnerships');
    }
    if (this.userAgent.device.isMobile && !allowedMobilePages.some((route) => transition.to.name.includes(route))) {
      this.router.replaceWith('mobile');
    }

    if (isNone(group)) {
      const defaultGroup = this.store.peekAll('group').get('firstObject');

      if (isNone(defaultGroup)) {
        // Temporary log to track down cause of redirecting to the `nogroup` route
        try {
          this.errors.log(
            'No group found, cluster route',
            {
              localGroupIds: this.store.peekAll('group').mapBy('id'),
              lastGroupId: this.cache.retrieve('last_group_id') || 'none',
              localStorage: { ...window.localStorage }
            },
            ErrorSeverity.Info
          );
        } catch (error) {
          this.errors.log('Could not log nogroup error, cluster route', { error }, ErrorSeverity.Info);
        }

        this.router.replaceWith('nogroup');
      } else {
        this.router.replaceWith('/' + defaultGroup.get('slug'));
      }
    } else {
      this.cache.add('last_group_id', group.id, { expiry: this.cache.expiry(1, 'day'), persist: true });
      this.auth.setGroup(group);

      //preload savedCaptions and labels async -iMack
      group.get('savedCaptions');
      group.get('labels');
      // on success
      this.faye.subscribeGroup(group);
    }
  }

  setupController(): void {
    this.localStorageManager.removeItem('show_nav_large');
    this.localStorageManager.removeItem('show_nav_small');
  }

  deactivate(): void {
    const group = this.modelFor('cluster') as GroupModel;
    this.faye.unsubscribeGroup(group);
  }
}
