import Service, { inject as service } from '@ember/service';
import { isNone } from '@ember/utils';

import type IntlService from 'ember-intl/services/intl';
import type UserAgentService from 'ember-useragent/services/user-agent';
import type AlertsService from 'later/services/alerts';

interface MinimumBrowserVersion {
  chrome: number;
  firefox: number;
  safari: number;
  'mobile-safari': number;
  edge: number;
  opera: number;
}

interface DiscouragedBrowsers {
  safari_14: {
    message: string;
  };
}

export default class BrowserSupportService extends Service {
  @service declare userAgent: UserAgentService;
  @service declare alerts: AlertsService;
  @service declare intl: IntlService;

  get userAgentCssClass(): string {
    const osClass = !isNone(this.#operatingSystem) ? `u--${this.#normalize(this.#operatingSystem)}` : '';
    const browserClass = !isNone(this.#browserName) ? `u--${this.#normalize(this.#browserName)}` : '';
    const versionClass = !isNone(this.#browserMajorVersion)
      ? `${browserClass}__${this.#normalize(this.#browserMajorVersion.toString())}`
      : '';

    return `${osClass} ${browserClass} ${versionClass}`;
  }

  displayBrowserWarnings(): void {
    if (!this.#isSupportedBrowser) {
      this.alerts.info(this.intl.t('browser_support.body'), {
        title: this.intl.t('browser_support.title'),
        link: 'https://help.later.com/hc/articles/360060680453',
        sticky: true
      });
    }

    if (this.#discouragedBrowser) {
      this.alerts.info(this.#discouragedBrowser.message, {
        title: this.intl.t('browser_support.discouraged_browser_title'),
        link: 'https://help.later.com/hc/articles/360060680453',
        sticky: true
      });
    }
  }

  readonly #minimumSupportedBrowsers: MinimumBrowserVersion = {
    chrome: 94,
    edge: 94,
    firefox: 100,
    safari: 14,
    'mobile-safari': 14,
    opera: 88
  };

  get #discouragedBrowsers(): DiscouragedBrowsers {
    return {
      safari_14: {
        message: this.intl.t('browser_support.safari_14_message')
      }
    };
  }

  get #operatingSystem(): string | undefined {
    return this.userAgent?.os?.info?.name;
  }

  get #browserName(): string | undefined {
    return this.userAgent?.browser?.info?.name;
  }

  get #browserMajorVersion(): number | undefined {
    const majorVersion = this.userAgent?.browser?.info?.major;
    if (!majorVersion) {
      return;
    }

    return parseInt(majorVersion);
  }

  get #isSupportedBrowser(): boolean {
    const name = this.#normalize(this.#browserName);
    const minimumVersion = this.#isKnownBrowser(name) ? this.#minimumSupportedBrowsers[name] : 0;

    if (!this.#browserMajorVersion) {
      return false;
    }

    return this.#browserMajorVersion >= minimumVersion;
  }

  get #discouragedBrowser(): DiscouragedBrowsers[keyof DiscouragedBrowsers] | undefined {
    const name = `${this.#normalize(this.#browserName)}_${this.#browserMajorVersion}`;
    if (this.#isDiscouragedBrowser(name)) {
      return this.#discouragedBrowsers[name];
    }

    return undefined;
  }

  #normalize(input = ''): string {
    return input.toString().toLowerCase().split(' ').join('-');
  }

  #isKnownBrowser(browser: string): browser is keyof MinimumBrowserVersion {
    return Object.keys(this.#minimumSupportedBrowsers).includes(browser);
  }

  #isDiscouragedBrowser(browser: string): browser is keyof DiscouragedBrowsers {
    return Object.keys(this.#discouragedBrowsers).includes(browser);
  }
}

declare module '@ember/service' {
  interface Registry {
    'browser-support': BrowserSupportService;
  }
}
