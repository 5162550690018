/**
 *
 * This service exists as the point of entry for and
 * monetary transaction that passes through Later
 *
 * @class PaymentService
 * @extends Service
 */

import { reads } from '@ember/object/computed';
import Service, { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import moment from 'moment-timezone';

import config from 'later/config/environment';
import { fetch } from 'later/utils/fetch';
import { STRATEGY } from 'shared/utils/retry';

import type { StripeCardElement, Card } from '@stripe/stripe-js';
import type { Task } from 'ember-concurrency';
import type AccountModel from 'later/models/account';
import type SubscriptionModel from 'later/models/subscription';
import type AuthService from 'later/services/auth';
import type CacheService from 'later/services/cache';
import type ErrorsService from 'later/services/errors';
import type SegmentService from 'later/services/segment';
import type StripeService from 'later/services/stripe';
import type { TRIAL_TYPES } from 'later/utils/constants';
import type { Maybe, ModifyInterface, ValueOfType } from 'shared/types';
import type { PaymentDetails, PaymentObject, Refunds } from 'shared/types/payment';
import type { JsonValue } from 'type-fest';

type FormattedPaymentObject = ModifyInterface<
  PaymentObject,
  {
    created: string;
    amount: string;
    currency: string;
    canReceipt: boolean;
    refunds: boolean | Refunds;
    status: string;
  }
>;
export default class PaymentService extends Service {
  @service declare cache: CacheService;

  @service declare errors: ErrorsService;

  @service declare segment: SegmentService;

  @service declare stripe: StripeService;

  @service declare auth: AuthService;

  @reads('auth.currentAccount') declare currentAccount: AccountModel;

  /**
   * Whether or not there is a billing error
   */
  hasErrorLoadingData = false;

  /**
   * Goes through the process of preparing data for, and creating, a subscription
   * to a Later Payment Plan
   */
  processSubscription(args: {
    card: StripeCardElement;
    planId: string;
    additionalUsers: number;
    additionalSocialSets: number;
    additionalAiCredits: number;
    totalCost: number;
    couponId?: string;
    trialStartLocation?: string;
  }): Promise<{ payment: PaymentDetails }> {
    const {
      card,
      planId,
      additionalUsers,
      additionalSocialSets,
      additionalAiCredits,
      totalCost,
      couponId,
      trialStartLocation
    } = args;

    return this.stripe.createToken(card).then((token) => {
      return this.#createSubscription({
        tokenId: token.id,
        planId,
        additionalUsers,
        additionalSocialSets,
        additionalAiCredits,
        totalCost,
        couponId,
        trialStartLocation
      });
    });
  }

  /**
   * Changes the subscription plan that an account is on.
   * This is called for any plan changes to an account that isn't on a free plan.
   * Also handles addon changes
   */
  changePlan(args: {
    stripePlanId: string;
    additionalUsers: number;
    additionalSocialSets: number;
    additionalAiCredits: number;
    totalCost?: number;
    couponId?: string;
    stripeToken?: string;
  }): Promise<{ notice: PaymentDetails }> {
    const {
      stripePlanId,
      additionalUsers,
      additionalSocialSets,
      additionalAiCredits,
      totalCost,
      stripeToken,
      couponId
    } = args;

    return fetch(
      `/api/v2/subscriptions/${stripePlanId}`,
      {
        method: 'PATCH',
        headers: { 'Content-type': 'application/json' },
        body: {
          additional_users: additionalUsers,
          additional_social_sets: additionalSocialSets,
          stripeToken,
          additional_ai_credits: additionalAiCredits,
          coupon_id: couponId,
          ...(totalCost ? { recurring_charge_amount: Math.round(totalCost) } : {})
        }
      },
      { intl: null, numRetries: 0, retryStrategy: STRATEGY.DEFAULT, raw: false }
    );
  }

  /**
   * Requests the lists of payments invoiced to this account
   *
   * Returns null (and saves to cache) if the user does not have a stripe card.
   */
  retrieve: Task<Maybe<FormattedPaymentObject[]>, []> = task(async () => {
    try {
      const cachedPayments = this.cache.retrieve<FormattedPaymentObject[] | null>('payments');
      if (cachedPayments) {
        return cachedPayments;
      }

      if (!this.currentAccount?.isStripeCustomer) {
        return;
      }

      const { payments } = await fetch('/api/v2/payments.json', { method: 'GET' });

      const formattedPaymentsObject: FormattedPaymentObject[] | null = payments
        ? payments.data.map((payment: PaymentObject) => {
            const paymentDetails = {
              created: moment.unix(payment.created).format('MMM D, YYYY'),
              amount: (payment.amount / 100.0).toFixed(2),
              currency: payment.currency.toUpperCase(),
              canReceipt: !(payment.status === 'failed' || payment.refunds.total_count > 0),
              refunds: payment.refunds.total_count === 0 ? false : payment.refunds,
              status: payment.status[0].toUpperCase() + payment.status.substr(1)
            };

            return { ...payment, ...paymentDetails };
          })
        : null; // Note: null required in order to save no payments to the cache.

      this.cache.add('payments', formattedPaymentsObject as unknown as JsonValue, {
        expiry: this.cache.expiry(1, 'day')
      });

      return formattedPaymentsObject;
    } catch (error) {
      if (error.code === 422) {
        return;
      }

      if (error.code === 403) {
        return;
      }

      this.errors.log(error);
      throw error;
    }
  });

  /**
   * Requests the details of the credit card we have for the current account
   *
   * Returns null (and saves to cache) if the user does not have a stripe card.
   */
  retrieveCard: Task<Maybe<Card>, []> = task(async () => {
    try {
      const cachedCard = this.cache.retrieve<Card | null>('card');
      if (cachedCard || cachedCard === null) {
        return cachedCard;
      }

      const response: { stripe_card: JsonValue | null } = await fetch('/api/v2/stripe_card.json', {
        method: 'GET'
      });

      this.cache.add('card', response.stripe_card, {
        expiry: this.cache.expiry(1, 'hour')
      });

      return response.stripe_card as Card | null;
    } catch (error) {
      if (error.code === 422) {
        return;
      }
      this.errors.log(error);
      throw error;
    }
  });

  /**
   * Starts a sourceless (credit cardless) trial for the current user.
   */
  startSourcelessTrial: Task<PaymentDetails, [string, ValueOfType<typeof TRIAL_TYPES>]> = task(
    async (stripePlanId: string, trialType: ValueOfType<typeof TRIAL_TYPES>) => {
      try {
        return await fetch('/api/v2/subscriptions/', {
          method: 'POST',
          body: {
            plan_id: stripePlanId,
            trial_type: trialType
          }
        });
      } catch (error) {
        this.errors.log('Credit cardless trial start failed', error);
        throw error;
      }
    }
  );

  /**
   * Requests the invoice that a user would be charged if they
   * checkout with the requested subscription plan and addons.
   */
  retrieveProration(args: {
    subscription: SubscriptionModel;
    stripePlanId: string;
    additionalUsers: number;
    additionalSocialSets: number;
    additionalAiCredits: number;
  }): ReturnType<SubscriptionModel['prorate']> {
    const { subscription, stripePlanId, additionalUsers, additionalSocialSets, additionalAiCredits } = args;

    return subscription.prorate({
      plan_id: stripePlanId,
      additional_users: additionalUsers,
      additional_social_sets: additionalSocialSets,
      additional_ai_credits: additionalAiCredits
    });
  }

  /**
   * Creates a Subscription Record
   */
  async #createSubscription(args: {
    tokenId: string;
    planId: string;
    additionalUsers: number;
    additionalSocialSets: number;
    additionalAiCredits: number;
    totalCost: number;
    couponId?: string;
    trialStartLocation?: string;
  }): Promise<{ payment: PaymentDetails }> {
    const {
      tokenId,
      planId,
      additionalUsers,
      additionalSocialSets,
      additionalAiCredits,
      totalCost,
      couponId,
      trialStartLocation
    } = args;
    // Note: Google script for recaptcha is loaded in lib/checkout/addon/components/seamless-checkout/checkout-modal.ts constructor
    // https://developers.google.com/recaptcha/docs/v3
    const recaptcha_token = await grecaptcha?.execute(config.APP.googleRecaptchaSiteKey, { action: 'submit' });

    return fetch(
      '/api/v2/subscriptions',
      {
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
        body: {
          plan_id: planId,
          stripeToken: tokenId,
          coupon_id: couponId,
          recaptcha_token,
          additional_users: additionalUsers,
          additional_social_sets: additionalSocialSets,
          additional_ai_credits: additionalAiCredits,
          google_client_id: this.segment.getClientId(),
          recurring_charge_amount: Math.round(totalCost),
          trial_start_location: trialStartLocation
        }
      },
      { intl: null, numRetries: 0, retryStrategy: STRATEGY.DEFAULT, raw: false }
    );
  }
}
