import { action } from '@ember/object';
import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { isUpgrade } from 'checkout/utils/upgrade-downgrade';
import SubscriptionPlanModel from 'later/models/subscription-plan';
import * as plansData from 'later/utils/plans-data';

import type DialogManagerService from './dialog-manager';
import type RouterService from '@ember/routing/router-service';
import type { FeatureActionItem } from 'checkout/types';
import type IntlService from 'ember-intl/services/intl';
import type AccountModel from 'later/models/account';
import type SessionService from 'later/services/-private/session';
import type AuthService from 'later/services/auth';
import type PlansService from 'later/services/plans';
import type SubscriptionsService from 'later/services/subscriptions';
import type { Maybe } from 'shared/types';
import type { PlanType, Plan, PlanInterval } from 'shared/types/plans';
import type { FeatureMap } from 'shared/utils/plans-data/feature-upgrade';

enum CheckoutModal {
  TrialPlansSnapshot = 'trial-plans-snapshot',
  Checkout = 'checkout',
  CheckoutSuccess = 'checkout-success'
}

interface SeamlessUpgradeArgs {
  confirmLeave?: boolean;
  feature?: string;
  location?: string;
  socialProfileType?: string;
  successCallback?: () => void;
  isSimplifiedCheckout?: boolean;
}

interface SeamlessCheckoutArgs {
  successCallback?: () => void;
  onSuccessModalClose?: () => void;
  location?: CheckoutLocation;
  onCloseCheckoutModal?: () => void;
  onCloseOrSuccess?: () => void;
  shouldShowCheckoutSuccessModal?: boolean;
  isSimplifiedCheckout?: boolean;
}

interface TrialPlansArgs {
  onCloseOrSuccess?: () => void;
  location?: CheckoutLocation;
  trialStartLocation?: string;
}

type CheckoutLocation = 'onboarding_plan_snapshot' | 'in_app_plan_snapshot' | 'sign_up_in_checkout';

const DEFAULT_CHECKOUT_LOCATION = 'in_app_plan_snapshot';

interface LastCheckoutInitialState {
  planType?: PlanType;
  interval?: PlanInterval;
  aiCreditsCount?: number;
  socialSetCount?: number;
  teamMemberCount?: number;
  hasUpcomingAiCreditsDowngrade?: boolean;
  hasUpcomingUserDowngrade?: boolean;
  hasUpcomingSocialSetDowngrade?: boolean;
}

export default class SeamlessCheckoutManagerService extends Service {
  @service declare auth: AuthService;
  @service declare intl: IntlService;
  @service declare router: RouterService;
  @service declare subscriptions: SubscriptionsService;
  @service declare dialogManager: DialogManagerService;
  @service declare session: SessionService;
  @service declare plans: PlansService;

  @tracked isCheckoutInProgress = false;
  @tracked canCloseCheckoutModal = true;
  @tracked _currentModal: Maybe<CheckoutModal> = null;
  @tracked _selectedPlanType: Maybe<PlanType> = null;
  @tracked _selectedFeature: Maybe<string> = null;
  @tracked _socialProfileType: Maybe<string> = null;
  trialStartLocation?: string;

  CheckoutModal = CheckoutModal;

  /**
   * Used for segment event to determine if a user is checking out from onboarding
   */
  #checkoutLocation: Maybe<CheckoutLocation>;

  /**
   * Configurable when opening seamless checkout
   * Set to false when performing a custom action after checkout
   * and success modal is not needed
   */
  shouldShowCheckoutSuccessModal = true;

  /**
   * lastCheckoutInitialState
   * Used for comparing the user's key plan and addon configurations
   * so that we can compare with the state at the end of checkout.
   */
  lastCheckoutInitialState: LastCheckoutInitialState = {};

  /**
   * An optional callback registered on checkout open that runs after checkout.
   * Can be used to get UI in correct state to match new plan
   * eg. fetching data or closing additional upgrade modals
   */
  successCallback: Maybe<() => void>;

  onSuccessModalClose: Maybe<() => void>;

  /**
   * onCloseCheckoutModal
   * An optional callback called from the checkout modal. Used to add any special handling for user initiated close of the checkout modal.
   */
  onCloseCheckoutModal: Maybe<() => void>;

  /**
   * onCloseOrSuccess
   * An optional callback called when user
   *  - opens trial plans modal and closes it
   *  - opens trial plans modal, selects plan, but closes checkout
   *  - opens trial plans modal, selects plan, and completes checkout
   *  - opens checkout modal and closes it
   *  - opens checkout modal, completes checkout, and closes success modal
   *
   * This means the callback is called when the user closes the modal, regardless of whether they complete checkout or not.
   */
  onCloseOrSuccess: Maybe<() => void>;

  /*
   * Whether the checkout should display in it's simplified form for addons
   */
  isSimplifiedCheckout: Maybe<boolean>;

  get featureMapList(): Record<string, FeatureMap> {
    return plansData.featureUpgradeMap(this.intl);
  }

  get featureActionsList(): Record<string, FeatureActionItem> {
    return {
      aiCredits: {
        action: this.openCheckoutForAiCredits
      },
      aiCreditsOneBundle: {
        action: this.openCheckoutForAiCredits
      },
      aiCreditsTwoBundles: {
        action: this.openCheckoutForAiCredits
      },
      creator: {
        action: () => this.openCheckoutModal('creator')
      },
      user: {
        action: () => {
          this.openCheckoutModal(this.subscriptions.planType);
        }
      },
      socialSet: {
        action: () => {
          this.openCheckoutModal(this.subscriptions.planType);
        }
      },
      interval: {
        action: () => {
          this.openCheckoutModal(this.subscriptions.planType);
        }
      },
      getMorePosts: {
        action: () => {
          const currentPlanType = this.subscriptions.planType ? this.subscriptions.planType : 'free';
          const currentPlanTypeAsKey = currentPlanType as keyof typeof this.subscriptions.postLimitUpgradeMap;

          const selectedPlanType = this.subscriptions.postLimitUpgradeMap[currentPlanTypeAsKey] || 'advanced';

          this.openCheckoutModal(selectedPlanType as PlanType);
        }
      },
      plans: {
        action: () => {
          this.router.transitionTo('plans.index');
        }
      },
      aiCaptionWriter: {
        action: () => {
          this.openCheckoutModal(this.selectedPlanType, { isSimplifiedCheckout: true });
        }
      }
    };
  }

  get #isBlockedFromSeamlessCheckout(): boolean {
    // Note: non-owners use the upgrade route to redirect to subscription overview.
    if (!this.isAccountOwner) {
      return true;
    }

    // Note: Users with an active mobile subscription are redirected to settings
    if (this.subscriptions.isMobileSubscription) {
      return true;
    }
    return false;
  }

  // Note: is now used for trial starts and upgrade
  get canSeamlessCheckout(): boolean {
    if (this.#isBlockedFromSeamlessCheckout) {
      return false;
    }
    return this.isSessionAuthenticated;
  }

  get currentAccount(): AccountModel {
    return this.auth.currentAccount;
  }

  get featureName(): string {
    return this._selectedFeature ? this.featureMapList[this._selectedFeature]?.featureName : '';
  }

  get isAccountOwner(): boolean {
    return this.auth.currentUserModel.isAccountOwner;
  }

  get isFreeEligibleForTrial(): boolean {
    return !this.currentAccount.hasActiveSubscription && this.subscriptions.isEligibleForTrial;
  }

  get isSessionAuthenticated(): boolean {
    return this.session.isAuthenticated;
  }

  get location(): Maybe<CheckoutLocation> {
    return this.#checkoutLocation;
  }

  get selectedPlanName(): string {
    return this.intl.t(`plans.plan_names.${this.selectedPlanType}`);
  }

  get selectedFeature(): Maybe<string> {
    return this._selectedFeature;
  }

  get selectedPlanType(): Maybe<PlanType> {
    return this._selectedPlanType;
  }

  get socialProfileType(): Maybe<string> {
    return this._socialProfileType;
  }

  get showCheckoutModal(): boolean {
    return this._currentModal == this.CheckoutModal.Checkout && !!this._selectedPlanType;
  }

  // Note: Trial Plans modal shows when no plan is selected.
  get showTrialPlansModal(): boolean {
    return this._currentModal == this.CheckoutModal.TrialPlansSnapshot;
  }

  // Note: Show this modal after checkout is complete
  get showCheckoutSuccessModal(): boolean {
    return this._currentModal == this.CheckoutModal.CheckoutSuccess;
  }

  get #shouldMigrateLegacyPlan(): boolean {
    return this.currentAccount?.isOnLegacyPlan && !this.currentAccount?.pricingV1Free;
  }

  getPlanTypeFromFeature(): Maybe<PlanType> {
    return this._selectedFeature ? this.featureMapList[this._selectedFeature]?.planName : null;
  }

  openCheckoutModal(planType: Maybe<PlanType>, args: SeamlessCheckoutArgs = {}): void {
    const {
      successCallback,
      location,
      onSuccessModalClose,
      shouldShowCheckoutSuccessModal,
      onCloseCheckoutModal,
      onCloseOrSuccess,
      isSimplifiedCheckout
    } = args;
    if (successCallback) {
      this.successCallback = successCallback;
    }

    if (onSuccessModalClose) {
      this.onSuccessModalClose = onSuccessModalClose;
    }

    if (onCloseOrSuccess) {
      this.onCloseOrSuccess = onCloseOrSuccess;
    }

    if (location) {
      this.#checkoutLocation = location;
    }

    if (onCloseCheckoutModal) {
      this.onCloseCheckoutModal = onCloseCheckoutModal;
    }

    if (typeof shouldShowCheckoutSuccessModal === 'boolean') {
      this.shouldShowCheckoutSuccessModal = shouldShowCheckoutSuccessModal;
    }

    if (typeof isSimplifiedCheckout === 'boolean') {
      this.isSimplifiedCheckout = isSimplifiedCheckout;
    }

    // Note: Need to fetch the latest subscription and account data before opening checkout. There can be a delay
    // in nexus events updating account and subscription data resulting in a desync between the UI and the backend.
    Promise.all([this.subscriptions.reload(), this.currentAccount.reload()]).then(() => {
      this._selectedPlanType = planType;
      this._currentModal = this.CheckoutModal.Checkout;
    });
  }

  async openTrialPlansModal(args: TrialPlansArgs = {}): Promise<void> {
    const { onCloseOrSuccess, location, trialStartLocation } = args;
    this.onCloseOrSuccess = onCloseOrSuccess;
    if (location) {
      this.#checkoutLocation = location;
    }

    if (trialStartLocation) {
      this.trialStartLocation = trialStartLocation;
    }

    await this.subscriptions.getAllSubscriptionPlans();
    this._currentModal = this.CheckoutModal.TrialPlansSnapshot;
  }

  openCheckoutSuccessModal(): void {
    this._currentModal = this.CheckoutModal.CheckoutSuccess;
  }

  // Note: Will close whatever modal was active
  closeCheckoutModal(): void {
    this.onCloseCheckoutModal?.();
    this.onCloseCheckoutModal = undefined;
    this.closeModal();
  }

  closeModal(): void {
    this._currentModal = undefined;
    this.onCloseOrSuccess?.();
    this.reset();
  }

  reset(): void {
    this.successCallback = undefined;
    this.onSuccessModalClose = undefined;
    this.onCloseOrSuccess = undefined;
    this.#checkoutLocation = DEFAULT_CHECKOUT_LOCATION;
    this._selectedPlanType = undefined;
    this._selectedFeature = undefined;
    this._socialProfileType = undefined;
    this.isSimplifiedCheckout = undefined;
    this.canCloseCheckoutModal = true;
    this.shouldShowCheckoutSuccessModal = true;
    this.setLastCheckoutInitialState({});
    this.trialStartLocation = undefined;
  }

  setLastCheckoutInitialState(state: LastCheckoutInitialState): void {
    this.lastCheckoutInitialState = state;
  }

  upgrade(args: SeamlessUpgradeArgs = {}): void {
    const { confirmLeave, feature, location, socialProfileType, successCallback, isSimplifiedCheckout } = args;

    this.subscriptions.trackUpgrade(location);

    if (!feature) {
      this.#handleUserToChoosePlan(confirmLeave);
      return;
    }

    if (successCallback) {
      this.successCallback = successCallback;
    }

    if (typeof isSimplifiedCheckout === 'boolean') {
      this.isSimplifiedCheckout = isSimplifiedCheckout;
    }

    this._selectedFeature = feature;
    this._socialProfileType = socialProfileType;
    const planTypeFromFeature = this.getPlanTypeFromFeature();

    if (this.#shouldMigrateLegacyPlan && this.subscriptions.subscriptionPlan instanceof SubscriptionPlanModel) {
      this.#handleLegacyUser(this.subscriptions.subscriptionPlan, feature, planTypeFromFeature);
      return;
    }

    if (planTypeFromFeature) {
      this.openCheckoutModal(planTypeFromFeature, { isSimplifiedCheckout });
    } else if (this.featureActionsList[feature]) {
      this.featureActionsList[feature].action();
    } else {
      // Note: catch-all needed to handle scenarios where feature could not be mapped (stale code?).
      this.#handleUserToChoosePlan(confirmLeave);
    }
  }

  async #confirmLeave(): Promise<boolean> {
    const title = this.intl.t('shared_phrases.are_you_sure');
    const confirmArgs = {
      description: this.intl.t('post.unsaved_modal.message'),
      confirmButton: this.intl.t('shared_words.continue')
    };

    return await this.dialogManager.confirmation(title, confirmArgs);
  }

  async #handleUserToChoosePlan(confirmLeave = false): Promise<void> {
    if (this.isFreeEligibleForTrial) {
      this.openTrialPlansModal();
    } else {
      // Note: this is potentially a temporary solution. A plans modal may be introduced down the road.
      if (confirmLeave) {
        const didConfirm = await this.#confirmLeave();
        if (!didConfirm) {
          return;
        }
      }
      this.router.transitionTo('plans.index');
    }
  }

  @action
  openCheckoutForAiCredits(): void {
    const planType = this.subscriptions.subscriptionPlan?.canAddAiCredits ? this.subscriptions.planType : 'starter';
    this.openCheckoutModal(planType, { isSimplifiedCheckout: Boolean(this.isSimplifiedCheckout) });
  }

  #handleLegacyUser(
    subscriptionPlan: SubscriptionPlanModel,
    feature: string,
    planTypeFromFeature: Maybe<PlanType>
  ): void {
    if (feature === 'migrate' || feature == 'plans') {
      this.router.transitionTo('plans.migrate');
    } else {
      const migrationPlanType = this.#getMigrationPlanType(subscriptionPlan, planTypeFromFeature);
      this.openCheckoutModal(migrationPlanType);
    }
  }

  /**
   * For getting the new plan type a user should migrate to if they are on a
   * custom or legacy plan.
   *
   * Returns 'advanced' for any custom plans.
   *
   * Returns plan type the user should upgrade to.
   *
   * Returns planTypeOverride if given and it is an upgrade over the migrationPlan found.
   *
   */
  #getMigrationPlanType(subscriptionPlan: SubscriptionPlanModel, planTypeOverride: Maybe<PlanType>): PlanType {
    if (this.auth.currentAccount.hasCustomPlan) {
      return 'advanced';
    }

    // The plan Type the user should migrate to based on their legacy plan
    const planTypeFromLegacyPlanMap = this.plans.legacyPlans.find(
      (plan) => plan.canonicalName === subscriptionPlan.planType
    )?.migrationPlan as PlanType;

    if (!planTypeOverride || planTypeFromLegacyPlanMap === planTypeOverride) {
      return planTypeFromLegacyPlanMap;
    }

    const planFromLegacyPlanMap = this.plans.plans.find((plan: Plan) => plan.planType === planTypeFromLegacyPlanMap);

    const planOverride = this.plans.plans.find((plan: Plan) => plan.planType === planTypeOverride);

    return isUpgrade(planFromLegacyPlanMap, planOverride, this.plans.plans)
      ? planTypeOverride
      : planTypeFromLegacyPlanMap;
  }
}

declare module '@ember/service' {
  interface Registry {
    'seamless-checkout-manager': SeamlessCheckoutManagerService;
  }
}
