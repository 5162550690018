import { A } from '@ember/array';
import { reads, readOnly } from '@ember/object/computed';
import Service, { inject as service } from '@ember/service';
import { isNone, isPresent } from '@ember/utils';
import { didCancel, task } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';

import IgPost from 'later/models/ig-post';
import { INSTAGRAM_GRAPH_URL, OauthSocialProfileType } from 'later/utils/constants';
import { fetch } from 'later/utils/fetch';
import objectPromiseProxy from 'later/utils/object-promise-proxy';
import redirect from 'shared/utils/redirect';

import type { Hashtag } from 'collect/types/hashtag-search';
import type { TaskGenerator, TaskInstance } from 'ember-concurrency';
import type IntlService from 'ember-intl/services/intl';
import type GroupModel from 'later/models/group';
import type IgComment from 'later/models/ig-comment';
import type SocialProfileModel from 'later/models/social-profile';
import type AlertsService from 'later/services/alerts';
import type AuthService from 'later/services/auth';
import type CacheService from 'later/services/cache';
import type ErrorsService from 'later/services/errors';
import type InstagramBasicDisplayService from 'later/services/instagram-basic-display';
import type InstagramGraphService from 'later/services/instagram-graph';
import type { FetchHashtagParams, FetchMediaParams, InstagramPosts } from 'later/services/instagram-graph';
import type JWTService from 'later/services/jwt';
import type LaterConfigService from 'later/services/later-config';
import type ConnectProfilesService from 'later/services/social/connect-profiles';
import type { Maybe, UntypedLaterModel } from 'shared/types';
import type {
  InstagramCommentReply,
  InstagramGraphUser,
  InstagramPagination,
  InstagramRequestError,
  RecentInstagramMedia
} from 'shared/types/instagram';

interface InstagramLoginData {
  credentials: { token: string };
  uid: string;
  permissionScope: string;
  scope: string[];
}

interface InstagramLoginEvent extends Event {
  origin?: string;
  data?: InstagramLoginData;
}

interface UsernameCacheQuery {
  [key: string]: string[];
}

/**
 * This service acts as an entry point for interacting with the
 * Instagram API. It's intent it to automatically decide whether
 * to use the Instagram Graph API or the Basic Display API by
 * looking at the social profile provided to a given method call.
 **/
export default class InstagramService extends Service {
  @service declare alerts: AlertsService;
  @service declare auth: AuthService;
  @service declare cache: CacheService;
  @service declare errors: ErrorsService;
  @service declare instagramBasicDisplay: InstagramBasicDisplayService;
  @service declare instagramGraph: InstagramGraphService;
  @service declare intl: IntlService;
  @service declare jwt: JWTService;
  @service declare laterConfig: LaterConfigService;
  @service('social/connect-profiles') declare connectProfiles: ConnectProfilesService;

  @readOnly('instagramGraph.errorCode') declare errorCode?: number;
  @readOnly('auth.currentUserModel.facebookToken') declare facebookUserToken: string;
  @reads('laterConfig.instagramClientId') declare instagramClientId: string;
  @reads('auth.currentSocialProfile') declare socialProfile: SocialProfileModel;

  /**
   * Sets the GrapAPI Token to the current user's Facebook Token.
   * This method is a direct call-through to the InstagramGraph Service
   */
  setGraphAccessToken(): void {
    this.instagramGraph.setAccessToken(this.facebookUserToken);
  }

  /**
   * Gets the GrapAPI Token that will be used by the `fbgraph` library
   * while making requests to the API.
   */
  getGraphAccessToken(): Maybe<string> {
    return this.instagramGraph.getAccessToken();
  }

  /**
   * Nullifies the errorCode property on the InstagramGraph Service.
   * This method is a direct call-through to the InstagramGraph Service
   */
  clearError(): void {
    this.instagramGraph.set('errorCode', undefined);
  }

  /**
   * Perform OAuth request with Instagram
   */
  async loginInstagram(socialProfile: SocialProfileModel, isRefresh: true): Promise<InstagramLoginData>;
  async loginInstagram(socialProfile: SocialProfileModel, isRefresh?: false): Promise<void>;
  async loginInstagram(socialProfile: SocialProfileModel, isRefresh = false): Promise<InstagramLoginData | void> {
    const path = this.connectProfiles.oAuthPath({ socialProfileType: OauthSocialProfileType.Instagram });

    if (isRefresh) {
      const popup = await this.#makePopupWindow('');
      if (popup) {
        popup.location = `${path}&social_profile_id=${socialProfile?.id}`;
        return this.#listenPopupEvent(popup);
      }
    }

    if (isNone(this.auth.currentGroup)) {
      redirect(path);
    } else {
      redirect(`${path}&group_id=${this.auth.currentGroup.id}`);
    }
  }

  /**
   * Perform OAuth request with Instagram
   */
  async createInstagramWithSet(setId: Maybe<string>, group: GroupModel, redirectPath: Maybe<string>): Promise<void> {
    const path = this.connectProfiles.oAuthPath({
      socialProfileType: OauthSocialProfileType.Instagram,
      redirectGroupSlug: group.slug,
      redirectPath
    });

    if (isNone(setId)) {
      redirect(`${path}&group_id=${group.id}`);
    } else {
      redirect(`${path}&social_identity_id=${setId}`);
    }
  }

  async refreshInstagramToken(socialProfileProxy: SocialProfileModel): Promise<SocialProfileModel> {
    const isRefresh = true;
    const socialProfile = (await objectPromiseProxy(socialProfileProxy)) as SocialProfileModel;
    const auth = await this.loginInstagram(socialProfile, isRefresh);
    const {
      uid,
      credentials: { token },
      permissionScope
    } = auth;
    const username = this.fetchBasicDisplayUsername(token);

    if (uid === socialProfile.uid || (await username) === socialProfile.nickname) {
      const scopes = socialProfile.permissionScope || A([]);
      scopes.pushObjects([permissionScope]);
      socialProfile.setProperties({
        basicDisplayId: uid,
        basicDisplayToken: token
      });
      socialProfile.set('permissionScope', scopes.uniq());
      await socialProfile.save();
      this.jwt.clearToken();
      this.alerts.success(this.intl.t('alerts.account.controllers.groups.reconnect_success'));
      return socialProfile;
    }
    throw new Error(
      this.intl.t('alerts.account.controllers.groups.wrong_profile', {
        social_profile: socialProfile.nickname,
        username
      })
    );
  }

  /**
   * Fetches Recent Media (Posts) for specified socialProfile,
   * can handle paging through this endpoint.
   */
  async fetchRecentMedia(
    socialProfileProxy: SocialProfileModel,
    params: FetchMediaParams,
    pagination?: InstagramPagination | Record<string, never>
  ): Promise<RecentInstagramMedia | undefined> {
    const socialProfile = (await objectPromiseProxy(socialProfileProxy)) as SocialProfileModel;
    const hasBasicDisplayToken = isPresent(socialProfile.basicDisplayToken);
    const isBusiness = socialProfile.hasProfessionalAccount;
    const token = isBusiness
      ? this.auth.currentUserModel.facebookToken || socialProfile.businessAccountToken
      : undefined;

    const paramsWithPagination = this.#nextPageParams(params, () => {
      if (this.#isBasicDisplayPagination(pagination) && hasBasicDisplayToken) {
        return { next: pagination.next };
      } else if (pagination && pagination.cursors && isBusiness) {
        return { after: pagination.cursors.after };
      }
      return {};
    });

    if (isBusiness && !this.#isBasicDisplayPagination(pagination)) {
      try {
        const response = await this.instagramGraph.fetchRecentMedia(socialProfile, paramsWithPagination, token);
        return response ? Object.assign({}, response, { isBusiness }) : undefined;
      } catch (error) {
        const response = await this.instagramBasicDisplay.fetchRecentMedia
          .perform(socialProfile, paramsWithPagination)
          .catch((err) => {
            if (!didCancel(err)) {
              throw err;
            }
          });

        return response ? Object.assign({}, response, { isBusiness }) : undefined;
      }
    }
    const response = await this.instagramBasicDisplay.fetchRecentMedia
      .perform(socialProfile, paramsWithPagination)
      .catch((err) => {
        if (!didCancel(err)) {
          throw err;
        }
      });

    return response ? Object.assign({}, response, { isBusiness }) : undefined;
  }

  /**
   * Calls the {instagramGraph,instagramBasicDisplay}.fetchSelf method
   */
  async fetchSelf(
    socialProfile: SocialProfileModel
  ): Promise<UntypedLaterModel<'IgUser'> | InstagramGraphUser | undefined> {
    if (socialProfile.hasProfessionalAccount) {
      try {
        return await this.instagramGraph.fetchSelf(socialProfile, this.facebookUserToken);
      } catch (error) {
        return await taskFor(this.instagramBasicDisplay.fetchSelf).perform(socialProfile);
      }
    }

    return taskFor(this.instagramBasicDisplay.fetchSelf).perform(socialProfile);
  }

  /**
   * Fetches specified Instagram Media
   *
   * @param socialProfile The socialProfile making the request
   * @param mediaId Instagram Media's ID. Either from IG or Graph APIs
   * @param params Endpoint specific parameters
   *
   * @returns Formatted Instagram Post
   */
  async fetchMedia(
    socialProfile: SocialProfileModel,
    mediaId: string,
    params: FetchMediaParams
  ): Promise<IgPost | undefined> {
    const isProfessional = socialProfile.hasProfessionalAccount;
    if (isProfessional) {
      try {
        return await this.instagramGraph.fetchMedia(socialProfile, mediaId, params, this.facebookUserToken);
      } catch (error) {
        return await taskFor(this.instagramBasicDisplay.fetchMedia).perform(socialProfile, mediaId);
      }
    }

    return taskFor(this.instagramBasicDisplay.fetchMedia).perform(socialProfile, mediaId);
  }

  fetchMediaComments(socialProfile: SocialProfileModel, post: IgPost, pagingInfo?: string): Promise<IgComment[]> {
    return this.instagramGraph.fetchMediaComments(socialProfile, post, pagingInfo, this.facebookUserToken);
  }

  /**
   * Fetches the Instagram Posts where the requesting user has
   * been tagged by another Instagram User.
   * This method is a direct call-through to the InstagramGraph Service
   *
   * @param params Endpoint specific parameters. See https://developers.facebook.com/docs/instagram-api/reference/user/tags
   */
  fetchTaggedMedia(socialProfile: SocialProfileModel, params: FetchMediaParams): Promise<InstagramPosts> {
    return this.instagramGraph.fetchTaggedMedia(socialProfile, params);
  }

  /**
   * Fetches a specific hashtag.
   * This method is a direct call-through to the InstagramGraph Service
   */
  fetchHashtag(socialProfile: SocialProfileModel, name: string): Promise<Hashtag> {
    return this.instagramGraph.fetchHashtag(socialProfile, name);
  }

  /**
   * Fetches recent media that has been tagged with a specific hashtag
   * This method is a direct call-through to the InstagramGraph Service
   *
   * @param params Endpoint specific parameters. See https://developers.facebook.com/docs/instagram-api/reference/hashtag/recent-media
   */
  fetchHashtagMedia(
    endpoint = 'recent_media',
    socialProfile: SocialProfileModel,
    hashtagId: string,
    params: FetchHashtagParams = { limit: 50 }
  ): Promise<InstagramPosts> {
    return this.instagramGraph.fetchHashtagMedia(endpoint, socialProfile, hashtagId, params);
  }

  /**
   * Fetches a specific hashtag by id
   * This method is a direct call-through to the InstagramGraph Service
   *
   * @param params Endpoint specific parameters. See https://developers.facebook.com/docs/instagram-api/reference/hashtag
   */
  fetchHashtagById(id: string, params: FetchHashtagParams): Promise<Hashtag> {
    return this.instagramGraph.fetchHashtagById(id, params);
  }

  /**
   * Fetches the list of recently searched hashtags for the provided profile
   * This method is a direct call-through to the InstagramGraph Service
   *
   * @param params Endpoint specific parameters. See https://developers.facebook.com/docs/instagram-api/reference/user/recently_searched_hashtags
   */
  fetchRecentHashtag(socialProfile: SocialProfileModel, params: FetchHashtagParams): Promise<Hashtag[]> {
    return this.instagramGraph.fetchRecentHashtag(socialProfile, params);
  }

  /**
   * Get a list of suggested_usernames from cache or by calling the backend api.
   */
  @task
  *fetchUsernames(socialProfile: SocialProfileModel, query: string): TaskGenerator<string[]> {
    const id = socialProfile.get('id');
    const cache: UsernameCacheQuery = (this.cache.retrieve(`usernames_${id}`) as UsernameCacheQuery) ?? {};
    let usernames: string[];

    if (cache[query]) {
      usernames = cache[query];
    } else if (query.length > 1 && cache[query.slice(0, -1)]) {
      usernames = cache[query.slice(0, -1)].filter((username) => username.includes(query.toLowerCase()));
    } else {
      usernames = yield taskFor(this.fetchUsernamesFromApi).perform(id, query);
    }

    cache[query] = usernames;
    this.cache.add(`usernames_${id}`, cache, { expiry: this.cache.expiry(1, 'day') });

    return usernames;
  }

  @task
  *fetchUsernamesFromApi(id: string, query: string): TaskGenerator<string[]> {
    try {
      const result = yield fetch(`/api/v2/social_profiles/${id}/collected_usernames?username_input=${query}`);
      return result.usernames;
    } catch (error) {
      this.errors.log(error);
      return [];
    }
  }

  fetchBasicDisplayUsername(token: string): TaskInstance<Maybe<string>> {
    return taskFor(this.instagramBasicDisplay.fetchUsername).perform(token);
  }

  postReply(commentId: string, message: string, isReply = false): Promise<InstagramCommentReply> {
    return this.instagramGraph.postReply(commentId, message, isReply);
  }

  deleteComment(commentId: string, params = {}): Promise<void> {
    return this.instagramGraph.deleteComment(commentId, params);
  }

  /**
   * Calls the instagramBasicDisplay.raiseError method
   */
  raiseError(error: InstagramRequestError): void {
    this.instagramBasicDisplay.raiseError(error);
  }

  /**
   * Checks whether the provided pagination object indicates
   * that there is another page of data that can be requested
   *
   * @param pagination The pagination object returned
   * from a previous call to an Instagram API
   */
  hasMorePages(pagination?: InstagramPagination): boolean {
    if (!pagination) {
      return false;
    }

    if (pagination && !pagination.next_url && !pagination.next) {
      return false;
    }

    return true;
  }

  isIgPostArray(posts: unknown[]): posts is IgPost[] {
    return posts.every((post) => post instanceof IgPost);
  }

  /**
   * Builds params for a paginated request
   *
   * @returns The original params for a request
   * with the extra pagination details added
   */
  #nextPageParams(
    params: FetchMediaParams,
    callback: () => Omit<InstagramPagination, 'cursors'> & FetchMediaParams
  ): Omit<InstagramPagination, 'cursors'> & FetchMediaParams {
    return Object.assign({}, params, callback());
  }

  /**
   * Attaches an event lister to the oAuth popup window
   */
  #listenPopupEvent(popup: Maybe<Window>): Promise<InstagramLoginData> {
    return new Promise((resolve, reject) => {
      const isIEWindow = isNone(window.addEventListener);
      const eventMethod = isIEWindow ? window.attachEvent : window.addEventListener;
      const messageEvent = isIEWindow ? 'onmessage' : 'message';

      try {
        const interval = window.setInterval(() => {
          if (!popup || popup.closed) {
            window.clearInterval(interval);
            reject('Please retry and login to Instagram to proceed.');
            return;
          }
        }, 1000);

        // Listen to message from child window
        eventMethod(
          messageEvent,
          (event: InstagramLoginEvent) => {
            console.log('From origin:', event.origin);
            if (event.origin !== document.location.origin) {
              reject('Please retry and login to Instagram to proceed.');
              return;
            }
            console.log('parent received message:', event.data);
            if (event.data?.credentials) {
              //need to filter for this, fullstory uses the message passing as well
              resolve(event.data);
            }
          },
          false
        );
      } catch (error) {
        this.errors.log(error);
        reject('Could not login to Instagram');
      }
    });
  }

  /**
   * Opens an oAuth Window
   */
  async #makePopupWindow(url: string): Promise<Maybe<Window>> {
    document.cookie = 'oauth_popup=1; path=/;max-age=60*5';
    return window.open(url, 'oauthWindow', 'location=0,status=0,width=800,height=600');
  }

  /**
   * Returns true if pagination object comes from basic display call
   */
  #isBasicDisplayPagination(
    pagination?: InstagramPagination | Record<string, never>
  ): pagination is InstagramPagination {
    return pagination?.next?.includes(INSTAGRAM_GRAPH_URL) ?? false;
  }
}

declare module '@ember/service' {
  interface Registry {
    instagram: InstagramService;
  }
}
