/**
 * @module Services
 */

import { assert } from '@ember/debug';
import Service, { inject as service } from '@ember/service';
import { isPresent, isEmpty } from '@ember/utils';
import { enqueueTask } from 'ember-concurrency';
import { flatten } from 'lodash';
import RSVP from 'rsvp';

import { convert } from 'later/utils/time-format';
import {
  sanitizeHashtagsOutsideCutoffRank,
  groupWeeklyDataByHashtag,
  fillMissingHashtagData
} from 'shared/utils/formatters/format-hashtag-trends';

/**
 * @class AnalyticsInstagramService
 * @extends Service
 */
export default class LaterAnalyticsService extends Service {
  @service errors;
  @service('analytics/helpers-analytics') helpersAnalytics;
  @service industryData;
  @service intl;
  @service('analytics/later-api') laterApi;

  /**
   * Returns Instagram posts for free users.
   *
   * @property getPosts
   * @param {Boolean} [forceRefresh=false]
   *
   * @return {Array<IgPost>} Instagram posts in the given time range.
   */
  @enqueueTask
  *getLinkinbioAnalyticsPosts(forceRefresh = false) {
    return yield this.laterApi.getLinkinbioAnalyticsPosts.linked().perform(forceRefresh);
  }

  /**
   * Returns Later hashtag usage statistics over
   * time for various industries
   *
   * @property getHashtagTrends
   * @param {Moment} startMoment
   * @param {Moment} endMoment
   * @param {SocialProfile} socialProfile
   * @param {Number} minRank
   * @param {Number} maxRank
   * @param {Boolean} [forceRefresh=false]
   *
   * @return {(AnalyticsError|Array<FormattedHashtagTrends>)} Hashtag Trends Object
   */
  @enqueueTask
  *getHashtagTrends(startMoment, endMoment, socialProfile, minRank, maxRank, forceRefresh = false) {
    assert('Must pass a valid social profile industry to getHashtagTrends', socialProfile?.industry);

    const durationSeconds = endMoment.unix() - startMoment.unix();
    const pointSpacingInS = convert.week().toSeconds();
    const numberOfPoints = Number((durationSeconds / pointSpacingInS).toFixed());

    const unixStartTimes = [...Array(numberOfPoints)].map((_, currentPointIndex) => {
      const currentPointTimeUnix = startMoment.unix() + currentPointIndex * pointSpacingInS;
      return moment(currentPointTimeUnix * 1000)
        .utc()
        .startOf('day')
        .unix();
    });
    const startDates = unixStartTimes.map((unixTimestamp) =>
      moment(unixTimestamp * 1000)
        .utc()
        .format('YYYY-MM-DD')
    );

    const settledPromises = yield RSVP.allSettled(
      startDates.map((startDate) =>
        this.industryData.getIndustryHashtags.linked().perform(socialProfile, startDate, 'week', forceRefresh)
      )
    );

    const rejectedPromises = settledPromises.filter((settledPromise) => settledPromise.state === 'rejected');
    const fulfilledPromises = settledPromises.filter((settledPromise) => settledPromise.state === 'fulfilled');

    if (isPresent(rejectedPromises)) {
      const errors = rejectedPromises.map((settledPromise) => settledPromise.reason);
      this.errors.log(errors.get('firstObject'), errors, 'warning');
      return this.helpersAnalytics.formatError(
        this.intl.t('analytics.instagram.hashtag.hashtag_trends.chart.error_state')
      );
    }

    const hashtagTrends = isEmpty(fulfilledPromises)
      ? []
      : flatten(fulfilledPromises.map((settledPromise) => settledPromise.value));

    const sanitizedHashtagData = sanitizeHashtagsOutsideCutoffRank(
      groupWeeklyDataByHashtag(hashtagTrends),
      minRank,
      maxRank,
      unixStartTimes
    );

    return { data: fillMissingHashtagData(sanitizedHashtagData, unixStartTimes), unixStartTimes };
  }
}
