import Service, { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency';
import moment from 'moment';
import { TrackedArray } from 'tracked-built-ins';

import { CredentialsNotFoundError } from 'later/errors/credentials';
import { ErrorSeverity } from 'later/services/errors';
import { timestamp } from 'later/utils/time-format';
import filterList from 'shared/utils/feature-requirements';

import type { TaskGenerator } from 'ember-concurrency';
import type GroupModel from 'later/models/group';
import type SocialProfileModel from 'later/models/social-profile';
import type UserModel from 'later/models/user';
import type AuthService from 'later/services/auth';
import type ErrorsService from 'later/services/errors';
import type InstagramService from 'later/services/instagram';
import type LocalStorageManagerService from 'later/services/local-storage-manager';
import type OnboardingService from 'later/services/onboarding';
import type { Maybe, UntypedService } from 'shared/types';
import type { FeatureRequirement } from 'shared/utils/feature-requirements';

export default class CredentialStatusService extends Service {
  @service declare auth: AuthService;
  @service declare errors: ErrorsService;
  @service declare localStorageManager: LocalStorageManagerService;
  @service('social/instagram-business') declare instagramBusiness: UntypedService;
  @service declare onboarding: OnboardingService;
  @service declare instagram: InstagramService;

  @tracked activeProfiles = new TrackedArray<SocialProfileModel>();
  @tracked nextCredentialDisplayDate = 1;
  @tracked successfulRefresh = false;
  @tracked isReconnectSocialProfileModalVisible = false;
  @tracked socialProfileToReconnect?: SocialProfileModel;

  MAX_EXPIRY_TIME_IN_HOURS = 720;

  get currentUserModel(): Maybe<UserModel> {
    return this.auth.currentUserModel;
  }

  get currentGroup(): Maybe<GroupModel> {
    return this.auth.currentGroup;
  }

  get currentSocialProfile(): SocialProfileModel {
    return this.auth.currentSocialProfile;
  }

  get completedOnboarding(): boolean {
    return !this.onboarding.hasNotCompletedOnboarding;
  }

  get instagramProfiles(): Maybe<SocialProfileModel[]> {
    return this.currentGroup?.socialProfiles.filter((profile: SocialProfileModel) => profile.isInstagram);
  }

  get isAccountOwner(): boolean {
    return Boolean(this.currentUserModel?.isAccountOwner);
  }

  get canDisplayModal(): boolean {
    return (
      this.invalidUserFacebookToken ||
      this.successfulRefresh ||
      this.hasErrors ||
      this.instagramHasErrors ||
      this.instagramBusiness.hasUnconnectedProfessional
    );
  }

  /**
   * Used to display AdvancedDisplayStatus modal for expired Instagram profiles
   */
  get displayModal(): boolean {
    return this.canDisplayModal && this.shouldShowDisplayModal && this.hasInstagramProfile;
  }

  get canDisplaySingleProfileModal(): boolean {
    return (
      this.invalidUserFacebookToken ||
      this.successfulRefresh ||
      this.hasErrors ||
      this.currentProfileHasErrors ||
      this.instagramBusiness.hasUnconnectedProfessional
    );
  }

  get singleProfileDisplayModal(): boolean {
    return this.canDisplaySingleProfileModal && this.shouldShowDisplayModal;
  }

  get hasInstagramProfile(): boolean {
    return Boolean(this.instagramProfiles?.length);
  }

  /**
   * Used to display modal if there are any invalid profiles within the group.
   * This is to be used when the modal is displayed in an area where you can have multiple social profiles selected/visible.
   */
  get instagramHasErrors(): boolean {
    return Boolean(
      this.instagramProfiles?.any(
        ({ hasError, needsRefresh, isProfessional, hasProfessionalAccount }) =>
          hasError || needsRefresh || (isProfessional && !hasProfessionalAccount)
      )
    );
  }

  /**
   * Used to display modal if the current social profile on the auth service need to display credential status.
   * This is to be used when the modal is displayed in an area where you can have at most one social profile selected at a time.
   */
  get currentProfileHasErrors(): boolean {
    return (
      this.currentSocialProfile?.hasError ||
      this.currentSocialProfile?.needsRefresh ||
      (this.currentSocialProfile?.isProfessional && !this.currentSocialProfile?.hasProfessionalAccount)
    );
  }

  /**
   * Array of social profiles that are coming back from the API and either have errors or require refreshes.
   */
  get brokenProfiles(): TrackedArray<SocialProfileModel> {
    if (this.invalidUserFacebookToken) {
      return this.activeProfiles;
    }
    const brokenProfiles = this.activeProfiles.filter(
      ({ hasError, needsRefresh, isProfessional, hasProfessionalAccount }) =>
        hasError || needsRefresh || (isProfessional && !hasProfessionalAccount)
    );

    return new TrackedArray(brokenProfiles);
  }

  /**
   * Returns true if any of the linked passed in social profiles need to be refreshed. See social-profile model to get
   * a better idea of what this is checking for.
   */
  get needsRefresh(): boolean {
    const results = this.activeProfiles.any((profile) => profile.needsRefresh);

    return results;
  }

  get needsConnectedProfessional(): boolean {
    return this.activeProfiles.any((profile) => profile.isProfessional && !profile?.hasProfessionalAccount);
  }

  /**
   * Returns true if any of the linked passed in social profiles have errors. See social-profile model to get
   * a better idea of what this is checking.
   */
  get hasBasicDisplayErrors(): boolean {
    return this.activeProfiles.any((profile) => profile.needsBasicDisplayTokenRefresh);
  }

  get hasErrors(): boolean {
    return this.activeProfiles.any((profile) => profile.hasError);
  }

  /**
   * Checks for Invalid User Facebook token on the currently logged in user. Used to
   * show accurate status for interactions where we interact with the Facebook graph API directly.
   *
   * Current Features using this:
   * - Conversations
   * - Collect (Hashtags)
   * - Collect (Tags)
   * - Collect (Mentions)
   */
  get invalidUserFacebookToken(): boolean {
    return isEmpty(this.currentUserModel?.facebookToken);
  }

  /**
   * Checks for Invalid User Facebook tokens on the broken social profiles. This field is taken from the account owner who set up
   * the connection. Used to show accurate status for interactions that are controlled through our API.
   *
   * Current Features using this:
   * - Autopublish
   * - Preview Grid
   * - Linkin.bio
   * - Analytics
   */
  get invalidProfileFacebookTokens(): boolean {
    return this.needsRefresh || this.hasErrors || this.needsConnectedProfessional;
  }

  /**
   * Returns a list of the features (from the reference Util) are listed as requiring the
   * Owner token to be valid.
   *
   * Current Features which could return from this:
   * - Autopublish
   * - Preview Grid
   * - Linkin.bio
   * - Analytics
   */
  get featuresBrokenByProfileFacebookTokens(): TrackedArray<FeatureRequirement> {
    let missingFeatures: FeatureRequirement[] = [];

    if (this.hasBasicDisplayErrors) {
      missingFeatures = filterList.filter((feature) => feature.requiresBasicToken === true);
    }

    if (this.invalidProfileFacebookTokens) {
      missingFeatures = [...missingFeatures, ...filterList.filter((feature) => feature.requiresOwnerToken === true)];
    }

    return new TrackedArray(missingFeatures);
  }

  /**
   * Returns a list of the features (from the reference Util) are listed as requiring the
   * User token to be valid.
   *
   * Current Features which could return from this:
   * - Conversations
   * - Collect (Hashtags)
   * - Collect (Tags)>
   * - Collect (Mentions)
   */
  get featuresBrokenByUserFacebookToken(): TrackedArray<FeatureRequirement> {
    const features = this.invalidUserFacebookToken
      ? filterList.filter((feature) => feature.requiresUserToken === true)
      : [];

    return new TrackedArray<FeatureRequirement>(features);
  }

  /**
   * Returns a list of the features (from the reference Util) that our social profiles are lacking the required
   * scopes to function (Caused by the account owner not giving us all of the required permissions within facebook)
   *
   * Current Features which could return from this:
   * - Conversations
   * - Collect (Hashtags)
   * - Collect (Tags)
   * - Collect (Mentions)
   * - Autopublish
   * - Preview Grid
   * - Linkin.bio
   * - Analytics
   */
  get featuresBrokenByMissingScopes(): TrackedArray<FeatureRequirement> {
    const missingScopes = filterList.filter((feature: FeatureRequirement) =>
      feature.AccountOwnerRequirements.every((scope: string) =>
        this.activeProfiles.any((profile: SocialProfileModel) => !profile.additionalPermissionScope?.includes(scope))
      )
    );

    return new TrackedArray(missingScopes);
  }

  /**
   * Returns a list of the features (from the reference Util) are listed as requiring the
   * User token to be valid.
   *
   * Current Features which could return from this:
   * - Conversations
   * - Collect (Hashtags)
   * - Collect (Tags)
   * - Collect (Mentions)
   */
  get missingFeatures(): TrackedArray<FeatureRequirement> {
    if (!this.invalidUserFacebookToken && !this.invalidProfileFacebookTokens) {
      return this.featuresBrokenByMissingScopes;
    }
    return new TrackedArray([
      ...new Set([...this.featuresBrokenByProfileFacebookTokens, ...this.featuresBrokenByUserFacebookToken])
    ]);
  }

  /**
   * Return a true or false if the current combination of user and social profile are unable to
   * function for the current user. To be used within templates to hide or show both the simple
   * and advanced credential status.
   */
  get hasMissingFeatures(): boolean {
    return !isEmpty(this.missingFeatures);
  }

  /**
   * Return true if the current user's credential status has expired and it's been exactly N days
   * since the last time they closed credential status modal. Maximum days length is 30.
   */
  get shouldShowDisplayModal(): boolean {
    const uid = this.currentUserModel?.id;
    const today = timestamp();
    const curTimestamp = moment.unix(today);
    let daysSinceLastClose;

    const prevDisplayDate = this.localStorageManager.getItem(`prevDisplayDate_${uid}`);
    if (typeof prevDisplayDate === 'number') {
      const lastCloseTime = moment.unix(prevDisplayDate);
      daysSinceLastClose = Math.floor(curTimestamp.diff(lastCloseTime, 'days'));
    }

    const prevCredentialDisplayDate = this.localStorageManager.getItem(`nextCredentialDisplayDate_${uid}`);
    if (typeof prevCredentialDisplayDate === 'number') {
      this.#incrementNextCredentialDisplayDate(prevCredentialDisplayDate);
    } else {
      this.localStorageManager.setItem(`nextCredentialDisplayDate_${uid}`, 0, this.MAX_EXPIRY_TIME_IN_HOURS);
    }

    if (!this.completedOnboarding || this.auth.currentFirstDay) {
      return false;
    } else if (
      typeof daysSinceLastClose === 'number' &&
      typeof prevCredentialDisplayDate === 'number' &&
      (daysSinceLastClose > 30 || daysSinceLastClose < prevCredentialDisplayDate)
    ) {
      return false;
    }
    return true;
  }

  /**
   * Check if there are still broken profiles after one refresh. If true, should show credential refresh again
   * with same day counts as last time. If no broken profiles, clean up local storage.
   */
  checkBrokenProfiles(): void {
    const uid = this.currentUserModel?.id;
    if (this.brokenProfiles.length > 1) {
      this.localStorageManager.setItem(`prevDisplayDate_${uid}`, null, this.MAX_EXPIRY_TIME_IN_HOURS);
    } else {
      this.localStorageManager.removeItem(`nextCredentialDisplayDate_${uid}`);
      this.localStorageManager.removeItem(`prevDisplayDate_${uid}`);
    }
  }

  initState(socialProfiles: TrackedArray<SocialProfileModel> | SocialProfileModel): void {
    this.activeProfiles = new TrackedArray<SocialProfileModel>();
    if (Array.isArray(socialProfiles)) {
      socialProfiles.forEach((profile: SocialProfileModel) => {
        this.activeProfiles.pushObject(profile);
      });
    } else {
      this.activeProfiles.pushObject(socialProfiles);
    }
  }

  async showReconnectSocialProfileModal(socialProfile: SocialProfileModel): Promise<void> {
    if (socialProfile.needsBasicDisplayTokenRefresh) {
      try {
        await this.instagram.refreshInstagramToken(socialProfile);
      } catch (error) {
        this.errors.show(error, { socialProfileId: socialProfile?.id }, ErrorSeverity.Info);
      }
      return;
    }

    this.isReconnectSocialProfileModalVisible = true;
    this.socialProfileToReconnect = socialProfile;
  }

  closeReconnectSocialProfileModal(): void {
    this.isReconnectSocialProfileModalVisible = false;
    this.socialProfileToReconnect = undefined;
  }

  @dropTask
  *connectBusinessAccount(socialProfile: SocialProfileModel, page: string): TaskGenerator<any> {
    try {
      return yield this.instagramBusiness.refreshBusinessAccountEndpoint(socialProfile, page);
    } catch (error) {
      if (error instanceof CredentialsNotFoundError) {
        error.resolve.call(this, socialProfile);
      } else {
        this.errors.log(error);
        throw error;
      }
    }
  }

  #incrementNextCredentialDisplayDate(prevCredentialDisplayDate: number): void {
    this.nextCredentialDisplayDate = prevCredentialDisplayDate + 1;
  }
}

declare module '@ember/service' {
  interface Registry {
    'credential-status': CredentialStatusService;
  }
}
