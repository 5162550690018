import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import moment from 'moment';

import { FetchableRecordTypes, FetchGramVersionTypes } from 'later/services/schedule/fetch-within-dates';
import { getDateRange, convertToUnixMilliseconds } from 'later/utils/context-nav';
import isWithinDates from 'later/utils/is-within-dates';

import type RouterService from '@ember/routing/router-service';
import type StoreService from '@ember-data/store';
import type { TaskGenerator } from 'ember-concurrency';
import type GramModel from 'later/models/gram';
import type MediaItemModel from 'later/models/media-item';
import type SocialProfileModel from 'later/models/social-profile';
import type ErrorsService from 'later/services/errors';
import type MediaLibraryService from 'later/services/media-library';
import type FetchWithinDatesService from 'later/services/schedule/fetch-within-dates';
import type SelectedSocialProfilesService from 'later/services/selected-social-profiles';
import type UserConfigService from 'later/services/user-config';
import type { ContextNavDateInterval } from 'later/utils/context-nav';
import type { UntypedService } from 'shared/types';

export default class DraftsController extends Controller {
  @service declare errors: ErrorsService;
  @service declare mediaLibrary: MediaLibraryService;
  @service declare router: RouterService;
  @service declare schedule: UntypedService;
  @service declare selectedSocialProfiles: SelectedSocialProfilesService;
  @service declare store: StoreService;
  @service declare userConfig: UserConfigService;
  @service('schedule/fetch-within-dates') declare fetchWithinDates: FetchWithinDatesService;

  @tracked target_date_interval?: ContextNavDateInterval;

  queryParams = ['target_date_interval'];

  get draftPostsFromStore(): GramModel[] {
    return this.store.peekAll('gram').filter((post) => post.isDraft);
  }

  get currentDateRange(): [moment.Moment, moment.Moment] {
    return getDateRange(this.targetDate, this.target_date_interval, this.timezone);
  }

  get selectedProfiles(): SocialProfileModel[] {
    return this.selectedSocialProfiles.profiles;
  }

  get hasPosts(): boolean {
    return this.posts.length > 0;
  }

  get posts(): GramModel[] {
    const [start, end] = this.currentDateRange;
    return this.#filterPosts(this.draftPostsFromStore, start.toDate(), end.toDate());
  }

  get selectedAssets(): MediaItemModel[] {
    return this.mediaLibrary.selectedMedia;
  }

  get targetDate(): number {
    const { targetDate } = this.router.currentRoute.queryParams;
    return targetDate ? Number(targetDate) : Date.now();
  }

  get timezone(): string {
    return this.userConfig.timeZoneIdentifier;
  }

  get isDragging(): boolean {
    return this.schedule.isDragging;
  }

  get isLoading(): boolean {
    return taskFor(this.fetchWithinDates.fetchRecords).isRunning;
  }

  // Note: Return a post scheduling time based on the current 'targetDate'
  // and provide this to the post service in the same format that fullcalendar would
  get postTimeFromCurrentDate(): number {
    return Number(this.targetDate) / 1000;
  }

  @action
  onChangeTargetDate(targetDateMoment: moment.Moment, targetDateInterval: ContextNavDateInterval): void {
    this.router.replaceWith(this.router.currentRouteName, {
      queryParams: {
        ...this.router.currentRoute.queryParams,
        targetDate: convertToUnixMilliseconds(targetDateMoment),
        targetDateInterval
      }
    });
  }

  @action
  onEditPost(post: GramModel): void {
    this.#transitionToEdit(post.id);
  }

  @action
  onDrop(dragEvent: DragEvent): void {
    const itemId = this.#getItemIdFromDragEvent(dragEvent);
    return this.#transitionToNew(itemId);
  }

  #getItemIdFromDragEvent(dragEvent: DragEvent): string {
    const { target, dataTransfer } = dragEvent;

    if (!target || !dataTransfer) {
      this.errors.log('Invalid `DragEvent` provided');
      return '';
    }

    const draggedElement = target as HTMLElement;
    const droppedItemIds = dataTransfer.getData('text');

    if (draggedElement.classList.contains('e--post__new') || draggedElement.classList.contains('e--post__scheduled')) {
      this.errors.log('Unsupported element dropped - only posts in sidebar allowed');
      return '';
    }

    return droppedItemIds;
  }

  // Note: Filters draft posts and only return posts that fall within expected dates, belong
  // to expected social profiles, and are not new
  #filterPosts(draftPosts: GramModel[], start: Date, end: Date): GramModel[] {
    const selectedProfileIds = this.selectedSocialProfiles.profileIds;
    return draftPosts.filter((post: GramModel) => {
      if (post.get('isNew')) {
        return false;
      }

      if (post.startTime) {
        const endTime = moment(post.startTime).add(30, 'minutes');
        const socialProfile = post.get('socialProfile');
        return (
          socialProfile &&
          isWithinDates(post.startTime.toDate(), endTime.toDate(), { start, end }) &&
          selectedProfileIds.includes(socialProfile.get('id') || '')
        );
      }
      return false;
    });
  }

  /**
   * Get the current route name without a `.index` suffix (if present).
   * @returns Current route name
   */
  #getCurrentRouteName(): string {
    return this.router.currentRouteName.replace(/\.index$/, '');
  }

  #transitionToEdit(postId: string): void {
    const redirectName = this.#getCurrentRouteName();
    this.router.transitionTo('cluster.schedule.drafts.post.edit', postId, {
      queryParams: { backTo: redirectName }
    });
  }

  #transitionToNew(mediaItemIds: string): void {
    const redirectName = this.#getCurrentRouteName();
    const isMultiProfile = this.selectedProfiles.length > 1;

    if (isMultiProfile) {
      this.router.transitionTo('cluster.schedule.drafts.post.multi', mediaItemIds, {
        queryParams: {
          draft: true,
          backTo: redirectName,
          targetDate: this.targetDate,
          scheduledTime: this.postTimeFromCurrentDate
        }
      });

      return;
    }

    this.router.transitionTo('cluster.schedule.drafts.post.new', mediaItemIds, {
      queryParams: {
        draft: true,
        backTo: redirectName,
        targetDate: this.targetDate,
        scheduledTime: this.postTimeFromCurrentDate
      }
    });
  }

  @task
  *fetchPosts(): TaskGenerator<void> {
    const [startDate, endDate] = this.currentDateRange;
    const start = startDate.toDate();
    const end = endDate.toDate();
    yield taskFor(this.fetchWithinDates.fetchRecords).perform(this.draftPostsFromStore, FetchableRecordTypes.Posts, {
      start,
      end,
      versionType: FetchGramVersionTypes.Draft
    });
    yield taskFor(this.fetchWithinDates.prefetch).perform(start, FetchableRecordTypes.Posts);
  }
}
