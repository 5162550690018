import { reads } from '@ember/object/computed';
import Service, { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { enqueueTask } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';

import type { TaskGenerator } from 'ember-concurrency';
import type SocialProfileModel from 'later/models/social-profile';
import type AnalyticsService from 'later/services/analytics';
import type HelpersAnalyticsService from 'later/services/analytics/helpers-analytics';
import type KeenApiService from 'later/services/analytics/keen-api';
import type ErrorsService from 'later/services/errors';
import type { Moment } from 'moment';
import type { BinnedTimeseriesDataPoint, TimeseriesDataPoint } from 'shared/types/analytics-data';
import type {
  LIBBlockClicksOverTime,
  LIBClicksOverTime,
  GenericClicksParams,
  MediaItemWithLIBData
} from 'shared/types/analytics-data/keen';

/**
 * @class AnalyticsKeenService
 * @extends Service
 */
export default class KeenAnalyticsService extends Service {
  @service declare analytics: AnalyticsService;
  @service declare errors: ErrorsService;
  @service('analytics/helpers-analytics') declare helpersAnalytics: HelpersAnalyticsService;
  @service('analytics/keen-api') declare keenApi: KeenApiService;

  /**
   * Default data point for Short Link clicks
   */
  defaultClickPoint: BinnedTimeseriesDataPoint = {
    sampled_at: undefined,
    // Note: Highcharts silently fails for undefined values (null doesn't fail) for line charts
    // eslint-disable-next-line unicorn/no-null
    count: null
  };

  /**
   * Default data point for page views
   */
  defaultPageViewPoint: TimeseriesDataPoint = {
    time: undefined,
    // Note: Highcharts silently fails for undefined values (null doesn't fail) for line charts
    // eslint-disable-next-line unicorn/no-null
    value: null
  };

  /**
   * Default data point for block clicks
   */
  defaultBlockClickPoint: LIBBlockClicksOverTime = {
    time: undefined,
    // Note: Highcharts silently fails for undefined values (null doesn't fail) for line charts
    // eslint-disable-next-line unicorn/no-null
    value: null
  };

  /**
   * Default data point for button clicks
   */
  defaultButtonClickPoint: TimeseriesDataPoint = {
    time: undefined,
    // Note: Highcharts silently fails for undefined values (null doesn't fail) for line charts
    // eslint-disable-next-line unicorn/no-null
    value: null
  };

  /**
   * Default data point for lib clicks over time
   */
  defaultClickOverTimePoint: LIBClicksOverTime = {
    time: undefined,
    // Note: Highcharts silently fails for undefined values (null doesn't fail) for line charts
    // eslint-disable-next-line unicorn/no-null
    value: null
  };

  @reads('analytics.socialProfile') declare socialProfile: SocialProfileModel;

  get linkinbioPageId(): string {
    const id = this.analytics.currentLinkinbioPage?.get('id');
    if (!id) {
      this.errors.log(new Error('No linkinbio page id found'));
      return '';
    }
    return id;
  }
  /**
   * Default start date for data calls in this service
   */
  get startDate(): Moment {
    return this.helpersAnalytics.createMomentInTz().subtract(3, 'months').subtract(1, 'day');
  }

  /**
   * Default end date for data calls in this service
   */
  get endDate(): Moment {
    return this.helpersAnalytics.createMomentInTz();
  }

  /**
   * Returns Linkin.bio page views over time.
   */
  @enqueueTask
  *getLibPageViewsOverTime(
    startDate = this.startDate,
    endDate = this.endDate,
    linkinbioPageId = this.linkinbioPageId,
    forceRefresh = false
  ): TaskGenerator<TimeseriesDataPoint[]> {
    const pageViews: TimeseriesDataPoint[] = yield taskFor(this.keenApi.getLibPageViewsOverTime)
      .linked()
      .perform(linkinbioPageId, forceRefresh);

    return this.helpersAnalytics.fillData(
      startDate.unix(),
      endDate.clone().endOf('day').unix(),
      pageViews,
      this.defaultPageViewPoint,
      'time'
    );
  }

  /**
   * Returns Linkin.bio button clicks daily.
   */
  @enqueueTask
  *getDailyButtonClicks({
    datasetName,
    linkinbioPageId,
    startDate = this.startDate,
    endDate = this.endDate,
    forceRefresh = false
  }: GenericClicksParams): TaskGenerator<LIBBlockClicksOverTime[]> {
    const dailyButtonClicks: LIBBlockClicksOverTime[] = yield taskFor(this.keenApi.getDailyButtonClicks)
      .linked()
      .perform({ datasetName, linkinbioPageId, startDate, endDate, forceRefresh });

    return this.helpersAnalytics.fillData(
      startDate.unix(),
      endDate.clone().endOf('day').unix(),
      dailyButtonClicks,
      this.defaultBlockClickPoint,
      'time'
    );
  }

  /**
   * Returns Linkin.bio button group clicks daily.
   */
  @enqueueTask
  *getDailyButtonGroupClicks({
    datasetName,
    linkinbioPageId,
    startDate = this.startDate,
    endDate = this.endDate,
    forceRefresh = false
  }: GenericClicksParams): TaskGenerator<LIBBlockClicksOverTime[]> {
    const libDailyButtonGroupClicks: LIBBlockClicksOverTime[] = yield taskFor(this.keenApi.getDailyButtonGroupClicks)
      .linked()
      .perform({
        datasetName,
        linkinbioPageId,
        startDate,
        endDate,
        forceRefresh
      });

    return this.helpersAnalytics.fillData(
      startDate.unix(),
      endDate.clone().endOf('day').unix(),
      libDailyButtonGroupClicks,
      this.defaultBlockClickPoint,
      'time'
    );
  }

  /**
   * Returns Linkin.bio App Block clicks daily.
   */
  @enqueueTask
  *getDailyAppClicks({
    datasetName,
    linkinbioPageId,
    startDate = this.startDate,
    endDate = this.endDate,
    forceRefresh = false
  }: GenericClicksParams): TaskGenerator<LIBBlockClicksOverTime[]> {
    const dailyButtonClicks: LIBBlockClicksOverTime[] = yield taskFor(this.keenApi.getDailyAppClicks)
      .linked()
      .perform({ datasetName, linkinbioPageId, startDate, endDate, forceRefresh });

    return this.helpersAnalytics.fillData(
      startDate.unix(),
      endDate.clone().endOf('day').unix(),
      dailyButtonClicks,
      this.defaultBlockClickPoint,
      'time'
    );
  }

  /**
   * Returns Linkin.bio Featured Banner Block clicks daily.
   */
  @enqueueTask
  *getDailyFeaturedBannerClicks({
    datasetName,
    linkinbioPageId,
    startDate = this.startDate,
    endDate = this.endDate,
    forceRefresh = false
  }: GenericClicksParams): TaskGenerator<LIBBlockClicksOverTime[]> {
    const dailyFeaturedBannerClicks: LIBBlockClicksOverTime[] = yield taskFor(this.keenApi.getDailyFeaturedBannerClicks)
      .linked()
      .perform({ datasetName, linkinbioPageId, startDate, endDate, forceRefresh });

    return this.helpersAnalytics.fillData(
      startDate.unix(),
      endDate.clone().endOf('day').unix(),
      dailyFeaturedBannerClicks,
      this.defaultBlockClickPoint,
      'time'
    );
  }

  /**
   * Returns Linkin.bio featured media daily.
   */
  @enqueueTask
  *getDailyCustomFeaturedMediaClicks({
    datasetName,
    linkinbioPageId,
    startDate = this.startDate,
    endDate = this.endDate,
    forceRefresh = false
  }: GenericClicksParams): TaskGenerator<LIBBlockClicksOverTime[]> {
    const libDailyCustomFeaturedMediaClicks: LIBBlockClicksOverTime[] = yield taskFor(
      this.keenApi.getDailyCustomFeaturedMediaClicks
    )
      .linked()
      .perform({
        datasetName,
        linkinbioPageId,
        startDate,
        endDate,
        forceRefresh
      });

    return this.helpersAnalytics.fillData(
      startDate.unix(),
      endDate.clone().endOf('day').unix(),
      libDailyCustomFeaturedMediaClicks,
      this.defaultBlockClickPoint,
      'time'
    );
  }

  /**
   * Returns Linkin.bio Tiktok post clicks daily.
   */
  @enqueueTask
  *getDailyTiktokPostClicks({
    datasetName,
    socialProfile,
    linkinbioPostId,
    startDate = this.startDate,
    endDate = this.endDate,
    forceRefresh = false
  }: GenericClicksParams & {
    linkinbioPostId: string;
  }): TaskGenerator<LIBClicksOverTime[]> {
    const dailyTiktokPostClicks: LIBClicksOverTime[] = yield taskFor(this.keenApi.getDailyTiktokPostClicks)
      .linked()
      .perform({
        datasetName,
        socialProfile,
        linkinbioPostId,
        startDate,
        endDate,
        forceRefresh
      });

    return this.helpersAnalytics.fillData(
      startDate.unix(),
      endDate.clone().endOf('day').unix(),
      dailyTiktokPostClicks,
      this.defaultClickOverTimePoint,
      'time'
    );
  }

  /**
   * Returns Linkin.bio social link clicks daily.
   */
  @enqueueTask
  *getDailySocialLinkClicks({
    datasetName,
    linkinbioPageId,
    startDate = this.startDate,
    endDate = this.endDate,
    forceRefresh = false
  }: GenericClicksParams): TaskGenerator<LIBBlockClicksOverTime[]> {
    const dailySocialLinkClicks: LIBBlockClicksOverTime[] = yield taskFor(this.keenApi.getDailySocialLinkClicks)
      .linked()
      .perform({ datasetName, linkinbioPageId, startDate, endDate, forceRefresh });

    return this.helpersAnalytics.fillData(
      startDate.unix(),
      endDate.clone().endOf('day').unix(),
      dailySocialLinkClicks,
      this.defaultBlockClickPoint,
      'time'
    );
  }

  /**
   * Returns Linkin.bio button clicks over time.
   */
  @enqueueTask
  *getLibButtonClicksOverTime(
    startDate = this.startDate,
    endDate = this.endDate,
    linkinbioPageId = this.linkinbioPageId,
    forceRefresh = false
  ): TaskGenerator<TimeseriesDataPoint[]> {
    const pageViews: TimeseriesDataPoint[] = yield taskFor(this.keenApi.getLibButtonClicksOverTime)
      .linked()
      .perform(linkinbioPageId, forceRefresh);

    return this.helpersAnalytics.fillData(
      startDate.unix(),
      endDate.clone().endOf('day').unix(),
      pageViews,
      this.defaultButtonClickPoint,
      'time'
    );
  }

  /**
   * Returns Linkin.bio click through percentage over time.
   */
  @enqueueTask
  *getLibClickThroughPercentageOverTime(
    linkinbioPageId: string,
    forceRefresh = false
  ): TaskGenerator<(TimeseriesDataPoint | TimeseriesDataPoint)[]> {
    const libPageViewsOverTime: TimeseriesDataPoint[] = yield taskFor(this.getLibPageViewsOverTime).perform(
      this.startDate,
      this.endDate,
      linkinbioPageId,
      forceRefresh
    );
    const libClicksOverTime: TimeseriesDataPoint[] = yield taskFor(this.getLibClicksOverTime).perform(
      this.startDate,
      this.endDate,
      linkinbioPageId,
      forceRefresh
    );
    const libButtonClicksOverTime: TimeseriesDataPoint[] = yield taskFor(this.getLibButtonClicksOverTime).perform(
      this.startDate,
      this.endDate,
      linkinbioPageId,
      forceRefresh
    );

    return libClicksOverTime?.map(({ time, value: clickCount }, index) => {
      const buttonClicks = libButtonClicksOverTime?.[index]?.value;
      const pageViews = libPageViewsOverTime?.[index]?.value;

      const isClicksMissing = isEmpty(clickCount) && isEmpty(buttonClicks);
      if (isEmpty(pageViews) || isClicksMissing) {
        return { time, value: null };
      }

      if (pageViews === 0 || !pageViews) {
        return { time, value: 0 };
      }

      const totalClicks = (clickCount ?? 0) + (buttonClicks ?? 0);
      return { time, value: Number((totalClicks / pageViews) * 100) };
    });
  }

  /**
   * Returns Linkin.bio clicks count over time for the given media item.
   */
  @enqueueTask
  *getLibLifespanClicksOverTime(
    mediaItem: MediaItemWithLIBData,
    forceRefresh = false
  ): TaskGenerator<BinnedTimeseriesDataPoint[] | undefined> {
    if (!mediaItem) {
      return;
    }

    const clicksOverTime: BinnedTimeseriesDataPoint[] = yield taskFor(this.keenApi.getLibLifespanClicksOverTime)
      .linked()
      .perform(mediaItem, forceRefresh);
    const startDate = this.helpersAnalytics.createMomentInTz(mediaItem.createdTime);
    const endDate = startDate.clone().add(2, 'weeks');
    return this.helpersAnalytics.fillData(
      startDate.unix(),
      endDate.unix(),
      clicksOverTime,
      this.defaultClickPoint,
      'sampled_at'
    );
  }

  /**
   * Returns Linkin.bio page clicks count over time for the given media item.
   */
  @enqueueTask
  *getLibPageClicksOverTime(
    mediaItem: MediaItemWithLIBData,
    forceRefresh = false
  ): TaskGenerator<BinnedTimeseriesDataPoint[] | undefined> {
    if (!mediaItem) {
      return;
    }

    const clicksOverTime: BinnedTimeseriesDataPoint[] = yield taskFor(this.keenApi.getLibPageClicksOverTime)
      .linked()
      .perform(mediaItem, forceRefresh);
    const startDate = this.helpersAnalytics.createMomentInTz(mediaItem.createdTime);
    const endDate = startDate.clone().add(2, 'weeks');

    return this.helpersAnalytics.fillData(
      startDate.unix(),
      endDate.unix(),
      clicksOverTime,
      this.defaultClickPoint,
      'sampled_at'
    );
  }

  /**
   * Returns Linkin.bio embedded clicks count over time for the given media item.
   */
  @enqueueTask
  *getLibEmbeddedClicksOverTime(
    mediaItem: MediaItemWithLIBData,
    forceRefresh = false
  ): TaskGenerator<BinnedTimeseriesDataPoint[] | undefined> {
    if (!mediaItem) {
      return;
    }

    const clicksOverTime: BinnedTimeseriesDataPoint[] = yield taskFor(this.keenApi.getLibEmbeddedClicksOverTime)
      .linked()
      .perform(mediaItem, forceRefresh);
    const startDate = this.helpersAnalytics.createMomentInTz(mediaItem.createdTime);
    const endDate = startDate.clone().add(2, 'weeks');

    return this.helpersAnalytics.fillData(
      startDate.unix(),
      endDate.unix(),
      clicksOverTime,
      this.defaultClickPoint,
      'sampled_at'
    );
  }

  /**
   * Returns Linkin.bio clicks count over time.
   */
  @enqueueTask
  *getLibClicksOverTime(
    startDate = this.startDate,
    endDate = this.endDate,
    linkinbioPageId = this.linkinbioPageId,
    forceRefresh = false
  ): TaskGenerator<LIBClicksOverTime[]> {
    const clicksOverTime: LIBClicksOverTime[] = yield taskFor(this.keenApi.getLibClicksOverTimeDailyTotals)
      .linked()
      .perform(linkinbioPageId, forceRefresh);

    if (isEmpty(clicksOverTime)) {
      return clicksOverTime;
    }

    return this.helpersAnalytics.fillData(
      startDate.unix(),
      endDate.clone().add(1, 'day').unix(),
      clicksOverTime,
      this.defaultClickOverTimePoint,
      'time'
    );
  }

  /**
   * Returns Short Link clicks count over time of the given media item
   * Note: endpoint is not cached, so is forceRefresh true by default
   */
  @enqueueTask
  *getShortLinkClicksOverTime(
    mediaItem: MediaItemWithLIBData,
    forceRefresh = false
  ): TaskGenerator<BinnedTimeseriesDataPoint[]> {
    const clicksOverTime: BinnedTimeseriesDataPoint[] = yield taskFor(this.keenApi.getShortLinkClicksOverTime)
      .linked()
      .perform(mediaItem, forceRefresh);

    if (!isEmpty(clicksOverTime)) {
      const start = this.helpersAnalytics.createMomentInTz(mediaItem.createdTime);
      const end = start.clone().add(24, 'hours');
      const secondsBetweenPoints = 3600;

      return this.helpersAnalytics.fillData(
        start.unix(),
        end.unix(),
        clicksOverTime,
        this.defaultClickPoint,
        'sampled_at',
        secondsBetweenPoints
      );
    }
    return clicksOverTime;
  }

  /**
   * Returns linkinbio page views sorted by country for analytics standard users.
   */
  @enqueueTask
  *getLibPageViewsByCountry(
    linkinbioPageId = this.linkinbioPageId,
    forceRefresh = false
  ): TaskGenerator<Record<string, number>> {
    return yield taskFor(this.keenApi.getLibPageViewsByCountry).linked().perform(linkinbioPageId, forceRefresh);
  }

  /**
   * Returns linkinbio page views sorted by city for analytics standard users.
   */
  @enqueueTask
  *getLibPageViewsByCity(
    linkinbioPageId = this.linkinbioPageId,
    forceRefresh = false
  ): TaskGenerator<Record<string, number>> {
    return yield taskFor(this.keenApi.getLibPageViewsByCity).linked().perform(linkinbioPageId, forceRefresh);
  }

  /**
   * Returns the total media clicks in the last 4 months.
   */
  @enqueueTask
  *getMediaTotalClicks(
    startDate = this.startDate,
    endDate = this.endDate,
    forceRefresh = false
  ): TaskGenerator<Record<string, number>> {
    return yield taskFor(this.keenApi.getMediaTotalClicks).perform(startDate, endDate, forceRefresh);
  }

  generateDefaultLibClicksPoint(ids: [string, number][]): LIBClicksOverTime {
    return {
      time: undefined,
      value: ids.map((id) => ({
        'media.id': id[0],
        'linkinbio_post.id': id[1],
        result: undefined
      }))
    };
  }
}

declare module '@ember/service' {
  interface Registry {
    'analytics/keen-analytics': KeenAnalyticsService;
  }
}
